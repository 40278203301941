import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  find,
  get,
  uniqueId,
} from 'lodash';
import {
  API,
  ExternalIdHelper,
  FieldGroupContainer,
  ToastActions,
  VibeModal,
} from 'vibeguide';
import ProfileExternalIDRow from './ProfileExternalDRow';
import './ProfileExternalIds.scss';

function ProfileExternalIds({
  className,
  style,
  externalIds,
  locationId,
  setHasChanges,
  setNewExternalIDs,
  newExternalIDs,
  onUpdate,
  updateCurrentLocation,
  user,
  queueToast,
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [extIdToDelete, setExtIdToDelete] = useState({});

  const canManageExternalIds = user.can('location.manage_external_ids');

  const sourceList = [
    {
      label: '',
      value: '',
    },
    ...ExternalIdHelper.list,
  ];

  /**
   * Change an new External Source value
   */
  const onChangeNewExternalSource = (e) => {
    const {
      target: {
        value,
        dataset: {
          id,
        },
      },
    } = e;

    setNewExternalIDs(newExternalIDs.map((newExternalID) => {
      if (newExternalID._id === id) {
        newExternalID.source = value;
        return newExternalID;
      }

      return newExternalID;
    }));
    setHasChanges(true);
  };

  /**
   * Change an new External ID value
   */
  const onChangeNewId = (e) => {
    const {
      target: {
        value,
        dataset: {
          id,
        },
      },
    } = e;

    setNewExternalIDs(newExternalIDs.map((newExternalID) => {
      if (newExternalID._id === id) {
        newExternalID.extId = value;
        return newExternalID;
      }

      return newExternalID;
    }));
    setHasChanges(true);
  };

  /**
   * Add new External Source/ID field row
   */
  const onAddExternalIDRow = () => {
    setNewExternalIDs([
      ...newExternalIDs,
      {
        _id: uniqueId('external-id-'),
        source: '',
        extId: '',
      },
    ]);
    setHasChanges(true);
  };

  /**
   * Delete a current or new External Source/ID field pair
   */
  const onRemoveExternalId = (extId, source, isNew) => {
    if (isNew) {
      setNewExternalIDs(newExternalIDs.filter(id => id.extId !== extId && id.source !== source));
    } else {
      const externalId = find(externalIds, { source, extId });

      if (externalId) {
        setExtIdToDelete(externalId);
        setShowDeleteModal(true);
      }
    }
  };

  /**
   * Click Delete External ID in confirm modal
   */
  const onConfirmRemoveExternalId = async () => {
    const response = await API.Location.deleteExternalID({
      id: locationId,
      source: extIdToDelete.source,
      extid: extIdToDelete.extId,
    });

    const success = get(response, '[0].type') === 'LOCATION.EXTERNAL_ID_DELETED';

    if (success) {
      queueToast({
        type: 'success',
        title: 'External ID Deleted!',
        allowClose: true,
        delay: 500,
      });

      const locationObj = await API.Location.getById(locationId);
      onUpdate(locationObj);
      updateCurrentLocation(locationObj);
      setHasChanges(false);
      setShowDeleteModal(false);
    }
  };

  /**
   * Close Delete modal
   */
  const onCloseRemoveExternalId = () => {
    setShowDeleteModal(false);
  };

  return (
    <div
      className={classNames('ProfileExternalIds', className)}
      style={style}
    >
      <div className={classNames('edit-section')}>
        <FieldGroupContainer
          addRowLabel="Add External Source ID"
          allowAddRow={canManageExternalIds}
          onAddRow={onAddExternalIDRow}
        >
          {externalIds.map((item, i) => {
            if (item.source === 'vibenomics') {
              return (
                <ProfileExternalIDRow
                  key={item._id}
                  id={item._id}
                  source={item.source}
                  extId={item.extId}
                  disableInput
                  showLabels={i === 0}
                  alignColumns={canManageExternalIds}
                  onRemove={onRemoveExternalId}
                />
              );
            }

            return (
              <ProfileExternalIDRow
                key={item._id}
                id={item._id}
                source={item.source}
                extId={item.extId}
                showLabels={i === 0}
                allowRemove={canManageExternalIds}
                disableInput
                onRemove={onRemoveExternalId}
              />
            );
          })}

          {canManageExternalIds && newExternalIDs.map((item, i) => (
            <ProfileExternalIDRow
              key={item._id}
              id={item._id}
              source={item.source}
              extId={item.extId}
              disableInput
              isNew
              options={sourceList}
              allowRemove={canManageExternalIds}
              onChangeSource={onChangeNewExternalSource}
              onChangeId={onChangeNewId}
              onRemove={onRemoveExternalId}
              dataId={i.toString()}
            />
          ))}
        </FieldGroupContainer>
      </div>

      <VibeModal
        show={showDeleteModal}
        type="confirm"
        confirmProps={{
          text: 'Delete',
          color: 'error',
        }}
        cancelProps={{
          text: 'Cancel',
        }}
        title="You are deleting an External Source ID"
        text={(
          <div>
            Deleting will remove its reference to this system.
          </div>
        )}
        onConfirm={onConfirmRemoveExternalId}
        onClose={onCloseRemoveExternalId}
      />
    </div>
  );
}

ProfileExternalIds.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  externalIds: PropTypes.arrayOf(PropTypes.object),
  locationId: PropTypes.string,
  setHasChanges: PropTypes.func.isRequired,
  setNewExternalIDs: PropTypes.func.isRequired,
  newExternalIDs: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func.isRequired,
  updateCurrentLocation: PropTypes.func.isRequired,
};

ProfileExternalIds.defaultProps = {
  className: '',
  style: {},
  externalIds: [],
  locationId: '',
  newExternalIDs: [],
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileExternalIds);
