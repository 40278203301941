import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEmpty,
} from 'lodash';
import Popper from '@mui/material/Popper';
import API from '../../api';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeIcon from '../VibeIcon/VibeIcon';
import viCloseCircle from '../../icons/viCloseCircle';
import defaultImage from '../../assets/ism-avatar.png';
import color from '../../sass/color.scss';
import './CompanySelector.scss';

// fetch items when the field is focused once
// let fetched = false;
// does the selector have a current selected value
let hasSelectedItem = false;
let onBlurTimeout = null;

function CompanySelector({
  user,
  name,
  value,
  id,
  partnerId,
  style,
  disabled,
  tabIndex,
  autoFocus,
  multipleValues,
  onClick,
  onChange,
  onBlur,
  onFocus,
}) {
  const inputRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);

  // this field has multiple values
  const values = id.split(',');
  const hasMultipleValues = multipleValues && values.length > 1;

  // is there a selected item (i.e. dragging an event onto a schedule sets company)
  hasSelectedItem = !isEmpty(id);

  /**
   * Send onChange to the parent component
   * Append the field name and if the value was changed
   * Store if the selector has a current selected item
   */
  const onChangeFieldData = ({
    id: itemId,
    value: fieldValue,
    changed = true,
  }) => {
    onChange({
      name,
      id: itemId,
      value: fieldValue,
      changed,
    });

    hasSelectedItem = !isEmpty(itemId);
  };

  /**
   * Fetch all the items
   */
  const getAllItems = async () => {
    let getCompaniesRoute = API.User.getCompanies;

    const routeParameters = {
      _id: user._id,
      pageSize: -1,
    };

    if (user.accountType === 'partner') {
      getCompaniesRoute = API.PartnerUser.getCompanies;
    } else if (partnerId) {
      getCompaniesRoute = API.Partner.getCompanies;
      routeParameters.filters = { partnerId };
    }

    const allItems = await getCompaniesRoute(routeParameters);

    setAllItems(allItems.data);
    setItems(allItems.data);

    return allItems.data;
  };

  /**
   * Fetch the details for each selected value
   */
  const getSelectedItems = async () => {
    const useAllItems = allItems.length <= 0
      ? await getAllItems()
      : allItems;

    const valueData = await Promise.all(values.map(async (itemId) => {
      const searchId = encodeURIComponent(itemId);

      // find the selected company ID from the list of all companies the user has access to
      const company = find(useAllItems, { _id: searchId }) || {
        _id: searchId,
        name: 'Unknown',
      };

      return Promise.resolve(company);
    }));

    setSelectedItems(valueData);

    if (valueData.length === 1) {
      // only one value selected, send back to the parent component
      const item = valueData[0];

      onChangeFieldData({
        id: item._id,
        value: item.name,
        // send so the parent component won't search off this change
        changed: false,
      });
    }
  };

  useEffect(() => {
    if (id) {
      getSelectedItems();
    }
  }, [id]);

  /**
   * Filter all items by a search query
   * Search by name
   */
  const filterItems = (search, useItems) => {
    if (!useItems) {
      useItems = allItems;
    }

    if (isEmpty(search)) {
      // search is empty, show all available items
      return useItems;
    }

    search = search.toLowerCase();
    return useItems.filter(item => get(item, 'name', '').toLowerCase().indexOf(search) >= 0);
  };

  /**
   * Open the autocomplete menu
   */
  const onOpen = (useItems) => {
    setItems(useItems);
    setSelectedIndex(0);
    setOpen(true);
    setVisible(true);
  };

  /**
   * Close the autocomplete menu
   */
  const onClose = () => {
    setVisible(false);

    // Ensure blur fires after selecting an item
    clearTimeout(onBlurTimeout);
    onBlurTimeout = setTimeout(() => {
      setOpen(false);
      setItems(allItems);
      setSelectedIndex(0);
    }, 500);
  };

  const onFocusField = async (e) => {
    const {
      target: {
        value = '',
      },
    } = e;

    const useAllItems = allItems.length <= 0
      ? await getAllItems()
      : allItems;

    const searchName = value.trim();
    const matchItems = filterItems(searchName, useAllItems);
    onOpen(matchItems);
    onFocus(e);
  };

  const onChangeField = (e) => {
    const {
      target: {
        value = '',
      },
    } = e;

    // an item must be selected from the dropdown list
    onChangeFieldData({
      id: '',
      value,
    });

    const searchName = value.trim();
    const matchItems = filterItems(searchName);
    onOpen(matchItems);
    autoCompleteRef.current.scroll(0, 0);
  };

  const onBlurField = (e) => {
    if (!hasSelectedItem && !isEmpty(value)) {
      // field does not have a selected value, clear any input text
      onChangeFieldData({
        id: '',
        value: '',
      });
    }

    onClose();
    onBlur(e);
  };

  const onKeyDownField = (e) => {
    if (e.key === 'ArrowDown') {
      const nextIndex = selectedIndex + 1;

      if (nextIndex > 2) {
        autoCompleteRef.current.scroll(0, (nextIndex * 40) - 80);
      }

      setSelectedIndex(nextIndex < items.length
        ? nextIndex
        : items.length - 1);
    } else if (e.key === 'ArrowUp') {
      const prevIndex = selectedIndex - 1;
      autoCompleteRef.current.scroll(0, (prevIndex * 40) - 80);

      setSelectedIndex(prevIndex >= 0
        ? prevIndex
        : 0);
    } else if (e.key === 'Enter') {
      const item = items[selectedIndex];

      if (!item) {
        // no item found to select
        return;
      }

      onChangeFieldData({
        id: item._id,
        value: item.name,
      });

      onOpen([]);
      inputRef.current.blur();
    }
  };

  const selectItem = (item) => {
    onChangeFieldData({
      id: item._id,
      value: item.name,
    });

    onClose();
  };

  // style the input box when the autocomplete menu is open
  const inputStyleMenuOpen = visible
    ? {
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }
    : {};

  return (
    <div
      className="CompanySelector"
      onClick={onClick}
    >
      <VibeTooltip
        title={hasMultipleValues ? (
          <div>
            {selectedItems.map((selectedItem) => (
              <div
                key={`selected-item-${selectedItem._id}`}
                style={{
                  padding: '4px 0',
                }}
              >
                {selectedItem.name}
              </div>
            ))}
          </div>
        ) : ''}
        placement="bottom"
        arrow
      >
        <div className="input-container">
          <input
            ref={inputRef}
            tabIndex={tabIndex}
            className="field-input item-input"
            style={{
              ...style,
              ...inputStyleMenuOpen,
            }}
            type="text"
            placeholder={!hasMultipleValues
              ? 'Search for a Company...'
              : ''}
            name={name}
            value={!hasMultipleValues
              ? value
              : ''}
            onFocus={onFocusField}
            onChange={onChangeField}
            onBlur={onBlurField}
            onKeyDown={onKeyDownField}
            autoComplete="off"
            autoFocus={autoFocus}
            disabled={disabled || hasMultipleValues}
          />

          {hasMultipleValues && (
            <div className="multiple-values-container">
              <div className="multiple-values">
                <VibeIcon
                  icon={viCloseCircle}
                  color={color.primary}
                  hoverColor={color.secondary}
                  size={16}
                  style={{
                    marginRight: 4,
                  }}
                  onClick={() => onChange({ target: { value: '' } })}
                />

                <div className="text">
                  {values.length} Search Values
                </div>
              </div>
            </div>
          )}
        </div>
      </VibeTooltip>

      <Popper
        className="CompanyPopper"
        anchorEl={inputRef.current}
        open={open}
        placement="bottom-start"
        style={{
          width: get(inputRef, 'current.offsetWidth', 350),
          zIndex: 1302,
          opacity: visible
            ? 1
            : 0,
        }}
      >
        <div
          ref={autoCompleteRef}
          className="item-select"
        >
          {items.map((item, index) => {
            return (
              <div
                key={item._id}
                className={classNames('item', { selected: selectedIndex === index })}
                data-id={item._id}
                data-name={item.name}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={selectItem.bind(this, item)}
              >
                <div className="item-content">
                  <img
                    className="icon"
                    src={item.imageUrl || defaultImage}
                    alt="Icon"
                  />

                  <div className="text">
                    {item.name}
                  </div>
                </div>
              </div>
            );
          })}

          {items.length <= 0 && (
            <div className="item disabled">
              No Companies found...
            </div>
          )}
        </div>
      </Popper>
    </div>
  );
}

CompanySelector.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  partnerId: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  /** Input tab index position */
  tabIndex: PropTypes.number,
  /** Auto focus on the input */
  autoFocus: PropTypes.bool,
  /** Allow multiple values for the field */
  multipleValues: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

CompanySelector.defaultProps = {
  name: '',
  value: '',
  id: '',
  partnerId: '',
  style: {},
  disabled: false,
  tabIndex: 0,
  autoFocus: false,
  multipleValues: false,
  onClick: () => {},
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(CompanySelector);
