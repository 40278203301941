import Http from '../Http';

const apiUrl = process.env.VAPI_BASE_URL;
const baseUrl = `${apiUrl}/lookuplists`;

export default {
  adDeliverySystems: {
    search: (data, options) => Http.post(`${baseUrl}/addeliverysystems/search`, data, options),
  },
  adNetworkOwners: {
    search: (data, options) => Http.post(`${baseUrl}/adnetworkowners/search`, data, options),
  },
  contentNetworkOwners: {
    search: (data, options) => Http.post(`${baseUrl}/contentnetworkowners/search`, data, options),
  },
  contentDeliverySystems: {
    search: (data, options) => Http.post(`${baseUrl}/contentdeliverysystems/search`, data, options),
  },
};
