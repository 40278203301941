/**
 * List of supported Segment Types
 * @param options object of options to build the list
 * @param options.includeEmpty boolean to include an empty name/value pair at the beginning
 */
export function list(options = {}) {
  const list = [
    {
      label: 'General',
      value: 'general',
      description: 'Used when the Host Network does not differentiate between Endemic and Non-Endemic ad types',
    },
    {
      label: 'Endemic',
      value: 'endemic',
      // eslint-disable-next-line max-len
      description: 'Refers to an advertisement for a good or service that the Host Network has in their store locations',
    },
    {
      label: 'Non Endemic',
      value: 'non-endemic',
      // eslint-disable-next-line max-len
      description: 'Refers to an advertisement for a good or service that the Host Network does not sell in their store locations',
    },
    {
      label: 'Adult Beverage',
      value: 'adult-beverage',
      description: 'Refers to an advertisement for beer, wine, spirits, and more',
    },
  ];

  if (options.includeEmpty === true) {
    list.unshift({
      label: '',
      value: '',
    });
  }

  return list;
}

export default {
  list,
};
