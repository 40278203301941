import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const apiUrl = process.env.VAPI_BASE_URL;
const baseUrl = `${apiUrl}/advertisers`;

export default {
  list: async (data, options) => {
    const response = await Http.post(`${baseUrl}/search`, data, options);

    return {
      ...response,
      data: response.data.map(item => {
        return {
          ...item,
          imageUrl: Http.cloudfront(item.imageUrl),
        };
      }),
    };
  },
  getById: async (id) => {
    const response = await Http.get(`${baseUrl}/${id}`);

    return {
      ...response,
      imageUrl: Http.cloudfront(response.imageUrl),
    };
  },
  create: data => Http.post(baseUrl, data),
  modify: (id, data) => Http.put(`${baseUrl}/${id}`, data),
  uploadImage: (id, image, onProgress) => Http.postFileUpload(
    `${baseUrl}/${id}/image`, image, 'image', onProgress,
  ),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
};
