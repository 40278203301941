import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import color from '../../sass/color.scss';

function SidePanelSection({
  className,
  style,
  title,
  required,
  children,
}) {
  return (
    <div
      className={classNames('SidePanelSection', className)}
      style={{
        marginBottom: 16,
        ...style,
      }}
    >
      {title && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            marginBottom: 16,
            fontSize: 14,
            fontWeight: 'bold',
          }}
        >
          {title}

          {required && (
            <div
              style={{
                width: 4,
                height: 4,
                backgroundColor: color.red,
                borderRadius: 2,
              }}
            />
          )}
        </div>
      )}

      {children}
    </div>
  );
}

SidePanelSection.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

SidePanelSection.defaultProps = {
  className: '',
  style: {},
  title: null,
  required: false,
};

export default SidePanelSection;
