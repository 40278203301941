import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  TimeUtil,
  VibeIcon,
  viMicrophone,
  color as vibeColor,
  transparent,
} from 'vibeguide';
import InterruptItemFullView from './FullView/InterruptItemFullView';
import './InterruptItem.scss';

function getStyle(interrupt, color, hover, fullView, availableToday) {
  // when hovering and has access to the current location
  if (hover && interrupt.accessCurrentLocation) {
    return {
      backgroundColor: transparent(color, 75),
    };
  }

  // when hovering and does not have access to the current location
  if (hover && !interrupt.accessCurrentLocation) {
    return {
      backgroundColor: transparent(color, 25),
    };
  }

  // does not have access to the current location
  if (!interrupt.accessCurrentLocation) {
    return {
      backgroundColor: vibeColor.white,
      opacity: 0.5,
    };
  }

  // does not have access to all locations
  if (!interrupt.accessAllLocations) {
    return {
      backgroundColor: vibeColor.white,
      // only add opacity if also not available for the selected day on the schedule
      opacity: !availableToday
        ? 0.5
        : 1,
    };
  }

  // message is scheduled and not available for the selected day in the schedule
  if (!availableToday) {
    return {
      backgroundColor: !fullView
        ? color
        : vibeColor.white,
      opacity: 0.5,
    };
  }

  return {
    backgroundColor: !fullView
      ? color
      : vibeColor.white,
  };
}

function InterruptItem({
  interrupt,
  day,
  color,
  fullView,
  tooltipView,
  isEvent,
  readOnly,
}) {
  const [hover, setHover] = useState(false);

  const itemStart = TimeUtil.convertToTwentyFourHour({
    time: interrupt.startTime,
  });

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const daysOfWeek = get(interrupt, 'message.daysOfWeek', []);
  // message is scheduled for specific days of the week (if all days are selected treat as not scheduled)
  const scheduled = daysOfWeek.length > 0 && daysOfWeek.length < 7;
  const dayIndex = TimeUtil.getDayOfWeekIndex(day);
  const daysOfWeekIndexes = daysOfWeek.map(day => TimeUtil.getDayOfWeekIndex(day));
  // is the message available to play on the selected day in the schedule?
  const availableToday = (!scheduled) || (scheduled && day && daysOfWeekIndexes.includes(dayIndex)) || !day;

  if (tooltipView) {
    return (
      <div className="InterruptItemTooltip">
        {!interrupt.accessCurrentLocation && (
          <div className="data-row info-box unavailable">
            This message is unavailable at this location
          </div>
        )}

        {scheduled && (
          <div className="data-row info-box info">
            <div>
              This message is only scheduled to play on

              <div
                style={{
                  marginTop: 4,
                }}
              >
                {daysOfWeek.join(', ')}
              </div>
            </div>
          </div>
        )}

        <div className="data-row interrupt-start">
          {itemStart}
        </div>

        <div className="data-row interrupt-container">
          <VibeIcon
            icon={viMicrophone}
            color={vibeColor.white}
            size={16}
          />

          <div className="title">
            {interrupt.message.name}
          </div>

          {interrupt.message.messageType === 'ad' ? (
            <span className="ad">
              $
            </span>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div
      className="InterruptItem"
      style={{
        border: `1px solid ${color}`,
        borderRadius: 4,
        boxShadow: `inset 4px 0 0 0 ${color}`,
        boxSizing: 'border-box',
        ...getStyle(interrupt, color, hover, fullView, availableToday),
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {fullView && (
        <InterruptItemFullView
          interrupt={interrupt}
          isEvent={isEvent}
          readOnly={readOnly}
        />
      )}
    </div>
  );
}

InterruptItem.propTypes = {
  interrupt: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  color: PropTypes.string.isRequired,
  /** Day of the week the message is being shown */
  day: PropTypes.string,
  /** View entire item */
  fullView: PropTypes.bool,
  /** View the tooltip item */
  tooltipView: PropTypes.bool,
  /** Item is part of an event */
  isEvent: PropTypes.bool,
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
};

InterruptItem.defaultProps = {
  interrupt: {},
  day: null,
  fullView: false,
  tooltipView: false,
  isEvent: false,
  readOnly: false,
};

export default InterruptItem;
