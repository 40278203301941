import React, { Component } from 'react';
import classNames from 'classnames';
import {
  VibeButton,
} from 'vibeguide';
import { FormHelperText } from '@mui/material';
import { Dialpad, Lock } from '@mui/icons-material';
import PasswordRequirements from '../Shared/Password/PasswordRequirements';
import './ResetPasswordForm.scss';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      isCodeFocus: false,
      isPasswordFocus: false,
    };
  }

  onCodeChange = (e) => {
    const {
      onConfirmationCodeChange,
    } = this.props;

    onConfirmationCodeChange(e);
  };

  onPasswordChange = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const {
      passwordValidateChange,
    } = this.props;

    this.setState({
      password: value,
    });

    passwordValidateChange(e);
  };

  onFocusCode = () => {
    this.setState({
      isCodeFocus: true,
    });
  };

  onBlurCode = () => {
    this.setState({
      isCodeFocus: false,
    });
  };

  onFocusPassword = () => {
    this.setState({
      isPasswordFocus: true,
    });
  };

  onBlurPassword = () => {
    this.setState({
      isPasswordFocus: false,
    });
  };

  onKeyDown = (e) => {
    const {
      resetPassword,
    } = this.props;

    if (e.key === 'Enter') {
      resetPassword(e);
    }
  };

  resendActivationCode = (e) => {
    const {
      resendActivationCode,
    } = this.props;

    resendActivationCode(e);
  };

  render() {
    const {
      resetPassword,
      confirmationCode,
      errors,
      confirmationCodeError,
      confirmationCodeErrorMessage,
    } = this.props;

    const {
      password,
      isCodeFocus,
      isPasswordFocus,
    } = this.state;

    return (
      <div
        className="ResetPasswordForm"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <div
          className="form-title"
          style={{
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Reset Password

          <div
            className="form-subtitle"
            style={{
              marginTop: 16,
              fontSize: 12,
              fontWeight: 'normal',
            }}
          >
            Please enter the code sent to your e-mail address, then provide a new password.
          </div>
        </div>

        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
          }}
        >
          <div className={classNames('input-container', { active: isCodeFocus, 'has-error': confirmationCodeError })}>
            <input
              className="login-input"
              type="number"
              placeholder="000000"
              value={confirmationCode}
              onChange={this.onCodeChange}
              onKeyDown={this.onKeyDown}
              onFocus={this.onFocusCode}
              onBlur={this.onBlurCode}
              autoFocus
            />

            <Dialpad className="login-icon pad-icon" />

            {confirmationCodeError && (
              <FormHelperText className="login-error">
                {confirmationCodeErrorMessage}
              </FormHelperText>
            )}
          </div>

          <div className={`input-container ${isPasswordFocus ? 'active' : ''}`}>
            <input
              className="login-input"
              type="password"
              placeholder="New Password"
              value={password}
              onChange={this.onPasswordChange}
              onKeyDown={this.onKeyDown}
              onFocus={this.onFocusPassword}
              onBlur={this.onBlurPassword}
            />

            <Lock className="login-icon password-icon" />
          </div>
        </form>

        <PasswordRequirements
          errors={errors}
        />

        <VibeButton
          style={{
            width: '100%',
            height: 48,
            fontSize: 16,
          }}
          text="Reset Password"
          color="primary"
          disabled={!confirmationCode || errors.errors}
          onClick={resetPassword}
        />

        <VibeButton
          style={{
            width: '100%',
            height: 48,
            fontSize: 16,
          }}
          text="Resend Activation Code"
          color="secondary"
          onClick={this.resendActivationCode}
        />
      </div>
    );
  }
}

export default ResetPasswordForm;
