// use browser locale (change to 'de-DE' for "." separated thousands)
const locale = undefined;

/**
 * Format currency based on currencyCode and locale
 *
 * @param {string} key
 * @returns string
 */
export function format(value, currencyCode, options = {}) {
  if (!currencyCode) {
    // do not attempt to format when no currency code is provided
    return value;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 8,
    ...options,
  }).format(value);
}

export default {
  format,
};
