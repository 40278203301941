import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import slice from 'lodash/slice';
import {
  API,
  GlobalActions,
  ToastActions,
  NavigationHelper,
  MimeTypeHelper,
  Field2 as Field,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  SidePanelSection,
  TableUsers,
  Assignments,
  Tag,
  VibeModal,
  VibeTooltip,
  VibeButton,
  VibeIcon,
  viClose,
  viArchive,
  viUnarchive,
  color,
  withRouter,
} from 'vibeguide';
import PartnerCompanies from './PartnerCompanies';
import PartnerAccess from './PartnerAccess';
import Information from './Content/Information';
import defaultImage from '../../../assets/ism-avatar.png';
import Reporting from './Content/Reporting/Reporting';
import PartnerSpotTypes from './Content/PartnerSpotTypes';

function PartnerDetails({
  className,
  style,
  user,
  history,
  setPanel,
  queueToast,
  partner,
  isNew,
  onClose,
  onUpdate,
  // onRefresh,
}) {
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [usersAssigned, setUsersAssigned] = useState([]);

  const {
    _id,
    name,
    imageUrl,
    tags = [],
    active,
    requireAdApproval = false,
    companies = [],
    expandedCompanies = [],
    partnersAssigned = [],
    tableauReporting = {
      reports: [{
        _id: uniqueId('report-'),
        name: '',
        url: '',
      }],
    },
    spotTypes = [],
  } = partner;

  const canViewPartnerUser = user.can('partner_user.view');
  const isACAF = companies.length === 1 && companies[0] === '*';

  // convert companies assigned to a standard location spec
  const locationSpec = companies.map(companyId => {
    return {
      companyId,
      locationId: '*',
    };
  });
  const exceedsLocations = user.exceedsLocations(locationSpec);
  const disableSave = !name
    || spotTypes.length <= 0
    || companies.length <= 0;

  const disableInput = (_id && !user.can('partner.modify'))
    || (!_id && !user.can('partner.create'));

  const showArchive = user.can('partner.delete');
  const isArchived = !active;

  /**
   * Get the warning message for the locations assigned (if disabled)
   */
  const getLocationWarning = () => {
    const warning = [];

    if (!exceedsLocations) {
      warning.push('You do not have access to all the companies assigned to this object');
    }

    return warning.join(' ');
  };

  /**
   * Build the tag string for the configured Partner Access
   */
  const getPartnerAccessString = () => {
    const partnerAccessCount = partnersAssigned.length;

    if (partnerAccessCount <= 0) {
      // no partners assigned
      return 0;
    }

    // show only the first 2 partners as a tag, and however many are left at the end
    const showPartners = slice(partnersAssigned, 0, 2);

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
        }}
      >
        {showPartners.map((partner) => (
          <Tag
            key={`partner-${partner.name}`}
            tag={{
              name: partner.name,
            }}
            style={{
              background: color.manatee16,
              color: color.manatee,
            }}
          />
        ))}

        {partnerAccessCount > 2 && (
          <Tag
            tag={{
              name: `+${partnerAccessCount - 2}`,
            }}
            style={{
              background: color.manatee16,
              color: color.manatee,
            }}
          />
        )}
      </div>
    );
  };

  /**
   * Ad Approval Setting Changed
   */
  const onChangeAdApproval = () => {
    onUpdate({
      requireAdApproval: !requireAdApproval,
    });
  };

  const onClickCompanies = async () => {
    const allowChanges = user.can('partner.manage_companies')
      && ((_id && user.can('partner.modify'))
        || (!_id && user.can('partner.create')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <PartnerCompanies
            companies={expandedCompanies}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  const onClickUsers = () => {
    setPanel({
      extraPanel: {
        title: 'Assigned Users',
        width: window.innerWidth,
        show: true,
        children: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <TableUsers
              toolbarStyle={{
                borderTop: 'none',
              }}
              columns={[
                'First Name',
                'Last Name',
                'Title',
                'Email',
                'Phone #',
                'Company',
                'Tags',
                '...',
              ]}
              collection={usersAssigned}
              defaultSortBy={{
                label: 'First Name',
                attr: 'fname',
                direction: 'asc',
              }}
              paginator
              paginatorProps={{
                label: 'Users',
                urlPaging: false,
                urlFilters: false,
              }}
              toggle
              toggleProps={{
                label: 'Show Archived Users',
                tooltip: true,
              }}
              menuItems={[
                { name: 'Archive', userCan: 'user.delete' },
              ]}
            />
          </div>
        ),
      },
    });
  };

  const onClickPartnersAssigned = () => {
    const allowChanges = user.can('partner.manage_partner_access_config')
      && ((_id && user.can('partner.modify'))
        || (!_id && user.can('partner.create')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <PartnerAccess
            partners={partnersAssigned}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * When a Reporting field is updated
   */
  const onUpdateReporting = (data) => {
    onUpdate({
      ...partner,
      tableauReporting: {
        ...tableauReporting,
        ...data,
      },
    });
  };

  const onDrop = async (fileObj) => {
    const file = get(fileObj, '[0]', {
      file: null,
    });

    if (file && file.error) {
      console.warn('File has an error', fileObj);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const {
        target: {
          result,
        },
      } = e;

      // Save file data to state to show as a preview
      setFile(file.file);
      setFileData(result);
    };

    reader.onabort = () => console.error('file reading was aborted');
    reader.onerror = () => console.error('file reading has failed');

    reader.readAsDataURL(file.file);
  };

  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  const onClickUnarchive = async () => {
    await API.Partner.reactivate({
      _id: partner._id,
    });

    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSavePartner'));
  };

  const onCloseArchive = () => {
    setConfirmArchive(false);
  };

  const onConfirmArchive = async () => {
    try {
      await API.Partner.deactivate(_id);
      onClose();

      // tell listening components the user was saved
      document.dispatchEvent(new Event('onSavePartner'));
    } catch (e) {
      console.error('Error Archiving', e);
    }

    onCloseArchive();
  };

  const onSave = async () => {
    document.dispatchEvent(new Event('onSavePartnerStart'));

    const data = {
      name,
      requireAdApproval,
    };

    // Add Tags
    if (user.can('partner.assign_tag') && tags.length > 0) {
      const modifyTags = tags.filter(tag => tag.status === 'add' || tag.status === 'remove');

      data.tags = modifyTags.map((tag) => {
        return {
          _id: tag._id,
          action: tag.status,
        };
      });
    }

    // Add Tableau Reporting
    if (user.can('partner.manage_report_config')) {
      const reports = get(tableauReporting, 'reports', []).map(report => {
        return {
          name: report.name,
          url: report.url,
        };
      }).filter(report => report.name && report.url);

      data.tableauReporting = {
        ...tableauReporting,
        reports,
      };
    }

    // Add Companies Assigned
    if (user.can('partner.manage_companies')) {
      data.companies = companies;
    }

    // Add Partner Access
    if (user.can('partner.manage_partner_access_config')) {
      data.partnerAccess = partnersAssigned.map((partner) => {
        return {
          partnerId: partner._id,
          partnerName: partner.name,
          sensitiveData: partner.sensitiveData,
        };
      });
    }

    // Add Spot Types
    if (user.can('partner.manage_spot_types')) {
      data.spotTypes = spotTypes;
    }

    if (_id && !isNew) {
      // editing
      data._id = _id;
    }

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const response = _id && !isNew
        ? await API.Partner.modify(data)
        : await API.Partner.create(data);

      const partnerId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = _id && !isNew
        ? responseType === 'PARTNER.UPDATED'
        : responseType === 'PARTNER.CREATED';

      if (success) {
        // Successfully saved the user
        const saveMessage = _id && !isNew
          ? 'Partner Updated!'
          : 'Partner Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });
      }

      if (success && file) {
        // Successfully saved the partner, now upload the image
        queueToast({
          type: 'info',
          title: 'Uploading Image...',
          allowClose: true,
        });

        const imageResponse = await API.Partner.uploadImage(partnerId, file);
        const imageSuccess = get(imageResponse, '[0].type') === 'PARTNER.IMAGE_UPLOADED';

        if (imageSuccess) {
          queueToast({
            type: 'success',
            title: 'Image Uploaded!',
            allowClose: true,
            delay: 500,
          });
        }
      }

      // Refresh the details and redirect to their edit screen
      if (success && isNew) {
        const redirectUrl = NavigationHelper.updateParams({
          partnerId,
          type: null,
        });

        history(redirectUrl);
      }

      // tell listening components the user was saved
      document.dispatchEvent(new Event('onSavePartner'));
    } catch (err) {
      document.dispatchEvent(new Event('onSavePartnerError'));
    }
  };

  useEffect(async () => {
    /**
     * Get Users Assigned
     */
    if (!isNew && canViewPartnerUser && _id) {
      const partnerUsers = await API.PartnerUser.list({
        filters: {
          partnerId: _id,
        },
        pageSize: -1,
      });

      setUsersAssigned(partnerUsers.data);
    }
  }, [_id]);

  return (
    <SidePanelContainer
      className={classNames('PartnerDetails', className)}
      style={style}
    >
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginRight: 16,
            }}
          >
            <Field
              type="dropzone"
              dropzoneProps={{
                type: 'image',
                style: {
                  width: 40,
                  height: 40,
                },
                dragOverStyle: {
                  background: 'rgba(0, 0, 0, 0.5)',
                },
                placeholder: (
                  <VibeTooltip
                    title={!disableInput
                      ? 'Click or drag an image to set the avatar'
                      : ''}
                  >
                    <img
                      style={{
                        width: 40,
                        height: 40,
                      }}
                      src={fileData || imageUrl || defaultImage}
                      alt="Partner"
                    />
                  </VibeTooltip>
                ),
                allowUpload: !disableInput,
                mimeTypes: MimeTypeHelper.images,
              }}
              onDrop={onDrop}
            />
          </div>

          {_id ? (
            <div className="flex-horizontal">
              <div className="title">
                <VibeTooltip
                  title={name}
                >
                  <span>
                    {name}
                  </span>
                </VibeTooltip>
              </div>
            </div>
          ) : (
            <div className="title">
              New Partner
            </div>
          )}
        </div>
      </SidePanelHeader>

      <SidePanelContent>
        <SidePanelSection title="Information">
          <Information
            name={name}
            tags={tags}
            disableInput={disableInput}
            onUpdate={onUpdate}
          />
        </SidePanelSection>

        <SidePanelSection title="Ad Approval Settings">
          <div
            style={{
              width: '50%',
            }}
          >
            <Field
              type="toggle"
              label="Ad Approval"
              toggleProps={{
                label: 'Require Approval',
              }}
              value={requireAdApproval
                ? 'on'
                : 'off'}
              disabled={disableInput}
              onChange={onChangeAdApproval}
            />
          </div>
        </SidePanelSection>

        {user.can('partner.view_spot_types') && (
          <SidePanelSection
            title="Ad Spot Type Settings"
            required
          >
            <PartnerSpotTypes
              spotTypes={spotTypes}
              disableInput={disableInput}
              onUpdate={onUpdate}
            />
          </SidePanelSection>
        )}

        {user.can('partner.view_report_config') && (
          <SidePanelSection title="Reporting">
            <Reporting
              securityGroup={tableauReporting.securityGroup}
              reports={tableauReporting.reports}
              disableInput={disableInput || !user.can('partner.manage_report_config')}
              onUpdate={onUpdateReporting}
            />
          </SidePanelSection>
        )}

        <Assignments
          items={[{
            label: 'Companies Assigned',
            count: isACAF
              ? 'All Current and Future'
              : companies.length,
            required: true,
            disabled: !exceedsLocations || !user.can('partner.view_companies'),
            warning: getLocationWarning(),
            onClick: exceedsLocations && user.can('partner.view_companies')
              ? onClickCompanies
              : null,
          },
          {
            label: 'Users Assigned',
            count: canViewPartnerUser
              ? usersAssigned.filter(user => user.active === true).length
              : '...',
            disabled: !canViewPartnerUser || isNew,
            onClick: canViewPartnerUser && !isNew
              ? onClickUsers
              : null,
          }]}
        />

        {user.can('partner.view_partner_access_config') && (
          <Assignments
            title="Partner Access"
            items={[{
              label: 'Partners Assigned',
              count: getPartnerAccessString(),
              onClick: onClickPartnersAssigned,
            }]}
          />
        )}
      </SidePanelContent>

      <SidePanelFooter>
        <VibeButton
          text="Save Changes"
          color="secondary"
          loadingEvent="onSavePartner"
          disabled={disableSave || disableInput}
          onClick={onSave}
        />

        {!isNew && (
          <>
            {(showArchive && !isArchived) && (
              <div>
                <VibeIcon
                  icon={viArchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.error,
                  }}
                  tooltip="Archive"
                  color={color.error}
                  size={20}
                  onClick={onClickArchive}
                />
              </div>
            )}

            {(showArchive && isArchived) && (
              <div>
                <VibeIcon
                  icon={viUnarchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.success,
                  }}
                  tooltip="Unarchive"
                  color={color.success}
                  size={20}
                  onClick={onClickUnarchive}
                />
              </div>
            )}
          </>
        )}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text={`Are you sure you want to archive ${name}?`}
        confirmProps={{
          text: 'Archive',
          color: 'error',
        }}
        cancelProps={{
          text: 'Cancel',
        }}
        onConfirm={onConfirmArchive}
        onClose={onCloseArchive}
      />
    </SidePanelContainer>
  );
}

PartnerDetails.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  partner: PropTypes.object,
  isNew: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

PartnerDetails.defaultProps = {
  className: '',
  style: {},
  partner: {},
  isNew: false,
  onClose: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerDetails));
