import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  find,
  toString,
} from 'lodash';
import {
  getCurrencies,
} from '../../actions/Currency/CurrencyActions';
import './CurrencyField.scss';

// use browser locale (change to 'de-DE' for "." separated thousands)
const locale = undefined;

/**
 * Get the thousand separator to determine what locale is being used
 * and what thousand character to remove out of numbers in the field
 */
function getThousandSeparator() {
  const numberWithDecimalSeparator = 1.1;

  const decimalSeparator = numberWithDecimalSeparator
    .toLocaleString(locale)
    .substring(1, 2);

  if (decimalSeparator === ',') {
    // decimals are separated with a comma for this locale
    return '.';
  }

  return ',';
}

/**
 * Get the RegEx used to replace the thousand separator when converting currency values back and forth
 */
function getThousandSeparatorRegEx() {
  // get the thousand separator based on locale to replace
  const replaceChar = getThousandSeparator();

  if (replaceChar === '.') {
    // this locale uses "." as a thousand separator
    return /\./g;
  }

  // this locale uses "," as a thousand separator
  return /,/g;
}

function toCurrency(value = '') {
  value = toString(value);

  return Number(value)
    .toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    });
}

function fromCurrency(value = '') {
  // remove the thousand separator characters
  value = value.replace(getThousandSeparatorRegEx(), '');

  if (getThousandSeparator() === '.') {
    // this locale uses decimals for thousand separator
    // replace the decimal character with a period for saving
    value = value.replace(/,/g, '.');
  }

  return Number(value);
}

function CurrencyField({
  className,
  name,
  placeholder,
  value,
  currencyCode,
  currencies,
  disabled,
  tabIndex,
  autoFocus,
  autoComplete,
  onChange,
  onBlur,
  onFocus,
  getCurrencies,
}) {
  const [friendlyValue, setFriendlyValue] = useState(toCurrency(value));
  const currency = find(currencies, { key: currencyCode }) ?? {};

  /**
   * Get the currency label to show in the field
   * if none exists use the supplied currencyCode
   */
  const getCurrencyLabel = () => {
    return currency.label ?? currencyCode;
  };

  const onChangeField = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    setFriendlyValue(value);
  };

  const onBlurField = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    // convert the value back to a number
    const numValue = fromCurrency(value);

    // only tell the parent component of the change after blur (to format properly)
    onChange({
      name,
      value: numValue,
    });

    onBlur(e);
  };

  // value has changed, update to show as currency
  useEffect(() => {
    setFriendlyValue(toCurrency(value));
  }, [value]);

  useEffect(() => {
    // fetch supported currencies
    getCurrencies();
  }, []);

  return (
    <div className={classNames('CurrencyField', className)}>
      <input
        name={name}
        className={classNames('field-input', 'has-input-group-right')}
        type="text"
        tabIndex={tabIndex}
        placeholder={placeholder || toCurrency(0)}
        value={friendlyValue !== '0.00' && friendlyValue !== '0,00'
          ? friendlyValue
          : ''}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        disabled={disabled}
        onBlur={onBlurField}
        onChange={onChangeField}
        onFocus={onFocus}
      />

      <div className="input-group-right">
        {getCurrencyLabel()}
      </div>
    </div>
  );
}

CurrencyField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  currencyCode: PropTypes.string,
  disabled: PropTypes.bool,
  /** Input tab index position */
  tabIndex: PropTypes.number,
  /** Auto focus on the input */
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

CurrencyField.defaultProps = {
  className: '',
  name: '',
  placeholder: '',
  value: 0,
  currencyCode: null,
  disabled: false,
  tabIndex: 0,
  autoFocus: false,
  autoComplete: 'on',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

function mapStateToProps(state) {
  return {
    currencies: state.currency.list,
  };
}

const mapDispatchToProps = {
  getCurrencies,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyField);
