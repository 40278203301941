import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  NavigationHelper,
  TablePartners,
  API,
  GlobalActions,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../Header/SubNavigation';
import PartnerSidebar from './Sidebar/PartnerSidebar';

function PartnersList({
  className,
  style,
  location,
  history,
  setPanel,
}) {
  const prevSearchRef = useRef(location.search);

  const onExport = (data) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `partners-${timestamp}.csv`);
  };

  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      partnerId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, partnerId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <PartnerSidebar
          partnerId={partnerId}
          isNew={type === 'new'}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.partnerId && qs.type !== 'new';

    if (isNew || isView) {
      setTimeout(() => {
        sidebar(qs.type, qs.partnerId);
      }, 1500);
    }
  }, []);

  useEffect(() => {
    const {
      search,
    } = location;

    if (search !== prevSearchRef) {
      const qs = NavigationHelper.getParams();

      if (qs.partnerId || qs.type === 'new') {
        sidebar(qs.type, qs.partnerId);
      }
    }
  }, [location.search]);

  return (
    <div
      className={classNames('PartnersList', className)}
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...style,
      }}
    >
      <SubNavigation
        title="Partners"
        buttons={[
          {
            text: 'New Partner',
            icon: viAdd,
            color: 'primary',
            url: NavigationHelper.updateParams({
              type: 'new',
            }),
            userCan: [
              'partner.create',
            ],
          },
        ]}
      />

      <TablePartners
        columns={[
          'Name',
          'Company',
          'Tags',
          '...',
        ]}
        fetch={API.Partner.list}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Partners',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Partners',
          tooltip: true,
        }}
        rowLink={{
          partnerId: '_id',
        }}
        menuItems={[
          { name: 'Archive', userCan: 'partner.delete' },
        ]}
        csv
        csvProps={{
          onExport,
        }}
      />
    </div>
  );
}

PartnersList.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

PartnersList.defaultProps = {
  className: '',
  style: {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(null, mapDispatchToProps)(PartnersList));
