import React from 'react';

function PasswordRequirements({
  errors,
}) {
  return (
    <ul key="list-1" className="verification-list-container">
      <li>
        <div className={errors.number ? 'non-verified verification-circle' : 'verification-circle'} />
        <span className={errors.number ? 'non-verified text' : 'text'}>One number</span>
      </li>
      <li>
        <div className={errors.lowercase ? 'non-verified verification-circle' : 'verification-circle'} />
        <span className={errors.lowercase ? 'non-verified text' : 'text'}>One lowercase letter</span>
      </li>
      <li>
        <div className={errors.uppercase ? 'non-verified verification-circle' : 'verification-circle'} />
        <span className={errors.uppercase ? 'non-verified text' : 'text'}>One uppercase letter</span>
      </li>
      <li>
        <div className={errors.special ? 'non-verified verification-circle' : 'verification-circle'} />
        <span className={errors.special ? 'non-verified text' : 'text'}>One special character</span>
      </li>
      <li>
        <div className={errors.minLength ? 'non-verified verification-circle' : 'verification-circle'} />
        <span className={errors.minLength ? 'non-verified text' : 'text'}>At least 8 characters</span>
      </li>
    </ul>
  );
}

export default PasswordRequirements;
