import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@mui/material';
import moment from 'moment';
import {
  isEmpty,
  toNumber,
  uniqBy,
} from 'lodash';
import {
  color,
  LoadingContent,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  ToastActions,
  VibeButton,
  VibeIcon,
  viClose,
} from 'vibeguide';
import './MediaSidebar.scss';
import DayPart from '../../../Flights/Sidebar/DayPart';

function MediaSidebar({
  className,
  dayPart,
  dayPartIndex,
  dayParts,
  segment,
  saveDayParts,
  onCloseSidebar,
}) {
  const [mediaDayParts, setMediaDayParts] = useState(dayParts);

  const [mediaDayPart, setMediaDayPart] = useState(dayPart);

  const [disableSave, setDisableSave] = useState(false);
  const [mediaDateErrors, setMediaDateErrors] = useState([]);

  useEffect(() => {
    const mediaDateErrs = [];
    dayPart.dateRanges.forEach((daterange, i) => {
      mediaDateErrs.push({
        index: i,
        error: false,
        // dateError: false,
        // weightError: false,
      });
    });
    setMediaDateErrors(mediaDateErrs);
    setMediaDayPart({
      startTime: dayPart.startTime,
      endTime: dayPart.endTime,
      dateRanges: dayPart.dateRanges.map((daterange) => {
        return {
          startDate: daterange.startDate,
          endDate: daterange.endDate,
          creatives: !isEmpty(daterange.creatives) ? daterange.creatives.map((creative) => {
            return {
              ...creative,
              weight: creative.weight * 100,
            };
          }) : [],
        };
      }),
    });
  }, [dayPart]);

  useEffect(() => {
    const creativeWeightDayParts = dayParts.map((daypart) => {
      return {
        startTime: daypart.startTime,
        endTime: daypart.endTime,
        dateRanges: daypart.dateRanges.map((daterange) => {
          return {
            startDate: daterange.startDate,
            endDate: daterange.endDate,
            creatives: !isEmpty(daterange.creatives) ? daterange.creatives.map((creative) => {
              return {
                ...creative,
                weight: creative.weight * 100,
              };
            }) : [],
          };
        }),
      };
    });
    setMediaDayParts(creativeWeightDayParts);
  }, [dayParts]);

  const canPartMultiple = true;

  const hourOptions = [];
  for (let i = 0; i <= 24; i++) {
    const hour = i < 10 ? `0${i}:00` : `${i}:00`;
    hourOptions.push({
      label: hour,
      value: hour,
    });
  }

  const checkAllContinuousCreativeDates = () => {
    let creativeDateError = false;
    mediaDayPart.dateRanges.forEach((dateRange, index) => {
      if (index > 0) {
        const firstEndDate = moment(mediaDayPart.dateRanges[index - 1].endDate);
        const startdate = moment(mediaDayPart.dateRanges[index].startDate);
        const tempErrors = mediaDateErrors.filter((err) => err.index !== index);
        const isError = startdate.isBefore(firstEndDate);
        const tempError = { index, error: isError };
        if (isError && !creativeDateError) creativeDateError = true;
        tempErrors.push(tempError);
        setMediaDateErrors(tempErrors);
      }
    });
    return creativeDateError;
  };

  const checkAllCreativeWeights = () => {
    let creativeWeightErrors = false;
    return mediaDayPart.dateRanges.every((daterange) => {
      if (!isEmpty(daterange.creatives)) {
        const totalCreativeWeight = daterange.creatives.reduce((accumulator, creative) => {
          return accumulator + toNumber(creative.weight);
        }, 0) || 0;
        creativeWeightErrors = totalCreativeWeight !== 100;
        if (creativeWeightErrors) return false;
      }
      return true;
    });
  };

  useEffect(() => {
    setDisableSave(checkAllContinuousCreativeDates() || !checkAllCreativeWeights());
  }, [mediaDayPart]);

  const updateDayParts = (updatedPart) => {
    const tempParts = mediaDayParts.filter((daypart, i) => i !== dayPartIndex);
    tempParts.splice(dayPartIndex, 0, updatedPart);
    setMediaDayPart(tempParts[dayPartIndex]);
    setMediaDayParts(tempParts);
  };

  const onDayPartCreativeChange = (data, index) => {
    const updatedDateRange = {
      ...mediaDayParts[index].dateRanges[data.dateRangeIndex],
      creatives: data.creatives,
    };

    const newDateRanges = mediaDayParts[index].dateRanges;
    newDateRanges[data.dateRangeIndex] = updatedDateRange;

    const updatedPart = {
      ...mediaDayParts[index],
    };

    updateDayParts(updatedPart, index);
  };

  const onDayPartSelectCreativeMedia = (data, index) => {
    const combinedCreatives = uniqBy([
      ...data.creatives,
      ...mediaDayParts[index].dateRanges[data.dateRangeIndex].creatives,
    ], 'creativeId');

    const updatedDateRange = {
      ...mediaDayParts[index].dateRanges[data.dateRangeIndex],
      creatives: combinedCreatives,
    };

    const newDateRanges = mediaDayParts[index].dateRanges;
    newDateRanges[data.dateRangeIndex] = updatedDateRange;

    const updatedPart = {
      ...mediaDayParts[index],
    };

    updateDayParts(updatedPart, index);
  };

  const onDateRangeStartDateChange = (data, index) => {
    const updatedDateRange = {
      ...mediaDayParts[index].dateRanges[data.dateRangeIndex],
      startDate: data.startDate,
    };

    const newDateRanges = [...mediaDayParts[index].dateRanges];
    newDateRanges[data.dateRangeIndex] = updatedDateRange;

    const updatedPart = {
      ...mediaDayParts[index],
      dateRanges: newDateRanges,
    };

    updateDayParts(updatedPart, index);
  };

  const onDateRangeEndDateChange = (data, index) => {
    const updatedDateRange = {
      ...mediaDayParts[index].dateRanges[data.dateRangeIndex],
      endDate: data.endDate,
    };

    const newDateRanges = [...mediaDayParts[index].dateRanges];
    newDateRanges[data.dateRangeIndex] = updatedDateRange;

    const updatedPart = {
      ...mediaDayParts[index],
      dateRanges: newDateRanges,
    };

    updateDayParts(updatedPart, index);
  };

  const onDayPartTimeChange = (e, index) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const updatedPart = {
      ...mediaDayParts[index],
      [name]: value,
    };

    updateDayParts(updatedPart, index);
    e.target.blur();
  };

  const localLocations = {};
  segment.companies.forEach(company => {
    localLocations[company._id] = company.locations.map(location => location._id);
  });

  const onSave = async () => {
    const translatedMediaDayParts = mediaDayParts.map((daypart) => {
      return {
        startTime: daypart.startTime,
        endTime: daypart.endTime,
        dateRanges: daypart.dateRanges.map((daterange) => {
          return {
            startDate: daterange.startDate,
            endDate: daterange.endDate,
            creatives: !isEmpty(daterange.creatives) ? daterange.creatives.map((creative) => {
              return {
                ...creative,
                weight: creative.weight / 100,
              };
            }) : [],
          };
        }),
      };
    });

    saveDayParts(translatedMediaDayParts);
  };

  return (
    <div className={classNames('SegmentSidebar', className)}>
      <SidePanelContainer className={classNames('MediaSidebar', className)}>
        <SidePanelHeader
          icons={(
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onCloseSidebar}
            />
          )}
        >
          <div className="title">
            Edit Media
          </div>
        </SidePanelHeader>
        <SidePanelContent>
          <div className="segment-dates-label">
            <span>Segment Dates: </span>
            {moment(segment.startDate).format('ll')} - {moment(segment.endDate).format('ll')}
          </div>
          <div className="SegmentDateTime">
            <Grid
              spacing={2}
              container
            >
              <Grid
                className="day-parts"
                xs={12}
                item
              >
                <DayPart
                  canAddMedia={canPartMultiple}
                  editMedia
                  advertiserId=""
                  segmentLocations={localLocations}
                  partIndex={dayPartIndex}
                  part={mediaDayPart || {}}
                  startDate={segment.startDate}
                  endDate={segment.endDate}
                  totalParts={0}
                  hourOptions={hourOptions}
                  disableMedia={false}
                  onTimeChange={(e) => onDayPartTimeChange(e, dayPartIndex)}
                  onCreativeChange={(data) => onDayPartCreativeChange(data, dayPartIndex)}
                  onSelectCreativeMedia={(data) => onDayPartSelectCreativeMedia(data, dayPartIndex)}
                  updateDayParts={updateDayParts}
                  onDateRangeStartDateChange={(data) => onDateRangeStartDateChange(data, dayPartIndex)}
                  onDateRangeEndDateChange={(data) => onDateRangeEndDateChange(data, dayPartIndex)}
                  // checkAllContinuousCreativeDates={checkAllContinuousCreativeDates}
                  mediaDateErrors={mediaDateErrors}
                />
              </Grid>
            </Grid>
          </div>
          {segment.segmentId && !segment._id && (
            <LoadingContent />
          )}
        </SidePanelContent>
        <SidePanelFooter className="panel-footer">
          <VibeButton
            text="Save Changes"
            style={{
              marginRight: 12,
            }}
            color="secondary"
            // loadingEvent="onSaveSegment"
            disabled={disableSave}
            onClick={onSave}
          />
        </SidePanelFooter>
      </SidePanelContainer>
    </div>
  );
}

MediaSidebar.propTypes = {
  className: PropTypes.string,
  segment: PropTypes.object,
  dayPart: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    dateRanges: PropTypes.arrayOf(PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      creatives: PropTypes.arrayOf(PropTypes.shape({
        creativeId: PropTypes.string,
        weight: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
    })),
  }),
  dayPartIndex: PropTypes.number,
  dayParts: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    dateRanges: PropTypes.arrayOf(PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      creatives: PropTypes.arrayOf(PropTypes.shape({
        creativeId: PropTypes.string,
        weight: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
    })),
  })),
  onCloseSidebar: PropTypes.func,
};

MediaSidebar.defaultProps = {
  className: '',
  segment: {},
  dayPart: {
    startTime: null,
    endTime: null,
    dateRanges: [],
  },
  dayParts: [],
  dayPartIndex: 0,
  onCloseSidebar: () => {},
};

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

function mapStateToProps(state) {
  return {
    socket: state.socket.connection,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaSidebar);
