import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import find from 'lodash/find';
import {
  setPanel,
} from '../../actions/Global/GlobalActions';
import API from '../../api';
import {
  toSpec,
} from '../../helpers/Location';
import {
  getLocationsData,
} from '../../helpers/ExpandedLocations';
import TableTargetGroups from '../VibeTable/Tables/TableTargetGroups';
import TargetGroupDetails from './TargetGroupDetails';
import VibeModal from '../VibeModal/VibeModal';
import VibeButton from '../VibeButton/VibeButton';
import VibeIcon from '../VibeIcon/VibeIcon';
import viClose from '../../icons/viClose';
import color from '../../sass/color.scss';
import './TargetGroupsUnassigned.scss';

class TargetGroupsUnassigned extends PureComponent {
  constructor(props) {
    super(props);

    // only highlight selected target groups
    const targetGroupItems = [];

    this.state = {
      highlightIds: targetGroupItems.map(targetGroup => targetGroup._id),
      // modal to replace or merge locations
      mergeModal: false,
      hasAdProgramsDisabled: false,
      hasAdProgramsDisabledMerge: false,
      onUpdateData: {},
    };
  }

  /**
   * Get all target groups locations
   */
  getTargetGroups = async () => {
    const {
      highlightIds: targetGroupIds,
    } = this.state;

    const targetGroups = await Promise.all(targetGroupIds.map(async (targetGroupId) => {
      const targetGroupDetails = await API.TargetGroup.get(targetGroupId);
      // get expanded locations to get the data shown in assigned locations
      const expandedLocations = await API.TargetGroup.getExpandedLocations({
        _id: targetGroupId,
      });
      targetGroupDetails.locationsData = getLocationsData(expandedLocations);

      return Promise.resolve(targetGroupDetails);
    }));

    return targetGroups;
  };

  /**
   * Get target groups location data
   * @param {mergeData} // Optional. Pass locations/locationsData to be merged with the target groups
   */
  getTargetGroupsLocationData = async (mergeData) => {
    const targetGroups = await this.getTargetGroups();
    const targetGroupLocations = [];
    let filteredLocationData = {};
    let filteredTargetGroupLocations = [];
    const targetGroupLocationsData = {
      companies: [],
      locations: [],
    };

    targetGroups.forEach((targetGroup) => {
      // add location spec to the target group locations array

      const locationSpec = toSpec(targetGroup.locations);

      targetGroupLocations.push(...locationSpec);

      // add company locationsData from the target group while checking for duplicates
      targetGroup.locationsData.companies.forEach((company) => {
        const companyExists = find(targetGroupLocationsData.companies, { _id: company._id });

        if (!companyExists) {
          // add the company to the locationsData
          targetGroupLocationsData.companies.push(company);
        }
      });

      // add location locationsData from the target group while checking for duplicates
      targetGroup.locationsData.locations.forEach((location) => {
        const locationExists = find(targetGroupLocationsData.locations, { _id: location._id });

        if (!locationExists && location.adProgramEnabled) {
          // add the location to the locationsData
          targetGroupLocationsData.locations.push(location);
        }
      });

      // filter out locations with Ad Programs disabled
      filteredLocationData = targetGroupLocationsData.locations.filter((location) => {
        return location.adProgramEnabled;
      });

      // filter out duplicates
      filteredTargetGroupLocations = targetGroupLocations.filter((location1) => {
        return filteredLocationData.find((location) => location._id === location1.locationId);
      });
    });

    // remove duplicates from the location array
    const newLocationSpec = uniqWith(filteredTargetGroupLocations, isEqual);

    // merge currently assigned locations with adProgram enabled locations from the selected Target Groups
    const mergedTargetGroupLocations = uniqBy([
      ...newLocationSpec,
      ...get(mergeData, 'locations', []),
    ], 'locationId');

    // merge currently assigned locations (locationData)
    // with adProgram enabled locations from the selected Target Groups
    const mergedTargetGroupLocationsData = {
      companies: uniqBy([
        ...targetGroupLocationsData.companies,
        ...get(mergeData, 'locationsData.companies', []),
      ], '_id'),
      locations: uniqBy([
        ...filteredLocationData,
        ...get(mergeData, 'locationsData.locations', []),
      ], '_id'),
    };

    // data to send back to the parent component
    return {
      locations: mergedTargetGroupLocations,
      locationsData: mergedTargetGroupLocationsData,
    };
  };

  onAddTargetGroup = (targetGroupItems) => {
    if (!isArray(targetGroupItems)) {
      targetGroupItems = [targetGroupItems];
    }

    this.setState((state) => {
      const targetGroupIds = targetGroupItems.map(targetGroup => targetGroup._id);

      return {
        highlightIds: [...state.highlightIds, ...targetGroupIds],
      };
    });
  };

  onRemoveTargetGroup = (targetGroupItems) => {
    if (!isArray(targetGroupItems)) {
      targetGroupItems = [targetGroupItems];
    }

    this.setState((state) => {
      const targetGroupIds = targetGroupItems.map(targetGroup => targetGroup._id);

      return {
        highlightIds: state.highlightIds.filter(id => !targetGroupIds.includes(id)),
      };
    });
  };

  /**
   * Show the Replace/Merge Locations Modal
   */
  onShowMergeModal = async () => {
    const hasLocationAdDisabled = await this.checkHasAdProgramsDisabled();
    if (hasLocationAdDisabled) {
      // throw modal to notify user that locations with Ad Program disabled will not be added to the segment
      this.setState({
        hasAdProgramsDisabledMerge: true,
      });
    }
    this.setState({
      mergeModal: true,
    });
  };

  /**
   * Show the Replace/Merge Locations Modal
   */
  onCloseMergeModal = () => {
    this.setState({
      mergeModal: false,
    });
  };

  /**
   * Close the third panel on the sidebar
   */
  onCloseAvailable = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * Check if locations have Ad Programs disabled
   */
  checkHasAdProgramsDisabled = async () => {
    const targetGroups = await this.getTargetGroups();
    let hasLocationAdDisabled = false;

    targetGroups.forEach((targetGroup) => {
      // add location locationsData from the target group while checking for duplicates
      targetGroup.locationsData.locations.some((location) => {
        if (!location.adProgramEnabled) {
          hasLocationAdDisabled = true;
          return true;
        }
        return false;
      });
    });

    return hasLocationAdDisabled;
  };

  /**
   * User Clicks Replace Locations in the Merge Modal
   */
  onConfirmReplaceLocations = async () => {
    const {
      onUpdate,
    } = this.props;

    const {
      mergeModal,
    } = this.state;

    document.dispatchEvent(new Event('onReplaceLocationsStart'));

    try {
      const data = await this.getTargetGroupsLocationData();

      const hasLocationAdDisabled = await this.checkHasAdProgramsDisabled();

      if (hasLocationAdDisabled && !mergeModal) {
        // throw modal to notify user that locations with Ad Program disabled will not be added to the segment
        this.setState({
          hasAdProgramsDisabled: true,
          onUpdateData: data,
        });
      } else {
        onUpdate(data);

        // send when locations are complete
        document.dispatchEvent(new Event('onReplaceLocations'));

        this.onCloseMergeModal();
        this.onCloseAvailable();
      }
    } catch (err) {
      console.error('Error fetching target groups', err);
      document.dispatchEvent(new Event('onReplaceLocationsError'));
    }
  };

  /**
   * User Clicks Merge Locations in the Merge Modal
   */
  onConfirmMergeLocations = async () => {
    const {
      locations,
      locationsData,
      onUpdate,
    } = this.props;

    document.dispatchEvent(new Event('onMergeLocationsStart'));

    try {
      // merge the current assigned locations into the selected target group locations
      const data = await this.getTargetGroupsLocationData({
        locations,
        locationsData,
      });

      // reset the location data to update the assigned locations table
      onUpdate({
        locations: [],
        locationsData: {
          companies: [],
          locations: [],
        },
      });

      // update the assigned locations
      onUpdate(data);

      // send when locations are complete
      document.dispatchEvent(new Event('onMergeLocations'));

      this.onCloseMergeModal();
      this.onCloseAvailable();
    } catch (err) {
      console.error('Error fetching target groups', err);
      document.dispatchEvent(new Event('onMergeLocationsError'));
    }
  };

  /**
   * User Confirms notice that locations with disabled ad programs will not be added
   */
  onConfirmAdProgramModal = () => {
    const {
      onUpdateData,
    } = this.state;

    const {
      onUpdate,
    } = this.props;

    onUpdate(onUpdateData);

    // send when locations are complete
    document.dispatchEvent(new Event('onReplaceLocations'));

    this.onCloseMergeModal();
    this.onCloseAvailable();
  };

  /**
   * When the user clicks a row
   */
  onClickRow = (row) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      fourthPanel: {
        width: window.innerWidth,
        show: true,
        title: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Assigned Locations in Target Group:

            <div
              style={{
                marginLeft: 8,
                color: color.primary,
              }}
            >
              {row.name}
            </div>
          </div>
        ),
        children: (
          <TargetGroupDetails
            targetGroupId={row._id}
          />
        ),
      },
    });
  };

  /**
   * When the user clicks the close icon
   */
  onClickClose = () => {
    const {
      locations,
    } = this.props;

    const {
      highlightIds,
    } = this.state;

    if (highlightIds.length > 0 && locations.length > 0) {
      // target groups are selected and there are already locations assigned, show the replace/merge modal
      this.onShowMergeModal();
    } else if (highlightIds.length > 0 && locations.length <= 0) {
      // target groups are selected and there are no locations assigned, simply assign the target groups
      this.onConfirmReplaceLocations();
    } else {
      // no target groups selected
      this.onCloseAvailable();
    }
  };

  render() {
    const {
      filtersUnassigned,
    } = this.props;

    const {
      highlightIds,
      mergeModal,
      hasAdProgramsDisabled,
      hasAdProgramsDisabledMerge,
    } = this.state;

    const highlight = highlightIds.map((_id) => {
      return {
        _id,
      };
    });

    return (
      <div className="TargetGroupsUnassigned">
        <div className="title-container">
          <div className="title">
            Assign Target Groups
          </div>

          <VibeIcon
            className="close"
            icon={viClose}
            color={color.white}
            hoverColor={color.lightGray}
            size={24}
            onClick={this.onClickClose}
          />
        </div>

        <div className="target-groups-content">
          <TableTargetGroups
            columns={[
              'Add/Remove',
              'Name',
              'Media Format',
              'Number of Locations',
              '...',
            ]}
            fetch={API.TargetGroup.list}
            filters={filtersUnassigned}
            defaultSortBy={{
              label: 'Name',
              attr: 'name',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Target Groups',
              urlPaging: true,
              urlFilters: true,
            }}
            available
            highlight={highlight}
            onAdd={this.onAddTargetGroup}
            onRemove={this.onRemoveTargetGroup}
            onClickRow={this.onClickRow}
          />
        </div>

        <VibeModal
          className="TargetGroupsUnassignedModal"
          type="custom"
          show={mergeModal}
          title="This object already has locations assigned"
          text={(
            <div>
              Do you want your Target Groups locations to replace existing locations,<br />
              or merge with existing locations?

              {hasAdProgramsDisabledMerge && (
                <>
                  <div style={{
                    marginTop: '24px',
                    marginBottom: '8px',
                  }}
                  >
                    * Ad Locations are disabled in this Target Group
                  </div>
                  <div style={{
                    marginTop: '8px',
                  }}
                  >Locations currently ad disabled in the target groups
                    will not be copied to this flight/segment.
                  </div>
                </>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 12,
                  marginTop: 32,
                }}
              >
                <VibeButton
                  variant="outlined"
                  text="Replace"
                  color="secondary"
                  loadingEvent="onReplaceLocations"
                  onClick={this.onConfirmReplaceLocations}
                />

                <VibeButton
                  text="Merge"
                  color="primary"
                  loadingEvent="onMergeLocations"
                  onClick={this.onConfirmMergeLocations}
                />
              </div>
            </div>
          )}
          onClose={this.onCloseMergeModal}
        />

        <VibeModal
          show={hasAdProgramsDisabled}
          type="confirm"
          title="Ad Locations are disabled in this Target Group"
          confirmProps={{
            text: 'OK',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          text="Locations currently ad disabled in the target groups will not be copied to this flight/segment."
          onConfirm={this.onConfirmAdProgramModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TargetGroupsUnassigned.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
  locationsData: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
  }),
  /** Custom filters for the API endpoint on unasssigned locations */
  filtersUnassigned: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Update the object data */
  onUpdate: PropTypes.func,
};

TargetGroupsUnassigned.defaultProps = {
  locations: [],
  locationsData: {
    companies: [],
    locations: [],
  },
  filtersUnassigned: {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetGroupsUnassigned);
