import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import toNumber from 'lodash/toNumber';
import { Grid } from '@mui/material';
import {
  Field2 as Field,
  VibeIcon,
  viTrash,
  viPlayCircleOutline,
  color,
  VibeButton,
  GlobalActions,
} from 'vibeguide';
import CreativeMedia from '../../Orders/OrderSegment/CreativeMedia';
import './DayPart.scss';
import DayPartCreative from './DayPartCreative';
import CreativeDateTime from './CreativeDateTime';

function DayPart({
  advertiserId,
  segmentLocations,
  partIndex,
  part,
  totalParts,
  hourOptions,
  canAddMedia,
  disableMedia,
  onTimeChange,
  onCreativeChange,
  onSelectCreativeMedia,
  updateDayParts,
  startDate,
  endDate,
  editMedia,
  onDelete,
  setPanel,
  onDateRangeStartDateChange,
  onDateRangeEndDateChange,
  mediaDateErrors,
}) {
  const onSelectMedia = (dayPart, dateRangeIndex, data) => {
    const creatives = data.map(message => {
      return {
        name: message.name,
        creativeId: message._id,
        url: message.url,
        durationSeconds: message.durationSeconds,
        fileSize: message.fileSizeBytes || '',
        fileType: message.contentType || '',
        graphicSize: message.graphicSize || '',
        weight: message.weight || 0,
      };
    });

    onSelectCreativeMedia({
      creatives,
      dateRangeIndex,
      dayPart,
    });
  };

  const onCreativeUpdate = (creative, dateRangeIndex) => {
    const updatedCreatives = part.dateRanges[dateRangeIndex].creatives.map(c => {
      return c.creativeId === creative.creativeId ? creative : c;
    });

    onCreativeChange({
      creatives: updatedCreatives,
      dateRangeIndex,
    });
  };

  const onCreativeStartDateUpdate = (data) => {
    onDateRangeStartDateChange({
      startDate: data.startDate,
      dateRangeIndex: data.dateRangeIndex,
    });
  };

  const onCreativeEndDateUpdate = (data) => {
    onDateRangeEndDateChange({
      endDate: data.endDate,
      dateRangeIndex: data.dateRangeIndex,
    });
  };

  const onCreativeDelete = (creative, dateRangeIndex) => {
    /* eslint-disable-next-line max-len */
    const updatedCreatives = part.dateRanges[dateRangeIndex].creatives.filter(c => c.creativeId !== creative.creativeId);

    onCreativeChange({
      dateRangeIndex,
      creatives: updatedCreatives,
    });
  };

  const onAddCreative = (rangeIndex) => {
    setPanel({
      extraPanel: {
        show: true,
        backdrop: false,
        width: window.innerWidth,
        children: (
          <CreativeMedia
            dayPart={`${part.startTime}-${part.endTime}`}
            dateRangeIndex={rangeIndex}
            locations={segmentLocations}
            advertiserId={!advertiserId ? null : advertiserId}
            onSelectMedia={onSelectMedia}
          />
        ),
      },
    });
  };

  /**
   * Show Creative Dates - for first creative set in a dayPart, show on click, for each successive
   * creative, show with date range by default
   */
  const clickShowCreativeDates = () => {
    const newDateRange = {
      startDate: null,
      endDate: null,
      creatives: [],
    };

    part.dateRanges.push(newDateRange);

    const updatedPart = {
      ...part,
    };

    updateDayParts(updatedPart, partIndex);
  };

  const clickDeleteCreativeDates = (dateRangeIndex) => {
    const updatedPart = {
      ...part,
    };
    updatedPart.dateRanges.splice(dateRangeIndex, 1);

    updateDayParts(updatedPart, partIndex);
  };

  const getTotalCreativeWeight = (dateRangeIndex) => {
    const totalCreativeWeight = canAddMedia && part.dateRanges[dateRangeIndex].creatives?.length > 0
      ? part.dateRanges[dateRangeIndex].creatives?.reduce((accumulator, creative) => {
        return accumulator + toNumber(creative.weight);
      }, 0) || 0
      : 0;
    return totalCreativeWeight !== 100;
  };

  const disableByLocation = !segmentLocations || Object.keys(segmentLocations).length === 0;

  return (
    <div className="DayPart">
      <div className="part-number">
        <div>
          Day Part {partIndex + 1}
        </div>
        {totalParts > 1 && (
          <VibeIcon
            icon={viTrash}
            color={color.primary}
            hoverColor={color.primary60}
            size={16}
            tooltip="Delete Day Part"
            onClick={() => onDelete(partIndex)}
          />
        )}
      </div>

      <Grid
        spacing={2}
        container
      >

        <Grid
          xs={6}
          item
        >
          <Field
            type="select"
            label="Start Time"
            name="startTime"
            placeholder="Choose Time"
            value={!part.startTime ? '' : part.startTime}
            options={[
              {
                label: '',
                value: '',
              },
              ...hourOptions,
            ]}
            tabIndex={15}
            onChange={onTimeChange}
            disabled={editMedia}
            required
          />
        </Grid>
        <Grid
          xs={6}
          item
        >
          <Field
            type="select"
            label="End Time"
            name="endTime"
            value={!part.endTime ? '' : part.endTime}
            options={[
              {
                label: '',
                value: '',
              },
              ...hourOptions,
            ]}
            tabIndex={15}
            onChange={onTimeChange}
            disabled={editMedia}
            required
          />
        </Grid>
      </Grid>

      {canAddMedia && (
        part.dateRanges.map((dateRange, rangeIndex) => (
          <Grid spacing={2} container key={`date-range-${rangeIndex}`}>
            <Grid xs={12} item>
              <CreativeDateTime
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                segmentStartDate={startDate}
                segmentEndDate={endDate}
                partIndex={partIndex}
                dateRangeIndex={rangeIndex}
                onStartDateUpdate={onCreativeStartDateUpdate}
                onEndDateUpdate={onCreativeEndDateUpdate}
                clickDeleteCreativeDates={clickDeleteCreativeDates}
                mediaDateErrors={mediaDateErrors}
              />
            </Grid>
            <Grid xs={12} item>
              <div className="creatives">
                {dateRange.creatives?.map((creative, index) => (
                  <DayPartCreative
                    key={`day-part-creative-${index}`}
                    index={index}
                    dateRangeIndex={rangeIndex}
                    creative={creative}
                    newTotalCreativeWeight={() => getTotalCreativeWeight(rangeIndex)}
                    onUpdate={onCreativeUpdate}
                    onRemove={onCreativeDelete}
                  />
                ))}

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className="creative-btn"
                    style={{
                      flexGrow: 1,
                    }}
                  >
                    <VibeButton
                      className="custom-button"
                      style={{
                        marginRight: 8,
                        borderRadius: 16,
                        border: 'none',
                        height: '22px',
                      }}
                      text="Add Media"
                      iconProps={{
                        style: {
                          color: color.whiteSmoke,
                        },
                      }}
                      color="secondary"
                      icon={viPlayCircleOutline}
                      onClick={() => onAddCreative(rangeIndex)}
                      tooltip={(disableMedia || disableByLocation)
                        ? 'Locations & Flight must be selected to add media'
                        : ''}
                      disabled={disableMedia || disableByLocation}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        ))
      )}

      <VibeButton
        style={{
          marginTop: 16,
        }}
        text="Add Active Dates"
        color="secondary"
        textColor="white"
        onClick={clickShowCreativeDates}
      />
    </div>
  );
}

DayPart.propTypes = {
  advertiserId: PropTypes.string,
  segmentLocations: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  partIndex: PropTypes.number,
  part: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    dateRanges: PropTypes.arrayOf(PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      creatives: PropTypes.arrayOf(PropTypes.shape({
        creativeId: PropTypes.string,
        weight: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
    })),
  }),
  totalParts: PropTypes.number,
  hourOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  canAddMedia: PropTypes.bool,
  disableMedia: PropTypes.bool,
  onTimeChange: PropTypes.func,
  onCreativeChange: PropTypes.func,
  onDelete: PropTypes.func,
};

DayPart.defaultProps = {
  advertiserId: '',
  segmentLocations: [],
  partIndex: 0,
  part: {
    startTime: null,
    endTime: null,
    dateRanges: [],
  },
  totalParts: 0,
  hourOptions: [],
  canAddMedia: false,
  disableMedia: false,
  onTimeChange: () => {},
  onCreativeChange: () => {},
  onDelete: () => {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(DayPart);
