import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/events`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  getById: async (id, options = {}) => {
    options = addVersion('3.0.4', options);
    const response = await Http.get(`${baseUrl}/${id}`, options);
    const interrupts = response.interrupts ?? [];
    const messageParts = response.messageParts ?? [];
    const musicParts = response.musicParts ?? [];

    return {
      ...response,
      interrupts: interrupts.map(item => {
        const detail = item.detail ?? {};

        return {
          ...item,
          detail: {
            ...detail,
            url: Http.cloudfront(detail.url),
          },
        };
      }),
      messageParts: messageParts.map(item => {
        const detail = item.detail ?? {};
        const messages = detail.messages ?? [];

        return {
          ...item,
          detail: {
            ...detail,
            messages: messages.map(message => {
              return {
                ...message,
                url: Http.cloudfront(message.url),
              };
            }),
          },
        };
      }),
      musicParts: musicParts.map(item => {
        const detail = item.detail ?? {};

        return {
          ...item,
          detail: {
            ...detail,
            imageUrl: Http.cloudfront(detail.imageUrl),
          },
        };
      }),
    };
  },
  getExpandedLocations: async (id, options) => {
    const response = await Http.get(`${baseUrl}/${id}/locations/expanded`, options) ?? [];

    return response.map(item => {
      const locations = item.locations ?? [];

      return {
        ...item,
        imageUrl: Http.cloudfront(item.imageUrl),
        locations: locations.map(location => {
          return {
            ...location,
            imageUrl: Http.cloudfront(location.imageUrl),
          };
        }),
      };
    });
  },
  create: data => Http.post(baseUrl, data),
  update: data => Http.put(`${baseUrl}/${data._id}`, data),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
};
