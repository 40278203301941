import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isArray,
} from 'lodash';
import {
  setPanel,
} from '../../actions/Global/GlobalActions';
import TableMessageBlocks from '../VibeTable/Tables/TableMessageBlocks';
import MessageBlocksUnassigned from './MessageBlocksUnassigned';
import VibeButton from '../VibeButton/VibeButton';
import VibeIcon from '../VibeIcon/VibeIcon';
import viClose from '../../icons/viClose';
import viAdd from '../../icons/viAdd';
import color from '../../sass/color.scss';
import './MessageBlocksAssigned.scss';

function MessageBlocksAssigned({
  className,
  companyId,
  locations,
  messageBlocks,
  disabled,
  setPanel,
  onUpdate,
}) {
  const columns = [
    'Name',
    'Company',
    'Integration',
    'Frequency',
    'Consecutive',
    'Start Type',
    'Sequence',
    // Opening a message in the library does not give a tag object, only the ID
    // 'Tags',
    '...',
  ];

  // show the add or remove column
  if (!disabled) {
    columns.unshift('Add/Remove');
  }

  /**
   * Close the second panel on the sidebar
   */
  const onClose = () => {
    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * When a row is removed from the assigned table
   */
  const onRemoveItems = (rows) => {
    if (!isArray(rows)) {
      rows = [rows];
    }

    const rowIds = rows.map(row => row._id);
    const newMessageBlocks = messageBlocks.filter(messageBlock => !rowIds.includes(messageBlock._id));

    onUpdate({
      messageBlocks: newMessageBlocks,
      messageLists: newMessageBlocks,
    });
  };

  /**
   * Open the unassigned panel
   */
  const openUnassigned = () => {
    setPanel({
      thirdPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <MessageBlocksUnassigned
            companyId={companyId}
            locations={locations}
            messageBlocks={messageBlocks}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  return (
    <div className={classNames('MessageBlocksAssigned', className)}>
      <div className="title-container">
        <div className="title">
          Assigned Message Blocks
        </div>

        <VibeIcon
          className="close"
          icon={viClose}
          color={color.manatee}
          hoverColor={color.obsidian}
          size={24}
          onClick={onClose}
        />
      </div>

      <div className="message-blocks-container">
        <TableMessageBlocks
          columns={columns}
          collection={messageBlocks}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Message Blocks',
            urlPaging: false,
            urlFilters: false,
          }}
          emptyProps={{
            title: 'No Message Blocks Assigned',
            description: 'This object has not been assigned to any message blocks.',
          }}
          toolbarProps={{
            customButtons: !disabled
              ? [(
                <VibeButton
                  key="assign-message-blocks"
                  text="Assign Message Blocks"
                  color="primary"
                  icon={viAdd}
                  onClick={openUnassigned}
                />
              )]
              : [],
          }}
          disableCardView
          assigned
          highlight={messageBlocks}
          onRemove={onRemoveItems}
        />
      </div>
    </div>
  );
}

MessageBlocksAssigned.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  messageBlocks: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func,
};

MessageBlocksAssigned.defaultProps = {
  className: '',
  companyId: '',
  locations: [],
  messageBlocks: [],
  disabled: false,
  onUpdate: () => {},
};

const mapDispatchToProps = {
  setPanel,
};

export default connect(null, mapDispatchToProps)(MessageBlocksAssigned);
