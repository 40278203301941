import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  TablePermissionSets,
  API,
  GlobalActions,
  NavigationHelper,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../Header/SubNavigation';
import NewPermissionSetContainer from './Permissions/NewPermissionSetContainer';
import './PermissionSetList.scss';

class PermissionSetList extends PureComponent {
  constructor(props) {
    super(props);

    this.sidebarTimeout = null;

    this.state = {
      selectedSets: [],
    };
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.permissionSetId && qs.type !== 'new';

    if (isNew || isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.permissionSetId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.permissionSetId || qs.type === 'new') {
        this.sidebar(qs.type, qs.permissionSetId);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.sidebarTimeout);
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      permissionSetId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  onToggleBulk = (type, selected) => {
    // only toggle for bulk edit mode
    if (type === 'edit') {
      this.setState({
        selectedSets: selected,
      }, () => {
        this.sidebar('update');
      });
    }
  };

  sidebar = (type, permissionSetId = null) => {
    const {
      selectedSets,
    } = this.state;

    const {
      setPanel,
    } = this.props;

    const formatSelectedItems = (selectedSets.length || 0).toLocaleString();

    setPanel({
      title: type === 'update' ? `Bulk Update ${formatSelectedItems} Permission Sets` : 'Permission Set',
      show: true,
      backdrop: type === 'new' || type === 'update',
      children: (
        <NewPermissionSetContainer
          permissionSetId={permissionSetId}
          isNew={type === 'new'}
          isBulkUpdate={type === 'update'}
          selectedSets={selectedSets}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    return (
      <div className="PermissionSetList">
        <SubNavigation
          title="Permission Sets"
          buttons={[
            {
              text: 'New Permission Set',
              icon: viAdd,
              color: 'primary',
              url: NavigationHelper.updateParams({
                type: 'new',
              }),
              userCan: [
                'permissionset.create',
              ],
            },
          ]}
        />

        <TablePermissionSets
          columns={[
            '.',
            'Name',
            'Description',
            '...',
          ]}
          fetch={API.PermissionSet.list}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Permission Sets',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Permission Sets',
            tooltip: true,
          }}
          bulk
          bulkProps={{
            edit: true,
            editType: 'sidebar',
            add: false,
            block: false,
            remove: false,
            archive: false,
            onToggle: this.onToggleBulk,
          }}
          rowLink={{
            permissionSetId: '_id',
          }}
          menuItems={[
            { name: 'Archive', userCan: 'permissionset.delete' },
          ]}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    showPanel: state.global.panel.show,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PermissionSetList));
