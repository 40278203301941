import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  API,
  LocationHelper,
  NavigationHelper,
  GlobalActions,
  ToastActions,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Assignments,
  VibeModal,
  VibeTooltip,
  VibeButton,
  VibeIcon,
  viClose,
  viCopy,
  viArchive,
  viUnarchive,
  withRouter,
  color,
} from 'vibeguide';
import Information from './Information';
import MixPlaylists from './MixPlaylists';
import MixLocations from './MixLocations';
import './MixDetails.scss';

class MixDetails extends PureComponent {
  constructor(props) {
    super(props);

    // Store which values are updated that require an endpoint
    this.updated = {};
    this.origMix = {};
    this.origMixSaved = false;
    this.mixImageFile = null;

    this.state = {
      confirmArchive: false,
    };
  }

  componentDidUpdate() {
    const {
      mix,
    } = this.props;

    if (!this.origMixSaved && mix._id) {
      this.origMixSaved = true;
      this.origMix = mix;
    }

    const {
      origMix,
    } = this;

    if (this.origMixSaved) {
      // Only track what fields are updated once the original mix is saved
      if (mix.mixs !== origMix.mixs) {
        this.updated.mixs = true;
      }
    }
  }

  /**
   * When the locations assigned is clicked
   */
  onClickLocations = () => {
    const {
      user,
      mix: {
        _id,
        companyId,
        locations,
        locationsData,
      },
      setPanel,
      onUpdate,
    } = this.props;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.can('mix.assign_locations')
      && ((_id && user.can('mix.modify'))
        || (!_id && user.can('mix.create')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <MixLocations
            companyId={companyId}
            locations={locations}
            locationsData={locationsData}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * When the Playlists assigned is clicked
   */
  onClickPlaylists = () => {
    const {
      user,
      mix: {
        _id,
        companyId,
        playlists,
        locations,
      },
      setPanel,
      onUpdate,
    } = this.props;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.exceedsLocations(locations)
      && ((_id && user.can('mix.modify'))
        || (!_id && user.can('mix.create')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <MixPlaylists
            mixId={_id}
            playlists={playlists}
            locations={locations}
            onClose={this.onCloseExtra}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * When the extra panel is closed
   */
  onCloseExtra = () => {
    const {
      onCloseExtra,
    } = this.props;

    onCloseExtra();
  };

  /**
   * When the archive button is clicked
   */
  onClickArchive = () => {
    this.setState({
      confirmArchive: true,
    });
  };

  /**
   * When the unarchive button is clicked
   */
  onClickUnarchive = async () => {
    const {
      mix: {
        _id,
        locations,
      },
      onClose,
    } = this.props;

    await API.Mix.reactivate({
      _id,
      locations,
    });

    onClose();
    document.dispatchEvent(new Event('onSaveStation'));
  };

  /**
   * When the archive modal is closed
   */
  onCloseArchive = () => {
    this.setState({
      confirmArchive: false,
    });
  };

  /**
   * When the archive modal is confirmed
   */
  onConfirmArchive = async () => {
    const {
      mix: {
        _id,
      },
      onClose,
    } = this.props;

    await API.Mix.deactivate(_id);

    this.onCloseArchive();
    onClose();

    document.dispatchEvent(new Event('onSaveStation'));
  };

  onUploadImage = (file) => {
    this.mixImageFile = file;
  };

  /**
   * Save the Mix
   */
  onSave = async () => {
    const {
      // user,
      mix: {
        _id,
        name,
        companyId,
        description,
        locations,
        playlists,
        tags,
      },
      history,
      isNew,
      queueToast,
    } = this.props;

    document.dispatchEvent(new Event('onSaveStationStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const playlistPercents = playlists.map(playlist => {
      return {
        playlistId: playlist._id,
        mixPercent: playlist.mixPercent,
        locked: playlist.locked,
      };
    });
    const locationData = locations;

    // Get tags to add/remove
    const modifyTags = tags ? tags.filter(tag => tag.status === 'add' || tag.status === 'remove') : [];
    const mixId = _id;

    // Data to create mix
    const data = {
      name,
      companyId,
      locations: locationData,
      playlists: playlistPercents,
      description,
      tags: modifyTags.map((tag) => {
        return {
          _id: tag._id,
          action: tag.status,
        };
      }),
    };

    let modify = false;

    if (mixId && !isNew) {
      // Editing or saving an item
      data._id = mixId;
      modify = true;
    }

    try {
      const mixResponse = modify
        ? await API.Mix.modify(mixId, data)
        : await API.Mix.create(data);
      const newMixId = get(mixResponse, '[0].documentId', null);

      if (this.mixImageFile) {
        // Upload mix image
        const uploadMixImage = await API.Mix.uploadImage(newMixId, this.mixImageFile);
        const uploadMixImageId = get(uploadMixImage, '[0].documentId', null);

        if (uploadMixImageId) {
          // Image was successful
          queueToast({
            type: 'success',
            title: 'Saved!',
            allowClose: true,
          });
        } else {
          queueToast({
            type: 'error',
            title: 'Error Saving Mix',
            timeout: 10,
            allowClose: true,
          });
        }
      } else {
        // No image to upload
        queueToast({
          type: 'success',
          title: 'Saved!',
          allowClose: true,
        });
      }

      if (isNew) {
        const redirectUrl = NavigationHelper.updateParams({
          stationId: newMixId,
          type: null,
        });

        history(redirectUrl);
      }

      document.dispatchEvent(new Event('onSaveStation'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveStationError'));
    }
  };

  render() {
    const {
      user,
      mix,
      onClose,
      onUpdate,
      isNew,
      // onRefresh,
      isDuplicate,
    } = this.props;

    const {
      confirmArchive,
    } = this.state;

    // ACAF match
    const isACAF = user.isAllLocations({
      companyId: mix.companyId,
      locations: mix.locations,
    });

    const locationCount = LocationHelper.getLocationCount(get(mix, 'locationsData.companies', []), isACAF);
    const playlistCount = get(mix, 'playlists.length', 0);
    const exceedsLocations = user.exceedsLocations(mix.locations);

    // disable the assignment sections if not filled in
    const disablePlaylists = get(mix, 'locations.length', 0) <= 0
      || !user.can('playlist.view');

    const disableSave = !mix.name
      || !mix.companyId
      || !mix.locations
      || !mix.playlists;

    const disableInput = !user.hasAccessToCompany(mix.companyId)
      || !exceedsLocations
      || (mix._id && !user.can('mix.modify'))
      || (!mix._id && !user.can('mix.create'));

    const showArchive = user.can('mix.delete')
      && user.hasAccessToCompany(mix.companyId)
      && exceedsLocations;
    const showDuplicate = user.can('mix.create')
      && exceedsLocations;

    const isArchived = !mix.active;

    return (
      <SidePanelContainer className="MixDetails">
        <SidePanelHeader
          icons={(
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onClose}
            />
          )}
        >
          {mix._id ? (
            <div className="flex-horizontal">
              <div className="title">
                <VibeTooltip title={mix.name}>
                  <span>
                    {mix.name}
                  </span>
                </VibeTooltip>
              </div>
            </div>
          ) : (
            <div className="title">
              New Station
            </div>
          )}
        </SidePanelHeader>

        <SidePanelContent>
          <Information
            name={mix.name}
            description={mix.description}
            image={mix.imageUrl}
            companyName={mix.companyName}
            companyId={mix.companyId}
            tags={mix.tags}
            isNew={isNew}
            isDuplicate={isDuplicate}
            disableInput={disableInput}
            onUpdate={onUpdate}
            onUploadImage={this.onUploadImage}
          />

          <Assignments
            items={[{
              label: 'Locations Assigned',
              count: locationCount.display.element,
              tooltip: locationCount.display.tooltip,
              required: true,
              disabled: !mix.companyId
                || !exceedsLocations,
              warning: !exceedsLocations
                ? 'You do not have access to all the locations assigned to this object'
                : null,
              onClick: mix.companyId && exceedsLocations
                ? this.onClickLocations
                : null,
            },
            {
              label: 'Playlists Assigned',
              count: playlistCount,
              required: true,
              disabled: disablePlaylists,
              warning: !user.can('playlist.view')
                ? 'You do not have permission to view playlists'
                : null,
              onClick: !disablePlaylists
                ? this.onClickPlaylists
                : null,
            }]}
          />
        </SidePanelContent>

        <SidePanelFooter>
          <VibeButton
            text="Save Changes"
            color="secondary"
            loadingEvent="onSaveStation"
            disabled={disableSave || disableInput}
            onClick={this.onSave}
          />

          {!isNew && (
            <>
              {showDuplicate && (
                <VibeIcon
                  icon={viCopy}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.primary,
                  }}
                  color={color.primary}
                  size={16}
                  tooltip="Duplicate"
                  link={NavigationHelper.updateParams({
                    type: 'new',
                  })}
                />
              )}

              {showArchive && !isArchived && (
                <VibeIcon
                  icon={viArchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.error,
                  }}
                  tooltip="Archive"
                  color={color.error}
                  size={20}
                  onClick={this.onClickArchive}
                />
              )}

              {showArchive && isArchived && (
                <VibeIcon
                  icon={viUnarchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.success,
                  }}
                  tooltip={locationCount.total > 0
                    ? 'Unarchive'
                    : 'Must have at least 1 active location to unarchive'}
                  color={color.success}
                  size={20}
                  disabled={locationCount.total <= 0}
                  onClick={this.onClickUnarchive}
                />
              )}
            </>
          )}
        </SidePanelFooter>

        <VibeModal
          show={confirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${mix.name}?`}
          confirmProps={{
            text: 'Archive',
            color: 'error',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCloseArchive}
        />
      </SidePanelContainer>
    );
  }
}

MixDetails.propTypes = {
  /** New Mix */
  mix: PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    description: PropTypes.string,
    durationSeconds: PropTypes.number,
    imageUrl: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({
      companyId: PropTypes.string,
      locationId: PropTypes.string,
    })),
    playlistCount: PropTypes.number,
    name: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onCloseExtra: PropTypes.func,
  onUpdate: PropTypes.func,
  /** Refresh the mix from the API */
  // onRefresh: PropTypes.func,
};

MixDetails.defaultProps = {
  mix: {},
  onClose: () => {},
  onCloseExtra: () => {},
  onUpdate: () => {},
  // onRefresh: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MixDetails));
