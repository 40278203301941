import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  toInteger,
} from 'lodash';
import {
  TimeUtil,
  Field2 as Field,
  VibeButton,
  VibeModal,
  VibeIcon,
  viMicrophone,
  viDollar,
  viTrash,
  viClose,
  color,
} from 'vibeguide';
import Popover from '@mui/material/Popover';
import './InterruptItemFullView.scss';

function InterruptItemFullView({
  className,
  interrupt,
  isEvent,
  readOnly,
}) {
  const itemRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removeOption, setRemoveOption] = useState('single');
  const [startTime, setStartTime] = useState(interrupt.startTime);
  const [repeat, setRepeat] = useState(false);
  const [repeatRangeStart, setRepeatRangeStart] = useState('00:00');
  const [repeatRangeStop, setRepeatRangeStop] = useState('24:00');
  const [repeatInterval, setRepeatInterval] = useState('30');
  const [repeatUnit, setRepeatUnit] = useState('minutes');
  const [repeatError, setRepeatError] = useState('');

  const name = get(interrupt, 'message.name', 'Unknown');
  const time = TimeUtil.convertToTwelveHour({ time: interrupt.startTime });
  const isAd = get(interrupt, 'message.messageType') === 'ad';

  const onClick = () => {
    setShowPopover(true);
  };

  const onClickRemove = () => {
    setShowRemoveModal(true);
  };

  const onClosePopover = () => {
    setShowPopover(false);
    setStartTime(interrupt.startTime);
    setRepeat(false);
  };

  const onChangeStartTime = (data) => {
    setStartTime(data.time24);
  };

  const toggleRepeat = () => {
    setRepeat(!repeat);
  };

  const onChangeRepeatStart = (data) => {
    setRepeatRangeStart(data.time24);
  };

  const onChangeRepeatStop = (data) => {
    setRepeatRangeStop(data.time24);
  };

  const onChangeRepeatInterval = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    setRepeatInterval(value);
  };

  const onChangeRepeatUnit = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    setRepeatUnit(value);
  };

  const onConfirmRemove = () => {
    document.dispatchEvent(new CustomEvent('onRemoveItem', {
      detail: {
        type: 'Message',
        _uniqueId: interrupt._uniqueId,
        removeOption,
      },
    }));

    setShowRemoveModal(false);
    setShowPopover(false);
  };

  const onCancelRemove = () => {
    setShowRemoveModal(false);
  };

  const onSave = () => {
    document.dispatchEvent(new CustomEvent('onChangeItemDuration', {
      detail: {
        type: 'Message',
        startTime,
        _uniqueId: interrupt._uniqueId,
      },
    }));

    if (repeat) {
      // copy the interrupt to timeslots that match the repeat settings
      document.dispatchEvent(new CustomEvent('onRepeatInterrupt', {
        detail: {
          rangeStart: repeatRangeStart,
          rangeStop: repeatRangeStop,
          interval: toInteger(repeatInterval),
          unit: repeatUnit,
          _uniqueId: interrupt._uniqueId,
        },
      }));
    }

    setRemoveOption('single');
    setRepeat(false);
    setRepeatRangeStart('00:00');
    setRepeatRangeStop('00:00');
    setRepeatInterval('30');
    setRepeatUnit('minutes');
    setRepeatError('');
    setShowPopover(false);
  };

  return (
    <div className={classNames('InterruptItemFullView', className)}>
      <div
        ref={itemRef}
        className="content"
        onClick={!readOnly
          ? onClick
          : null}
      >
        <VibeIcon
          className="icon"
          icon={viMicrophone}
          color={color.primary}
          size={16}
        />

        <div className="name">
          {name}
        </div>

        {isAd && (
          <VibeIcon
            className="icon"
            icon={viDollar}
            color={color.success}
            size={16}
          />
        )}

        <div className="time">
          {time}
        </div>
      </div>

      <Popover
        anchorReference="anchorEl"
        anchorEl={itemRef.current}
        open={showPopover}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{
          root: 'ItemPopperRoot',
          paper: 'ItemPopper interrupt-popper',
        }}
      >
        <div className="item-details-container">
          <div className="item-details">
            <div className="details-toolbar">
              <div
                style={{
                  flexGrow: 1,
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}
              >
                {name}
              </div>

              <VibeIcon
                icon={viTrash}
                color={color.manatee}
                hoverColor={color.obsidian}
                style={{
                  marginRight: 4,
                }}
                onClick={onClickRemove}
              />

              <VibeIcon
                icon={viClose}
                color={color.manatee}
                hoverColor={color.obsidian}
                onClick={onClosePopover}
              />
            </div>
            <div className="time-fields">
              <div className="start-time only-start">
                <Field
                  type="time"
                  label="Start Time"
                  placeholder="9:00am"
                  value={startTime}
                  onChange={onChangeStartTime}
                />
              </div>
            </div>

            <div className="repeat-container">
              <Field
                className="repeat-toggle"
                type="toggle"
                toggleProps={{
                  label: 'Repeat',
                }}
                value={repeat
                  ? 'on'
                  : 'off'}
                onChange={toggleRepeat}
              />

              {repeat ? (
                <div>
                  <div className="repeat-range">
                    <div className="repeat-title">
                      Time Range
                    </div>

                    <div className="repeat-options">
                      <Field
                        type="time"
                        value={repeatRangeStart}
                        onChange={onChangeRepeatStart}
                      />

                      <span className="repeat-to">
                        to
                      </span>

                      <Field
                        type="time"
                        value={repeatRangeStop}
                        timeProps={{
                          endOfDay: true,
                        }}
                        onChange={onChangeRepeatStop}
                      />
                    </div>
                  </div>

                  <div className="repeat-interval">
                    <div className="repeat-title">
                      Every
                    </div>

                    <div className="repeat-options">
                      <div
                        style={{
                          width: 50,
                        }}
                      >
                        <Field
                          type="text"
                          value={repeatInterval}
                          onChange={onChangeRepeatInterval}
                        />
                      </div>

                      <div
                        style={{
                          flexGrow: 1,
                          marginLeft: 4,
                        }}
                      >
                        <Field
                          type="select"
                          value={repeatUnit}
                          options={[
                            { label: 'minutes', value: 'minutes' },
                            { label: 'hours', value: 'hours' },
                          ]}
                          onChange={onChangeRepeatUnit}
                        />
                      </div>
                    </div>
                  </div>

                  {repeatError ? (
                    <div className="repeat-error">
                      {repeatError}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>

            <div
              style={{
                marginTop: 8,
                textAlign: 'center',
              }}
            >
              <VibeButton
                text="Save"
                color="secondary"
                disabled={repeatError !== ''}
                onClick={onSave}
              />
            </div>
          </div>
        </div>
      </Popover>

      <VibeModal
        show={showRemoveModal}
        type="confirm"
        width={376}
        confirmProps={{
          text: 'Remove',
          color: 'error',
        }}
        cancelProps={{
          text: 'Cancel',
        }}
        title={`Remove ${name}`}
        onConfirm={onConfirmRemove}
        onClose={onCancelRemove}
      >
        {isEvent ? (
          <div>
            <Field
              type="radio"
              label="Just this one"
              radioProps={{
                selected: removeOption === 'single',
              }}
              onClick={() => setRemoveOption('single')}
            />

            <Field
              type="radio"
              label="All instances of this interrupt on this event"
              radioProps={{
                selected: removeOption === 'all',
              }}
              onClick={() => setRemoveOption('all')}
            />
          </div>
        ) : (
          <div>
            <Field
              type="radio"
              label="Just this one"
              radioProps={{
                selected: removeOption === 'single',
              }}
              onClick={() => setRemoveOption('single')}
            />

            <Field
              type="radio"
              label="All instances of this interrupt on this day"
              radioProps={{
                selected: removeOption === 'day',
              }}
              onClick={() => setRemoveOption('day')}
            />

            {/* <Field
              type="radio"
              label="All instances of this interrupt on this baseline"
              radioProps={{
                selected: removeOption === 'all',
              }}
              onClick={() => setRemoveOption('all')}
            /> */}
          </div>
        )}
      </VibeModal>
    </div>
  );
}

InterruptItemFullView.propTypes = {
  className: PropTypes.string,
  interrupt: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  /** Is part of an event */
  isEvent: PropTypes.bool,
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
};

InterruptItemFullView.defaultProps = {
  className: '',
  isEvent: false,
  readOnly: false,
};

export default InterruptItemFullView;
