import { each, isUndefined } from 'lodash';
import {
  toSpec,
} from '../helpers/Location';

class Segment {
  constructor(data = {}) {
    this._id = data._id || '';
    this.active = data.active || false;
    this.adProvider = data.adProvider || '';
    this.allocateRemainder = data.allocateRemainder || false;
    this.allocationMode = data.allocationMode || 'equal-spread';
    this.budget = data.budget || 0;
    this.cpm = data.cpm || 0;
    this.computedCampaignTriadValue = data.computedCampaignTriadValue || null;
    this.currencyCode = data.currencyCode || '';
    this.daysOfWeek = data.daysOfWeek || [];
    this.dayParts = data.dayParts || [];
    this.deliveredImpressions = data.deliveredImpressions || 0;
    this.deliveredSpots = data.deliveredSpots || 0;
    this.flightId = data.flightId || '';
    // segments use a location group instead of a spec
    this.locations = data.locations || {};
    this.locationsData = data.locationsData || {
      companies: [],
      locations: [],
    };
    this.impressions = data.impressions || 0;
    this.name = data.name || '';
    this.mediaFormat = data.mediaFormat || '';
    this.missingCreatives = data.missingCreatives === undefined ? true : data.missingCreatives || false;
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.startTime = data.startTime || '';
    this.endTime = data.endTime || '';
    this.dayParts = data.dayParts || [{
      startTime: null,
      endTime: null,
      dateRanges: [{
        startDate: null,
        endDate: null,
        creatives: [],
      }],
    }];
    this.spotSelectionStrategy = data.spotSelectionStrategy || 'maximize-frequency';
    this.frequencyCap = data.frequencyCap || 0;
    this.frequencyCapped = data.frequencyCapped || false;
    this.spotType = data.spotType || '';
    this.spotsPerHour = data.spotsPerHour || 0;
    this.createdDate = data.createdDate || null;
    this.createdBy = data.createdBy || null;
    this.createdByUserName = data.createdByUserName || '';
    this.modifiedDate = data.modifiedDate || null;
    this.modifiedBy = data.modifiedBy || null;
    this.lastArchivedBy = data.lastArchivedBy || null;
    this.lastArchivedDate = data.lastArchivedDate || null;
    this.lastUnarchivedBy = data.lastUnarchivedBy || null;
    this.lastUnarchivedDate = data.lastUnarchivedDate || null;
    this.segmentType = data.segmentType || 'standard';
    this.targetImpressions = data.targetImpressions || 0;
    this.totalImpressions = data.totalImpressions || 0;
    this.totalSpots = data.totalSpots || 0;
    this.frequency = data.frequency || 0;
    this.pacingPercent = data.pacingPercent || 0;
    this.reportingAuthority = data.reportingAuthority || '';
    this.allowBackToBackAdDelivery = data.allowBackToBackAdDelivery || false;
    this.warnings = data.warnings || [];

    // convert the locations group to a location spec
    this.locationSpec = toSpec(this.locations);
    this.locationCount = this.locationSpec.length;

    // determine if all the calculator fields are set (determines if the calculator is locked by default)
    const allCalculatorFieldsSet = this.cpm > 0
      && this.budget > 0
      && this.impressions > 0;

    // only lock the calculator if the segment type is standard and all the fields are set
    const lockCalculator = this.segmentType === 'standard'
      && allCalculatorFieldsSet;

    // lock the calculator fields (to allow saving if all 3 fields are pupulated)
    this.lockCalculator = data.lockCalculator || lockCalculator;

    each(data, (val, key) => {
      if (isUndefined(this[key])) {
        // Add the property to the model
        this[key] = val;

        console.warn(`${key} is not added to Segment Model`);
      }
    });
  }
}

export default Segment;
