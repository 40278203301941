/**
 * Filter a list of columns based on filters supplied
 */
export function filterColumns(columns, fields, location, urlFilter) {
  let fieldsFromFilter = [];

  switch (urlFilter[0].status) {
    // Manage cancelled columns
    case 'cancelled': {
      if (urlFilter[0].orderType === 'past') {
        fieldsFromFilter = ['Cancelled Date'];
      } else {
        fieldsFromFilter = ['Cancelled Date'];
      }
      break;
    }

    case 'none': {
      fieldsFromFilter = fields;
      break;
    }

    default:
      break;
  }

  if (!columns.every(column => fieldsFromFilter.indexOf(column) === -1)) return columns;

  const updatedColumns = [...columns];

  let fieldCount = 1;

  columns.forEach((column, i) => {
    if ((i >= location && i < (location + fieldCount)) && fieldCount < (fieldsFromFilter.length + 1)) {
      const fieldResult = fieldsFromFilter[fieldCount - 1];
      updatedColumns.splice(i + (fieldCount - 1), 0, fieldResult);
      fieldCount++;
    }
  });

  return updatedColumns;
}

export default {
  filterColumns,
};
