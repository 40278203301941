import {
  get,
  set,
  omit,
  forEach,
  toInteger,
} from 'lodash';
import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';
import Message from '../../models/Message';

const baseUrl = `${process.env.VAPI_BASE_URL}/locations`;

export default {
  getById: async (id, options) => {
    options = addVersion('6.20.0', options);
    const response = await Http.get(`${baseUrl}/${id}`, options);

    return {
      ...response,
      imageUrl: Http.cloudfront(response.imageUrl),
    };
  },
  getUserSummary: id => Http.get(`${baseUrl}/${id}/users/summary`),
  getUserList: (data, options) => Http.post(`${baseUrl}/${data._id}/users`, data, options),
  getSchedule: (id, options = {}) => {
    options = addVersion('4.12.0', options);
    return Http.get(`${baseUrl}/${id}/schedule`, options);
  },
  create: (data, options) => {
    options = addVersion('8.8.0', options);
    return Http.post(baseUrl, data, options);
  },
  update: (data, options) => {
    options = addVersion('7.17.0', options);
    return Http.put(`${baseUrl}/${data._id}`, data, options);
  },
  updateSchedule: (data) => Http.put(`${baseUrl}/schedule`, data),
  updateVolume: (data) => Http.put(`${baseUrl}/${data._id}/volume`, data),
  uploadImage: (id, file) => Http.postFileUpload(`${baseUrl}/${id}/image`, file, 'image'),
  unblockSong: (data) => Http.delete(`${baseUrl}/${data._id}/blocked`, data),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: (id, options) => {
    options = addVersion('8.6.0', options);
    return Http.delete(`${baseUrl}/${id}`, {}, options);
  },
  deactivateBulk: (locations, options) => {
    options = addVersion('8.6.0', options);
    return Http.delete(baseUrl, {
      locations,
    }, options);
  },

  // Retrieve play queue data
  getPlayQueue: (id, type) => Http.get(`${baseUrl}/${id}/queue/${type}`),
  getNowPlaying: (id) => Http.get(`${baseUrl}/${id}/reporting/mediaplayed/last`),

  // Target Tags
  getTargetTags: id => Http.get(`${baseUrl}/${id}/targetingtags`),
  updateTargetTags: (data) => Http.put(`${baseUrl}/${data._id}/targetingtags`, data),
  bulkCopyTargetTags: (data) => Http.put(`${baseUrl}/targetingtags`, data),

  // Retrieve Location based content vv
  listBaselines: (data, options) => Http.post(`${baseUrl}/${data._id}/baselines`, data, options),
  listEvents: (id, data, options) => Http.post(`${baseUrl}/${id}/events`, data, options),
  listMixes: (data, options) => Http.post(`${baseUrl}/${data._id}/mixes`, data, options),
  listMessages: async (data, options) => {
    const response = await Http.post(`${baseUrl}/${data._id}/messages`, data, options);

    if (response.data) {
      for (let i = 0; i < response.data.length; i++) {
        const message = response.data[i];
        response.data[i] = new Message(message);
        response.data[i].url = Http.cloudfront(response.data[i].url);
      }
    }

    return response;
  },
  listMessageLists: async (data, options) => {
    const response = Http.post(`${baseUrl}/${data._id}/messagelists`, data, options);

    if (response.data) {
      for (let i = 0; i < response.data.length; i++) {
        const messageBlock = response.data[i];

        if (messageBlock.messages) {
          for (let y = 0; y < messageBlock.messages.length; y++) {
            const message = messageBlock.messages[y];
            messageBlock.messages[y] = new Message(message);
          }
        }
      }
    }

    return response;
  },
  listPlaylists: (data, options) => Http.post(`${baseUrl}/${data._id}/playlists`, data, options),
  listBlocked: (data, options) => {
    const tempo = get(data, 'filters.tempo');

    if (tempo) {
      // tempo must be a number
      set(data, 'filters.tempo', toInteger(tempo));
    }

    return Http.post(`${baseUrl}/${data._id}/blocked`, data, options);
  },

  // Flag data
  getFlagActivity: (id) => Http.get(`${baseUrl}/${id}/flag`),
  enableFlag: (id) => Http.post(`${baseUrl}/${id}/flag`),
  disableFlag: (id) => Http.delete(`${baseUrl}/${id}/flag`),
  addFlagComment: (data) => Http.post(`${baseUrl}/${data._id}/flag/comment`, data),

  // Bulk add tags
  bulkAddTags: (data) => Http.put(`${baseUrl}/tags`, data),

  // Add/Delete External IDs
  addExternalID: (data) => Http.post(`${baseUrl}/${data.id}/externalids/${data.source}/${data.extid}`),
  deleteExternalID: (data) => Http.delete(`${baseUrl}/${data.id}/externalids/${data.source}/${data.extid}`),
  // {{BASE_URL}}/api/locations/{{LOCATION_ID}}/externalids/{{EXTERNAL_ID_SOURCE}}/{{EXTERNAL_ID}}

  // Alerts
  Alerts: {
    list: (data, options) => Http.post(`${baseUrl}/reporting/alerts/open`, data, options),
    listLocation: (data, options) => Http.post(`${baseUrl}/${data._id}/reporting/alerts`, data, options),
    summary: (data, options) => Http.post(`${baseUrl}/reporting/alerts/open/summary`, data, options),

    Config: {
      // eslint-disable-next-line max-len
      listActive: (data, options) => Http.post(`${baseUrl}/reporting/alerts/config/locations`, data, options),
      get: (id, options) => Http.get(`${baseUrl}/${id}/reporting/alerts/config`, options),
      update: (data) => Http.put(`${baseUrl}/${data._id}/reporting/alerts/config`, data),
    },

    Volume: {
      get: () => Http.get(`${baseUrl}/reporting/alerts/config/volume`),
      update: (data) => Http.put(`${baseUrl}/reporting/alerts/config/volume`, data),
    },

    Audio: {
      get: () => Http.get(`${baseUrl}/reporting/alerts/config/audio`),
      update: (data) => Http.put(`${baseUrl}/reporting/alerts/config/audio`, data),
    },

    Battery: {
      get: () => Http.get(`${baseUrl}/reporting/alerts/config/battery`),
      update: (data) => Http.put(`${baseUrl}/reporting/alerts/config/battery`, data),
    },

    Memory: {
      get: () => Http.get(`${baseUrl}/reporting/alerts/config/memory`),
      update: (data) => Http.put(`${baseUrl}/reporting/alerts/config/memory`, data),
    },

    Network: {
      get: () => Http.get(`${baseUrl}/reporting/alerts/config/network`),
      update: (data) => Http.put(`${baseUrl}/reporting/alerts/config/network`, data),
    },

    Offline: {
      get: () => Http.get(`${baseUrl}/reporting/alerts/config/offline`),
      update: (data) => Http.put(`${baseUrl}/reporting/alerts/config/offline`, data),
    },
  },

  // Import locations file data
  Import: {
    create: (data) => Http.post(`${baseUrl}/import`, data),
    uploadImage: (id, image, onProgress) => Http.postFileUpload(
      `${baseUrl}/import/${id}/image`,
      image,
      'image',
      onProgress,
    ),
    uploadData: (id, data, onProgress) => Http.postFileUpload(
      `${baseUrl}/import/${id}/data`,
      data,
      'data',
      onProgress,
    ),
    run: (data) => Http.post(`${baseUrl}/import/${data._id}/run`, data),
  },

  // Player
  Player: {
    command: (data) => Http.post(`${baseUrl}/${data._id}/commands`, data),
  },

  // Telemetry
  Telemetry: {
    current: (data) => Http.post(`${baseUrl}/${data._id}/reporting/telemetry/current`, data),
    getCurrentList: (data, options) => Http.post(`${baseUrl}/reporting/telemetry`, data, options),
    getMediaPlayed: (data) => Http.post(`${baseUrl}/${data._id}/reporting/mediaplayed/log`, data),
    getLog: (data, options) => {
      options = addVersion('6.20.0', options);
      return Http.post(`${baseUrl}/${data._id}/reporting/telemetry/log`, data, options);
    },
    getOfflineSummary: (data, options) => {
      return Http.post(`${baseUrl}/reporting/telemetry/offlinesummary`, data, options);
    },
    getStatusSummary: (data, options) => {
      return Http.post(`${baseUrl}/reporting/telemetry/statussummary`, data, options);
    },
    getVersionSummary: (data, options) => {
      return Http.post(`${baseUrl}/reporting/telemetry/versionsummary`, data, options);
    },
    getConnectionSummary: (data, options) => {
      return Http.post(`${baseUrl}/reporting/telemetry/connectionsummary`, data, options);
    },

    // Specific device telemetry
    Device: {
      current: (data, options) => {
        const postData = omit(data, ['_id', 'deviceId']);
        return Http.post(`${baseUrl}/${data._id}/reporting/telemetry/${data.deviceId}/current`, postData, options);
      },
      getMediaPlayed: (data) => {
        const postData = omit(data, ['_id', 'deviceId']);
        return Http.post(`${baseUrl}/${data._id}/reporting/mediaplayed/${data.deviceId}/log`, postData);
      },
      getLog: (data, options) => {
        const postData = omit(data, ['_id', 'deviceId']);
        return Http.post(
          `${baseUrl}/${data._id}/reporting/telemetry/${data.deviceId}/log`, postData, options);
      },
      getLogDetails: (data, options) => {
        return Http.get(`${baseUrl}/${data._id}/reporting/telemetry/${data.deviceId}/log/${data.logId}`, options);
      },
      getImpressionsTracking: (data, options) => {
        const postData = omit(data, ['_id', 'deviceId']);
        // include the device ID or an empty string if one isn't provided
        const deviceId = get(data, 'deviceId', '');

        return Http.post(`${baseUrl}/${data._id}/reporting/impressiontracking/${deviceId}`, postData, options);
      },
    },
  },

  // Device Attributes
  DeviceAttributes: {
    list: (data) => Http.post(`${baseUrl}/${data._id}/deviceattributes`),
    update: (data) => Http.put(`${baseUrl}/${data._id}/deviceattributes`, data),
    bulkCopy: (data) => Http.put(`${baseUrl}/deviceattributes`, data),
  },

  // Device Config
  DeviceConfig: {
    get: (id, options) => {
      options = addVersion('6.20.0', options);
      return Http.get(`${baseUrl}/${id}/deviceConfig`, options);
    },
    update: (data, options) => {
      options = addVersion('6.20.0', options);
      return Http.put(`${baseUrl}/${data._id}/deviceConfig`, data, options);
    },
    reset: (id) => Http.put(`${baseUrl}/${id}/deviceConfig/reset`),
  },

  // Schedule Override
  ScheduleOverride: {
    get: async (id) => {
      const response = await Http.get(`${baseUrl}/${id}/schedule/override`);

      forEach(response.refs.mixes, (item) => {
        item.imageUrl = Http.cloudfront(item.imageUrl);
      });

      forEach(response.refs.playlists, (item) => {
        item.imageUrl = Http.cloudfront(item.imageUrl);
      });

      return response;
    },
    enable: (id) => Http.post(`${baseUrl}/${id}/schedule/override/enabled`),
    disable: (id) => Http.delete(`${baseUrl}/${id}/schedule/override/enabled`),
    update: (data) => Http.put(`${baseUrl}/${data._id}/schedule/override`, data),
    delete: (id) => Http.delete(`${baseUrl}/${id}/schedule/override`),
  },

  // Ad Programs
  AdPrograms: {
    list: (data, options) => Http.post(`${baseUrl}/${data.locationId}/adprograms/search`, data, options),
    get: (data) => Http.get(`${baseUrl}/${data.locationId}/adprograms/${data.adProgramId}`),
    update: (data, options) => Http.put(`${baseUrl}/${data.locationId}/adprograms/${data.adProgramId}`, data, options),
    updateEffectiveDates: (data, options) => {
      return Http.put(`${baseUrl}/${data.locationId}/adprograms/${data.adProgramId}/effectivedates`, data, options);
    },
    copyPreview: (data, options) => {
      return Http.post(`${baseUrl}/${data.locationId}/adprograms/${data.adProgramId}/copy/preview`, data, options);
    },
    copyApply: (data, options) => {
      return Http.post(`${baseUrl}/${data.locationId}/adprograms/${data.adProgramId}/copy/apply`, data, options);
    },
    terminate: (data, options) => {
      return Http.post(`${baseUrl}/${data.locationId}/adprograms/${data.adProgramId}/terminate`, data, options);
    },
  },
};
