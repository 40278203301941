import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  find,
  last,
} from 'lodash';
import classNames from 'classnames';
import API from '../../../api';
import Field from '../../Field/Field';
import VibeButton from '../../VibeButton/VibeButton';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viAdd from '../../../icons/viAdd';
import {
  setTargetTagActive,
  addTargetTag,
  updateTargetTag,
} from '../../../actions/TargetTag/TargetTagActions';
import color from '../../../sass/color.scss';
import './PanelDetailsEdit.scss';

/**
 * Utilize an async/await inside a forEach
 */
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

class PanelDetailsEdit extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
    } = props;

    this.state = {
      showAddChild: user.can('targeting_tag.create'),
      parentTag: {},
      childTags: [],
    };
  }

  componentDidMount() {
    const {
      targetTag: {
        _id,
      },
      isCreateChild,
    } = this.props;

    this.getParentTags(_id);

    if (isCreateChild) {
      this.addChild();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      targetTag: {
        _id,
      },
      onClose,
    } = this.props;

    const {
      targetTag: {
        _id: prevId,
      },
    } = prevProps;

    if (_id !== prevId) {
      // Force close the edit view when the active target tag changes
      onClose();
    }
  }

  /**
   * When a parent tag is changed
   */
  onChangeParentName = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState((state) => {
      const isNew = !state.parentTag._id;

      return {
        parentTag: {
          ...state.parentTag,
          name: value.trim(),
          update: !isNew,
          add: isNew,
          isNew,
        },
      };
    });
  };

  /**
   * When a child tag is changed
   */
  onChangeChildName = (e) => {
    const {
      target: {
        dataset: {
          id: qualifiedName,
        },
        value,
      },
    } = e;

    this.setState((state) => {
      return {
        childTags: state.childTags.map((childTag) => {
          if (qualifiedName === childTag.qualifiedName) {
            return {
              ...childTag,
              // Only update if the child tag has an ID
              update: childTag._id !== undefined,
              name: value.trim(),
            };
          }

          return childTag;
        }),
      };
    }, this.getQualifiedName);
  };

  /**
   * When add/edit is canceled
   */
  onCancel = () => {
    const {
      isLocation,
      setTargetTagActive,
      onClose,
    } = this.props;

    if (isLocation) {
      setTargetTagActive({
        _id: null,
      });
    }

    onClose();
  };

  /**
   * Recursively get parent tags
   */
  getParentTags = (parentId, childTags = []) => {
    const {
      targetTags,
    } = this.props;

    const parentTag = find(targetTags, { _id: parentId });

    if (parentTag && parentTag.parentId) {
      // Has more tags
      childTags.push(parentTag);
      this.getParentTags(parentTag.parentId, childTags);
    } else if (parentTag && !parentTag.parentId) {
      // Root level tag (no need to traverse anymore tag levels)
      this.setState({
        parentTag,
        childTags: childTags.reverse(),
      });
    }
  };

  /**
   * Get the Qualified Name for a tag
   */
  getQualifiedName = () => {
    const {
      onQualifiedNameChange,
    } = this.props;

    const {
      parentTag,
      childTags,
    } = this.state;

    let qualifiedName = (parentTag.name || '').replace(/\s/g, '-');

    childTags.forEach((childTag) => {
      qualifiedName += `:${(childTag.name || '').replace(/\s/g, '-')}`;
    });

    onQualifiedNameChange(qualifiedName);
  };

  /**
   * Add Child Tag
   */
  addChild = () => {
    this.setState((state) => {
      const {
        parentTag,
        childTags,
      } = state;

      const lastChild = last(childTags);
      const qualifiedName = lastChild
        ? `${lastChild.qualifiedName}:New-Child`
        : `${parentTag.qualifiedName}:New-Child`;

      return {
        childTags: [...state.childTags, {
          isNew: true,
          add: true,
          qualifiedName,
        }],
      };
    });
  };

  /**
   * Save Tags
   */
  save = async () => {
    const {
      targetTag: {
        _id,
      },
      addTargetTag,
      updateTargetTag,
      onClose,
    } = this.props;

    const {
      parentTag,
      childTags,
    } = this.state;

    const addTags = childTags.filter(childTag => childTag.add === true);
    const updateTags = childTags.filter(childTag => childTag.update === true);
    const updateResults = [];

    if (addTags.length > 0) {
      const parentData = parentTag.add
        ? { name: parentTag.name }
        : { _id };

      const addData = [
        {
          ...parentData,
        },
        ...addTags.map((tag) => {
          return {
            name: tag.name,
          };
        }),
      ];

      const addTagsResponse = await API.TargetTag.create(addData);

      addTagsResponse.forEach((result) => {
        if (result.type === 'TARGETINGTAG.CREATED') {
          addTargetTag({
            _id: result.documentId,
            ...result.data,
          });
        } else {
          console.error('Error Creating Tag:', result);
        }
      });
    }

    if (parentTag.add && childTags.length <= 0) {
      // Add the parent tag (if no child tags exist)
      const addData = [
        {
          name: parentTag.name,
        },
      ];

      const addTagsResponse = await API.TargetTag.create(addData);

      addTagsResponse.forEach((result) => {
        if (result.type === 'TARGETINGTAG.CREATED') {
          addTargetTag({
            _id: result.documentId,
            ...result.data,
          });
        } else {
          console.error('Error Creating Tag:', result);
        }
      });
    }

    if (parentTag.update) {
      // Update the parent tag
      const updateData = {
        _id: parentTag._id,
        name: parentTag.name,
      };

      const updateParentTagResponse = await API.TargetTag.update(updateData);

      updateParentTagResponse.forEach((result) => {
        if (result.type === 'TARGETINGTAG.UPDATED') {
          updateTargetTag({
            _id: result.documentId,
            ...result.data,
          });
        } else {
          console.error('Error Updating Parent Tag:', result);
        }
      });
    }

    await asyncForEach(updateTags, async (tag) => {
      const updateData = {
        _id: tag._id,
        name: tag.name,
      };

      const updateTagResponse = await API.TargetTag.update(updateData);
      updateResults.push(updateTagResponse[0]);
    });

    updateResults.forEach((result) => {
      if (result.type === 'TARGETINGTAG.UPDATED') {
        updateTargetTag({
          _id: result.documentId,
          ...result.data,
        });
      } else {
        console.error('Error Updating Tag:', result);
      }
    });

    onClose();
  };

  render() {
    const {
      className,
      targetTag: {
        qualifiedName,
      },
    } = this.props;

    const {
      showAddChild,
      parentTag,
      childTags,
    } = this.state;

    // Check if any child tags are empty
    const emptyChildTagsCount = childTags.filter(tag => !tag.name).length;

    // Check if there are any empty tags
    const hasEmptyTags = !parentTag.name || emptyChildTagsCount > 0;

    return (
      <div className={classNames('PanelDetailsEdit', className)}>
        <div className="tag-details-section">
          <Field
            type="text"
            label="Parent Name"
            placeholder="Parent Name"
            value={parentTag.name}
            tabIndex={2}
            marginBottom={25}
            autoFocus={qualifiedName === parentTag.qualifiedName || childTags.length <= 0}
            onChange={this.onChangeParentName}
          />
        </div>

        <div className="tag-details-section child-section">
          {childTags.map((childTag, index) => {
            return (
              <Field
                key={childTag.qualifiedName}
                type="text"
                label="Child Name"
                placeholder="Child Name"
                value={childTag.name}
                tabIndex={3 + index}
                marginBottom={20}
                autoFocus={qualifiedName === childTag.qualifiedName || childTag.isNew}
                dataId={childTag.qualifiedName}
                onChange={this.onChangeChildName}
              />
            );
          })}

          {showAddChild ? (
            <div
              className="add-child"
              onClick={this.addChild}
            >
              <VibeIcon
                className="add-child-icon"
                icon={viAdd}
                color={color.primary}
                size={24}
              />

              <div className="text">
                Add Child Name
              </div>
            </div>
          ) : null}
        </div>

        <div className="tag-details-section footer-section">
          <VibeButton
            text="Save Tag"
            color="success"
            disabled={hasEmptyTags}
            onClick={this.save}
          />

          <VibeButton
            variant="text"
            text="Cancel"
            color="secondary"
            onClick={this.onCancel}
          />
        </div>
      </div>
    );
  }
}

PanelDetailsEdit.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Creating a child tag */
  isCreateChild: PropTypes.bool,
  /** Is part of a location panel */
  isLocation: PropTypes.bool,
  /** When the qualified name changes */
  onQualifiedNameChange: PropTypes.func,
  /** When closing edit mode */
  onClose: PropTypes.func,
};

PanelDetailsEdit.defaultProps = {
  className: '',
  isCreateChild: false,
  isLocation: false,
  onQualifiedNameChange: () => {},
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    targetTag: state.targetTag.activeTag,
    targetTags: state.targetTag.tags,
  };
}

const mapDispatchToProps = {
  setTargetTagActive,
  addTargetTag,
  updateTargetTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelDetailsEdit);
