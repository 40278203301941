import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  map,
  isEmpty,
} from 'lodash';
import {
  Button,
  Grid,
} from '@mui/material';
import {
  Cards,
  EventCard,
  Paginator,
  PageLayout,
  API,
  GlobalActions,
  NavigationHelper,
  SearchByHelper,
  SortByHelper,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';
import EventSidebar from './Sidebar/EventSidebar';
import defaultEventsImage from '../../../assets/events.png';
import './EventsContainer.scss';

class EventsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredEvents: [],
      totalItems: 0,
      refresh: false,
    };
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.eventId && qs.type !== 'new';

    if (isNew || isView) {
      setTimeout(() => {
        this.sidebar(qs.type, qs.eventId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.eventId || qs.type === 'new') {
        this.sidebar(qs.type, qs.eventId);
      }
    }
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      eventId: null,
      type: null,
    }, {
      keepPage: true,
    });

    this.refresh();
    history(url);
  };

  onFilter = (data) => {
    const {
      collection,
      totalItems,
    } = data;

    this.setState({
      filteredEvents: collection,
      totalItems,
    });
  };

  /**
   * When user clicks new event from an empty state
   */
  onClickNewEvent = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      type: 'new',
    });

    history(url);
  };

  sidebar = (type, eventId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 475,
      children: (
        <EventSidebar
          eventId={eventId}
          isNew={type === 'new'}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  refresh = () => {
    // Refresh Page Layout
    this.setState({
      refresh: true,
    }, () => {
      this.setState({
        refresh: false,
      });
    });
  };

  renderEventsContainer = () => {
    const {
      user,
    } = this.props;

    const {
      filteredEvents,
    } = this.state;

    const canViewEvent = user.can('event.view');

    return isEmpty(filteredEvents) ? (
      <div className="empty-state">
        <img
          src={defaultEventsImage}
          width="100%"
          height="auto"
          alt="Event"
        />

        <h2 className="empty-state-title">
          CREATE YOUR FIRST EVENT!
        </h2>

        <p className="empty-state-description">
          Combine music and messaging for special occasions! Schedule events once, or many times. It&apos;s up to you!
        </p>

        <Button
          variant="contained"
          className="create-new-btn"
          onClick={this.onClickNewEvent}
        >
          <span className="create-new-text">
            New Event
          </span>
        </Button>
      </div>
    ) : (
      map(filteredEvents, event => (
        <EventCard
          key={event._id}
          event={event}
          link={canViewEvent
            ? NavigationHelper.updateParams({
              eventId: event._id,
              type: null,
            })
            : null}
          allowClick={canViewEvent}
          onArchive={this.refresh}
        />
      ))
    );
  };

  render() {
    const {
      scrollRef,
    } = this.props;

    const {
      filteredEvents,
      totalItems,
      refresh,
    } = this.state;

    return (
      <div className="EventsContainer">
        <SubNavigation
          title="Events"
          buttons={[
            {
              text: 'New Event',
              icon: viAdd,
              color: 'primary',
              url: NavigationHelper.updateParams({
                type: 'new',
              }),
              userCan: [
                'event.create',
              ],
            },
          ]}
        />

        <PageLayout
          sortOptions={SortByHelper.events}
          searchOptions={SearchByHelper.events}
          scrollRef={scrollRef}
          toggleLabel="Show Archived Events"
          refresh={refresh}
          filter={API.Event.list}
          onFilter={this.onFilter}
          disableView
          showToggle
        />
        <div className="cards-container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paginator
                className="event-paginator"
                totalLoaded={filteredEvents.length}
                totalItems={totalItems}
                label="Events"
              />
            </Grid>

            <Grid item xs={12}>
              <Cards>
                {this.renderEventsContainer()}
              </Cards>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsContainer));
