import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  find,
} from 'lodash';
import classNames from 'classnames';
import {
  handleLocationsData,
} from '../../../helpers/ExpandedLocations';
import API from '../../../api';
import LocationSelection from '../../Locations/LocationSelection';
import VibeButton from '../../VibeButton/VibeButton';
import './DeviceAttributeLocations.scss';

class DeviceAttributeLocations extends PureComponent {
  constructor(props) {
    super(props);

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    this.state = {
      companyId: adminCompanyId,
      locations: [],
      locationsData: {
        companies: [],
        locations: [],
      },
    };
  }

  onSelect = (location) => {
    this.setState((state) => {
      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      let {
        locations: stateLocations,
      } = state;

      if (location.locationId === '*') {
        // Add an entire company
        // Remove all individual company locations
        stateLocations = stateLocations.filter(loc => loc.companyId !== location.companyId);
      }

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'add',
          data: location,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: [...stateLocations, location],
      };
    });
  };

  onDeselect = (locationData) => {
    this.setState((state) => {
      const locationItem = find(state.locations, {
        companyId: locationData.companyId,
        locationId: locationData.locationId,
      });

      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'remove',
          data: locationData,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: locationData.locationId === '*'
          // Remove all locations for this company
          ? state.locations.filter(location => locationData.companyId !== location.companyId)
          // Remove single location
          : state.locations.filter(location => location !== locationItem),
      };
    });
  };

  onDeselectAll = () => {
    this.setState({
      locationsData: {
        companies: [],
        locations: [],
      },
      locations: [],
    });
  };

  onConfirmLocations = async () => {
    const {
      selectedIds,
      mergeMode,
      onClose,
    } = this.props;

    const {
      locations,
    } = this.state;

    const bulkUpdateResponse = await API.Location.DeviceAttributes.bulkCopy({
      mergeMode,
      locations: locations.map(location => {
        return {
          companyId: location.companyId,
          locationId: location.locationId,
        };
      }),
      deviceAttributes: selectedIds,
    });

    bulkUpdateResponse.forEach((response) => {
      if (response.type !== 'LOCATION.DEVICE_ATTRIBUTES_UPDATED') {
        console.error('Error updating location device attributes', response);
      }
    });

    onClose();
  };

  render() {
    const {
      className,
      user,
      mergeMode,
    } = this.props;

    const {
      companyId,
      locations,
      locationsData,
    } = this.state;

    const locationFunc = user.getLocationFunc(user.companyId) === 'user'
      ? API.User.getLocations
      : API.Company.getLocations;

    const confirmText = mergeMode === 'replace'
      ? 'Confirm Replace'
      : 'Confirm Add To';

    return (
      <div className={classNames('DeviceAttributeLocations', className)}>
        <LocationSelection
          companyId={companyId}
          selectedLocations={locations}
          locationsData={locationsData}
          filterLocations={locationFunc}
          filterCompanies={API.User.getCompanies}
          customToolbarAssigned={(
            <VibeButton
              text={confirmText}
              color="success"
              onClick={this.onConfirmLocations}
            />
          )}
          onSelect={this.onSelect}
          onDeselect={this.onDeselect}
          onDeselectAll={this.onDeselectAll}
          allowChanges
          allowPaginator
          allowCompanies
          allowAssignCompany
        />
      </div>
    );
  }
}

DeviceAttributeLocations.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Selected attribute IDs */
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  /** Location merge mode */
  mergeMode: PropTypes.oneOf([
    'replace',
    'merge',
  ]),
  /** When the locations panel is closed */
  onClose: PropTypes.func,
};

DeviceAttributeLocations.defaultProps = {
  className: '',
  selectedIds: [],
  mergeMode: 'replace',
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(DeviceAttributeLocations);
