import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  isEqual,
  capitalize,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import {
  convertDuration,
} from '../../../utils/TimeUtil';
import VibeTable from '../VibeTable';
import CellTooltip from '../CellTypes/CellTooltip';
import LabelCell from '../CellTypes/LabelCell';
import TimestampCell from '../CellTypes/TimestampCell';
import viTime from '../../../icons/viTime';
import color from '../../../sass/color.scss';

const tableId = 'table:telemetry:media:played';

class TableTelemetryMediaPlayed extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: '...',
      defaultWidth: 72,
    },
    {
      name: 'Media Name',
      searchAttr: 'name',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Timestamp',
      searchAttr: 'timestamp',
      defaultWidth: 260,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Device Status',
      searchAttr: 'deviceActivationStatus',
      defaultWidth: 165,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Live',
          value: 'live',
        },
        {
          label: 'Standby',
          value: 'standby',
        },
        {
          label: 'Setup',
          value: 'setup',
        },
        {
          label: 'Decommissioned',
          value: 'decommissioned',
        },
      ],
    },
    {
      name: 'Type',
      searchAttr: 'mediaType',
      defaultWidth: 130,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Play Type',
      searchAttr: 'playMode',
      defaultWidth: 130,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Duration',
      searchAttr: 'durationSeconds',
      icon: viTime,
      defaultWidth: 100,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Media ID',
      searchAttr: 'mediaId',
      defaultWidth: 270,
      resizable: true,
      searchable: true,
      sortable: true,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
    };

    // listen for when data changes
    document.addEventListener('onUpdateTableTelemetryMediaPlayed', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onUpdateTableTelemetryMediaPlayed', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    const {
      paginatorProps: {
        urlPaging,
      },
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        pageNumber: urlPaging
          ? parseInt(getParam('page') || 1, 10)
          : 1,
      },
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Get label style for the message type cell
   */
  getLabelStyle = (status) => {
    switch (status) {
      case 'live':
        return {
          text: 'Live',
          textStyle: {
            background: color.success16,
            color: color.success,
          },
        };

      case 'standby':
        return {
          text: 'Standby',
          textStyle: {
            background: color.orange16,
            color: color.orange,
          },
        };

      case 'setup':
        return {
          text: 'Setup',
          textStyle: {
            background: color.orange16,
            color: color.orange,
          },
        };

      case 'decommissioned':
        return {
          text: 'Decommissioned',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      default:
        return {
          text: capitalize(status),
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };
    }
  };

  /**
   * Get the Type Label Colors
   */
  getTypeColors = (type = '') => {
    switch (type.toLowerCase()) {
      case 'music':
        return {
          text: 'Music',
          textStyle: {
            background: color.rainboots16,
            color: color.rainboots,
          },
        };

      case 'message':
        return {
          text: 'Message',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      case 'status':
        return {
          text: 'Status',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      case 'sounder':
        return {
          text: 'Sounder',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      case 'ad':
        return {
          text: 'Ad',
          textStyle: {
            background: color.success16,
            color: color.success,
          },
        };

      default:
        return {
          text: type,
          textStyle: {
            background: 'transparent',
            color: color.manatee,
          },
        };
    }
  };

  /**
   * Get the Play Type Label Colors
   */
  getPlayTypeColors = (type = '') => {
    switch (type.toLowerCase()) {
      case 'standard':
        return {
          text: 'Standard',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      default:
        return {
          text: type,
          textStyle: {
            background: 'transparent',
            color: color.manatee,
          },
        };
    }
  };

  renderCell = ({
    column,
    row,
  }) => {
    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Timestamp':
        return (
          <TimestampCell
            time={value}
            format="YYYY-MM-DD HH:mm:ss"
          />
        );

      case 'Device Status': {
        const {
          text,
          textStyle,
        } = this.getLabelStyle(value);

        return (
          <LabelCell
            value={text}
            background={textStyle.background}
            color={textStyle.color}
          />
        );
      }

      case 'Type': {
        const {
          text,
          textStyle,
        } = this.getTypeColors(value);

        return (
          <LabelCell
            value={text}
            background={textStyle.background}
            color={textStyle.color}
          />
        );
      }

      case 'Play Type': {
        const {
          text,
          textStyle,
        } = this.getPlayTypeColors(value);

        return (
          <LabelCell
            value={text}
            background={textStyle.background}
            color={textStyle.color}
          />
        );
      }

      case 'Duration': {
        const duration = convertDuration(value, 'm:ss');

        return (
          <CellTooltip title={duration}>
            <div className="cell-content">
              {duration}
            </div>
          </CellTooltip>
        );
      }

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
    } = this.state;

    return (
      <div className={classNames('Table', 'TableTelemetryMediaPlayed', className)}>
        <VibeTable
          {...this.props}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />
      </div>
    );
  }
}

TableTelemetryMediaPlayed.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableTelemetryMediaPlayed.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableTelemetryMediaPlayed);
