import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  API,
  VibeIcon,
  viCheck,
  color,
} from 'vibeguide';
import { Popover } from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import NotificationItem from './NotificationItem';
import './Notifications.scss';

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.notificationRef = React.createRef();

    this.state = {
      numUnread: 0,
      showNotifications: false,
      notifications: [],
      pageNumber: 1,
      hasMoreItems: false,
    };
  }

  componentDidMount() {
    const {
      socket,
    } = this.props;

    this.getNotifications({ reset: true });

    socket.on('VAPI_EVENT', this.onApiEvent);
  }

  componentDidUpdate(prevProps) {
    const {
      socket,
    } = this.props;

    const {
      socket: prevSocket,
    } = prevProps;

    if (socket !== prevSocket) {
      socket.off('VAPI_EVENT', this.onApiEvent);
      socket.on('VAPI_EVENT', this.onApiEvent);
    }
  }

  componentWillUnmount() {
    const {
      socket,
    } = this.props;

    socket.off('VAPI_EVENT', this.onApiEvent);
  }

  onApiEvent = (e) => {
    if (e.type === 'NOTIFICATION.CREATED') {
      // Show user they have an unread notification
      this.setState((state) => {
        const numUnread = state.numUnread + 1;
        console.log('New Notification (Unread)', numUnread);

        return {
          numUnread,
        };
      });
    }
  };

  onMarkRead = () => {
    this.setState((state) => {
      const numUnread = state.numUnread - 1;

      return {
        numUnread,
      };
    });
  };

  onMarkAllRead = async () => {
    const notificationsToRead = {
      notificationIds: '*',
    };
    await API.Notification.markAllRead(notificationsToRead);

    await this.getNotifications({ reset: true });
  };

  onScrollBottom = async () => {
    const {
      hasMoreItems,
    } = this.state;

    if (hasMoreItems) {
      this.getNotifications({ append: true });
    }
  };

  getNotifications = async (options = {}) => {
    const isReset = options.reset || false;
    const isAppend = options.append || false;
    const pageNumber = isReset ? 1 : get(this.state, 'pageNumber', 1);

    const notificationsResponse = await API.Notification.list({
      ps: 15,
      pn: pageNumber,
    });
    const notifications = get(notificationsResponse, 'value.data', []);
    const totalPages = get(notificationsResponse, 'value.totalPages', 1);
    let numUnread = 0;

    notifications.forEach((notification) => {
      if (!notification.readDate) {
        numUnread += 1;
      }
    });

    this.setState((state) => {
      return {
        pageNumber: isReset ? pageNumber + 1 : state.pageNumber + 1,
        hasMoreItems: pageNumber !== totalPages,
        notifications: isAppend ? [...state.notifications, ...notifications] : notifications,
        numUnread,
      };
    });
  };

  toggleNotifications = async () => {
    const {
      showNotifications,
    } = this.state;

    const newShowNotifications = !showNotifications;

    if (newShowNotifications) {
      await this.getNotifications({ reset: true });
    }

    this.setState({
      showNotifications: newShowNotifications,
    });
  };

  closeNotifications = () => {
    this.setState({
      showNotifications: false,
    });
  };

  render() {
    const {
      numUnread,
      showNotifications,
      notifications,
    } = this.state;

    const {
      notificationRef: {
        current: notificationRef,
      },
    } = this;

    return (
      <div className="Notifications">
        <span ref={this.notificationRef}>
          <VibeIcon
            icon={(<NotificationsIcon />)}
            color={numUnread > 0
              ? color.white
              : color.navigationText}
            size={24}
            onClick={this.toggleNotifications}
          />
        </span>

        {numUnread > 0 && (
          <div className={classNames('notification-circle', { 'has-unread': numUnread > 0 })} />
        )}

        <Popover
          anchorEl={notificationRef}
          className="NotificationPopover"
          open={showNotifications}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: `NotificationPopoverPaper ${notifications.length <= 0 ? 'is-empty' : ''}`,
          }}
          onClose={this.closeNotifications}
        >
          {notifications.length <= 0 ? (
            <div className="notification-content">
              <div className="content-is-empty">
                No notifications
              </div>
            </div>
          ) : (
            <div className="notification-content">
              <div className="mark-all-btn" onClick={this.onMarkAllRead}>
                <VibeIcon
                  className="check-icon"
                  icon={viCheck}
                  color={color.primary}
                  size={16}
                />
                Mark All as Read
              </div>
              <PerfectScrollbar
                onYReachEnd={this.onScrollBottom}
              >
                <div>
                  {notifications.map((notification) => {
                    return (
                      <NotificationItem
                        key={notification._id}
                        notification={notification}
                        onMarkRead={this.onMarkRead}
                      />
                    );
                  })}
                </div>
              </PerfectScrollbar>
            </div>
          )}
        </Popover>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    socket: state.socket.connection,
  };
}

export default connect(mapStateToProps)(Notifications);
