import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  API,
  TableCompanies,
  NavigationHelper,
  GlobalActions,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../Header/SubNavigation';
import CompanySidebar from './Sidebar/CompanySidebar';
import './CompaniesList.scss';

class CompaniesList extends PureComponent {
  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.companyId && qs.type !== 'new';

    if (isNew || isView) {
      setTimeout(() => {
        this.sidebar(qs.type, qs.companyId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.companyId || qs.type === 'new') {
        this.sidebar(qs.type, qs.companyId);
      }
    }
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      companyId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  sidebar = (type, companyId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <CompanySidebar
          companyId={companyId}
          isNew={type === 'new'}
          isDuplicate={type === 'new' && companyId !== null}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      user,
    } = this.props;

    return (
      <div className="CompaniesList">
        <SubNavigation
          title="Companies"
          buttons={[
            {
              text: 'New Company',
              icon: viAdd,
              color: 'primary',
              url: NavigationHelper.updateParams({
                type: 'new',
              }),
              userCan: [
                'company.create',
              ],
            },
          ]}
        />

        <TableCompanies
          columns={[
            '.',
            'Company',
            'Category',
            'Address 1',
            'Address 2',
            'Country',
            'City',
            'State/Province',
            'Postal Code',
            'Salesforce ID',
            'Tags',
            '...',
          ]}
          fetch={user.partnerId
            ? API.PartnerUser.getCompanies
            : API.User.getCompanies}
          fetchProps={{
            filters: {
              _id: user._id,
            },
          }}
          defaultSortBy={{
            label: 'Company',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Companies',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Companies',
            tooltip: true,
          }}
          bulk
          bulkProps={{
            edit: false,
            add: false,
            block: false,
            remove: false,
            archive: true,
          }}
          rowLink={{
            companyId: '_id',
          }}
          menuItems={[
            { name: 'Archive', userCan: 'company.delete' },
          ]}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompaniesList));
