import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
  EventActions,
  LocationHelper,
  NavigationHelper,
  EventModel,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Assignments,
  VibeTooltip,
  VibeButton,
  VibeModal,
  VibeIcon,
  viClose,
  viCopy,
  viEdit,
  viArchive,
  viUnarchive,
  withRouter,
  color,
} from 'vibeguide';
import EventLocations from './EventLocations';
import EventSchedule from './EventSchedule';
import Information from './Content/Information';
import DateAndTime from './Content/DateAndTime';
import './EventDetails.scss';

class EventDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmArchive: false,
    };
  }

  /**
   * When the extra panel is closed
   */
  onCloseExtraPanel = () => {
    const {
      onCloseExtraPanel,
    } = this.props;

    onCloseExtraPanel();
  };

  /**
   * When the archive button is clicked
   */
  onClickArchive = () => {
    this.setState({
      confirmArchive: true,
    });
  };

  /**
   * When the unarchive button is clicked
   */
  onClickUnarchive = async () => {
    const {
      event: {
        _id,
        locations,
      },
      onClose,
    } = this.props;

    await API.Event.reactivate({
      _id,
      locations,
    });
    onClose();

    document.dispatchEvent(new Event('onSaveEvent'));
  };

  /**
   * When the archive modal is closed
   */
  onCloseArchive = () => {
    this.setState({
      confirmArchive: false,
    });
  };

  /**
   * When the archive modal is confirmed
   */
  onConfirmArchive = async () => {
    const {
      event: {
        _id,
      },
      onClose,
    } = this.props;

    await API.Event.deactivate(_id);

    this.onCloseArchive();
    onClose();

    document.dispatchEvent(new Event('onSaveEvent'));
  };

  /**
   * When locations assigned is clicked
   */
  onClickLocations = () => {
    const {
      user,
      event: {
        _id,
        companyId,
        locations,
        locationsData,
      },
      setPanel,
      onUpdate,
    } = this.props;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.can('event.assign_locations')
      && ((_id && _id !== 'new-event' && user.can('event.modify'))
        || ((!_id || _id === 'new-event') && user.can('event.create')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <EventLocations
            companyId={companyId}
            locations={locations}
            locationsData={locationsData}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * When edit content is clicked
   */
  onClickEditContent = () => {
    const {
      user,
      event,
      setPanel,
      onUpdate,
    } = this.props;

    const isACAF = user.isAllLocations({
      companyId: event.companyId,
      locations: event.locations,
    });

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <EventSchedule
            event={event}
            isACAF={isACAF}
            onUpdate={onUpdate}
            onClose={this.onCloseExtraPanel}
          />
        ),
      },
    });
  };

  /**
   * When an event is being saved
   */
  onSave = async () => {
    const {
      isNew,
      event,
      history,
      queueToast,
      saveEvent,
    } = this.props;

    document.dispatchEvent(new Event('onSaveEventStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    if (isNew) {
      // creating or duplicating an event
      event._id = 'new-event';
    }

    try {
      const eventResponse = await saveEvent(event);

      const responseType = get(eventResponse, '[0].type');
      const eventId = get(eventResponse, '[0].documentId');

      const success = responseType === 'EVENT.CREATED'
        || responseType === 'EVENT.UPDATED';

      if (success) {
        // Successfully saved the event
        const saveMessage = responseType === 'EVENT.UPDATED'
          ? 'Event Updated!'
          : 'Event Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });
      }

      // Refresh the details and redirect to the edit screen
      if (success && isNew) {
        const redirectUrl = NavigationHelper.updateParams({
          eventId,
          type: null,
        });

        history(redirectUrl);
      }

      document.dispatchEvent(new Event('onSaveEvent'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveEventError'));
    }
  };

  render() {
    const {
      user,
      event,
      onClose,
      onUpdate,
      isNew,
    } = this.props;

    const {
      confirmArchive,
    } = this.state;

    // ACAF match
    const isACAF = user.isAllLocations({
      companyId: event.companyId,
      locations: event.locations,
    });

    const locationCount = LocationHelper.getLocationCount(get(event, 'locationsData.companies', []), isACAF);

    const disableSave = !event.title
      || !event.description
      || get(event, 'locations.length', 0) <= 0;

    const exceedsLocations = user.exceedsLocations(event.locations);
    const disableEditContent = get(event, 'locations.length', 0) <= 0;
    const disableCompany = !isNew;
    const disableInput = !user.hasAccessToCompany(event.companyId)
      || !exceedsLocations
      || (event._id && event._id !== 'new-event' && !user.can('event.modify'))
      || (!event._id && !user.can('event.create'));

    const showDuplicate = user.can('event.create')
      && exceedsLocations;

    const showArchive = user.can('event.delete')
      && user.hasAccessToCompany(event.companyId)
      && exceedsLocations;
    const isArchived = !event.active;

    return (
      <SidePanelContainer className="EventDetails">
        <SidePanelHeader
          icons={(
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onClose}
            />
          )}
        >
          <div className="details-header">
            {event._id && event._id.indexOf('new-event') < 0 ? (
              <div className="flex-horizontal">
                <div className="title">
                  <VibeTooltip title={event.title}>
                    <span>
                      {event.title}
                    </span>
                  </VibeTooltip>
                </div>
              </div>
            ) : (
              <div className="title">
                New Event
              </div>
            )}
          </div>
        </SidePanelHeader>

        <SidePanelContent>
          <Information
            isNew={isNew}
            eventColor={event.color}
            title={event.title}
            description={event.description}
            companyId={event.companyId}
            companyName={event.companyName}
            tags={event.tags}
            disableInput={disableInput}
            disableCompany={disableCompany}
            onUpdate={onUpdate}
          />

          <DateAndTime
            eventColor={event.color}
            startDate={event.startDate}
            stopDate={event.stopDate}
            startTime={event.startTime}
            stopTime={event.stopTime}
            repeat={event.repeat}
            repeatDays={event.repeatDays}
            repeatInterval={event.repeatInterval}
            repeatFrequency={event.repeatFrequency}
            scheduleOriginTimeZone={event.scheduleOriginTimeZone}
            disableInput={disableInput}
            onUpdate={onUpdate}
          />

          <Assignments
            items={[{
              label: 'Locations Assigned',
              count: locationCount.display.element,
              tooltip: locationCount.display.tooltip,
              required: true,
              disabled: disableInput,
              warning: !exceedsLocations
                ? 'You do not have access to all the locations assigned to this object'
                : null,
              onClick: !disableInput
                ? this.onClickLocations
                : null,
            }]}
          />
        </SidePanelContent>

        <SidePanelFooter>
          <VibeButton
            text="Save Changes"
            color="secondary"
            loadingEvent="onSaveEvent"
            disabled={disableSave || disableInput || event.hasError}
            onClick={this.onSave}
          />

          <VibeIcon
            icon={viEdit}
            type="button"
            buttonProps={{
              size: 32,
              borderColor: color.primary,
            }}
            color={color.primary}
            size={16}
            tooltip="Event Content"
            disabled={disableEditContent}
            onClick={this.onClickEditContent}
          />

          {!isNew && showDuplicate && (
            <VibeIcon
              icon={viCopy}
              type="button"
              buttonProps={{
                size: 32,
                borderColor: color.primary,
              }}
              color={color.primary}
              size={16}
              tooltip="Duplicate"
              link={NavigationHelper.updateParams({
                type: 'new',
              })}
            />
          )}

          {!isNew && showArchive && !isArchived && (
            <VibeIcon
              icon={viArchive}
              type="button"
              buttonProps={{
                size: 32,
                borderColor: color.error,
              }}
              tooltip="Archive"
              color={color.error}
              size={20}
              onClick={this.onClickArchive}
            />
          )}

          {!isNew && showArchive && isArchived && (
            <VibeIcon
              icon={viUnarchive}
              type="button"
              buttonProps={{
                size: 32,
                borderColor: color.success,
              }}
              tooltip={locationCount.total > 0
                ? 'Unarchive'
                : 'Must have at least 1 active location to unarchive'}
              color={color.success}
              size={20}
              disabled={locationCount.total <= 0}
              onClick={this.onClickUnarchive}
            />
          )}
        </SidePanelFooter>

        <VibeModal
          show={confirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${event.title}?`}
          confirmProps={{
            text: 'Archive',
            color: 'error',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCloseArchive}
        />
      </SidePanelContainer>
    );
  }
}

EventDetails.propTypes = {
  event: PropTypes.instanceOf(EventModel).isRequired,
  isNew: PropTypes.bool,
  // isDuplicate: PropTypes.bool,
  onClose: PropTypes.func,
  onCloseExtraPanel: PropTypes.func,
  onUpdate: PropTypes.func,
};

EventDetails.defaultProps = {
  isNew: false,
  // isDuplicate: false,
  onClose: () => {},
  onCloseExtraPanel: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  saveEvent: EventActions.saveEvent,
  setPanel: GlobalActions.setPanel,
  setModal: GlobalActions.setModal,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventDetails));
