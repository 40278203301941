import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  API,
  VibeToggle,
  VibeModal,
  VibeDialog,
  VibeIcon,
  viMusic,
  viTrash,
  color,
} from 'vibeguide';
import ScheduleOverrideEditor from './ScheduleOverrideEditor';
import './ScheduleOverride.scss';

class ScheduleOverride extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmEnable: false,
      showConfirmEdit: false,
      showConfirmRemove: false,
      editOverride: false,
    };
  }

  /**
   * When the edit override music is selected
   */
  onClickEditOverride = () => {
    this.setState({
      showConfirmEdit: true,
    });
  };

  /**
   * When the override is being removed
   */
  onClickRemoveOverride = () => {
    this.setState({
      showConfirmRemove: true,
    });
  };

  /**
   * When the enable override is toggled
   */
  onToggleMusicOverride = async () => {
    const {
      musicOverride,
    } = this.props;

    if (musicOverride) {
      // do not warn user about schedule overrides
      const success = await this.toggleOverride('disable');

      if (!success) {
        console.error('Error toggling muic override', success);
      }
    } else {
      // user is turning on schedule overrides, show confirmation modal
      this.setState({
        showConfirmEnable: true,
      });
    }
  };

  /**
   * When the user confirms enabling the override
   */
  onConfirmEnable = async () => {
    const success = await this.toggleOverride('enable');

    if (success) {
      this.setState({
        showConfirmEnable: false,
      });
    }
  };

  /**
   * When the user cancels enabling the override
   */
  onCancelEnable = () => {
    this.setState({
      showConfirmEnable: false,
    });
  };

  /**
   * When the user confirms editing the override
   */
  onConfirmEdit = async () => {
    const {
      onOpenEditOverride,
    } = this.props;

    this.setState({
      showConfirmEdit: false,
      editOverride: true,
    });

    onOpenEditOverride();
  };

  /**
   * When the user cancels editing the override
   */
  onCancelEdit = () => {
    this.setState({
      showConfirmEdit: false,
    });
  };

  /**
   * When the user confirms removinbg the override
   */
  onConfirmRemove = async () => {
    const {
      locationId,
    } = this.props;

    this.setState({
      showConfirmRemove: false,
    });

    await API.Location.ScheduleOverride.delete(locationId);
  };

  /**
   * When the user cancels removing the override
   */
  onCancelRemove = () => {
    this.setState({
      showConfirmRemove: false,
    });
  };

  /**
   * When the edit override dialog is closed
   */
  onCloseEditOverride = () => {
    const {
      onCloseEditOverride,
    } = this.props;

    this.setState({
      editOverride: false,
    });

    onCloseEditOverride();
  };

  /**
   * Toggle the override on/off
   */
  toggleOverride = async (type) => {
    const {
      locationId,
      onToggleMusicOverride,
    } = this.props;

    const response = type === 'disable'
      // disable the schedule override
      ? await API.Location.ScheduleOverride.disable(locationId)
      // enable the schedule override
      : await API.Location.ScheduleOverride.enable(locationId);

    const responseType = get(response, '[0].type');
    const success = responseType === 'LOCATION.SCHEDULE_OVERRIDE_ENABLED_UPDATED';

    onToggleMusicOverride(type);
    return success;
  };

  render() {
    const {
      className,
      companyId,
      locationId,
      musicOverride,
      musicOverrideSchedule,
    } = this.props;

    const {
      showConfirmEnable,
      showConfirmEdit,
      showConfirmRemove,
      editOverride,
    } = this.state;

    // check if there is a configured schedule for the schedule override
    const days = get(musicOverrideSchedule, 'scheduleOverride.days') || [];
    const hasOverrideSchedule = days.length > 0;

    const overrideModalText = (
      <div>
        Overriding the music will take over the entire week.
        You must schedule playlists and/or stations whenever
        you would like to hear music. Blank blocks of time in
        your new music schedule will mean <strong>NO MUSIC</strong> will be
        played during that time.
      </div>
    );

    return (
      <div className={classNames('ScheduleOverride', className)}>
        {hasOverrideSchedule ? (
          <div className="override-container">
            <div
              className="schedule-button edit-override-music"
              onClick={this.onClickEditOverride}
            >
              <VibeIcon
                className="schedule-icon"
                icon={viMusic}
                color={color.white}
                size={16}
              />

              Edit Music Override
            </div>

            <div
              className="schedule-button remove-override-music"
              onClick={this.onClickRemoveOverride}
            >
              <VibeIcon
                className="schedule-icon"
                icon={viTrash}
                color={color.white}
                size={16}
              />

              Remove Override
            </div>

            <VibeToggle
              className="schedule-button toggle-override"
              label="Override Enabled"
              labelStyle={{
                color: musicOverride
                  ? color.carrot
                  : color.manatee,
                fontSize: 12,
              }}
              height={16}
              checked={musicOverride}
              color={color.carrot}
              onChange={this.onToggleMusicOverride}
            />
          </div>
        ) : (
          <div className="override-container">
            <div
              className="schedule-button override-music"
              onClick={this.onClickEditOverride}
            >
              <VibeIcon
                className="schedule-icon"
                icon={viMusic}
                color={color.white}
                size={16}
              />

              Override Music
            </div>
          </div>
        )}

        <VibeDialog
          title="Music Override Editor"
          open={editOverride}
          fullScreen
          onClose={this.onCloseEditOverride}
        >
          <ScheduleOverrideEditor
            companyId={companyId}
            locationId={locationId}
            schedule={musicOverrideSchedule}
            onClose={this.onCloseEditOverride}
          />
        </VibeDialog>

        <VibeModal
          show={showConfirmEnable}
          type="confirm"
          confirmProps={{
            text: 'Got It!',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          text={overrideModalText}
          onConfirm={this.onConfirmEnable}
          onClose={this.onCancelEnable}
        />

        <VibeModal
          show={showConfirmEdit}
          type="confirm"
          confirmProps={{
            text: 'Got It!',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          text={overrideModalText}
          onConfirm={this.onConfirmEdit}
          onClose={this.onCancelEdit}
        />

        <VibeModal
          show={showConfirmRemove}
          type="confirm"
          confirmProps={{
            text: 'Remove Override',
            color: 'error',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          title="Remove Music Override"
          text="Are you sure you want to remove the override schedule?"
          onConfirm={this.onConfirmRemove}
          onClose={this.onCancelRemove}
        />
      </div>
    );
  }
}

ScheduleOverride.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  /** Is the schedule being overridden? */
  musicOverride: PropTypes.bool,
  /** The overridden music schedule */
  musicOverrideSchedule: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** When the music override is toggled */
  onToggleMusicOverride: PropTypes.func,
  /** When the edit override dialog is open */
  onOpenEditOverride: PropTypes.func,
  /** When the edit override dialog is closed */
  onCloseEditOverride: PropTypes.func,
};

ScheduleOverride.defaultProps = {
  className: '',
  musicOverride: false,
  musicOverrideSchedule: null,
  onToggleMusicOverride: () => {},
  onOpenEditOverride: () => {},
  onCloseEditOverride: () => {},
};

export default ScheduleOverride;
