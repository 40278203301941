import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import {
  isEmpty,
} from 'lodash';
import {
  API,
  Field2 as Field,
  VibeIcon,
  viUser,
  color,
} from 'vibeguide';
import ApproverCard from './ApproverCard';
import './ApproverOptions.scss';

function ApproverOptions({
  className,
  style,
  type,
  approverType,
  message,
  disableInput,
  required,
  isNew,
  isCopy,
  isRequest,
  allowEditApprover,
  onUpdate,
  onClickApprover,
}) {
  const approverRef = useRef(null);
  const approverTypeLabel = capitalize(approverType);
  const approverSettings = get(message, `${approverType}Approval`, {}) || {};
  const approvers = approverSettings.approvers || [];

  const allowRemove = !disableInput
    && isNew
    && (type === 'request' || (type === 'library' && isNew));

  const approverObject = approverType === 'client'
    ? 'clientApproval'
    : 'partnerApproval';

  /**
   * Toggle the publish automatically switch
   */
  const onChangePublishAutomatically = () => {
    // the new publish mode to set the message to
    const publishMode = message.publishMode === 'review'
      ? 'automatic'
      : 'review';

    onUpdate({
      publishMode,
      [approverObject]: {
        ...message[approverObject],
        // change the approval style to empty if no approvers are required
        style: publishMode === 'automatic'
          ? ''
          : message[approverObject].style,
      },
    });
  };

  /**
   * Change an Approver Setting
   */
  const onChangeApproverSetting = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [approverObject]: {
        ...message[approverObject],
        [name]: value,
        // change the number of required approvers to 1 if not unanimous
        approvalsRequired: value === 'n-required'
          ? 1
          : approvers.length,
      },
    });
  };

  /**
   * Remove an Approver
   */
  const onRemoveApprover = (removeApprover) => {
    const newApprovers = approvers.filter(approver => approver._id !== removeApprover._id);

    onUpdate({
      [approverObject]: {
        ...message[approverObject],
        approvers: newApprovers,
        // update the required approver count if all approvers are required
        approvalsRequired: approverSettings.style === 'unanimous'
          ? newApprovers.length
          : message[approverObject].approvalsRequired,
      },
    });
  };

  /**
   * Get approver settings when the company changes
   */
  const getApproverSettings = () => {
    const approverSettings = get(message, approverObject, {}) || {};

    // re-requesting a message
    if (isNew && message._id) {
      // approvers to use (remove any override approvers)
      const approvers = (approverSettings.approvers || [])
        .filter(approver => approver.approvalType !== 'override-client'
          && approver.approvalType !== 'override-global'
          && approver.approvalType !== 'override-partner')
        .map((approver) => {
          return {
            ...approver,
            // reset the approved time and approval status
            reviewedAt: '',
            status: '',
          };
        });

      return {
        ...approverSettings,
        approvers,
      };
    }

    return approverSettings;
  };

  const approverTooltip = [];

  if (!message.companyId) {
    approverTooltip.push('Missing Company');
  }

  if (message.locations <= 0) {
    approverTooltip.push('Missing Locations');
  }

  // Should check partnerId if Partner Message?
  const disableApprover = disableInput
    || (!isNew && !isCopy)
    || !message.companyId
    || message.locations <= 0
    || approverSettings.style === 'anyone';

  // Get approval requirements for partner
  useEffect(async () => {
    if (!isEmpty(message.partnerId)) {
      if (message.partnerId) {
        const adConfig = await API.Partner.getAdConfig({
          _id: message.partnerId,
        });

        const requireApproval = adConfig.requireAdApproval;

        const updateData = {
          requireApproval,
          [approverObject]: getApproverSettings(),
        };

        // partner requires approval, force the publish automatically to be turned off
        if (requireApproval) {
          updateData.publishMode = 'review';
        }

        onUpdate(updateData);
      } else {
        /**
         * What to do here for Partner
         */
        onUpdate({
          requireApproval: false,
          [approverObject]: {
            ...message[approverObject],
            approvers: [],
            style: '',
          },
        });
      }
    }
  }, [message.partnerId, message._id]);

  /**
   * When the Company ID changes, determine if approval is required
   */
  useEffect(async () => {
    if (!isEmpty(message.companyId) && isEmpty(message.partnerId)) {
      if (message.companyId) {
        const messageConfig = await API.Company.getMessageConfig({
          _id: message.companyId,
        });

        const requireApproval = messageConfig.requireMessageApproval;

        const updateData = {
          requireApproval,
          [approverObject]: getApproverSettings(),
        };

        // company requires approval, force the publish automatically to be turned off
        if (requireApproval) {
          updateData.publishMode = 'review';
        }

        onUpdate(updateData);
      } else {
        /**
         * no company is selected, reset the require approval until a new company is chosen
         * reset the selected approvers
         */
        onUpdate({
          requireApproval: false,
          [approverObject]: {
            ...message[approverObject],
            approvers: [],
            style: '',
          },
        });
      }
    }
  }, [message.companyId]);

  /**
   * Render the approver card
   */
  const renderApproverCard = (approver, index) => {
    return (
      <div
        key={`approver-card-${index}`}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ApproverCard
          style={{
            flexGrow: 1,
          }}
          approver={approver}
          // show waiting status only on the message request
          showWaitingStatus={type === 'request'}
          showIcon
        />

        {allowRemove && (
          <div
            className="remove-approver"
            onClick={() => onRemoveApprover(approver)}
          >
            Remove
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={classNames('ApproverOptions', className)}
      style={{
        marginTop: 16,
        ...style,
      }}
    >
      {(isRequest || isNew) && (
        <Field
          type="toggle"
          label="Publish Automatically"
          labelProps={{
            tooltip: message.requireApproval
              ? 'The configured settings requires message approval'
              : '',
            tooltipPlacement: 'right',
          }}
          rootStyle={{
            marginTop: 16,
          }}
          toggleProps={{
            label: 'This will publish automatically, skipping the approval process.',
          }}
          value={message.publishMode === 'automatic'
            ? 'on'
            : 'off'}
          tabIndex={10}
          disabled={disableInput
            || (!isNew && !isCopy)
            || message.requireApproval}
          onChange={onChangePublishAutomatically}
        />
      )}

      <div className={classNames('approvers', { hide: message.publishMode === 'automatic' })}>
        <div
          ref={approverRef}
          className="approvers-content"
        >
          <Field
            type="select"
            label={`${approverTypeLabel} Approver Settings`}
            name="style"
            value={approverSettings.style}
            style={{
              border: required && isCopy && isEmpty(approverSettings.style) && `1px solid ${color.error}`,
            }}
            rootStyle={{
              marginTop: 16,
            }}
            options={[
              {
                label: '',
                value: '',
              },
              {
                label: 'Anyone can approve',
                value: 'anyone',
              },
              {
                label: 'All approvers must approve',
                value: 'unanimous',
              },
              {
                label: 'Only one approver must approve',
                value: 'n-required',
              },
            ]}
            onChange={onChangeApproverSetting}
            tabIndex={11}
            disabled={disableInput || (!isNew && !isCopy)}
            required={message.requireApproval || approvers.length > 0}
          />

          <Field
            type="custom"
            label={`${approverTypeLabel} Approver(s)`}
            labelProps={{
              tooltip: approverTooltip,
              tooltipPlacement: 'right',
            }}
            rootStyle={{
              marginTop: 16,
            }}
            tabIndex={12}
            required={approverSettings.style !== 'anyone'
              && (message.requireApproval || message.publishMode === 'review')}
          >
            {(isNew || isCopy) && (
              <div
                className={classNames('selected-approver', {
                  disabled: disableApprover,
                  editable: allowEditApprover,
                  required,
                })}
                style={{
                  border: approverSettings.style !== 'anyone' && required && isCopy && `1px solid ${color.error}`,
                }}
                onClick={allowEditApprover && !disableApprover
                  ? onClickApprover
                  : null}
              >
                <VibeIcon
                  style={{
                    marginRight: 8,
                  }}
                  icon={viUser}
                  color={color.primary}
                  size={16}
                />

                <span>Select {approverTypeLabel} Approver</span>
              </div>
            )}
          </Field>

          {approvers.length > 0 && (
            <div
              className="approvers-assigned"
              style={{
                marginTop: (isNew || isCopy)
                  ? 16
                  : 0,
              }}
            >
              {approvers.filter(approver => approver.approvalType === 'override-client'
                || approver.approvalType === 'override-global'
                || approver.approvalType === 'override-partner')
                .map((approver, index) => {
                  return renderApproverCard(approver, index);
                })}

              {approvers.filter(approver => approver.approvalType !== 'override-client'
                && approver.approvalType !== 'override-global'
                && approver.approvalType !== 'override-partner')
                .map((approver, index) => {
                  return renderApproverCard(approver, index);
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ApproverOptions.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string.isRequired,
  approverType: PropTypes.string.isRequired,
  message: PropTypes.object,
  disableInput: PropTypes.bool,
  isNew: PropTypes.bool,
  isRequest: PropTypes.bool,
  allowEditApprover: PropTypes.bool,
  onUpdate: PropTypes.func,
  onClickApprover: PropTypes.func,
};

ApproverOptions.defaultProps = {
  className: '',
  style: {},
  message: {},
  disableInput: false,
  isNew: false,
  isRequest: false,
  allowEditApprover: false,
  onUpdate: () => {},
  onClickApprover: () => {},
};

export default ApproverOptions;
