import request from 'superagent';
import Advertiser from './app/AdvertiserAPI';
import Authentication from './app/AuthenticationAPI';
import Baseline from './app/BaselineAPI';
import Campaign from './app/CampaignAPI';
import Company from './app/CompanyAPI';
import Currency from './app/CurrencyAPI';
import DeviceAttribute from './app/DeviceAttributeAPI';
import DeviceManagement from './app/DeviceManagementAPI';
import Dma from './app/DmaAPI';
import Event from './app/EventAPI';
import Flight from './app/FlightAPI';
import Local from './app/LocalAPI';
import Location from './app/LocationAPI';
import LocationControlFile from './app/LocationControlFileAPI';
import LocationZones from './app/LocationZonesAPI';
import Message from './app/MessageAPI';
import MessageBlock from './app/MessageBlockAPI';
import MessageRequest from './app/MessageRequestAPI';
import Mix from './app/MixAPI';
import Music from './app/MusicAPI';
import Notification from './app/NotificationAPI';
import Order from './app/OrderAPI';
import Partner from './app/PartnerAPI';
import PartnerUser from './app/PartnerUserAPI';
import Permission from './app/PermissionAPI';
import PermissionSet from './app/PermissionSetAPI';
import Playlist from './app/PlaylistAPI';
import ScreenResolution from './app/ScreenResolutionAPI';
import Tag from './app/TagAPI';
import TargetGroup from './app/TargetGroupAPI';
import TargetTag from './app/TargetTagAPI';
import ThirdParty from './app/ThirdPartyAPI';
import User from './app/UserAPI';
import IntegrationUser from './app/IntegrationUserAPI';
import PlayerUser from './app/PlayerUserAPI';
import LookupList from './app/LookupListAPI';

/**
 * Get the version number the API is running
 */
function getVersion() {
  return new Promise((resolve, reject) => {
    request
      .get(process.env.VAPI_BASE_URL)
      .end((err, res) => {
        if (err) {
          reject(err);
        } else {
          const apiVersion = /^VAPI \((?<version>\d{1,2}\.\d{1,3}\.\d{1,3})\) is listening\.$/i
            .exec(res.text)
            .groups
            .version;

          resolve(apiVersion || 'Unknown');
        }
      });
  });
}

export default {
  Advertiser,
  Authentication,
  Baseline,
  Campaign,
  Company,
  Currency,
  DeviceAttribute,
  DeviceManagement,
  Dma,
  Event,
  Flight,
  Local,
  Location,
  LocationZones,
  LocationControlFile,
  Message,
  MessageBlock,
  MessageRequest,
  Mix,
  Music,
  Notification,
  Order,
  Partner,
  PartnerUser,
  Permission,
  PermissionSet,
  Playlist,
  ScreenResolution,
  Tag,
  TargetGroup,
  TargetTag,
  ThirdParty,
  User,
  IntegrationUser,
  PlayerUser,
  LookupList,
  getVersion,
};
