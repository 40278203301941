// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PermissionSetBulkUsers {
  height: 100%;
}
.PermissionSetBulkUsers .assigned-toolbar {
  display: flex;
  align-items: center;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Users/Permissions/PermissionSetBulkUsers.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACJ","sourcesContent":[".PermissionSetBulkUsers {\n  height: 100%;\n\n  .assigned-toolbar {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
