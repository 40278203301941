import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  values,
  isEmpty,
} from 'lodash';
import {
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import {
  passwordValidation,
} from '../../../../utils/validation';
import './ChangePassword.scss';

class ChangePassword extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      passwordErrors: {
        number: true,
        uppercase: true,
        lowercase: true,
        special: true,
        minLength: true,
        errors: true,
        match: true,
      },
    };
  }

  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onChange,
    } = this.props;

    this.setState((state) => {
      let {
        passwordErrors,
        newPassword,
        newPasswordConfirm,
      } = state;

      // set the password value based on the field being edited
      if (name === 'newPassword') {
        newPassword = value;
      } else if (name === 'newPasswordConfirm') {
        newPasswordConfirm = value;
      }

      passwordErrors = passwordValidation(newPassword, newPasswordConfirm);

      return {
        [name]: value,
        passwordErrors,
      };
    }, () => {
      const {
        currentPassword,
        newPassword,
        newPasswordConfirm,
        passwordErrors,
      } = this.state;

      const errorValues = values(passwordErrors);
      // Are any of the change password fields not empty?
      const changing = !isEmpty(currentPassword)
        || !isEmpty(newPassword)
        || !isEmpty(newPasswordConfirm);

      if (errorValues.indexOf(true) === -1) {
        // no errors, send back to update on save
        onChange({
          changing,
          currentPassword,
          newPassword,
        });
      } else {
        // change password still has errors, do not send back to update until resolved
        onChange({
          changing,
          currentPassword: null,
          newPassword: null,
        });
      }
    });
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      currentPassword,
      newPassword,
      newPasswordConfirm,
      passwordErrors,
    } = this.state;

    return (
      <div className={classNames('ChangePassword', className)}>
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={6} item>
            <Field
              type="password"
              label="Current Password"
              name="currentPassword"
              placeholder="Current Password"
              value={currentPassword}
              tabIndex={8}
              marginBottom={8}
              onChange={this.onChange}
            />

            <Field
              type="password"
              label="New Password"
              name="newPassword"
              placeholder="New Password"
              value={newPassword}
              tabIndex={9}
              marginBottom={8}
              onChange={this.onChange}
            />

            <Field
              type="password"
              label="Confirm New Password"
              name="newPasswordConfirm"
              placeholder="Confirm New Password"
              value={newPasswordConfirm}
              tabIndex={10}
              onChange={this.onChange}
            />
          </Grid>

          <Grid xs={6} item>
            <Field
              type="custom"
              label="Requirements"
            >
              <div className="requirement-container">
                <ul className="verification-list-container">
                  <li>
                    <div className={classNames('verification-circle', { unverified: passwordErrors.number })} />
                    <span className={classNames('text', { unverified: passwordErrors.number })}>
                      One number
                    </span>
                  </li>
                  <li>
                    <div className={classNames('verification-circle', { unverified: passwordErrors.lowercase })} />
                    <span className={classNames('text', { unverified: passwordErrors.lowercase })}>
                      One lowercase letter
                    </span>
                  </li>
                  <li>
                    <div className={classNames('verification-circle', { unverified: passwordErrors.uppercase })} />
                    <span className={classNames('text', { unverified: passwordErrors.uppercase })}>
                      One uppercase letter
                    </span>
                  </li>
                  <li>
                    <div className={classNames('verification-circle', { unverified: passwordErrors.special })} />
                    <span className={classNames('text', { unverified: passwordErrors.special })}>
                      One special character
                    </span>
                  </li>
                  <li>
                    <div className={classNames('verification-circle', { unverified: passwordErrors.minLength })} />
                    <span className={classNames('text', { unverified: passwordErrors.minLength })}>
                      8 character minimum
                    </span>
                  </li>
                  <li>
                    <div className={classNames('verification-circle', { unverified: passwordErrors.match })} />
                    <span className={classNames('text', { unverified: passwordErrors.match })}>
                      New password match
                    </span>
                  </li>
                </ul>
              </div>
            </Field>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

ChangePassword.defaultProps = {
  className: '',
  onChange: () => {},
};

export default ChangePassword;
