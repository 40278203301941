import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  isEmpty,
} from 'lodash';
import {
  API,
  GlobalActions,
  LoadingContent,
} from 'vibeguide';
import LocationDetails from './LocationDetails';

class LocationSidebar extends PureComponent {
  constructor(props) {
    super(props);

    // fetch company to see if the ad program toggle should be enabled
    this.company = {};

    this.state = {
      location: {
        resolution: {
          _id: '',
          name: '',
          size: [0, 0],
          custom: true,
        },
      },
    };
  }

  componentDidMount() {
    const {
      locationId,
    } = this.props;

    if (locationId && locationId !== 'new') {
      this.getLocation();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      locationId,
      isNew,
    } = this.props;

    const {
      locationId: prevLocationId,
    } = prevProps;

    if (locationId && !isNew && locationId !== prevLocationId) {
      this.getLocation();
    }
  }

  /**
   * Update the location
   */
  onUpdate = async (data) => {
    if (data.companyId === '') {
      // removing the selected company, reset the saved company details
      this.company = {};
      // reset the ad program enabled toggle when the company is cleared
      data.adProgramEnabled = false;
      data.adNetworkEnabled = false;
    }

    // get company if set to enable/disable the ad program toggle
    this.company = data.companyId
      ? await API.Company.getById(data.companyId)
      : this.company;

    this.setState((state) => {
      return {
        location: {
          ...state.location,
          ...data,
          // allow enabling ad program only if the company has it enabled
          companyAdProgramEnabled: this.company.adProgramEnabled || false,
          companyAdNetworkEnabled: this.company.adNetworkEnabled || false,
        },
      };
    });
  };

  /**
   * Close the sidebar
   */
  onClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  getLocation = async () => {
    const {
      locationId,
    } = this.props;

    const location = await API.Location.getById(locationId);
    const company = await API.Company.getById(location.companyId);

    // save the company ad program status
    location.companyAdProgramEnabled = company.adProgramEnabled;
    location.companyAdNetworkEnabled = company.adNetworkEnabled;

    // save the company
    this.company = company;

    if (!isEmpty(location.resolution)) {
      location.resolution.selected = true;
      // eslint-disable-next-line max-len
      location.resolution.value = `${location.resolution.custom ? 'Custom' : location.resolution.name} (${location.resolution.size[0]} x ${location.resolution.size[1]})`;
    }

    this.setState({
      location,
    });
  };

  render() {
    const {
      locationId,
      isNew,
    } = this.props;

    const {
      location,
    } = this.state;

    return (
      <div
        className="LocationSidebar"
        style={{
          height: '100%',
        }}
      >
        <LocationDetails
          locationData={location}
          isNew={isNew}
          onClose={this.onClose}
          onUpdate={this.onUpdate}
        />

        {locationId && !location._id && (
          <LoadingContent />
        )}
      </div>
    );
  }
}

LocationSidebar.propTypes = {
  locationId: PropTypes.string,
  /** New Location */
  isNew: PropTypes.bool,
};

LocationSidebar.defaultProps = {
  locationId: null,
  isNew: false,
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(LocationSidebar);
