import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import {
  SegmentTypeHelper,
  MediaFormatHelper,
  SpotTypeHelper,
  SpotSelectionStrategyHelper,
  Field2 as Field,
  VibeCheckbox,
  color,
} from 'vibeguide';

function SegmentInformation({
  className,
  style,
  segmentType,
  mediaFormat,
  spotType,
  spotSelectionStrategy,
  frequencyCapped,
  frequencyCap,
  allowBackToBackAdDelivery,
  allocateRemainder,
}) {
  const titleStyle = {
    fontSize: 16,
    fontWeight: 'bold',
    color: color.secondary,
  };

  return (
    <div
      className={classNames('SegmentInformation', className)}
      style={style}
    >
      <Grid spacing={4} container>
        {/* Type */}
        <Grid xs={6} item>
          <Grid spacing={2} container>
            <Grid xs={12} item>
              <div
                style={titleStyle}
              >
                Type
              </div>
            </Grid>

            <Grid xs={6} item>
              <Field
                type="select"
                label="Segment Type"
                name="segmentType"
                value={segmentType}
                options={SegmentTypeHelper.list({ includeEmpty: true })}
                disabled
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="select"
                label="Media Format"
                name="mediaFormat"
                value={mediaFormat}
                options={MediaFormatHelper.list({ includeEmpty: true })}
                disabled
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="select"
                label="Spot Type"
                name="spotType"
                value={spotType}
                options={SpotTypeHelper.list({ includeEmpty: true })}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Delivery */}
        <Grid xs={6} item>
          <Grid spacing={2} container>
            <Grid xs={12} item>
              <div
                style={titleStyle}
              >
                Delivery
              </div>
            </Grid>

            <Grid xs={6} item>
              <Field
                type="select"
                label="Spot Selection Strategy"
                name="spotSelectionStrategy"
                value={spotSelectionStrategy}
                options={SpotSelectionStrategyHelper.list({ includeEmpty: true })}
                disabled
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="toggle"
                label="Allocate Remainder"
                toggleProps={{
                  label: 'Allocate Remainder',
                }}
                value={allocateRemainder
                  ? 'on'
                  : 'off'}
                disabled
              />
            </Grid>

            {spotSelectionStrategy !== 'spots-per-hour' && (
              <>
                <Grid xs={6} item>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <VibeCheckbox
                      rootStyle={{
                        padding: 0,
                        paddingRight: 8,
                      }}
                      label="Add Frequency Capping"
                      size={24}
                      color={color.primary}
                      name="frequencyCapped"
                      checked={frequencyCapped}
                      disabled
                    />

                    <Field
                      type="number"
                      style={{
                        width: 52,
                      }}
                      name="frequencyCap"
                      value={frequencyCap}
                      disabled
                    />
                  </div>
                </Grid>

                <Grid xs={6} item />
              </>
            )}

            <Grid xs={6} item>
              <Field
                type="toggle"
                label="Ad Plays"
                toggleProps={{
                  label: 'Allow Duplicate Ads Back to Back',
                  helpText: 'Disabling limits inventory to ad programs',
                }}
                value={allowBackToBackAdDelivery
                  ? 'on'
                  : 'off'}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

SegmentInformation.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  segmentType: PropTypes.string,
  mediaFormat: PropTypes.string,
  spotType: PropTypes.string,
  spotSelectionStrategy: PropTypes.string,
  frequencyCapped: PropTypes.bool,
  frequencyCap: PropTypes.number,
  allowBackToBackAdDelivery: PropTypes.bool,
  allocateRemainder: PropTypes.bool,
};

SegmentInformation.defaultProps = {
  className: '',
  style: {},
  segmentType: '',
  mediaFormat: '',
  spotType: '',
  spotSelectionStrategy: '',
  frequencyCapped: false,
  frequencyCap: 0,
  allowBackToBackAdDelivery: false,
  allocateRemainder: false,
};

export default SegmentInformation;
