import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
  NavigationHelper,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  VibeTooltip,
  VibeButton,
  VibeModal,
  VibeIcon,
  viClose,
  viArchive,
  viUnarchive,
  withRouter,
  color,
  Field,
  MimeTypeHelper,
} from 'vibeguide';
import Information from './Content/Information';
import defaultUserImage from '../../../assets/users_t.png';
import './AdvertiserDetails.scss';

function AdvertiserDetails({
  advertiser,
  user,
  onClose,
  onUpdate,
  isNew,
  history,
  queueToast,
}) {
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);

  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  const onClickUnarchive = async () => {
    const {
      _id,
    } = advertiser;

    await API.Advertiser.reactivate({
      _id,
    });

    onClose();

    document.dispatchEvent(new Event('onSaveAdvertiser'));
  };

  const onCloseArchive = () => {
    setConfirmArchive(false);
  };

  const onConfirmArchive = async () => {
    const {
      _id,
    } = advertiser;

    await API.Advertiser.deactivate(_id);

    onCloseArchive();
    onClose();

    document.dispatchEvent(new Event('onSaveAdvertiser'));
  };

  const onDrop = async (fileObj) => {
    const file = get(fileObj, '[0]', {
      file: null,
    });

    if (file && file.error) {
      console.warn('File has an error', fileObj);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const {
        target: {
          result,
        },
      } = e;

      setFile(file.file);
      setFileData(result);
    };

    reader.onabort = () => console.error('file reading was aborted');
    reader.onerror = () => console.error('file reading has failed');

    reader.readAsDataURL(file.file);
  };

  const onSave = async () => {
    const {
      _id,
      name,
    } = advertiser;

    const data = {
      name,
    };

    if (_id && !isNew) {
      data._id = _id;
    }

    document.dispatchEvent(new Event('onSaveAdvertiserStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const response = _id && !isNew
        ? await API.Advertiser.modify(_id, data)
        : await API.Advertiser.create(data);

      const advertiserId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = _id && !isNew
        ? responseType === 'ADVERTISER.UPDATED'
        : responseType === 'ADVERTISER.CREATED';

      if (success) {
        // Successfully saved the baseline
        const saveMessage = _id && !isNew
          ? 'Advertiser Updated!'
          : 'Advertiser Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });
      }

      if (success && file) {
        queueToast({
          type: 'info',
          title: 'Uploading Image...',
          allowClose: true,
        });

        const imageResponse = await API.Advertiser.uploadImage(advertiserId, file);
        const imageSuccess = get(imageResponse, '[0].type') === 'ADVERTISER.IMAGE_UPLOADED';

        if (imageSuccess) {
          queueToast({
            type: 'success',
            title: 'Image Uploaded!',
            allowClose: true,
            delay: 500,
          });
        }
      }

      // Refresh the user details and redirect to their edit screen
      if (success && isNew) {
        const redirectUrl = NavigationHelper.updateParams({
          advertiserId,
          type: null,
        });

        history(redirectUrl);
      }

      document.dispatchEvent(new Event('onSaveAdvertiser'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveAdvertiserError'));
    }
  };

  const disableSave = !advertiser.name;

  const disableInput = (advertiser._id && !user.can('advertiser.modify'))
    || (!advertiser._id && !user.can('advertiser.create'));

  const showArchive = user.can('advertiser.delete');

  const isArchived = !advertiser.active;

  return (
    <SidePanelContainer className="AdvertiserDetails">
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="details-header">
          <div className="ad-image">
            <Field
              type="dropzone"
              dropzoneProps={{
                type: 'image',
                style: {
                  width: 40,
                  height: 40,
                },
                dragOverStyle: {
                  background: 'rgba(0, 0, 0, 0.5)',
                },
                placeholder: (
                  <VibeTooltip
                    title={!disableInput
                      ? 'Click or drag an image to change advertiser avatar'
                      : ''}
                  >
                    <img
                      className="upload-image"
                      src={fileData || advertiser.imageUrl || defaultUserImage}
                      alt="User"
                    />
                  </VibeTooltip>
                ),
                allowUpload: !disableInput,
                mimeTypes: MimeTypeHelper.images,
              }}
              onDrop={onDrop}
            />
          </div>
          {advertiser._id ? (
            <div className="flex-horizontal">
              <div className="title">
                <VibeTooltip title={advertiser.name}>
                  <span>
                    {advertiser.name}
                  </span>
                </VibeTooltip>
              </div>
            </div>
          ) : (
            <div className="title">
              New Advertiser
            </div>
          )}
        </div>
      </SidePanelHeader>

      <SidePanelContent>
        <Information
          isNew={isNew}
          name={advertiser.name}
          disableInput={disableInput}
          onUpdate={onUpdate}
        />
      </SidePanelContent>

      <SidePanelFooter className="panel-footer">
        <VibeButton
          text="Save Changes"
          color="secondary"
          loadingEvent="onSaveAdvertiser"
          disabled={disableSave || disableInput}
          onClick={onSave}
        />

        {!isNew && showArchive && !isArchived && (
          <VibeIcon
            icon={viArchive}
            type="button"
            buttonProps={{
              size: 32,
              borderColor: color.error,
            }}
            tooltip="Archive"
            color={color.error}
            size={20}
            onClick={onClickArchive}
          />
        )}

        {!isNew && showArchive && isArchived && (
          <VibeIcon
            icon={viUnarchive}
            type="button"
            buttonProps={{
              size: 32,
              borderColor: color.success,
            }}
            tooltip="Unarchive"
            color={color.success}
            size={20}
            onClick={onClickUnarchive}
          />
        )}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text={`Are you sure you want to archive ${advertiser.name}?`}
        confirmProps={{
          text: 'Archive',
          color: color.error,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCloseArchive}
      />
    </SidePanelContainer>
  );
}

AdvertiserDetails.propTypes = {
  isNew: PropTypes.bool,
  advertiser: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

AdvertiserDetails.defaultProps = {
  isNew: false,
  advertiser: {},
  onClose: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  setModal: GlobalActions.setModal,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvertiserDetails));
