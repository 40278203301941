import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useNavigate,
} from 'react-router-dom';
import {
  API,
  TableLocationZoneManagement,
  NavigationHelper,
  GlobalActions,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../Header/SubNavigation';
import ZoneSidebar from './Sidebar/ZoneSidebar';

function ZoneManagement({
  setPanel,
}) {
  const history = useNavigate();
  const qs = NavigationHelper.getParams() || {};

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      zoneId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, zoneId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 400,
      title: type === 'new'
        ? 'New Zone'
        : 'Update Zone',
      children: (
        <ZoneSidebar
          zoneId={zoneId}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const isNew = qs.type === 'new';
    const isView = qs.zoneId && qs.type !== 'new';

    if (isNew || isView) {
      sidebar(qs.type, qs.zoneId);
    }
  }, [qs.type, qs.zoneId]);

  return (
    <div
      className="CompanyCategories"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <SubNavigation
        title="Zones"
        buttons={[
          {
            text: 'New Zone',
            color: 'primary',
            icon: viAdd,
            url: NavigationHelper.updateParams({
              type: 'new',
            }),
            userCan: [
              'location-zone.create',
            ],
          },
        ]}
      />

      <TableLocationZoneManagement
        columns={[
          '.',
          'Zone',
          '...',
        ]}
        fetch={API.LocationZones.list}
        defaultSortBy={{
          label: 'Zone',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Zones',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Zones',
          tooltip: true,
        }}
        bulk
        bulkProps={{
          edit: false,
          add: false,
          block: false,
          remove: false,
          archive: true,
        }}
        rowLink={{
          zoneId: '_id',
        }}
        menuItems={[
          { name: 'Archive', userCan: 'location-zone.delete' },
        ]}
      />
    </div>
  );
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(null, mapDispatchToProps)(ZoneManagement));
