import React from 'react';
import PropTypes from 'prop-types';
import {
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';

function SystemIntegrator({
  sysIntegrator,
  disableInput,
  onUpdate,
}) {
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  return (
    <div className="Information info-user">
      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid xs={6} item>
          <Field
            type="text"
            label="System Integrator"
            name="integrationName"
            placeholder="System Integrator"
            value={sysIntegrator}
            tabIndex={1}
            disabled={disableInput}
            onChange={onChange}
            required
            autoFocus
          />
        </Grid>
      </Grid>
    </div>
  );
}

SystemIntegrator.propTypes = {
  sysIntegrator: PropTypes.string,
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

SystemIntegrator.defaultProps = {
  sysIntegrator: '',
  disableInput: false,
  onUpdate: () => {},
};

export default SystemIntegrator;
