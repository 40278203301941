import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import {
  Submenu,
  color,
} from 'vibeguide';
import {
  get,
  isEmpty,
  trim,
} from 'lodash';
import ProfileInformation from './Profile/Sections/ProfileInformation';
import ProfilePlayer from './Profile/Sections/ProfilePlayer';

function ProfileContainer({
  user,
  currentLocation,
}) {
  if (!currentLocation) {
    return null;
  }

  const [location, setLocation] = useState(currentLocation);
  const [isDisabled, setIsDisabled] = useState(false);

  // update resolution
  useEffect(() => {
    // eslint-disable-next-line max-len
    const resolutionValue = currentLocation.resolution ? `${currentLocation.resolution.custom ? 'Custom' : currentLocation.resolution.name} (${currentLocation.resolution.size[0]} x ${currentLocation.resolution.size[1]})` : '';
    setLocation(get(currentLocation, 'mediaFormat', '') === 'visual' ? {
      ...currentLocation,
      resolution: {
        ...currentLocation.resolution,
        selected: true,
        // eslint-disable-next-line max-len
        value: resolutionValue,
      },
    } : currentLocation);
  }, [currentLocation.resolution]);

  const {
    id,
    type,
  } = useParams();

  /**
   * Update the location
   */
  const onUpdateLocation = (data) => {
    const newLocation = {
      ...location,
      ...data,
    };

    const hasVisualIssue = newLocation.mediaFormat === 'visual' ? (isEmpty(trim(newLocation.screenSize))
          || isEmpty(trim(newLocation.orientation)))
          || (isEmpty(newLocation.resolution) || !newLocation.resolution.selected) : false;

    const disabled = isEmpty(trim(newLocation.name))
      || isEmpty(trim(newLocation.address1))
      || isEmpty(trim(newLocation.postalCode))
      || isEmpty(trim(newLocation.city))
      || isEmpty(trim(newLocation.state))
      || isEmpty(trim(newLocation.country))
      || isEmpty(trim(newLocation.companyId))
      || isEmpty(trim(newLocation.category))
      || isEmpty(trim(newLocation.bannerName))
      || hasVisualIssue
      || isEmpty(trim(newLocation.contentNetworkOwner))
      || isEmpty(trim(newLocation.contentDeliverySystem))
      || (newLocation.adNetworkEnabled && isEmpty(trim(newLocation.adDeliverySystem)))
      || (newLocation.adNetworkEnabled && isEmpty(trim(newLocation.adNetworkOwner)));

    setIsDisabled(disabled);
    setLocation(get(newLocation, 'mediaFormat', '') === 'visual' ? {
      ...newLocation,
      resolution: {
        ...newLocation.resolution,
        selected: true,
      },
    } : newLocation);
  };

  /**
   * Get the page content
   */
  const getPageContent = () => {
    switch (type) {
      case 'player':
        return (
          <ProfilePlayer
            location={location}
            user={user}
            onUpdate={onUpdateLocation}
          />
        );

      case 'information':
      default:
        return (
          <ProfileInformation
            location={location}
            user={user}
            onUpdate={onUpdateLocation}
            isDisabled={isDisabled}
          />
        );
    }
  };

  return (
    <div
      className="ProfileContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 150px)',
        overflow: 'auto',
      }}
    >
      <Submenu
        rootUrl={`/location/${id}/profile`}
        style={{
          position: 'sticky',
          top: 0,
          padding: '0 24px',
          // borderBottom: `1px solid ${color.whiteSmoke}`,
          backgroundColor: color.lightGray,
          zIndex: 2,
        }}
        items={[
          {
            name: 'Information',
            show: true,
            enabled: true,
          },
          {
            name: 'Player',
            show: user.can('location.modify_volume')
              || user.can('location.view_device_config'),
            enabled: true,
          },
        ]}
      />

      {getPageContent()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default connect(mapStateToProps)(ProfileContainer);
