import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  clone,
  get,
  groupBy,
  isEmpty,
  sortBy,
} from 'lodash';
import {
  API,
  TableCreatives,
  color,
  GlobalActions,
  ToastActions,
} from 'vibeguide';
import './OrderCreatives.scss';
import MediaSidebar from './Sidebar/MediaSidebar';

function OrderCreatives({
  orderId,
  segmentId,
  segment,
  allowCreativeManagement,
  updateDayPartsNew,
  setPanel,
  queueToast,
}) {
  const [creatives, setCreatives] = useState([]);
  const [onSuccess, setOnSuccess] = useState(false);

  const creativeSetup = (dayParts) => {
    let mappedCreativesArr = [];
    const defaultCreative = {
      dayPart: '',
      url: null,
      creativeId: '',
      name: '',
      weight: '',
      fileType: '',
      fileSize: '',
      graphicSize: '',
      durationSeconds: '',
      active: true,
    };

    dayParts.forEach((dayPart) => {
      const {
        startTime, endTime, dateRanges,
      } = dayPart;
      const dayPartString = `${startTime}-${endTime}`;
      let mappedDateRanges = [];
      const newCreatives = [];

      if (dateRanges) {
        mappedDateRanges = dateRanges.map((daterange) => {
          if (!isEmpty(daterange.creatives)) {
            return {
              ...daterange,
              creatives: daterange.creatives.map((creative) => {
                return {
                  ...creative,
                  _id: creative.creativeId,
                  startDate: moment(daterange.startDate).format('ddd, MMM Do, YYYY'),
                  endDate: moment(daterange.endDate).format('ddd, MMM Do, YYYY'),
                  dayPart: dayPartString,
                  fileType: creative.contentType || '',
                  fileSize: creative.fileSizeBytes || '',
                  graphicSize: creative.graphicSize || '',
                  active: true,
                };
              }),
            };
          }
          return daterange;
        });
      }

      mappedDateRanges.forEach((mdr) => {
        mdr.creatives.forEach((creative) => {
          newCreatives.push(creative);
        });
      });

      mappedCreativesArr = [
        ...mappedCreativesArr,
        ...newCreatives,
      ];

      if (!newCreatives || newCreatives.length === 0) {
        mappedCreativesArr.push({
          ...defaultCreative,
          dayPart: dayPartString,
        });
      }
    });

    setCreatives(mappedCreativesArr);
  };

  const updateDayParts = async (dayParts) => {
    const response = await API.Order.Segment.updateDayParts({
      _id: orderId,
      segmentId,
      dayParts,
    });

    const successText = 'ORDERSEGMENT.CREATIVES_UPDATED';
    const success = get(response, '[0].type') === successText;

    if (success) {
      queueToast({
        type: 'success',
        title: 'Saved...',
        allowClose: true,
      });
      setOnSuccess(true);
      creativeSetup(dayParts);
    }

    setOnSuccess(false);
  };

  const creativesToDayParts = (creatives) => {
    const { dayParts } = segment;

    const filteredCreatives = creatives.filter(creative => creative.creativeId !== '');

    // Group by daypart
    const creativesByDayPart = groupBy(filteredCreatives, 'dayPart');

    return dayParts.map(dayPart => {
      const { startTime, endTime } = dayPart;
      const dayPartString = `${startTime}-${endTime}`;
      const dayPartCreatives = creativesByDayPart[dayPartString] || [];

      const updatedCreatives = dayPartCreatives.map(creative => ({
        ...creative,
        weight: creative.weight,
      }));

      return {
        ...dayPart,
        creatives: updatedCreatives,
      };
    });
  };

  const deleteCreative = async (creatives) => {
    // Convert creativesByDayPart to dayParts format
    const newDayParts = creativesToDayParts(creatives);
    updateDayParts(newDayParts);
  };

  const onSelectMedia = async (dayPart, messages) => {
    const allCreativesByDayPart = [
      ...creatives.filter(creative => creative.dayPart === dayPart && creative.creativeId !== ''),
      ...messages,
    ];

    const newCreatives = allCreativesByDayPart.map(creative => {
      const {
        active,
        name,
        durationSeconds,
        url,
        creativeId,
        _id,
        weight,
        contentType,
        fileSizeBytes,
        grpahicSize,
      } = creative;

      return {
        creativeId: !creativeId ? _id : creativeId,
        weight: !weight ? '' : weight,
        fileType: contentType || '',
        fileSize: fileSizeBytes || '',
        graphicSize: grpahicSize || '',
        dayPart,
        active,
        name,
        durationSeconds,
        url,
      };
    });

    setCreatives([
      ...creatives.filter(creative => creative.dayPart !== dayPart),
      ...newCreatives,
    ]);

    setPanel({
      show: false,
    });
  };

  const onCancelEdit = async () => {
    const response = await API.Order.Segment.get({ _id: orderId, segmentId });

    if (response) {
      creativeSetup(response.dayParts);
    }
  };

  const onCloseSidebar = () => {
    setPanel({
      show: false,
      children: null,
    });
  };

  const addCreative = (row) => {
    const { dayParts } = segment;
    const dayPartIndex = dayParts.findIndex(daypart => row.dayPart === `${daypart.startTime}-${daypart.endTime}`);
    const dayPart = dayParts.filter(daypart => row.dayPart === `${daypart.startTime}-${daypart.endTime}`);
    const mediaDayPart = clone(dayPart[0]);
    setPanel({
      show: true,
      backdrop: true,
      width: 400,
      children: (
        <MediaSidebar
          editMedia
          dayPart={mediaDayPart}
          dayPartIndex={dayPartIndex}
          dayParts={dayParts}
          segment={segment}
          orderId={orderId}
          onSelectMedia={onSelectMedia}
          saveDayParts={updateDayPartsNew}
          creativeSetup={creativeSetup}
          onCloseSidebar={onCloseSidebar}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  const saveCreatives = (rows) => {
    // convert creatives into dayParts
    const newDayParts = creativesToDayParts(rows);
    updateDayParts(newDayParts);
  };

  useEffect(() => {
    if (!segment && !segment.dayParts) return;

    creativeSetup(segment.dayParts);
  }, [segment.dayParts]);

  return (
    <div className="OrderCreatives">
      <div className="creative-title">
        Attached Creatives
      </div>

      <div className="creative-sub-title">
        Media that has been attached to this segment.
      </div>

      <TableCreatives
        className="custom-creative-table"
        columns={allowCreativeManagement
          ? [
            'Day Part',
            'Start Date',
            'End Date',
            'Message Name',
            'Weight',
            'File Type',
            'File Size',
            'Duration',
            '...']
          : [
            'Day Part',
            'Creative Management Unavailable',
          ]}
        collection={sortBy(creatives, ({ dayPart }) => {
          const [startTime] = dayPart.split('-');
          return startTime;
        })}
        style={{
          height: 400,
          border: `1px solid ${color.manatee16}`,
          borderTop: 'none',
        }}
        defaultSortBy={{
          label: 'Message Name',
          attr: '',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Media',
          urlPaging: false,
          urlFilters: true,
        }}
        orderId={orderId}
        segmentId={segmentId}
        onCancelEdit={onCancelEdit}
        onAddMedia={addCreative}
        onSaveTable={saveCreatives}
        onRemove={deleteCreative}
        onSuccess={onSuccess}
        menuItems={[
          { name: 'Delete', userCan: 'order_segment.modify' },
        ]}
        editMode={allowCreativeManagement
          && segment.orderStatus !== 'completed'
          && segment.orderStatus !== 'cancelled'}
      />
    </div>
  );
}

OrderCreatives.propTypes = {
  orderId: PropTypes.string,
  segmentId: PropTypes.string,
  segment: PropTypes.object,
  allowCreativeManagement: PropTypes.bool,
};

OrderCreatives.defaultProps = {
  orderId: '',
  segmentId: '',
  segment: {},
  allowCreativeManagement: false,
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default connect(null, mapDispatchToProps)(OrderCreatives);
