import { each, isUndefined } from 'lodash';

class Flight {
  constructor(data = {}) {
    this._id = data._id || '';
    this.active = data.active || false;
    this.advertiserId = data.advertiserId || '';
    this.advertiserName = data.advertiserName || '';
    this.averageDailyImpressions = data.averageDailyImpressions || 0;
    this.name = data.name || '';
    this.category = data.category || '';
    this.currencyCode = data.currencyCode || '';
    this.dealId = data.dealId || '';
    this.deliverySystem = data.deliverySystem || '';
    this.adProvider = data.adProvider || '';
    this.mediaCost = data.mediaCost || 0;
    this.mediaFormats = data.mediaFormats || [];
    this.missingCreatives = data.missingCreatives || false;
    this.opportunityId = data.opportunityId || '';
    this.opportunityName = data.opportunityName || '';
    this.pacingPercent = data.pacingPercent || 0;
    this.partnerId = data.partnerId || '';
    this.partnerName = data.partnerName || '';
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.status = data.status || 'unconfirmed';
    this.statusReason = data.statusReason || '';
    this.flightStage = data.flightStage || '';
    this.reportingAuthority = data.reportingAuthority || '';
    this.warnings = data.warnings || [];
    this.locationSummary = data.locationSummary || {
      banners: 0,
      companies: 0,
      locations: 0,
    };
    this.targetImpressions = data.targetImpressions || 0;
    this.totalImpressions = data.totalImpressions || 0;
    this.totalAddedValueImpressions = data.totalAddedValueImpressions || 0;
    this.totalSpots = data.totalSpots || 0;
    this.budget = data.budget || 0;
    this.frequency = data.frequency || 0;
    this.weightedCpm = data.weightedCpm || null;
    this.createdDate = data.createdDate || null;
    this.createdBy = data.createdBy || null;
    this.modifiedDate = data.modifiedDate || null;
    this.modifiedBy = data.modifiedBy || null;
    this.lastArchivedBy = data.lastArchivedBy || null;
    this.lastArchivedDate = data.lastArchivedDate || null;
    this.lastUnarchivedBy = data.lastUnarchivedBy || null;
    this.lastUnarchivedDate = data.lastUnarchivedDate || null;

    each(data, (val, key) => {
      if (isUndefined(this[key])) {
        // Add the property to the model
        this[key] = val;

        console.warn(`${key} is not added to Flight Model`);
      }
    });
  }
}

export default Flight;
