import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  find,
  findIndex,
  head,
  clone,
  last,
  inRange,
  sortBy,
  uniqueId,
} from 'lodash';
import {
  API,
  EventModel,
  TimeUtil,
  viAdd,
  color,
} from 'vibeguide';
import {
  Tooltip,
} from '@mui/material';
import GridItem from '../GridItem';
import MusicItem from '../Items/MusicItem';
import MessageBlockItem from '../Items/MessageBlockItem';
import InterruptItem from '../Items/InterruptItem';
import DragItem from '../Items/DragItem';
import Playlist from '../../../../../Programs/Baselines/Calendar/Items/Playlist';
import Mix from '../../../../../Programs/Baselines/Calendar/Items/Mix';
import MessageBlock from '../../../../../Programs/Baselines/Calendar/Items/MessageBlock';
import './GridItemColumn.scss';

// save the current Y position to prevent multiple renders
let currentY = 0;
// save the current slot mouse position to prevent multiple renders
let currentSlot = null;
// the current item ID being moved
let moveItemId = null;
// the type of item being moved
let moveItemType = null;
// the current item ID being resized
let resizeItemId = null;
// are we resizing the start/stop time of the item
let resizeItemStartOrStopTime = null;

/**
 * Get the slot when dragging something into the day
 */
function getSlot({
  activeDisplay,
  slotHeight,
  y,
}) {
  // get the full slot position (index is the full number, remainder is the position within the slot)
  const slotPos = y / slotHeight;
  const slotIndex = Math.floor(slotPos) >= 0
    ? Math.floor(slotPos)
    : 0;
  // position within the slot
  const slotPercent = (slotPos % 1) * 100;

  switch (activeDisplay) {
    case '24h':
    case '1h': {
      const hour = slotIndex < 10
        ? `0${slotIndex}`
        : slotIndex;

      if (slotPercent < 25) {
        return `${hour}:00`;
      }

      if (slotPercent < 50) {
        return `${hour}:15`;
      }

      if (slotPercent < 75) {
        return `${hour}:30`;
      }

      if (slotPercent < 90) {
        return `${hour}:45`;
      }

      // between 90-100% (jump to the next hour)
      const nextHourInt = parseInt(hour, 10) + 1;
      const nextHour = nextHourInt < 10
        ? `0${nextHourInt}`
        : nextHourInt;

      return `${nextHour}:00`;
    }

    case '15m': {
      const slotTimeInSeconds = slotIndex * 15;

      if (slotPercent < 33) {
        return TimeUtil.convertDuration(slotTimeInSeconds);
      }
      if (slotPercent < 66) {
        return TimeUtil.convertDuration(slotTimeInSeconds + 5);
      }
      if (slotPercent < 90) {
        return TimeUtil.convertDuration(slotTimeInSeconds + 10);
      }

      // between 90-100% (jump to the next 15 minute)
      return TimeUtil.convertDuration(slotTimeInSeconds + 15);
    }

    case '1m': {
      if (slotPercent < 90) {
        return TimeUtil.convertDuration(slotIndex);
      }

      // between 90-100% (jump to the next minute)
      return TimeUtil.convertDuration(slotIndex + 1);
    }

    default:
      return 'Unknown';
  }
}

/**
 * Get the items available start/stop time based on the current mouse position when dragging
 */
function getAvailableItemSpan({
  type,
  slot,
  dragType,
  items,
  activeEvent,
}) {
  if (type === 'Music' && (dragType !== 'Playlist' && dragType !== 'Mix')) {
    // Music column only accept playlists and stations
    return false;
  }

  if (type === 'Message Blocks' && dragType !== 'Message Block') {
    // Message Block column only accept message blocks
    return false;
  }

  if (type === 'Interrupts' && dragType !== 'Message') {
    // Interrupt column only accept messages
    return false;
  }

  const slotMinutes = TimeUtil.getMinutes(slot);

  const eventStartMinutes = TimeUtil.getMinutes(get(activeEvent, 'startTime'));
  const eventStopMinutes = TimeUtil.getMinutes(get(activeEvent, 'stopTime'));

  if (activeEvent && slotMinutes < eventStartMinutes) {
    // if this is an event only allow a time span within the event bounds
    return false;
  }

  if (activeEvent && slotMinutes >= eventStopMinutes) {
    // if this is an event only allow a time span within the event bounds
    return false;
  }

  // which items to use (remove the item being moved if applicable)
  const useItems = moveItemId
    ? items.filter(item => item._uniqueId !== moveItemId)
    : items;

  if (type === 'Interrupts' && dragType === 'Message') {
    // Interrupt set start time to the slot minute
    // Determine if an interrupt is already scheduled at this time
    const slotTime = TimeUtil.convertMinutes(slotMinutes);
    const interrupt = find(useItems, { startTime: slotTime });

    if (interrupt) {
      // an item is already occuring at this time
      // warn user this will replace the conflicted item with the item being dragged
      return {
        startTime: interrupt.startTime,
        // interrupts have no stop time, use the start time
        stopTime: interrupt.startTime,
        replaceId: interrupt._uniqueId,
        replaceName: get(interrupt, 'message.name'),
      };
    }

    return {
      startTime: slotTime,
      stopTime: slotTime,
    };
  }

  // is an item currently in this range?
  const conflictItem = find(useItems, (item) => {
    const startMinutes = TimeUtil.getMinutes(item.startTime);
    const stopMinutes = TimeUtil.getMinutes(item.stopTime);

    return inRange(slotMinutes, startMinutes, stopMinutes);
  });

  if (conflictItem) {
    // an item is already occuring at this time
    // warn user this will replace the conflicted item with the item being dragged
    return {
      startTime: conflictItem.startTime,
      stopTime: conflictItem.stopTime,
      replaceId: conflictItem._uniqueId,
      // show the name of the item being replaced
      replaceName: get(conflictItem, 'playlist.name')
        || get(conflictItem, 'mix.name')
        || get(conflictItem, 'messageBlock.name')
        || get(conflictItem, 'message.name'),
    };
  }

  // get the last item before this time slot
  const prevItem = last(useItems.filter(item => TimeUtil.getMinutes(item.stopTime) <= slotMinutes));
  // get the first item after this time slot
  const nextItem = head(useItems.filter(item => TimeUtil.getMinutes(item.startTime) >= slotMinutes));

  // if this is an event only allow a time span within the event bounds
  const startTimeEventCheck = activeEvent && !prevItem
    ? activeEvent.startTime
    : '00:00';
  const stopTimeEventCheck = activeEvent && !nextItem
    ? activeEvent.stopTime
    : '24:00';

  // set the start/stop time for the item to be dropped based on the range between items
  const startTime = prevItem
    ? prevItem.stopTime
    : startTimeEventCheck;

  const stopTime = nextItem
    ? nextItem.startTime
    : stopTimeEventCheck;

  return {
    startTime,
    stopTime,
  };
}

/**
 * Get the tooltip used when dragging an item on the schedule
 */
function getDragItemTooltip(type, dragItemSpan) {
  // warn the user this will replace the conflicting item on the schedule with the item being dragged
  if (dragItemSpan.replaceId) {
    return `${dragItemSpan.replaceName || 'This'} will be replaced`;
  }

  if (type === 'Interrupts') {
    return TimeUtil.convertToTwelveHour({ time: dragItemSpan.startTime });
  }

  // show time span
  const startTimeStr = TimeUtil.convertToTwelveHour({ time: dragItemSpan.startTime });
  const stopTimeStr = TimeUtil.convertToTwelveHour({ time: dragItemSpan.stopTime });
  return `${startTimeStr} - ${stopTimeStr}`;
}

/**
 * Get the height of the drop zone (used for interrupts without a stop time)
 */
function getInterruptItemHeight(replaceId) {
  if (replaceId) {
    // use the interrupt item height to show it is being replaced
    return 24;
  }

  // show a line where the user cursor is pointed
  return 4;
}

/**
 * Update the item in the schedule if no collisions are detected
 */
function updateItem({
  items,
  data,
  minStartTime = '00:00',
  maxStopTime = '24:00',
  moving = false,
  interrupt = false,
}) {
  const {
    _uniqueId,
    startTime,
    stopTime,
  } = data;

  const duration = TimeUtil.getDuration(startTime, stopTime);

  /**
   * Do not allow the item to have a 00:00 duration
   * Skip check when the item is an interrupt
   */
  if (duration === '00:00' && !interrupt) {
    // do not allow an item to have no duration
    console.error(_uniqueId, 'cannot have a 00:00 duration');
    return items;
  }

  const itemIndex = findIndex(items, { _uniqueId });
  const prevItem = items[itemIndex - 1];
  const nextItem = items[itemIndex + 1];

  /**
   * Do not allow the item to start before the previous item stops
   * Skip check when an item is being moved
   */
  if (!moving
    && prevItem
    && TimeUtil.getMinutes(startTime) < TimeUtil.getMinutes(prevItem.stopTime)
  ) {
    // do not allow the item to encroach into the prev item
    console.error(_uniqueId, 'cannot encroach into 1', prevItem._uniqueId);

    return sortBy(items.map((item) => {
      if (item._uniqueId === _uniqueId) {
        // force the item being modified to have a start time of the prev items stop time
        return {
          ...item,
          ...data,
          startTime: prevItem.stopTime,
        };
      }

      return item;
    }), 'startTime');
  }

  /**
   * Do not allow the item to stop after the next item starts
   * Skip check when an item is being moved
   */
  if (!moving
    && nextItem
    && TimeUtil.getMinutes(stopTime) > TimeUtil.getMinutes(nextItem.startTime)
  ) {
    // do not allow the item to encroach into the next item
    console.error(_uniqueId, 'cannot encroach into 2', nextItem._uniqueId);

    return sortBy(items.map((item) => {
      if (item._uniqueId === _uniqueId) {
        // force the item being modified to have a stop time of the next items start time
        return {
          ...item,
          ...data,
          stopTime: nextItem.startTime,
        };
      }

      return item;
    }), 'startTime');
  }

  /**
   * Do not allow the item to start before the minimum start time
   */
  if (TimeUtil.getMinutes(startTime) < TimeUtil.getMinutes(minStartTime)) {
    console.error(_uniqueId, 'has a minimum start time of', minStartTime);

    return sortBy(items.map((item) => {
      if (item._uniqueId === _uniqueId) {
        // force the item being modified to have a start time of the minimum start time
        return {
          ...item,
          ...data,
          startTime: minStartTime,
        };
      }

      return item;
    }), 'startTime');
  }

  /**
   * Do not allow the item to stop after the maximum stop time
   */
  if (TimeUtil.getMinutes(stopTime) > TimeUtil.getMinutes(maxStopTime)) {
    console.error(_uniqueId, 'has a maximum stop time of', maxStopTime);

    return sortBy(items.map((item) => {
      if (item._uniqueId === _uniqueId) {
        // force the item being modified to have a stop time of the maximum stop time
        return {
          ...item,
          ...data,
          stopTime: maxStopTime,
        };
      }

      return item;
    }), 'startTime');
  }

  // no collision detected, update the item
  return sortBy(items.map((item) => {
    if (item._uniqueId === _uniqueId) {
      return {
        ...item,
        ...data,
      };
    }

    return item;
  }), 'startTime');
}

function GridItemColumn({
  type,
  activeDisplay,
  slotHeight,
  items: propItems,
  dragging,
  dragType,
  activeEvent,
  readOnly,
  // onUpdateEvent,
  onUpdateItems,
}) {
  const dayRef = useRef(null);
  const [items, setItems] = useState(propItems);
  const [dragItemSpan, setDragItemSpan] = useState({
    startTime: null,
    stopTime: null,
    replaceId: null,
    replaceName: null,
  });
  const [resizing, setResizing] = useState(false);
  const [moving, setMoving] = useState(false);

  const onDragOver = (e) => {
    e.preventDefault();

    const {
      pageY,
    } = e;

    if (pageY === currentY) {
      // do not re-render until the mouse moves
      return;
    }

    const {
      top,
    } = dayRef.current.getBoundingClientRect();

    // get the position of the mouse relative to the grid element
    const y = pageY - top;
    // get the current slot being hovered over
    const slot = getSlot({
      activeDisplay,
      slotHeight,
      y,
    });

    // prevent multiple re-renders and only update if the slot they are hovering over changes
    if (slot === currentSlot) {
      return;
    }

    // get the start/stop time of the item if dropped at this spot
    const itemSpan = getAvailableItemSpan({
      type,
      slot,
      dragType: dragType || moveItemType,
      items,
      activeEvent,
    });

    if (itemSpan) {
      // item can be dropped in this range
      setDragItemSpan({
        startTime: itemSpan.startTime,
        stopTime: itemSpan.stopTime,
        replaceId: itemSpan.replaceId || null,
        replaceName: itemSpan.replaceName || null,
      });
    } else {
      // item cannot be dropped in this time slot
      setDragItemSpan({
        startTime: null,
        stopTime: null,
        replaceId: null,
        replaceName: null,
      });
    }

    // set the current mouse position to prevent re-renders until the mouse moves
    currentY = pageY;
    currentSlot = slot;
  };

  const onDragLeave = () => {
    setDragItemSpan({
      startTime: null,
      stopTime: null,
      replaceId: null,
      replaceName: null,
    });
  };

  const onDrop = async (e) => {
    const {
      dataTransfer,
    } = e;

    const movingItem = moveItemId !== null;

    const dropData = movingItem
      // currently moving an item
      ? null
      // dropping from a table in the drawer
      : JSON.parse(dataTransfer.getData('row:data'));

    const item = movingItem
      ? find(items, { _uniqueId: moveItemId })
      // dropping from a table in the drawer
      : head(dropData);

    // if replacing an existing item, remove the item being replaced first
    const useItems = dragItemSpan.replaceId
      ? items.filter(item => item._uniqueId !== dragItemSpan.replaceId)
      : items;

    if (dragItemSpan.replaceId && activeEvent) {
      // attempt to remove the replaced item from the event
      activeEvent.removePlaylist(dragItemSpan.replaceId);
      activeEvent.removeStation(dragItemSpan.replaceId);
      activeEvent.removeMessageBlock(dragItemSpan.replaceId);
      activeEvent.removeInterrupt(dragItemSpan.replaceId);
    }

    // only allow drop when a viable timeslot is found
    if (!movingItem && dragItemSpan.startTime && dragItemSpan.stopTime) {
      // Dropping an item from the drawer
      let newItems;

      if (dragType === 'Playlist') {
        newItems = sortBy([
          ...useItems,
          {
            _uniqueId: uniqueId('playlist-'),
            startTime: dragItemSpan.startTime,
            stopTime: dragItemSpan.stopTime,
            playlist: item,
          },
        ], 'startTime');
      } else if (dragType === 'Mix') {
        newItems = sortBy([
          ...useItems,
          {
            _uniqueId: uniqueId('station-'),
            startTime: dragItemSpan.startTime,
            stopTime: dragItemSpan.stopTime,
            mix: item,
          },
        ], 'startTime');
      } else if (dragType === 'Message Block') {
        newItems = sortBy([
          ...useItems,
          {
            _uniqueId: uniqueId('message-block-'),
            startTime: dragItemSpan.startTime,
            stopTime: dragItemSpan.stopTime,
            messageBlock: item,
          },
        ], 'startTime');
      } else if (dragType === 'Message') {
        // fetch the dropped item details to get the locations
        // used to determine if the item can be played on a baseline with its locations
        const message = await API.Message.getById(item._id);
        item.locations = message.locations;

        newItems = sortBy([
          ...useItems,
          {
            _uniqueId: uniqueId('message-'),
            startTime: dragItemSpan.startTime,
            // do not check if the message will play
            accessAllLocations: true,
            accessCurrentLocation: true,
            message: item,
          },
        ], 'startTime');
      }

      // tell the schedule to add the dropped item
      setItems(newItems);
      onUpdateItems(newItems, type);

      document.dispatchEvent(new CustomEvent('onDropItemOnEditor', {
        detail: {
          type: dragType,
          itemStart: dragItemSpan.startTime,
          itemStop: dragItemSpan.stopTime,
          item,
        },
      }));

      setDragItemSpan({
        startTime: null,
        stopTime: null,
        replaceId: null,
        replaceName: null,
      });
    } else if (movingItem && dragItemSpan.startTime && dragItemSpan.stopTime) {
      // Moving an item
      let newItems;

      if (item.playlist) {
        newItems = updateItem({
          items: useItems,
          data: {
            _uniqueId: moveItemId,
            startTime: dragItemSpan.startTime,
            stopTime: dragItemSpan.stopTime,
          },
          moving: true,
        });
      } else if (item.mix) {
        newItems = updateItem({
          items: useItems,
          data: {
            _uniqueId: moveItemId,
            startTime: dragItemSpan.startTime,
            stopTime: dragItemSpan.stopTime,
          },
          moving: true,
        });
      } else if (item.messageBlock) {
        newItems = updateItem({
          items: useItems,
          data: {
            _uniqueId: moveItemId,
            startTime: dragItemSpan.startTime,
            stopTime: dragItemSpan.stopTime,
          },
          moving: true,
        });
      } else if (item.message) {
        newItems = updateItem({
          items: useItems,
          data: {
            _uniqueId: moveItemId,
            startTime: dragItemSpan.startTime,
            // stopTime: dragItemSpan.stopTime,
          },
          interrupt: true,
          moving: true,
        });
      }

      setItems(newItems);
      onUpdateItems(newItems, type);

      document.dispatchEvent(new CustomEvent('onUpdateScheduleItems', {
        detail: {
          items: newItems,
        },
      }));

      moveItemId = null;
      moveItemType = null;
      currentSlot = null;
      setMoving(false);
      setDragItemSpan({
        startTime: null,
        stopTime: null,
        replaceId: null,
        replaceName: null,
      });
    } else {
      console.warn(`onDrop GridItemColumn: ${dragType} cannot be dropped here`);
    }
  };

  const onMouseMove = (e) => {
    const {
      pageY,
    } = e;

    const {
      top,
    } = dayRef.current.getBoundingClientRect();

    // get the position of the mouse relative to the grid element
    const y = pageY - top;
    // get the current slot being hovered over
    const slot = getSlot({
      activeDisplay,
      slotHeight,
      y,
    });

    // prevent multiple re-renders and only update if the slot they are hovering over changes
    if (slot === currentSlot) {
      return;
    }

    const itemIndex = findIndex(items, { _uniqueId: resizeItemId });

    if (itemIndex >= 0) {
      const item = clone(items[itemIndex]);
      let newItems;

      item.startTime = resizeItemStartOrStopTime === 'startTime'
        ? slot
        : item.startTime;

      item.stopTime = resizeItemStartOrStopTime === 'stopTime'
        ? slot
        : item.stopTime;

      if (item.playlist) {
        newItems = updateItem({
          items,
          data: {
            _uniqueId: resizeItemId,
            ...item,
          },
          minStartTime: get(activeEvent, 'startTime', '00:00'),
          maxStopTime: get(activeEvent, 'stopTime', '24:00'),
        });
      } else if (item.mix) {
        newItems = updateItem({
          items,
          data: {
            _uniqueId: resizeItemId,
            ...item,
          },
          minStartTime: get(activeEvent, 'startTime', '00:00'),
          maxStopTime: get(activeEvent, 'stopTime', '24:00'),
        });
      } else if (item.messageBlock) {
        newItems = updateItem({
          items,
          data: {
            _uniqueId: resizeItemId,
            ...item,
          },
          minStartTime: get(activeEvent, 'startTime', '00:00'),
          maxStopTime: get(activeEvent, 'stopTime', '24:00'),
        });
      }

      setItems(newItems);
      onUpdateItems(newItems, type);

      document.dispatchEvent(new CustomEvent('onUpdateScheduleItems', {
        detail: {
          items: newItems,
        },
      }));
    }

    currentSlot = slot;
  };

  const onResizingItem = (e, data) => {
    if (data.type) {
      // started resizing an item
      resizeItemId = data._uniqueId;
      resizeItemStartOrStopTime = data.type;
      setResizing(true);
    } else {
      // stopped resizing an item
      resizeItemId = null;
      resizeItemStartOrStopTime = null;
      currentSlot = null;
      setResizing(false);
    }
  };

  const onMovingItem = (e, data) => {
    if (data.type) {
      // started moving an item
      moveItemId = data._uniqueId;
      moveItemType = data.type;
      setMoving(true);
    } else {
      // stopped moving an item
      moveItemId = null;
      moveItemType = null;
      currentSlot = null;
      setMoving(false);
    }
  };

  /**
   * Clear the items in the schedule
   */
  const onClearScheduleItems = () => {
    setItems([]);
    onUpdateItems([], type);
  };

  /**
   * Change an items start/stop time
   */
  const onChangeItemDuration = (e) => {
    const {
      startTime,
      stopTime,
      _uniqueId,
    } = e.detail;

    const data = {};

    if (startTime) {
      data.startTime = startTime;
    }

    if (stopTime) {
      data.stopTime = stopTime;
    }

    const newItems = items.map((item) => {
      if (item._uniqueId === _uniqueId) {
        return {
          ...item,
          ...data,
        };
      }

      return item;
    });

    setItems(newItems);
    onUpdateItems(newItems, type);
  };

  /**
   * Remove an item from the column
   */
  const onRemoveItem = (e) => {
    const {
      _uniqueId,
      removeOption,
    } = e.detail;

    // items to keep after being removed
    let newItems = items;

    if (removeOption === 'day') {
      // remove all instances of this item from the day
      const removeItem = find(items, { _uniqueId });

      switch (type) {
        case 'Music':
          if (removeItem) {
            newItems = items.filter(item => get(item, 'playlist._id') !== get(removeItem, 'playlist._id')
              && get(item, 'mix._id') !== get(removeItem, 'mix._id'));
          }
          break;

        case 'Message Blocks':
          if (removeItem) {
            newItems = items.filter(item => get(item, 'messageBlock._id') !== get(removeItem, 'messageBlock._id'));
          }
          break;

        case 'Interrupts':
          if (removeItem) {
            newItems = items.filter(item => get(item, 'message._id') !== get(removeItem, 'message._id'));
          }
          break;

        default:
          console.error('unknown item type', type);
          break;
      }
    } else if (removeOption === 'all') {
      // remove all instances of this item from the schedule
      const removeItem = find(items, { _uniqueId });

      console.warn('TODO: Remove all instances of this item from the schedule', removeItem);
    } else {
      // remove a single instance of an item
      newItems = items.filter(item => item._uniqueId !== _uniqueId);
    }

    setItems(newItems);
    onUpdateItems(newItems, type);
  };

  /**
   * Update/Change the items (from EventSchedule)
   */
  const onChangeItems = (e) => {
    const {
      type: updateType,
      items: updateItems,
    } = e.detail;

    if (
      (type === 'Music' && updateType === 'Music')
      || (type === 'Message Blocks' && updateType === 'Message Blocks')
      || (type === 'Interrupts' && updateType === 'Interrupts')
    ) {
      setItems(updateItems);
      onUpdateItems(updateItems, type);
    }
  };

  /**
   * Repeat an interrupt
   */
  const onRepeatInterrupt = (e) => {
    const {
      _uniqueId,
      rangeStart,
      rangeStop,
      interval,
      unit,
    } = e.detail;

    // only used for repeating an interrupt
    if (type !== 'Interrupts') {
      return;
    }

    console.log('onRepeatInterrupt', items);

    // find the interrupt to copy
    const interrupt = clone(find(items, { _uniqueId }));

    if (!interrupt) {
      console.error('Interrupt not found to copy with _uniqueId', _uniqueId);
      return;
    }

    delete interrupt._uniqueId;
    // const dayInterrupts = items.filter(interruptItem => interruptItem.day === interrupt.day);
    const newInterrupts = [];
    const rangeStartMinutes = TimeUtil.getMinutes(rangeStart);
    const rangeStopMinutes = TimeUtil.getMinutes(rangeStop);
    // Get the repeat innterval in minutes
    const repeatIntervalMinutes = unit === 'minutes'
      ? interval
      : (interval * 60);

    for (let i = rangeStartMinutes; i <= rangeStopMinutes; i += repeatIntervalMinutes) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      const startTime = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

      const item = find(items, { startTime });

      if (!item) {
        // interrupt does not exist at this time, add it
        newInterrupts.push({
          ...interrupt,
          startTime,
          _uniqueId: uniqueId('interrupt-'),
        });
      }
    }

    const mergeInterrupts = sortBy([
      ...items,
      ...newInterrupts,
    ], 'startTime');

    setItems(mergeInterrupts);
    onUpdateItems(mergeInterrupts, type);
  };

  useEffect(() => {
    document.addEventListener('onChangeItemDuration', onChangeItemDuration);
    document.addEventListener('onRemoveItem', onRemoveItem);
    document.addEventListener('onClearScheduleItems', onClearScheduleItems);
    document.addEventListener('onChangeItems', onChangeItems);

    if (type === 'Interrupts') {
      document.addEventListener('onRepeatInterrupt', onRepeatInterrupt);
    }

    return () => {
      document.removeEventListener('onChangeItemDuration', onChangeItemDuration);
      document.removeEventListener('onRemoveItem', onRemoveItem);
      document.removeEventListener('onClearScheduleItems', onClearScheduleItems);
      document.removeEventListener('onChangeItems', onChangeItems);

      if (type === 'Interrupts') {
        document.removeEventListener('onRepeatInterrupt', onRepeatInterrupt);
      }
    };
  }, [items]);

  return (
    <div
      ref={dayRef}
      className="GridItemColumn"
    >
      <div className="column-content">
        {items.map((item, index) => {
          const prevItem = items[index - 1];
          const nextItem = items[index + 1];

          if (type === 'Music') {
            return (
              <GridItem
                key={`item-${item.startTime}`}
                type={item.playlist
                  ? 'Playlist'
                  : 'Mix'}
                _uniqueId={item._uniqueId}
                startTime={item.startTime}
                stopTime={item.stopTime}
                // extra pixel of width to merge adjoining borders
                width={350}
                left={0}
                style={{
                  backgroundColor: color.white,
                }}
                layer={item._uniqueId === moveItemId
                  ? 12
                  : 2}
                tooltip={item.playlist ? (
                  <Playlist
                    playlist={item.playlist}
                    startTime={item.startTime}
                    stopTime={item.stopTime}
                    archived={!get(item, 'playlist.active')}
                    isHover
                  />
                ) : (
                  <Mix
                    mix={item.mix}
                    startTime={item.startTime}
                    stopTime={item.stopTime}
                    archived={!get(item, 'mix.active')}
                    isHover
                  />
                )}
                draggable={!readOnly}
                resizable={!readOnly}
                onMoving={onMovingItem}
                onResizing={onResizingItem}
              >
                <MusicItem
                  type={item.playlist
                    ? 'playlist'
                    : 'station'}
                  playlist={item}
                  station={item}
                  minStartTime={prevItem
                    ? prevItem.stopTime
                    : get(activeEvent, 'startTime', '00:00')}
                  maxStopTime={nextItem
                    ? nextItem.startTime
                    : get(activeEvent, 'stopTime', '24:00')}
                  color={color.white}
                  style={{
                    border: `1px solid ${color.primary}4D`,
                  }}
                  readOnly={readOnly}
                  fullView
                />
              </GridItem>
            );
          }

          if (type === 'Message Blocks') {
            return (
              <GridItem
                key={`block-${item.startTime}`}
                type="Message Block"
                _uniqueId={item._uniqueId}
                startTime={item.startTime}
                stopTime={item.stopTime}
                width={350}
                left={0}
                style={{
                  backgroundColor: color.white,
                }}
                layer={item._uniqueId === moveItemId
                  ? 12
                  : 2}
                tooltip={(
                  <MessageBlock
                    messageBlock={item.messageBlock}
                    startTime={item.startTime}
                    stopTime={item.stopTime}
                    archived={!get(item, 'messageBlock.active')}
                    isHover
                  />
                )}
                draggable={!readOnly}
                resizable={!readOnly}
                onMoving={onMovingItem}
                onResizing={onResizingItem}
              >
                <MessageBlockItem
                  messageBlock={item}
                  minStartTime={prevItem
                    ? prevItem.stopTime
                    : get(activeEvent, 'startTime', '00:00')}
                  maxStopTime={nextItem
                    ? nextItem.startTime
                    : get(activeEvent, 'stopTime', '24:00')}
                  color={color.white}
                  style={{
                    border: `1px solid ${color.primary}4D`,
                  }}
                  readOnly={readOnly}
                  fullView
                />
              </GridItem>
            );
          }

          if (type === 'Interrupts') {
            return (
              <GridItem
                key={`interrupt-${item.startTime}`}
                type="Message"
                _uniqueId={item._uniqueId}
                startTime={item.startTime}
                stopTime={item.startTime}
                width={350}
                height={24}
                left={0}
                style={{
                  backgroundColor: color.white,
                }}
                layer={item._uniqueId === moveItemId
                  ? 12
                  : 2}
                tooltip={(
                  <InterruptItem
                    interrupt={item}
                    color={color.scheduleInterrupt}
                    tooltipView
                  />
                )}
                tooltipProps={{
                  custom: true,
                }}
                draggable={!readOnly}
                onMoving={onMovingItem}
              >
                <InterruptItem
                  interrupt={item}
                  color={color.scheduleInterrupt}
                  isEvent={activeEvent !== null}
                  readOnly={readOnly}
                  fullView
                />
              </GridItem>
            );
          }

          return null;
        })}

        {dragItemSpan.startTime && (
          <GridItem
            type="Other"
            startTime={dragItemSpan.startTime}
            stopTime={dragItemSpan.stopTime}
            width={350}
            height={type === 'Interrupts'
              ? getInterruptItemHeight(dragItemSpan.replaceId)
              : 0}
            left={0}
            style={{
              backgroundColor: color.primary50,
            }}
            layer={6}
          >
            <Tooltip
              title={getDragItemTooltip(type, dragItemSpan)}
              placement="left"
              arrow
              open
            >
              <div
                style={{
                  height: '100%',
                }}
              >
                <DragItem
                  icon={type !== 'Interrupts'
                    ? viAdd
                    : null}
                />
              </div>
            </Tooltip>
          </GridItem>
        )}
      </div>

      {dragging || resizing || moving ? (
        <div
          className="drag-overlay"
          style={{
            cursor:
              resizing
                ? 'ns-resize'
                : 'default',
          }}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onMouseMove={onMouseMove}
        />
      ) : null}
    </div>
  );
}

GridItemColumn.propTypes = {
  type: PropTypes.oneOf([
    'Music',
    'Message Blocks',
    'Interrupts',
  ]).isRequired,
  activeDisplay: PropTypes.string.isRequired,
  slotHeight: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  /** Active Event */
  activeEvent: PropTypes.instanceOf(EventModel),
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
  // onUpdateEvent: PropTypes.func,
  onUpdateItems: PropTypes.func,
};

GridItemColumn.defaultProps = {
  items: [],
  activeEvent: null,
  readOnly: false,
  // onUpdateEvent: () => {},
  onUpdateItems: () => {},
};

function mapStateToProps(state) {
  return {
    dragging: state.global.drag.dragging,
    dragType: state.global.drag.dragType,
  };
}

export default connect(mapStateToProps)(GridItemColumn);
