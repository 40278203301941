import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useNavigate,
} from 'react-router-dom';
import {
  API,
  TableCompanyCategories,
  NavigationHelper,
  GlobalActions,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../Header/SubNavigation';
import CategorySidebar from './Sidebar/CategorySidebar';

function CompanyCategories({
  setPanel,
}) {
  const history = useNavigate();
  const qs = NavigationHelper.getParams() || {};

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      categoryId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, categoryId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 400,
      title: type === 'new'
        ? 'New Category'
        : 'Update Category',
      children: (
        <CategorySidebar
          categoryId={categoryId}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const isNew = qs.type === 'new';
    const isView = qs.categoryId && qs.type !== 'new';

    if (isNew || isView) {
      sidebar(qs.type, qs.categoryId);
    }
  }, [qs.type, qs.categoryId]);

  return (
    <div
      className="CompanyCategories"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <SubNavigation
        title="Categories"
        buttons={[
          {
            text: 'New Category',
            icon: viAdd,
            color: 'primary',
            url: NavigationHelper.updateParams({
              type: 'new',
            }),
            userCan: [
              'company_category.create',
            ],
          },
        ]}
      />

      <TableCompanyCategories
        columns={[
          'Category',
          '...',
        ]}
        fetch={API.Company.Category.list}
        defaultSortBy={{
          label: 'Category',
          attr: 'value',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Categories',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Categories',
          tooltip: true,
        }}
        rowLink={{
          categoryId: '_id',
        }}
        menuItems={[
          { name: 'Archive', userCan: 'company_category.delete' },
        ]}
      />
    </div>
  );
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(null, mapDispatchToProps)(CompanyCategories));
