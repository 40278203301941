import { handleActions } from 'redux-actions';

const initialState = {
  list: [],
  fetched: false,
};

export default handleActions(
  {
    SET_CURRENCIES: (state, action) => {
      const currencies = action.payload;

      return {
        ...state,
        list: currencies,
      };
    },

    SET_CURRENCIES_FETCHED: (state, action) => {
      const fetched = action.payload;

      return {
        ...state,
        fetched,
      };
    },

    RESET_CURRENCY: () => {
      return initialState;
    },
  },
  // Initial State
  initialState,
);
