import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  get,
  find,
  trim,
  isArray,
} from 'lodash';
import {
  setPanel,
} from '../../actions/Global/GlobalActions';
import Company from '../../models/ExpandedLocations/Company';
import API from '../../api';
import EmptyState from '../EmptyState/EmptyState';
import Field from '../Field/Field2';
import LocationsUnassigned from './LocationsUnassigned';
import TargetGroupsUnassigned from './TargetGroupsUnassigned';
import TableLocations from '../VibeTable/Tables/TableLocations';
import TableCompanies from '../VibeTable/Tables/TableCompanies';
import VibeToggle from '../VibeToggle/VibeToggle';
import VibeButton from '../VibeButton/VibeButton';
import VibeModal from '../VibeModal/VibeModal';
import VibeIcon from '../VibeIcon/VibeIcon';
import viChevronDown from '../../icons/viChevronDown';
import viChevronRight from '../../icons/viChevronRight';
import viAdd from '../../icons/viAdd';
import viClose from '../../icons/viClose';
import color from '../../sass/color.scss';
import './LocationsAssigned.scss';

class LocationsAssigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
      companyId,
      locations,
      locationsData,
      allowACAF: propAllowACAF,
      allowCompanies,
      adminCompanyOnly,
    } = props;

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    // ACAF match
    const isACAF = user.isAllLocations({
      companyId,
      locations,
      adminCompanyOnly,
    });

    // save original locations in case ACAF is toggled by accident
    this.origLocations = isACAF
      // do not revert on ACAF toggle if the original locations are ACAF
      ? []
      : locations;

    this.origLocationsData = isACAF
      // do not revert on ACAF toggle if the original locations are ACAF
      ? {
        companies: [],
        locations: [],
      }
      : locationsData;

    const isAdminCompany = companyId === adminCompanyId;

    // Only allow ACAF when the message company is Vibenomics and the user has access to ACAF
    // allowACAF prop must also be true
    const allowACAF = propAllowACAF
      && (
        (companyId === adminCompanyId && find(user.locationsAllowed, { companyId: '*' }) !== undefined)
        || (companyId !== adminCompanyId && (
          find(user.locationsAllowed, { companyId, locationId: '*' }) !== undefined
          || find(user.locationsAllowed, { companyId: '*' }) !== undefined)
        )
      );

    this.state = {
      collapseLocations: false,
      collapseCompanies: false,
      showCompanies: allowCompanies
        && isAdminCompany
        && (user.sysAdmin || user.accountType === 'partner'),
      showAssignByID: false,
      assignByIdType: 'locationId',
      assignByIds: '',
      allowACAF,
      isACAF,
    };
  }

  /**
   * Get Locations Function by User Type
   */
  getLocationsFunc = () => {
    const {
      user,
    } = this.props;

    if (user.accountType === 'partner') {
      return API.PartnerUser.getLocations;
    }

    return API.User.getLocations;
  };

  onChangeAssignAllCurrentAndFuture = () => {
    const {
      user,
      companyId,
      adminCompanyOnly = false,
      onUpdate,
    } = this.props;

    this.setState((state) => {
      const isACAF = !state.isACAF;

      const locations = isACAF
        ? user.getAllCurrentAndFutureSpec(companyId, adminCompanyOnly)
        : this.origLocations;

      onUpdate({
        locations,
        locationsData: {
          companies: isACAF
            ? []
            : this.origLocationsData.companies,
          locations: isACAF
            ? []
            : this.origLocationsData.locations,
        },
      });

      return {
        isACAF,
      };
    });
  };

  /**
   * When a location is added
   */
  onAddLocation = (locationItems) => {
    const {
      locations,
      locationsData,
      onUpdate,
    } = this.props;

    // de-dupe items
    const items = [];

    // prevent duplicates from being added
    locationItems.forEach((location) => {
      const locationExists = find(locations, {
        locationId: location._id,
        companyId: location.companyId,
      });

      if (!locationExists) {
        // add the new location to the list of items to add
        items.push(location);
      }
    });

    const locationItemsSpec = items.map((location) => {
      return {
        companyId: location.companyId,
        locationId: location._id,
      };
    });

    items.forEach((item) => {
      const company = find(locationsData.companies, { _id: item.companyId });

      if (company) {
        company.locations.push(item);
      } else {
        locationsData.companies.push(new Company({
          _id: item.companyId,
          name: item.companyName,
          locations: [item],
        }));
      }
    });

    onUpdate({
      locations: [...locations, ...locationItemsSpec],
      locationsData: {
        ...locationsData,
        locations: [...locationsData.locations, ...items],
      },
    });
  };

  /**
   * When a company is added
   */
  onAddCompany = (companyItems) => {
    const {
      locations,
      locationsData,
      onUpdate,
    } = this.props;

    companyItems.forEach((company) => {
      // add all locations for the company
      company.locationCount = '*';

      // remove possible duplicates
      locationsData.companies = locationsData.companies.filter(c => c._id !== company._id);
    });

    const companyIds = companyItems.map(company => company._id);
    // new locations from the location spec that are not assigned to any of the newly added companies
    const newLocations = locations.filter((spec) => {
      if (companyIds.includes(spec.companyId) && spec.locationId !== '*') {
        // this is a single location assigned to a company that was just added as ACAF
        // remove this location
        return null;
      }

      return spec;
    });

    const locationItemsSpec = companyItems.map((company) => {
      return {
        companyId: company._id,
        locationId: '*',
      };
    });

    onUpdate({
      // merge location spec
      locations: [...newLocations, ...locationItemsSpec],
      locationsData: {
        ...locationsData,
        companies: [...locationsData.companies, ...companyItems],
        // remove individual locations assigned to this company
        locations: locationsData.locations.filter(location => !companyIds.includes(location.companyId)),
      },
    });
  };

  /**
   * When a location is removed
   */
  onRemoveLocation = (locationItems) => {
    const {
      locations,
      locationsData,
      onUpdate,
    } = this.props;

    if (!isArray(locationItems)) {
      locationItems = [locationItems];
    }

    const locationIds = locationItems.map(location => location._id);

    onUpdate({
      locations: locations.filter(location => !locationIds.includes(location.locationId)),
      locationsData: {
        ...locationsData,
        locations: locationsData.locations.filter(location => !locationIds.includes(location._id)),
        companies: locationsData.companies.map((company) => {
          locationItems.forEach((item) => {
            company.locations = company.locations.filter(location => item._id !== location._id);
          });

          return company;
        }),
      },
    });
  };

  /**
   * When a company is removed
   */
  onRemoveCompany = (companyItems) => {
    const {
      locations,
      locationsData,
      onUpdate,
    } = this.props;

    if (!isArray(companyItems)) {
      companyItems = [companyItems];
    }

    const companyIds = companyItems.map(company => company._id);

    onUpdate({
      locations: locations.filter(location => !companyIds.includes(location.companyId)),
      locationsData: {
        ...locationsData,
        companies: locationsData.companies.filter(company => !companyIds.includes(company._id)),
      },
    });
  };

  /**
   * Close the second panel on the sidebar
   */
  onCloseAssigned = () => {
    const {
      setPanel,
      onClose,
    } = this.props;

    onClose();

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * Open the Assign By ID Modal
   */
  onClickAssignByID = () => {
    this.setState({
      showAssignByID: true,
    });
  };

  /**
   * Confirm assigning locations by ID
   */
  onConfirmAssignByID = async () => {
    const {
      user,
    } = this.props;

    const {
      assignByIdType,
      assignByIds,
    } = this.state;

    // convert IDs to an array
    const idArr = assignByIds.indexOf(',') < 0
      // each ID is on a new line
      ? assignByIds.split('\n').map(id => trim(id))
      // each ID is comma separated
      : assignByIds.split(',').map(id => trim(id));

    const filters = {
      active: true,
    };

    if (assignByIdType === 'locationId') {
      filters._id = idArr;
    } else if (assignByIdType === 'salesforceId') {
      filters.salesforceId = idArr;
    }

    const getLocations = this.getLocationsFunc();
    const locations = await getLocations({
      _id: user._id,
      pageSize: -1,
      filters,
    });

    const locationItems = get(locations, 'data', []);
    this.onAddLocation(locationItems);

    this.onCancelAssignByID();
  };

  /**
   * Cancel assigning locations by ID
   */
  onCancelAssignByID = () => {
    this.setState({
      showAssignByID: false,
      assignByIdType: 'locationId',
      assignByIds: '',
    });
  };

  /**
   * Change the assign by ID type
   */
  onChangeAssignByIDType = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      assignByIdType: value,
    });
  };

  /**
   * Change the assign by ID's
   */
  onChangeAssignByIDs = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      assignByIds: value,
    });
  };

  /**
   * Export locations to CSV file
   */
  onExport = async (data) => {
    const {
      user,
    } = this.props;

    // save the location IDs to an array to fetch
    const locationIds = data.map(location => location._id);

    // fetch the list of locations filtered to only the assigned location IDs
    const locations = await API.User.getLocations({
      _id: user._id,
      pageSize: -1,
      filters: {
        _id: locationIds,
      },
    }, {
      headers: {
        Accept: 'text/csv',
      },
    });

    const blob = new Blob([locations], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `locations-${timestamp}.csv`);
  };

  toggleCollapseLocations = () => {
    this.setState((state) => {
      return {
        collapseLocations: !state.collapseLocations,
      };
    }, () => {
      document.dispatchEvent(new Event('onToggleTableCollapse'));
    });
  };

  toggleCollapseCompanies = () => {
    this.setState((state) => {
      return {
        collapseCompanies: !state.collapseCompanies,
      };
    }, () => {
      document.dispatchEvent(new Event('onToggleTableCollapse'));
    });
  };

  /**
   * Open Target Group Selector
   */
  openTargetGroups = () => {
    const {
      locations,
      locationsData,
      filtersUnassigned,
      onUpdate,
      setPanel,
    } = this.props;

    setPanel({
      thirdPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <TargetGroupsUnassigned
            locations={locations}
            locationsData={locationsData}
            filtersUnassigned={filtersUnassigned}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * Open Company Selector
   */
  openCompanies = () => {
    this.renderUnassigned('companies');
  };

  /**
   * Open Location Selector
   */
  openLocations = () => {
    this.renderUnassigned('locations');
  };

  renderUnassigned = (type) => {
    const {
      companyId,
      partnerId,
      locations,
      scopeLimit,
      fetchUnassignedLocations,
      fetchUnassignedCompanies,
      filtersUnassigned,
      onUpdate,
      setPanel,
    } = this.props;

    setPanel({
      thirdPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <LocationsUnassigned
            type={type}
            companyId={companyId}
            partnerId={partnerId}
            locations={locations}
            scopeLimit={scopeLimit}
            fetchUnassignedLocations={fetchUnassignedLocations}
            fetchUnassignedCompanies={fetchUnassignedCompanies}
            filtersUnassigned={filtersUnassigned}
            onAddLocation={this.onAddLocation}
            onAddCompany={this.onAddCompany}
            onRemoveLocation={this.onRemoveLocation}
            onRemoveCompany={this.onRemoveCompany}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  render() {
    const {
      user,
      locationsData,
      filtersUnassigned,
      disabled,
      customToolbar,
      allowTargetGroups,
    } = this.props;

    const {
      collapseLocations,
      collapseCompanies,
      showCompanies,
      showAssignByID,
      assignByIdType,
      assignByIds,
      allowACAF,
      isACAF,
    } = this.state;

    const allowAssignById = ['standard', 'partner'].includes(user.accountType);

    const locationItems = locationsData.locations;

    const companyItems = locationsData.companies.filter(company => company.locationCount === '*');

    const assignLocationsButton = (
      <div key="assign-locations">
        {allowAssignById && (
          <VibeButton
            style={{
              marginRight: 8,
            }}
            text="Assign by ID's"
            variant="outlined"
            color="secondary"
            icon={viAdd}
            tooltip="Assign by a list of location or salesforce ID's"
            tooltipProps={{
              placement: 'top',
            }}
            onClick={this.onClickAssignByID}
          />
        )}

        {allowTargetGroups && user.can('target_group.view') && (
          <VibeButton
            style={{
              marginRight: 8,
            }}
            text="Assign by Target Group"
            variant="outlined"
            color="secondary"
            icon={viAdd}
            onClick={this.openTargetGroups}
          />
        )}

        <VibeButton
          text="Assign Locations"
          color="primary"
          icon={viAdd}
          onClick={this.openLocations}
        />
      </div>
    );

    const assignCompaniesButton = (
      <VibeButton
        key="assign-companies"
        text="Assign ACAF Companies"
        color="primary"
        icon={viAdd}
        onClick={this.openCompanies}
      />
    );

    let mediaTypeColumns = [
      'Name',
      'Company',
      'Media Format',
      'Screen Orientation',
      'Screen Size',
      'Resolution',
      'Address 1',
      'Address 2',
      'Country',
      'City',
      'State/Province',
      'Postal Code',
      'DMA',
      'Ad Network',
      'Ad Programs',
      'Tags',
      '...',
    ];

    if (filtersUnassigned.mediaFormat) {
      mediaTypeColumns = filtersUnassigned.mediaFormat === 'visual'
        ? [
          'Name',
          'Company',
          'Media Format',
          'Screen Orientation',
          'Screen Size',
          'Resolution',
          'Address 1',
          'Address 2',
          'Country',
          'City',
          'State/Province',
          'Postal Code',
          'DMA',
          'Ad Network',
          'Ad Programs',
          'Tags',
          '...',
        ] : [
          'Name',
          'Company',
          'Media Format',
          'Address 1',
          'Address 2',
          'Country',
          'City',
          'State/Province',
          'Postal Code',
          'DMA',
          'Ad Network',
          'Ad Programs',
          'Tags',
          '...',
        ];
    }

    const companyColumns = [
      'Company',
      'Category',
      'Address 1',
      'Address 2',
      'Country',
      'City',
      'State/Province',
      'Postal Code',
      'Salesforce ID',
      'Tags',
      '...',
    ];

    if (!disabled) {
      mediaTypeColumns.unshift('Add/Remove');
      companyColumns.unshift('Add/Remove');
    }

    return (
      <div className="LocationsAssigned">
        <div className="title-container">
          <div className="title">
            Assigned Locations

            {allowACAF ? (
              <div className="assign-all-container">
                <VibeToggle
                  label="Assign all current and future locations"
                  labelStyle={{
                    color: color.success,
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                  height={16}
                  checked={isACAF}
                  disabled={disabled}
                  color={color.success}
                  onChange={this.onChangeAssignAllCurrentAndFuture}
                />
              </div>
            ) : null}
          </div>

          {customToolbar}

          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={this.onCloseAssigned}
          />
        </div>

        {isACAF ? (
          <div className="locations-root">
            <EmptyState
              title="All Current and Future Locations Assigned"
              description="This object has been assigned to all current and future locations at this company."
            />
          </div>
        ) : (
          <div className="locations-root">
            <div className={classNames('locations-container', {
              'show-companies': showCompanies,
              'collapsed-companies': showCompanies && collapseCompanies,
              collapsed: collapseLocations,
            })}
            >
              <div className="locations-content">
                <TableLocations
                  columns={mediaTypeColumns}
                  mediaFormat={filtersUnassigned.mediaFormat}
                  collection={locationItems}
                  defaultSortBy={{
                    label: 'Name',
                    attr: 'name',
                    direction: 'asc',
                  }}
                  paginator
                  paginatorProps={{
                    label: 'Locations',
                    urlPaging: false,
                    urlFilters: false,
                  }}
                  emptyProps={{
                    title: 'No Locations Assigned',
                    description: 'This object has not been assigned to any locations.',
                  }}
                  toolbarProps={{
                    customHtmlLeft: showCompanies
                      ? (
                        <VibeIcon
                          className="collapse-icon"
                          icon={collapseLocations
                            ? viChevronRight
                            : viChevronDown}
                          color={color.obsidian}
                          hoverColor={color.manatee}
                          tooltip={collapseLocations
                            ? 'Expand Locations'
                            : 'Collapse Locations'}
                          size={24}
                          onClick={this.toggleCollapseLocations}
                        />
                      )
                      : null,
                    customButtons: !disabled
                      ? [assignLocationsButton]
                      : [],
                    collapseTable: collapseLocations,
                  }}
                  assigned
                  highlight={locationItems}
                  csv
                  csvProps={{
                    onExport: this.onExport,
                  }}
                  onRemove={this.onRemoveLocation}
                />
              </div>
            </div>

            {showCompanies ? (
              <div className={classNames('companies-container', {
                'show-locations': !collapseLocations,
                'collapsed-locations': collapseLocations,
                collapsed: collapseCompanies,
              })}
              >
                <div className="locations-content">
                  <TableCompanies
                    columns={companyColumns}
                    collection={companyItems}
                    defaultSortBy={{
                      label: 'Company',
                      attr: 'name',
                      direction: 'asc',
                    }}
                    paginator
                    paginatorProps={{
                      label: 'Companies',
                      urlPaging: false,
                      urlFilters: false,
                    }}
                    emptyProps={{
                      title: 'No ACAF Companies Assigned',
                      description: 'This object has not been assigned to any ACAF Companies.',
                    }}
                    toolbarProps={{
                      customHtmlLeft: (
                        <VibeIcon
                          className="collapse-icon"
                          icon={collapseCompanies
                            ? viChevronRight
                            : viChevronDown}
                          color={color.obsidian}
                          hoverColor={color.manatee}
                          tooltip={collapseCompanies
                            ? 'Expand Companies'
                            : 'Collapse Companies'}
                          size={24}
                          onClick={this.toggleCollapseCompanies}
                        />
                      ),
                      customButtons: !disabled
                        ? [assignCompaniesButton]
                        : [],
                      collapseTable: collapseCompanies,
                    }}
                    assigned
                    highlight={companyItems}
                    onRemove={this.onRemoveCompany}
                  />
                </div>
              </div>
            ) : null}

            <VibeModal
              className="LocationsAssignedModal"
              type="confirm"
              show={showAssignByID}
              confirmProps={{
                text: 'Assign Locations',
                disabled: !assignByIds,
                color: 'success',
              }}
              cancelProps={{
                text: 'Cancel',
              }}
              title="Assign by Location or Salesforce IDs"
              text={(
                <div>
                  <Field
                    type="select"
                    label="Assign By"
                    value={assignByIdType}
                    options={[
                      {
                        label: 'Location ID',
                        value: 'locationId',
                      },
                      {
                        label: 'Salesforce ID',
                        value: 'salesforceId',
                      },
                    ]}
                    marginTop={8}
                    onChange={this.onChangeAssignByIDType}
                  />

                  <Field
                    className="textarea-assign-ids"
                    type="textarea"
                    label={assignByIdType === 'locationId'
                      ? 'Location ID\'s'
                      : 'Salesforce ID\'s'}
                    placeholder="Paste a list or CSV of ID's"
                    value={assignByIds}
                    marginTop={16}
                    autoFocus
                    onChange={this.onChangeAssignByIDs}
                  />
                </div>
              )}
              onConfirm={this.onConfirmAssignByID}
              onClose={this.onCancelAssignByID}
            />
          </div>
        )}
      </div>
    );
  }
}

LocationsAssigned.propTypes = {
  /** Company ID */
  companyId: PropTypes.string.isRequired,
  partnerId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  locationsData: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.object),
    locations: PropTypes.arrayOf(PropTypes.object),
  }),
  /** Locations to use as a limit when querying the API for available locations */
  scopeLimit: PropTypes.arrayOf(PropTypes.object),
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Custom API endpoint to fetch locations */
  fetchUnassignedLocations: PropTypes.func,
  /** Custom API endpoint to fetch companies */
  fetchUnassignedCompanies: PropTypes.func,
  /** Custom filters for the API endpoint on unasssigned locations */
  filtersUnassigned: PropTypes.object,
  disabled: PropTypes.bool,
  /** Allow all current and future toggle */
  allowACAF: PropTypes.bool,
  /** Allow companies to be selected */
  allowCompanies: PropTypes.bool,
  /** Allow target groups to be selected */
  allowTargetGroups: PropTypes.bool,
  /**
   * Only allow ACAF for the admin company if the admin company is the selected company
   * i.e. changes { companyId: '*' } to { companyId: 'ADMIN_COMPANY_ID', locationId: '*' }
  */
  adminCompanyOnly: PropTypes.bool,
  /** When the locations are updated */
  onUpdate: PropTypes.func,
  /** When the assigned locations panel is closed */
  onClose: PropTypes.func,
};

LocationsAssigned.defaultProps = {
  partnerId: '',
  locations: [],
  locationsData: {
    companies: [],
    locations: [],
  },
  scopeLimit: [],
  customToolbar: null,
  fetchUnassignedLocations: null,
  fetchUnassignedCompanies: null,
  filtersUnassigned: {},
  disabled: false,
  allowACAF: false,
  allowCompanies: false,
  allowTargetGroups: false,
  adminCompanyOnly: false,
  onUpdate: () => {},
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsAssigned);
