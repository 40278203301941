import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/flights`;

export default {
  list: (data, options) => {
    options = addVersion('7.17.0', options);
    return Http.post(`${baseUrl}/search`, data, options);
  },
  getById: (id, options) => Http.get(`${baseUrl}/${id}`, options),
  summary: (id, options) => Http.get(`${baseUrl}/${id}/summary`, options),
  history: (data, options) => Http.post(`${baseUrl}/${data._id}/history`, data, options),
  copy: (id, options) => Http.post(`${baseUrl}/${id}/copy`, {}, options),
  confirm: (id, options) => Http.post(`${baseUrl}/${id}/confirmation`, {}, options),
  unconfirm: (id, options) => Http.delete(`${baseUrl}/${id}/confirmation`, options),
  create: (data, options) => {
    options = addVersion('8.8.0', options);
    return Http.post(baseUrl, data, options);
  },
  update: (data, options) => Http.put(`${baseUrl}/${data._id}`, data, options),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: (id) => Http.delete(`${baseUrl}/${id}`),

  // Delivery System
  DeliverySystem: {
    list: (data, options) => Http.post(`${baseUrl}/deliverysystems/search`, data, options),
    create: (data) => Http.post(`${baseUrl}/deliverysystems`, data),
    update: (data) => Http.put(`${baseUrl}/deliverysystems/${data._id}`, data),
    reactivate: (data, options) => Http.post(`${baseUrl}/deliverysystems/${data._id}`, data, options),
    deactivate: (id) => Http.delete(`${baseUrl}/deliverysystems/${id}`),
  },

  // Segments
  Segment: {
    list: (data, options) => Http.post(`${baseUrl}/${data.flightId}/segments/search`, data, options),
    get: (data, options) => {
      options = addVersion('8.5.0', options);
      return Http.get(`${baseUrl}/${data.flightId}/segments/${data.segmentId}`, options);
    },
    getExpandedLocations: async (data, options) => {
      const response = await Http.get(
        `${baseUrl}/${data.flightId}/segments/${data.segmentId}/locations/expanded`,
        options,
      ) ?? [];

      return response.map(item => {
        const locations = item.locations ?? [];

        return {
          ...item,
          imageUrl: Http.cloudfront(item.imageUrl),
          locations: locations.map(location => {
            return {
              ...location,
              imageUrl: Http.cloudfront(location.imageUrl),
            };
          }),
        };
      });
    },
    history: (data, options) => {
      return Http.post(`${baseUrl}/${data.flightId}/segments/${data.segment._id}/history`, data, options);
    },
    create: (data, options) => {
      options = addVersion('8.8.0', options);
      return Http.post(`${baseUrl}/${data.flightId}/segments`, data, options);
    },
    createScenario: (data, options) => {
      options = addVersion('8.8.0', options);
      return Http.post(`${baseUrl}cenarios`, data, options);
    },
    update: (data, options) => {
      options = addVersion('8.5.0', options);
      return Http.put(`${baseUrl}/${data.flightId}/segments/${data._id}`, data, options);
    },
    reactivate: (data, options) => {
      options = addVersion('5.36.0', options);
      return Http.post(`${baseUrl}/${data.flightId}/segments/${data._id}`, data, options);
    },
    deactivate: (data) => Http.delete(`${baseUrl}/${data.flightId}/segments/${data._id}`),
  },
};
