import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeIcon from '../VibeIcon/VibeIcon';
import viCheck from '../../icons/viCheck';
import viSubtract from '../../icons/viSubtract';
import viInformation from '../../icons/viInformation';
import color from '../../sass/color.scss';
import './VibeCheckbox.scss';

class VibeCheckbox extends PureComponent {
  onChange = () => {
    const {
      name,
      checked,
      disabled,
      onChange,
    } = this.props;

    if (disabled) {
      return;
    }

    onChange({
      name,
      checked: !checked,
    });
  };

  render() {
    const {
      className,
      label,
      tooltip,
      size,
      color: checkboxColor,
      outline,
      rootStyle,
      style,
      indeterminate,
      disabled,
      checked,
      error,
    } = this.props;

    // Only use the custom color when checked
    let borderColor = color.manatee;

    if (checked) {
      borderColor = checkboxColor;
    } else if (error) {
      borderColor = color.lightGray;
    } else {
      borderColor = color.manatee;
    }

    const backgroundColor = checked
      ? checkboxColor
      : 'transparent';

    return (
      <div
        className={classNames('VibeCheckbox', className, { disabled })}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          ...rootStyle,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            cursor: disabled
              ? 'default'
              : 'pointer',
          }}
          onClick={this.onChange}
        >
          <div
            className="check-item"
            style={{
              width: size,
              height: size,
              border: `1px solid ${borderColor}`,
              background:
                outline
                  ? 'transparent'
                  : backgroundColor,
              ...style,
            }}
          >
            {checked && (
              <VibeIcon
                icon={indeterminate
                  ? viSubtract
                  : viCheck}
                color={outline
                  ? checkboxColor
                  : color.white}
                size={size - 5}
              />
            )}
          </div>

          {label && (
            <div
              style={{
                fontSize: 12,
              }}
            >
              {label}
            </div>
          )}
        </div>

        {tooltip && (
          <VibeIcon
            icon={viInformation}
            color={color.manatee}
            size={16}
            tooltip={tooltip}
            tooltipProps={{
              placement: 'right',
            }}
          />
        )}
      </div>
    );
  }
}

VibeCheckbox.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  /** Name of the input */
  name: PropTypes.string,
  /** Custom root style */
  rootStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Text to use for the checkbox */
  label: PropTypes.string,
  /** Tooltip text */
  tooltip: PropTypes.string,
  /** Size of checkbox */
  size: PropTypes.number,
  /** Color of checkbox */
  color: PropTypes.string,
  /** Use an outline checkbox instead of solid */
  outline: PropTypes.bool,
  /** Show indeterminate icon instead of check */
  indeterminate: PropTypes.bool,
  /** Is it checked */
  checked: PropTypes.bool,
  /** Is it disabled */
  disabled: PropTypes.bool,
  /** Is it in a row with an error */
  error: PropTypes.bool,
  /** When the checkbox changes */
  onChange: PropTypes.func,
};

VibeCheckbox.defaultProps = {
  className: '',
  name: '',
  rootStyle: {},
  style: {},
  label: null,
  tooltip: null,
  size: 16,
  color: '#00a474',
  outline: false,
  indeterminate: false,
  checked: false,
  disabled: false,
  error: false,
  onChange: () => {},
};

export default VibeCheckbox;
