import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  TableMessages,
  GlobalActions,
  color,
  VibeIcon,
  viClose,
} from 'vibeguide';

function CreativeMedia({
  dayPart,
  companies,
  locations,
  advertiserId,
  onSelectMedia,
  dateRangeIndex,
  onClose,
  setPanel,
}) {
  /**
   * Close the panel
   */
  const onCloseBanner = () => {
    onClose();

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  const onBulkToggle = (bulkOption, data) => {
    switch (bulkOption) {
      case 'add':
        onSelectMedia(dayPart, dateRangeIndex, data);
        onCloseBanner();
        break;
      default:
        break;
    }
  };

  const localLocations = locations || {};

  if (!locations || (locations && locations.length === 0)) {
    companies.forEach(company => {
      localLocations[company._id] = company.locations.map(location => location._id);
    });
  }

  const filterSearch = {
    messageType: 'ad|ext-ad',
    locations: {
      matchType: 'contains',
      locations: localLocations,
    },
  };

  if (advertiserId) {
    filterSearch.advertiserId = advertiserId;
  }

  return (
    <SidePanelContainer className="AssignMedia">
      <SidePanelHeader
        style={{
          background: color.success,
          color: color.white,
        }}
        icons={(
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.white}
              hoverColor={color.lightGray}
              size={24}
              onClick={onCloseBanner}
            />
          </div>
        )}
      >
        <div className="title">
          Assign Media
        </div>
      </SidePanelHeader>

      <SidePanelContent
        style={{
          display: 'flex',
          padding: 0,
        }}
      >
        <TableMessages
          columns={[
            '.',
            'Message Name',
            'Company',
            'Last Updated',
            'Start Date',
            'End Date',
            'Requester',
            'Duration',
            'Tags',
            '...',
          ]}
          fetch={API.Message.list}
          filters={filterSearch}
          defaultSortBy={{
            label: 'Last Updated',
            attr: 'modifiedDate',
            direction: 'desc',
          }}
          paginator
          paginatorProps={{
            label: 'Ads',
            urlPaging: false,
            urlFilters: false,
          }}
          bulk
          bulkProps={{
            edit: false,
            add: true,
            block: false,
            remove: false,
            archive: false,
            onToggle: onBulkToggle,
          }}
          menuItems={(row) => {
            const isAd = row.messageType === 'ad'
              || row.messageType === 'ext-ad';

            return [{
              name: 'Archive',
              userCan: isAd
                ? 'advertisement.delete'
                : 'message.delete',
            }];
          }}
        />
      </SidePanelContent>
    </SidePanelContainer>
  );
}

CreativeMedia.propTypes = {
  onSelectMedia: PropTypes.func,
  onClose: PropTypes.func,
};

CreativeMedia.defaultProps = {
  onSelectMedia: () => {},
  onClose: () => {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(CreativeMedia);
