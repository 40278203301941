import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEqual,
  capitalize,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeModal from '../../VibeModal/VibeModal';
import TimestampCell from '../CellTypes/TimestampCell';
import CellTooltip from '../CellTypes/CellTooltip';
import LabelCell from '../CellTypes/LabelCell';
import color from '../../../sass/color.scss';

const tableId = 'table:devices';
const permissionPrefix = 'location';

class TableDevices extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: 'Device Type',
      searchAttr: 'deviceType',
      defaultWidth: 150,
      resizable: true,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Android',
          value: 'android',
        },
        {
          label: 'MAndroid',
          value: 'mandroid',
        },
        {
          label: 'Optra',
          value: 'optra',
        },
      ],
    },
    {
      name: 'Connected',
      searchAttr: 'online',
      defaultWidth: 156,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Online',
          value: 'true',
        },
        {
          label: 'Offline',
          value: 'false',
        },
      ],
    },
    {
      name: 'Status',
      searchAttr: 'activationStatus',
      // unused, only set to not show the Status column as the selected one for sorting
      sortAttr: 'activationStatusSorted',
      defaultWidth: 156,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Live',
          value: 'live',
        },
        {
          label: 'Standby',
          value: 'standby',
        },
        {
          label: 'Setup',
          value: 'setup',
        },
        {
          label: 'Decommissioned',
          value: 'decommissioned',
        },
      ],
    },
    {
      name: 'Player ID',
      searchAttr: 'identifiers.playerId',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
    },
    {
      name: 'Serial Number',
      searchAttr: 'identifiers.serialNumber',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
    },
    {
      name: 'Mac Address',
      searchAttr: 'identifiers.macAddress',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
    },
    {
      name: 'Phone Number',
      searchAttr: 'identifiers.phoneNumber',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
    },
    {
      name: 'Activation Date',
      searchAttr: 'activationDate',
      defaultWidth: 225,
      resizable: true,
      searchable: false,
      sortable: false,
      datepicker: false,
    },
    {
      name: '...',
      defaultWidth: 72,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      confirm: false,
      confirmRow: {},
      // confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveDevice', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveDevice', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      onSelectMenuItem,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    // switch (itemName) {
    //   case 'archive': {
    //     this.setState({
    //       confirm: true,
    //       // confirmAction: itemName,
    //       confirmRow: row,
    //       confirmText: `Are you sure you want to archive ${row.name}?`,
    //       confirmApproveText: 'Yes, Archive',
    //       confirmApproveColor: 'error',
    //     });

    //     break;
    //   }

    //   case 'unarchive': {
    //     this.setState({
    //       confirm: true,
    //       // confirmAction: itemName,
    //       confirmRow: row,
    //       confirmText: `Are you sure you want to unarchive ${row.name}?`,
    //       confirmApproveText: 'Yes, Unarchive',
    //       confirmApproveColor: 'success',
    //     });

    //     break;
    //   }

    //   default:
    //     break;
    // }

    onSelectMenuItem({
      name: itemName,
      row,
    });
  };

  onConfirmModal = async () => {
    // const {
    //   collection,
    //   onRemove,
    // } = this.props;

    const {
      // confirmAction,
      confirmRow: {
        _id,
      },
    } = this.state;

    if (!_id) {
      console.error('onConfirmModal no row ID');
      return;
    }

    // switch (confirmAction) {
    //   case 'archive': {
    //     const response = await API.DeviceManagement.deactivate(_id);

    //     const successText = 'DEVICE.DEACTIVATED';
    //     const success = get(response, '[0].type') === successText;

    //     if (success) {
    //       // if using a local collection, remove from the table
    //       if (collection.length > 0) {
    //         const items = collection.filter(item => item._id === _id);

    //         items.forEach((item) => {
    //           // remove the item from the table
    //           onRemove(item);
    //         });
    //       }

    //       // tell listening components to update the counts and table data
    //       this.onUpdate({
    //         refresh: true,
    //       });
    //     }

    //     break;
    //   }

    //   case 'unarchive': {
    //     const response = await API.DeviceManagement.reactivate({
    //       _id,
    //     });

    //     const successText = 'DEVICE.REACTIVATED';
    //     const success = get(response, '[0].type') === successText;

    //     if (success) {
    //       // tell listening components to update the counts and table data
    //       this.onUpdate({
    //         refresh: true,
    //       });
    //     }

    //     break;
    //   }

    //   default:
    //     break;
    // }

    this.resetConfirmModal();
  };

  /**
   * When the Bulk Archive Completes
   */
  onBulkArchive = () => {
    // tell listening components to update the counts and table data
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Get label style for the message type cell
   */
  getLabelStyle = (status) => {
    switch (status) {
      case 'live':
        return {
          text: 'Live',
          textStyle: {
            background: color.success16,
            color: color.success,
          },
        };

      case 'standby':
        return {
          text: 'Standby',
          textStyle: {
            background: color.orange16,
            color: color.orange,
          },
        };

      case 'setup':
        return {
          text: 'Setup',
          textStyle: {
            background: color.orange16,
            color: color.orange,
          },
        };

      case 'decommissioned':
        return {
          text: 'Decommissioned',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      default:
        return {
          text: capitalize(status),
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };
    }
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      // confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    });
  };

  renderCell = ({
    column,
    row,
  }) => {
    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Connected':
        return (
          <LabelCell
            value={row.online
              ? 'Online'
              : 'Offline'}
            background={row.online
              ? color.success16
              : color.error16}
            color={row.online
              ? color.success
              : color.error}
          />
        );

      case 'Status': {
        const {
          text,
          textStyle,
        } = this.getLabelStyle(row.activationStatus);

        return (
          <LabelCell
            value={text}
            background={textStyle.background}
            color={textStyle.color}
          />
        );
      }

      case 'Device Type':
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {capitalize(value)}
            </div>
          </CellTooltip>
        );

      case 'Activation Date':
        return (
          <TimestampCell
            time={value}
          />
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      confirm,
      confirmText,
      confirmApproveText,
      confirmApproveColor,
    } = this.state;

    return (
      <div className={classNames('Table', 'TableDevices', className)}>
        <VibeTable
          {...this.props}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          bulkArchive={API.DeviceManagement.deactivateBulk}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          text={confirmText}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TableDevices.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableDevices.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableDevices);
