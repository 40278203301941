import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import VibeButton from '../../VibeButton/VibeButton';
import './ApplyCancel.scss';

class ApplyCancel extends PureComponent {
  render() {
    const {
      onCancel,
      onApply,
    } = this.props;

    return (
      <div className="ApplyCancel">
        <VibeButton
          text="Cancel"
          variant="outlined"
          color="secondary"
          style={{
            width: 120,
          }}
          onClick={onCancel}
        />

        <VibeButton
          text="Apply Edits"
          color="primary"
          style={{
            width: 120,
          }}
          onClick={onApply}
        />
      </div>
    );
  }
}

ApplyCancel.propTypes = {
  /** When cancel is clicked */
  onCancel: PropTypes.func,
  /** When apply is clicked */
  onApply: PropTypes.func,
};

ApplyCancel.defaultProps = {
  onCancel: () => {},
  onApply: () => {},
};

export default ApplyCancel;
