import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  LocationsAssigned,
  VibeButton,
  viCopy,
} from 'vibeguide';
import AdProgramCopyPreview from './AdProgramCopyPreview';

const {
  ADMIN_COMPANY_ID: adminCompanyId,
} = process.env;

function AdProgramCopy({
  className,
  currTime,
  locationId,
  adProgramId,
  onClose,
}) {
  const [data, setData] = useState({
    locations: [],
    locationsData: {
      companies: [],
      locations: [],
    },
  });
  const [showPreview, setShowPreview] = useState(false);

  const onUpdateLocations = ({
    locations,
    locationsData,
  }) => {
    setData({
      locations,
      locationsData,
    });
  };

  const onClickCopy = () => {
    setShowPreview(true);
  };

  const onCloseCopy = () => {
    setShowPreview(false);
  };

  return (
    <div
      className={classNames('AdProgramCopy', className)}
      style={{
        height: '100%',
      }}
    >
      <LocationsAssigned
        companyId={adminCompanyId}
        locations={data.locations}
        locationsData={data.locationsData}
        filtersUnassigned={{
          adProgramEnabled: true,
        }}
        customToolbar={(
          <VibeButton
            text="Copy Ad Program"
            icon={viCopy}
            color="primary"
            disabled={data.locations.length <= 0}
            style={{
              marginRight: 8,
            }}
            onClick={onClickCopy}
          />
        )}
        allowCompanies
        onUpdate={onUpdateLocations}
        onClose={onClose}
      />

      <AdProgramCopyPreview
        show={showPreview}
        currTime={currTime}
        locationId={locationId}
        adProgramId={adProgramId}
        locations={data.locations}
        onClose={onCloseCopy}
        onSuccess={onClose}
      />
    </div>
  );
}

AdProgramCopy.propTypes = {
  className: PropTypes.string,
  currTime: PropTypes.instanceOf(moment).isRequired,
  locationId: PropTypes.string.isRequired,
  adProgramId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

AdProgramCopy.defaultProps = {
  className: '',
  onClose: () => {},
};

export default AdProgramCopy;
