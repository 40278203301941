import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
  clone,
  forEach,
  isEmpty,
  isUndefined,
  uniqueId,
  sortBy,
  inRange,
  toInteger,
} from 'lodash';
import {
  API,
  NavigationHelper,
  TimeUtil,
  EmptyState,
  VibeModal,
  color,
} from 'vibeguide';
import WeekDates from '../../Schedule/Controls/WeekDates';
import ScheduleData from '../../Schedule/ScheduleData';
import {
  calculateHourlySpotsForSpotPool,
} from '../hourlySpotCalculator';
import './AdProgramSchedule.scss';

const startOfWeek = moment().startOf('week');

function AdProgramSchedule({
  className,
  user,
  locationId,
  adProgramId,
  adProgram,
  mediaFormat,
  currTime,
  timezone,
  onChange,
}) {
  const [currAdProgram, setCurrAdProgram] = useState(false);
  const [collisions, setCollisions] = useState([]);
  const [copyToDays, setCopyToDays] = useState([]);
  const [copySpotPool, setCopySpotPool] = useState({});
  const [confirmReplaceSpotPools, setConfirmReplaceSpotPools] = useState(false);

  const qs = NavigationHelper.getParams() || {};

  /**
   * Get a new spot pool object based on the mediaFormat
   */
  const getNewSpotPool = ({
    startTime,
    stopTime,
  }) => {
    const newSpotPool = {
      _uniqueId: uniqueId('spot-pool-'),
      distribution: {
        'adult-beverage': 0,
        endemic: 0,
        general: 0,
        'non-endemic': 0,
        'self-promotion': 0,
      },
      startTime,
      endTime: stopTime,
      maxHourlySpots: 0,
    };

    switch (mediaFormat) {
      case 'visual':
        return newSpotPool;
      case 'audio':
      default:
        return {
          ...newSpotPool,
          frequency: 1,
          groupSize: 1,
          integrationType: 'rotate',
        };
    }
  };

  /**
   * Check for collisions on the spot pool being copied to another day
   */
  const onCheckCollisions = (spotPool, day) => {
    const dayCollisions = [];
    const spotPoolStart = TimeUtil.getMinutes(spotPool.startTime);
    const spotPoolStop = TimeUtil.getMinutes(spotPool.endTime);

    const copyToDaySpotPools = get(adProgram, `spotPools[${day}]`, []);

    copyToDaySpotPools.forEach((copyToDaySpotPool) => {
      const copyToDaySpotPoolStart = TimeUtil.getMinutes(copyToDaySpotPool.startTime);
      const copyToDaySpotPoolStop = TimeUtil.getMinutes(copyToDaySpotPool.endTime);

      // does the new day part interfere with the exsiting one?
      // A start is between B start & stop
      const collision = inRange(spotPoolStart, copyToDaySpotPoolStart, copyToDaySpotPoolStop)
        // A stop is between B start (+1 minute) & stop
        || inRange(spotPoolStop, copyToDaySpotPoolStart + 1, copyToDaySpotPoolStop)
        // B start is between A start & stop
        || inRange(copyToDaySpotPoolStart, spotPoolStart, spotPoolStop)
        // B stop is between A start (+1 minute) & stop
        || inRange(copyToDaySpotPoolStop, spotPoolStart + 1, spotPoolStop);

      if (collision) {
        dayCollisions.push({
          day,
          spotPool: copyToDaySpotPool,
        });
      }
    });

    return dayCollisions;
  };

  /**
   * When a spot pool is added
   */
  const onAddSpotPool = ({
    day,
    startTime,
    stopTime,
  }) => {
    const dayDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'dd');
    const newSpotPool = calculateHourlySpotsForSpotPool(getNewSpotPool({
      startTime,
      stopTime,
    }), mediaFormat);

    onChange({
      ...adProgram,
      spotPools: {
        ...adProgram.spotPools,
        [dayDD]: [
          ...adProgram.spotPools[dayDD],
          newSpotPool,
        ],
      },
      changed: true,
    });
  };

  /**
   * Copy the spot pool to another day of the week
   */
  const onCopySpotPool = ({
    days,
    spotPool,
    spotPoolCollisions = [],
  }) => {
    const newSpotPools = clone(adProgram.spotPools);

    // remove the collision spot pools first
    spotPoolCollisions.forEach((collision) => {
      newSpotPools[collision.day] = newSpotPools[collision.day]
        .filter(sp => sp._uniqueId !== collision.spotPool._uniqueId);
    });

    days.forEach((day) => {
      // clone the spot pool to allow a new unique ID
      const copySpotPool = clone(spotPool);
      // generate a new unique ID for the copied spot pool
      copySpotPool._uniqueId = uniqueId('spot-pool-');

      // append the copied spot pool to the array of spot pools for the day
      newSpotPools[day] = [
        ...newSpotPools[day],
        copySpotPool,
      ];
    });

    onChange({
      ...adProgram,
      spotPools: newSpotPools,
      changed: true,
    });
  };

  /**
   * Checking to see if a spot pool can be copied to days of the week
   */
  const onCheckCopySpotPool = ({
    days,
    spotPool,
  }) => {
    // check for collisions
    let allCollisions = [];

    days.forEach((day) => {
      const dayCollisions = onCheckCollisions(spotPool, day);
      // merge with all collisions
      allCollisions = [
        ...allCollisions,
        ...dayCollisions,
      ];
    });

    if (allCollisions.length > 0) {
      // warn the user spot pools will be replaced
      setCollisions(allCollisions);
      setCopyToDays(days);
      setCopySpotPool(spotPool);
      setConfirmReplaceSpotPools(true);
      return;
    }

    // no collisions, copy the spot pool to the selected days
    onCopySpotPool({
      days,
      spotPool,
    });
  };

  /**
   * When copying a spot pool that replaces existing spot pool(s)
   */
  const onConfirmReplaceSpotPools = () => {
    // has collisions, copy the spot pool to the selected days, replacing the destination spot pool collisions
    onCopySpotPool({
      days: copyToDays,
      spotPool: copySpotPool,
      spotPoolCollisions: collisions,
    });

    setCollisions([]);
    setCopyToDays([]);
    setCopySpotPool({});
    setConfirmReplaceSpotPools(false);
  };

  /**
   * When canceling copying a spot pool that replaces existing spot pool(s)
   */
  const onCancelReplaceSpotPools = () => {
    setCollisions([]);
    setCopyToDays([]);
    setCopySpotPool({});
    setConfirmReplaceSpotPools(false);
  };

  /**
   * When a spot pool is changed
   */
  const onChangeSpotPool = ({
    day,
    spotPool,
  }) => {
    const dayDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'dd');

    const newDaySpotPools = adProgram.spotPools[dayDD].map((pool) => {
      if (spotPool._uniqueId === pool._uniqueId) {
        // replace the spot pool with the updated values
        return spotPool;
      }

      // return the original spot pool
      return pool;
    });

    onChange({
      ...adProgram,
      spotPools: {
        ...adProgram.spotPools,
        [dayDD]: newDaySpotPools,
      },
      changed: true,
    });
  };

  /**
   * When a spot pool is removed
   */
  const onRemoveSpotPool = ({
    day,
    spotPool,
  }) => {
    const dayDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'dd');

    const newDaySpotPools = adProgram.spotPools[dayDD].filter((pool) => {
      if (spotPool._uniqueId === pool._uniqueId) {
        // remove the spot pool
        return false;
      }

      // return the spot pool
      return true;
    });

    onChange({
      ...adProgram,
      spotPools: {
        ...adProgram.spotPools,
        [dayDD]: newDaySpotPools,
      },
      changed: true,
    });
  };

  /**
   * When a spot value is changed
   */
  const onChangeSpotValue = ({
    day,
    startTime,
    value,
  }) => {
    const dayDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'dd');
    const hour = parseInt(startTime.split(':')[0], 10);

    const newDaySpotValues = adProgram.spotValues[dayDD].map((hourValue, hourIndex) => {
      if (hourIndex === hour) {
        // replace the value with the new one
        return toInteger(value);
      }

      // return the original value for the hour
      return hourValue;
    });

    onChange({
      ...adProgram,
      spotValues: {
        ...adProgram.spotValues,
        [dayDD]: newDaySpotValues,
      },
      changed: true,
    });
  };

  /**
   * Convert the spot pools into a format readable by the schedule
   */
  const convertSpotPools = () => {
    const converted = [];

    forEach(adProgram.spotPools, (spotPools, dayKey) => {
      const dayDDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(dayKey), 'ddd');
      const sortedSpotPools = sortBy(spotPools, 'startTime');

      forEach(sortedSpotPools, (spotPool) => {
        converted.push({
          day: dayDDD,
          spotPool,
          startTime: spotPool.startTime,
          stopTime: spotPool.endTime,
          canView: user.can('location-ad-program.view-spot-pools'),
          canEdit: user.can('location-ad-program.manage-spot-pools') && adProgram.future,
          onCopy: onCheckCopySpotPool,
          onChange: onChangeSpotPool,
          onRemove: onRemoveSpotPool,
        });
      });
    });

    return converted;
  };

  /**
   * Convert the spot values into a format readable by the schedule
   */
  const convertSpotValues = () => {
    const converted = [];

    forEach(adProgram.spotValues, (value, dayKey) => {
      const dayDDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(dayKey), 'ddd');

      forEach(value, (count, hour) => {
        const stopHour = hour + 1;

        converted.push({
          day: dayDDD,
          startTime: hour < 10
            ? `0${hour}:00`
            : `${hour}:00`,
          stopTime: stopHour < 10
            ? `0${stopHour}:00`
            : `${stopHour}:00`,
          value: `${count}`,
          canView: user.can('location-ad-program.view-spot-values'),
          canEdit: user.can('location-ad-program.manage-spot-values') && adProgram.future,
          onChange: onChangeSpotValue,
        });
      });
    });

    return converted;
  };

  /**
   * Fetch the ad program
   */
  const getAdProgram = async () => {
    const response = await API.Location.AdPrograms.get({
      locationId,
      adProgramId,
    });

    // use inclusivity parameters for isBetween - [] means include, () means exclude
    if (currTime && currTime.isBetween(response.startDate, response.endDate, undefined, '[]')) {
      setCurrAdProgram(true);
      response.current = true;
      response.future = false;
    } else {
      setCurrAdProgram(false);

      // only allow editing of ad programs in the future
      if (currTime && currTime.isBefore(response.startDate)) {
        response.future = true;
      }
    }

    // set a unique ID for each spot pool to use when updating later
    forEach(response.spotPools, (value) => {
      forEach(value, (spotPool) => {
        spotPool._uniqueId = uniqueId('spot-pool-');

        // calculate max/hourly spots if none provided in the API response
        if (isUndefined(spotPool.maxHourlySpots) || isUndefined(spotPool.allowedHourlySpots)) {
          const {
            maxHourlySpots,
            allowedHourlySpots,
          } = calculateHourlySpotsForSpotPool(spotPool, mediaFormat);

          spotPool.maxHourlySpots = maxHourlySpots;
          spotPool.allowedHourlySpots = allowedHourlySpots;
        }
      });
    });

    return response;
  };

  /**
   * Force a refresh of the ad program data
   */
  useEffect(async () => {
    if (qs.refresh === 'true') {
      const response = await getAdProgram();
      onChange(response);
    }
  }, [qs.refresh]);

  /**
   * When the ad program ID changes, fetch a new one
   */
  useEffect(async () => {
    if (adProgramId) {
      const response = await getAdProgram();
      onChange(response);
    } else if (!adProgramId && !isEmpty(adProgram)) {
      // no ad program ID provided and the ad program is not empty
      // reset the ad program
      onChange({});
    }
  }, [adProgramId]);

  if (!adProgramId) {
    return (
      <div className={classNames('AdProgramSchedule', className)}>
        <EmptyState
          title="No Ad Program Selected"
          description="Select an ad program from the list to view the schedule."
        />
      </div>
    );
  }

  return (
    <div className={classNames('AdProgramSchedule', className)}>
      <WeekDates
        style={{
          position: 'sticky',
          top: 0,
          // remove margin and use padding to hide the time labels when scrolling
          marginLeft: 0,
          paddingLeft: 55,
          zIndex: 999,
          backgroundColor: color.white,
        }}
        startOfWeek={startOfWeek}
        dayOfWeekOnly
        hideIcons
      />

      <ScheduleData
        type="schedule"
        style={{
          height: 'calc(100% - 65px)',
        }}
        activeDisplay="24h"
        startOfWeek={startOfWeek}
        timezone={timezone}
        currTime={currAdProgram
          ? currTime
          : {}}
        adProgramProps={{
          canAddSpotPool: adProgram.future,
          onAddSpotPool,
        }}
        spotPools={convertSpotPools()}
        spotValues={convertSpotValues()}
      />

      <VibeModal
        show={confirmReplaceSpotPools}
        type="confirm"
        text={(
          <div>
            This will replace existing Spot Pools

            <div
              style={{
                margin: '16px 0',
                fontWeight: 'bold',
              }}
            >
              {collisions.map((collision, index) => (
                <div
                  key={`collision-${index}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                    }}
                  >
                    {TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(collision.day), 'dddd')}
                  </div>

                  <div
                    style={{
                      color: color.manatee,
                    }}
                  >
                    {collision.spotPool.startTime} - {collision.spotPool.endTime}
                  </div>
                </div>
              ))}
            </div>

            Are you sure you want to continue?
          </div>
        )}
        confirmProps={{
          text: 'Replace',
          color: 'primary',
        }}
        cancelProps={{
          text: 'Cancel',
        }}
        onConfirm={onConfirmReplaceSpotPools}
        onClose={onCancelReplaceSpotPools}
      />
    </div>
  );
}

AdProgramSchedule.propTypes = {
  className: PropTypes.string,
  locationId: PropTypes.string.isRequired,
  adProgramId: PropTypes.string,
  adProgram: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  mediaFormat: PropTypes.oneOf([
    'audio',
    'visual',
  ]),
  currTime: PropTypes.instanceOf(moment).isRequired,
  timezone: PropTypes.string.isRequired,
  /** When the ad program is changed */
  onChange: PropTypes.func,
};

AdProgramSchedule.defaultProps = {
  className: '',
  adProgramId: '',
  adProgram: {},
  mediaFormat: 'audio',
  onChange: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(AdProgramSchedule);
