import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  size,
  map,
  forEach,
  groupBy,
  orderBy,
} from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import {
  API,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  Month,
  MonthNavigate,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';
import ScheduledItem from './ScheduledItem';
import AddScheduledItem from './AddScheduledItem';
import MoveItem from './MoveItem';
import './BaselineSchedule.scss';

/**
 * Get Multiple Baseline Details
 */
async function getBaselines(baselines) {
  const fetchedBaselines = [];

  await Promise.all(baselines.map(async (baseline) => {
    const { baseline: baselineDetails } = await API.Baseline.getById(baseline._id);

    fetchedBaselines.push({
      ...baseline,
      ...baselineDetails,
    });

    return Promise.resolve(fetchedBaselines);
  }));

  return fetchedBaselines;
}

class BaselineSchedule extends PureComponent {
  constructor(props) {
    super(props);

    const {
      baselines,
      timezone,
    } = props;

    this.currDate = new moment().tz(timezone);
    this.shiftDown = false;
    this.selectedDayDates = [];

    this.state = {
      baselines,
      scheduled: [],
      activated: [],
      selectedDays: [],
      addBaselineDay: null,
      showConfirmMove: false,
      currMonth: new moment()
        .toDate(),
      nextMonth: new moment()
        .add(1, 'month')
        .toDate(),
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
    document.addEventListener('keyup', this.onKeyUp, false);
  }

  componentWillUnmount() {
    // Remove listener for shift key listener
    document.removeEventListener('keydown', this.onKeyDown, false);
    document.removeEventListener('keyup', this.onKeyUp, false);
  }

  /**
   * When a key is pressed
   */
  onKeyDown = (e) => {
    const {
      key,
    } = e;

    if (key === 'Shift') {
      // Shift is currently pressed
      this.shiftDown = true;
    }
  };

  /**
   * When a key is pressed
   */
  onKeyUp = (e) => {
    const {
      key,
    } = e;

    if (key === 'Shift') {
      // Shift is no longer being pressed
      this.shiftDown = false;
    }
  };

  /**
   * Previous Month
   */
  onPrevMonth = () => {
    this.setState((state) => {
      const {
        currMonth,
        nextMonth,
      } = state;

      return {
        currMonth: new moment(currMonth)
          .subtract(1, 'month')
          .toDate(),
        nextMonth: new moment(nextMonth)
          .subtract(1, 'month')
          .toDate(),
      };
    });
  };

  /**
   * Next Month
   */
  onNextMonth = () => {
    this.setState((state) => {
      const {
        currMonth,
        nextMonth,
      } = state;

      return {
        currMonth: new moment(currMonth)
          .add(1, 'month')
          .toDate(),
        nextMonth: new moment(nextMonth)
          .add(1, 'month')
          .toDate(),
      };
    });
  };

  /**
   * When a day is selected
   */
  onSelectDay = async (day) => {
    const {
      user,
      timezone,
    } = this.props;

    const {
      selectedDays,
    } = this.state;

    const {
      currDate,
      shiftDown,
    } = this;

    const dayMMDDYYYY = day.format('MM/DD/YYYY');
    let newSelectedDays = [];

    const dayBaselines = this.getBaselinesForDay(day);

    if (shiftDown && dayBaselines.length <= 0) {
      // Do not allow multi-select of days without a scheduled baseline
      console.warn('Cannot multi-select days without a scheduled baseline');
      return;
    }

    if (shiftDown && dayBaselines.length > 0 && selectedDays.length > 0) {
      // Check to see if there is already a selected day without a scheduled baseline
      let hasBaselines = false;
      // Check to see if there is already a selected day in the past
      let hasSelectedPast = false;

      if (currDate.isAfter(day)) {
        // Do not allow multi-select of past scheduled days
        console.warn('Cannot multi-select past scheduled days');
        return;
      }

      this.selectedDayDates.forEach((selectedDay) => {
        const dayBaselines = this.getBaselinesForDay(selectedDay);

        if (dayBaselines.length > 0) {
          // Current selected date has scheduled baselines
          hasBaselines = true;
        }

        if (currDate.isAfter(selectedDay)) {
          // Already selected a day in the past
          hasSelectedPast = true;
        }
      });

      if (!hasBaselines || hasSelectedPast) {
        // Do not allow multi-select if a day is already selected without a scheduled baseline
        // Do not allow multi-select if a day is already selected from the past
        console.warn('Cannot multi-select days without a scheduled baseline or the past');
        return;
      }
    }

    if (shiftDown) {
      // Shift is pressed, add the date to multiple selected days
      newSelectedDays = !selectedDays.includes(dayMMDDYYYY)
        // Add the day
        ? [...selectedDays, dayMMDDYYYY]
        // Remove the day
        : selectedDays.filter(d => d !== dayMMDDYYYY);

      this.selectedDayDates = !selectedDays.includes(dayMMDDYYYY)
        // Add the day
        ? [...this.selectedDayDates, day]
        // Remove the day
        : this.selectedDayDates.filter(d => d.format('MM/DD/YYYY') !== dayMMDDYYYY);
    } else if (selectedDays.includes(dayMMDDYYYY)) {
      // Shift is not pressed, the day is already selected. Deselect the day
      newSelectedDays = [];
      this.selectedDayDates = [];
    } else {
      // Shift is not pressed, only add the one day as selected
      newSelectedDays = [dayMMDDYYYY];
      this.selectedDayDates = [day];
    }

    if (newSelectedDays.length <= 0) {
      // No days are selected
      this.setState({
        scheduled: [],
        activated: [],
        selectedDays: [],
        addBaselineDay: null,
      });

      return;
    }

    let baselines = [];

    this.selectedDayDates.forEach((selectedDay) => {
      const dayBaselines = this.getBaselinesForDay(selectedDay);
      baselines = [...baselines, ...dayBaselines];
    });

    if (baselines.length <= 0) {
      // No baselines found for the selected day(s)
      this.setState({
        scheduled: [],
        activated: [],
      });
    }

    if (baselines.length <= 0) {
      console.log('No baselines');
    }

    // Whether or not to add a baseline on this day
    const addBaselineDay = baselines.length <= 0 && user.can('location.schedule')
      ? day
      : null;

    const fetchBaselinesResponse = baselines.length > 0
      ? await getBaselines(baselines)
      : [];

    const scheduled = [];
    const activated = [];

    fetchBaselinesResponse.forEach((baseline) => {
      const startDate = new moment(baseline.startDate).tz(timezone);
      // Track which month the baseline starts for grouping
      baseline.startMonth = startDate.format('MMMM');

      if (startDate.format('HH:mm:ss') === '00:00:00') {
        // Baselines starting at midnight are "scheduled"
        scheduled.push(baseline);
      } else {
        // Baselines starting at a different time are "activated"
        activated.push(baseline);
      }
    });

    const orderActivated = orderBy(activated, 'startDate');
    const orderScheduled = orderBy(scheduled, 'startDate');
    const groupScheduled = groupBy(orderScheduled, 'startMonth');

    this.setState({
      scheduled: groupScheduled,
      activated: orderActivated,
      selectedDays: newSelectedDays,
      addBaselineDay,
    });
  };

  /**
   * When a Baseline is dropped
   */
  onDrop = async (data) => {
    const {
      from,
      to,
    } = data;

    const {
      currentLocation: {
        companyId,
        _id: locationId,
      },
      timezone,
    } = this.props;

    const fromBaselines = this.getBaselinesForDay(from);
    const toBaselines = this.getBaselinesForDay(to);

    if (toBaselines.length > 0) {
      console.error('Cannot drop baselines on a date with baselines');
      return;
    }

    // TODO: Uncomment when allowing selection of locations to move
    // this.setState({
    //   showConfirmMove: true,
    // });

    // Locally save new baseline start dates before sending to API
    // to prevent calendar display from reverting to old data
    const newBaselines = fromBaselines.map((baseline) => {
      const {
        _id,
      } = baseline;

      return {
        _id,
        startDate: to.format(),
      };
    });

    this.setState((state) => {
      const {
        baselines,
      } = state;

      // Remove the baselines from the date dragged from and include the others locally
      const removeFromBaselines = baselines.filter(baseline => !fromBaselines.includes(baseline));

      return {
        baselines: [...removeFromBaselines, ...newBaselines],
      };
    });

    // Add the baselines from the original date to the new date
    const addBaselinesToSchedule = await API.Location.updateSchedule({
      action: 'add',
      baselines: fromBaselines.map((baseline) => {
        const {
          _id,
        } = baseline;

        return {
          _id,
          startDate: to.format('YYYY-MM-DD'),
          originTimeZone: timezone,
        };
      }),
      locations: [{
        companyId,
        locationId,
      }],
    });

    if (get(addBaselinesToSchedule, '[0].type') !== 'LOCATION.SCHEDULE_UPDATED') {
      // Baselines were not added to the day
      this.fetchSchedule();
      console.error('Failed adding baselines to dropped day', addBaselinesToSchedule);

      return;
    }

    // Remove the baselines from the original date (after successfully adding to a new date)
    const removeBaselinesFromSchedule = await API.Location.updateSchedule({
      action: 'remove',
      baselines: fromBaselines.map((baseline) => {
        const {
          _id,
          startDate,
        } = baseline;

        return {
          _id,
          startDate,
          originTimeZone: timezone,
        };
      }),
      locations: [{
        companyId,
        locationId,
      }],
    });

    if (get(removeBaselinesFromSchedule, '[0].type') !== 'LOCATION.SCHEDULE_UPDATED') {
      // Baselines were not removed from the original date
      console.error('Failed removing baselines from original day', removeBaselinesFromSchedule);
    }

    console.warn('Finished Moving Schedule');
    // Finished moving the schedules, fetch the new schedule
    this.fetchSchedule(to);
  };

  /**
   * When baseline(s) are unscheduled
   */
  onUnschedule = () => {
    // Refresh the schedule
    this.fetchSchedule();

    // Reset selected days
    this.selectedDayDates = [];
    this.setState({
      selectedDays: [],
      scheduled: [],
      activated: [],
    });
  };

  /**
   * When a baseline is scheduled
   */
  onSchedule = () => {
    const {
      addBaselineDay,
    } = this.state;

    this.setState({
      addBaselineDay: null,
      selectedDays: [],
    }, () => {
      // Refresh the schedule
      this.fetchSchedule(addBaselineDay);
    });
  };

  /**
   * When baseline(s) are copied
   */
  onCopy = () => {
    // Refresh the schedule
    this.fetchSchedule();

    // Reset selected days
    this.selectedDayDates = [];
    this.setState({
      selectedDays: [],
      scheduled: [],
      activated: [],
    });
  };

  /**
   * When a baseline move is canceled
   */
  onCancelMove = () => {
    this.setState({
      showConfirmMove: false,
    });
  };

  /**
   * When a baseline is moved
   */
  onMove = (locations) => {
    console.warn('onMove Called', locations);
  };

  /**
   * When the user cancels adding a scheduled baseline for a day
   */
  onCancelAdd = () => {
    this.selectedDayDates = [];
    this.setState({
      addBaselineDay: null,
      selectedDays: [],
    });
  };

  /**
   * Get baselines for a specific day
   */
  getBaselinesForDay(day) {
    const {
      timezone,
    } = this.props;

    const {
      baselines,
    } = this.state;

    const dayMMDDYYYY = day.format('MM/DD/YYYY');

    return baselines.filter((baseline) => {
      const {
        startDate,
      } = baseline;

      const startMMDDYYYY = new moment(startDate)
        .tz(timezone)
        .format('MM/DD/YYYY');

      if (startMMDDYYYY === dayMMDDYYYY) {
        return baseline;
      }

      return null;
    });
  }

  /**
   * Fetch the Location Schedule
   */
  fetchSchedule = async (selectDay) => {
    const {
      currentLocation: {
        _id,
      },
    } = this.props;

    const schedule = await API.Location.getSchedule(_id);
    this.selectedDayDates = [];

    this.setState({
      baselines: get(schedule, 'schedule.baselines', []),
      selectedDays: [],
      scheduled: [],
      activated: [],
      addBaselineDay: null,
    }, () => {
      if (selectDay) {
        this.onSelectDay(selectDay);
      }
    });
  };

  render() {
    const {
      user,
      timezone,
      onClose,
    } = this.props;

    const {
      baselines,
      scheduled,
      activated,
      selectedDays,
      addBaselineDay,
      showConfirmMove,
      currMonth,
      nextMonth,
    } = this.state;

    const currMonthName = new moment(currMonth).format('MMMM');
    const nextMonthName = new moment(nextMonth).format('MMMM');

    // Minimum date to allow select
    const minDate = new moment()
      .tz(timezone)
      .add(1, 'day')
      .startOf('day')
      .toDate();

    // const maxDate = new moment()
    //   .add(6, 'days')
    //   .toDate();

    const eventDays = baselines.map(baseline => moment(new Date(baseline.startDate)).tz(timezone).toDate());
    const bulkBaselines = [];

    if (selectedDays.length > 1) {
      // Merge baselines into a bulk array
      forEach(scheduled, (baselines) => {
        forEach(baselines, (baseline) => {
          const {
            _id,
            name,
            startDate,
          } = baseline;

          bulkBaselines.push({
            _id,
            name,
            startDate,
          });
        });
      });
    }

    return (
      <SidePanelContainer className="BaselineSchedule">
        <SidePanelHeader
          icons={(
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onClose}
            />
          )}
        >
          <div className="title">
            Baseline Schedule
          </div>
        </SidePanelHeader>

        <SidePanelContent className="sidepanel-content">
          <div className="baseline-schedule-content">
            <MonthNavigate
              text={`${currMonthName} - ${nextMonthName}`}
              iconColor={color.primary}
              iconSize={20}
              iconStyle={{
                padding: 5,
                border: `1px solid ${color.primary}`,
                borderRadius: 4,
              }}
              onPrev={this.onPrevMonth}
              onNext={this.onNextMonth}
            />

            <div className="calendar-month">
              <Month
                date={currMonth}
                showToday
                // navigate
                timezone={timezone}
                minDate={minDate}
                selectedDays={selectedDays}
                eventDays={eventDays}
                dragEvents={user.can('location.schedule')}
                selectDisabled
                onSelectDay={this.onSelectDay}
                onDrop={this.onDrop}
              />
            </div>

            <div className="calendar-month">
              <Month
                date={nextMonth}
                showToday
                // navigate
                timezone={timezone}
                minDate={minDate}
                // maxDate={maxDate}
                selectedDays={selectedDays}
                eventDays={eventDays}
                dragEvents={user.can('location.schedule')}
                selectDisabled
                onSelectDay={this.onSelectDay}
                onDrop={this.onDrop}
              />
            </div>
          </div>

          {addBaselineDay ? (
            <div className="add-baseline">
              <div className="scheduled-month">
                <div className="month-name">
                  {addBaselineDay.format('MMMM')}
                </div>

                <AddScheduledItem
                  day={addBaselineDay}
                  onSchedule={this.onSchedule}
                  onCancel={this.onCancelAdd}
                />
              </div>
            </div>
          ) : null}

          {size(scheduled) > 0 ? (
            <div className="scheduled">
              {map(scheduled, (baselines, month) => {
                return (
                  <div
                    key={month}
                    className="scheduled-month"
                  >
                    <div className="month-name">
                      {month}
                    </div>

                    {baselines.map((baseline, index) => {
                      const {
                        _id,
                        name,
                        startDate,
                      } = baseline;

                      return (
                        <ScheduledItem
                          key={`scheduled-${_id}-${index}`}
                          _id={_id}
                          name={name}
                          startDate={startDate}
                          onUnschedule={this.onUnschedule}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          ) : null}

          {activated.length > 0 ? (
            <div className="activated">
              <div className="title">
                Activated
              </div>

              {activated.map((baseline, index) => {
                const startTime = new moment(baseline.startDate).tz(timezone).format('HH:mm:ss');

                return (
                  <div
                    key={`activated-${baseline._id}-${index}`}
                    className="activated-baseline"
                  >
                    <div className="activated-content">
                      <div className="baseline-name">
                        {baseline.name}
                      </div>

                      <div className="baseline-time">
                        {startTime}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {selectedDays.length > 1 && user.can('location.schedule') ? (
            <div className="bulk">
              <ScheduledItem
                baselines={bulkBaselines}
                bulk
                onUnschedule={this.onUnschedule}
                onCopy={this.onCopy}
              />
            </div>
          ) : null}

          {showConfirmMove ? (
            <MoveItem
              onCancel={this.onCancelMove}
              onMove={this.onMove}
            />
          ) : null}
        </SidePanelContent>
      </SidePanelContainer>
    );
  }
}

BaselineSchedule.propTypes = {
  baselines: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({
      companyId: PropTypes.string,
      locationId: PropTypes.string,
    })),
    days: PropTypes.arrayOf(PropTypes.shape({
      musicParts: PropTypes.arrayOf(PropTypes.object),
      messageParts: PropTypes.arrayOf(PropTypes.object),
      interrupts: PropTypes.arrayOf(PropTypes.object),
    })),
    active: PropTypes.bool,
    createdDate: PropTypes.string,
    modifiedDate: PropTypes.string,
    startDate: PropTypes.string,
    current: PropTypes.bool,
  })),
  timezone: PropTypes.string,
  onClose: PropTypes.func,
};

BaselineSchedule.defaultProps = {
  baselines: [],
  timezone: '',
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default connect(mapStateToProps)(BaselineSchedule);
