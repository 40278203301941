import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useNavigate,
} from 'react-router-dom';
import {
  API,
  GlobalActions,
  NavigationHelper,
  TableDevices,
  VibeModal,
  VibeButton,
  viAdd,
} from 'vibeguide';
import DeviceManagementSidebar from '../Sidebar/DeviceManagementSidebar';

/**
 * Reset the confirmation modal dialog
 */
function resetConfirmModal() {
  return {
    show: false,
    title: '',
    description: '',
    confirmText: '',
    confirmColor: '',
    cancelText: '',
    onConfirm: () => { },
    onCancel: () => { },
  };
}

function ProfileDeviceManagement({
  className,
  style,
  location,
  socket,
  setPanel,
  user,
}) {
  const [confirmModal, setConfirmModal] = useState(resetConfirmModal());

  const history = useNavigate();
  const qs = NavigationHelper.getParams();

  /**
   * When the API sends a socket event
   */
  const onApiEvent = (e) => {
    switch (e.type) {
      /**
       * When the active player changes online status
       * When the device config is updated
       * Refresh the "Devices" table to get the new device list and online state
       */
      case 'LOCATION.PLAYER_ONLINE_UPDATED':
      case 'LOCATION.DEVICE_CONFIG_UPDATED': {
        // tell listening components to update the table
        document.dispatchEvent(new Event('onSaveDevice'));
        break;
      }

      default:
        // console.warn('Event', e);
        break;
    }
  };

  /**
   * Cancel the confirmation modal
   */
  const onCancelConfirmModal = () => {
    setConfirmModal(resetConfirmModal());
  };

  /**
   * Confirm switching the live device
   */
  const onConfirmLive = async (_id) => {
    onCancelConfirmModal();
    await API.DeviceManagement.activate({
      _id,
    });
  };

  /**
   * Confirm decommissioning a device
   */
  const onConfirmDecommission = async (_id) => {
    onCancelConfirmModal();
    await API.DeviceManagement.decommission({
      _id,
    });
  };

  /**
   * Confirm resetting a device
   */
  const onConfirmReset = async (_id) => {
    onCancelConfirmModal();
    await API.DeviceManagement.reset({
      _id,
    });
  };

  /**
   * When an option is selected
   */
  const onClickOption = ({
    option,
    device,
  }) => {
    switch (option) {
      case 'make live':
        setConfirmModal({
          show: true,
          title: 'Mark this Device as Live?',
          description: `Are you sure you want to make ${device.deviceType} the live device?`,
          confirmText: 'Make Live',
          confirmColor: 'success',
          cancelText: 'Cancel',
          onConfirm: () => {
            onConfirmLive(device._id);
          },
          onCancel: onCancelConfirmModal,
        });
        break;

      case 'decommission':
        setConfirmModal({
          show: true,
          title: 'Decommission this Device?',
          description: `Are you sure you want to decommission ${device.deviceType}?`,
          confirmText: 'Decommission',
          confirmColor: 'error',
          cancelText: 'Cancel',
          onConfirm: () => {
            onConfirmDecommission(device._id);
          },
          onCancel: onCancelConfirmModal,
        });
        break;

      case 'reset':
        setConfirmModal({
          show: true,
          title: 'Reset this Device?',
          description: `Are you sure you want to reset ${device.deviceType}?`,
          confirmText: 'Reset',
          confirmColor: 'primary',
          cancelText: 'Cancel',
          onConfirm: () => {
            onConfirmReset(device._id);
          },
          onCancel: onCancelConfirmModal,
        });
        break;

      case 'edit':
      case 'transfer':
        // edit/transfer is handled with a URL
        break;

      default:
        console.warn('Unknown option selected', option);
        break;
    }
  };

  /**
   * When a menu item is clicked in the table
   */
  const onSelectMenuItem = ({
    name: option,
    row: device,
  }) => {
    onClickOption({
      option,
      device,
    });
  };

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      deviceId: null,
      type: null,
    });

    history(url);

    setPanel({
      show: false,
    });
  };

  /**
   * Open the sidebar
   */
  const onOpenSidebar = () => {
    setPanel({
      show: true,
      backdrop: false,
      width: 400,
      children: (
        <DeviceManagementSidebar
          deviceId={qs.deviceId}
          locationId={location._id}
          onClickOption={onClickOption}
          onClose={onCloseSidebar}
        />
      ),
    });
  };

  /**
   * Open the sidebar
   */
  useEffect(() => {
    if (qs.deviceId || qs.type === 'new') {
      onOpenSidebar();
    }
  }, [qs.deviceId, qs.type]);

  /**
   * When connected to the socket, listen for flight events
   * Resubscribe to the socket events when the flight data is fetched
   */
  useEffect(() => {
    if (socket.connected) {
      socket.off('VAPI_EVENT', onApiEvent);
      socket.on('VAPI_EVENT', onApiEvent);
    }

    // when component unmounts
    return () => {
      socket.off('VAPI_EVENT', onApiEvent);
    };
  }, [socket.connected]);

  const userCanManageDevice = user.can('admin.system_admin')
    && user.can('location.manage_device_config');

  return (
    <div
      className={classNames('ProfileDeviceManagement', className)}
      style={{
        flexGrow: 1,
        // Firefox: height needed to expand the table to full height
        height: '100%',
        ...style,
      }}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TableDevices
          columns={[
            'Device Type',
            'Connected',
            'Status',
            'Player ID',
            'Serial Number',
            'Mac Address',
            'Phone Number',
            'Activation Date',
            '...',
          ]}
          fetch={API.DeviceManagement.list}
          filters={{
            locationId: location._id,
          }}
          defaultSortBy={{
            label: 'Status',
            attr: 'activationStatus',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Devices',
            urlPaging: true,
            urlFilters: true,
          }}
          toolbarProps={{
            customButtons: [
              (
                <VibeButton
                  key="button-new-device"
                  text="New Device"
                  icon={viAdd}
                  color="primary"
                  link={NavigationHelper.updateParams({
                    type: 'new',
                  })}
                />
              ),
            ],
          }}
          rowLink={userCanManageDevice
            ? {
              deviceId: '_id',
            } : null}
          menuItems={(row) => {
            return [
              {
                name: 'Edit',
                userCan: () => user.can('admin.system_admin') && user.can('location.manage_device_config'),
                link: {
                  deviceId: '_id',
                },
              },
              {
                name: 'Make Live',
                userCan: () => user.can('admin.system_admin') && user.can('location.manage_device_config'),
                disabled: row.activationStatus === 'live',
              },
              {
                name: 'Transfer',
                userCan: () => user.can('admin.system_admin') && user.can('location.manage_device_config'),
                link: {
                  deviceId: '_id',
                  type: 'transfer',
                },
              },
              {
                name: 'Reset',
                userCan: () => user.can('admin.system_admin') && user.can('location.manage_device_config'),
              },
              {
                name: 'Decommission',
                userCan: () => user.can('admin.system_admin') && user.can('location.manage_device_config'),
                disabled: row.activationStatus === 'decommissioned',
              },
            ];
          }}
          onSelectMenuItem={onSelectMenuItem}
        />
      </div>

      <VibeModal
        type="confirm"
        show={confirmModal.show}
        confirmProps={{
          text: confirmModal.confirmText,
          color: confirmModal.confirmColor,
        }}
        cancelProps={{
          text: confirmModal.cancelText,
        }}
        title={confirmModal.title}
        text={confirmModal.description}
        onConfirm={confirmModal.onConfirm}
        onClose={confirmModal.onCancel}
      />
    </div>
  );
}

ProfileDeviceManagement.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  location: PropTypes.object.isRequired,
};

ProfileDeviceManagement.defaultProps = {
  className: '',
  style: {},
};

function mapStateToProps(state) {
  return {
    socket: state.socket.connection,
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDeviceManagement);
