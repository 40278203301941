import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  NavigationSide,
  NavigationSideItem,
  viCompany,
  viCategory,
  viZone,
} from 'vibeguide';

function CompaniesContainer({
  user,
}) {
  return (
    <div
      className="CompaniesContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginLeft: '80px',
      }}
    >
      <NavigationSide>
        <NavigationSideItem
          text="Basic Info"
          url="/companies/list"
          icon={viCompany}
          show={user.can('company.view')}
        />

        <NavigationSideItem
          text="Categories"
          url="/companies/categories"
          icon={viCategory}
          show={user.can('company_category.view')}
        />

        <NavigationSideItem
          text="Zones"
          url="/companies/zones"
          icon={viZone}
          show={user.can('location-zone.view')}
        />
      </NavigationSide>

      <Outlet />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(CompaniesContainer);
