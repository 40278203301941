import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import API from '../../api';
import VibeModal from '../VibeModal/VibeModal';
import VibeCard from '../VibeCard/VibeCard';
import defaultImage from '../../assets/ism-avatar.png';
import './CompanyCard.scss';

class CompanyCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmArchive: false,
    };
  }

  /**
   * When a Menu Item is Selected
   */
  onSelectMenuItem = async (name) => {
    const {
      company: {
        _id,
      },
      onArchive,
    } = this.props;

    if (name === 'archive') {
      this.setState({
        showConfirmArchive: true,
      });
    } else if (name === 'unarchive') {
      const response = await API.Company.reactivate({
        _id,
      });
      const isSuccess = get(response, '[0].documentId', null);

      if (isSuccess) {
        onArchive(_id);
      }
    }
  };

  /**
   * Confirm Archive Message
   */
  onConfirmArchive = async (e) => {
    e.stopPropagation();

    const {
      company: {
        _id,
      },
      onArchive,
    } = this.props;

    const response = await API.Company.deactivate(_id);
    const isSuccess = get(response, '[0].documentId', null);

    if (isSuccess) {
      onArchive(_id);
    }

    this.setState({
      showConfirmArchive: false,
    });
  };

  /**
   * Cancel Archive Message
   */
  onCancelArchive = () => {
    this.setState({
      showConfirmArchive: false,
    });
  };

  toggleCard = () => {
    const {
      company,
      toggleSelected,
      selected,
      disabled,
      allowClick,
      onClick,
    } = this.props;

    onClick(company);

    if (!disabled && allowClick) {
      // Send selected state back to the parent component
      toggleSelected(company._id, !selected);
    }
  };

  render() {
    const {
      className,
      company,
      disabled,
      selected,
      allowClick,
    } = this.props;

    const {
      showConfirmArchive,
    } = this.state;

    const isSelected = selected;

    return (
      <div className="CompanyCard">
        <VibeCard
          className={`card-container ${className}`}
          width={350}
          height={80}
          selected={isSelected}
          disabled={disabled}
          onClick={this.toggleCard}
          onSelectMenuItem={this.onSelectMenuItem}
          allowClick={allowClick}
        >
          <div className="company-data">
            <div className="company-image-container">
              {company.imageUrl ? (
                <img className="company-image" src={company.imageUrl} alt={company.name} />
              ) : (
                <img className="company-image" src={defaultImage} alt={company.name} />
              )}
            </div>

            <div className="company-info">
              <div className="company-name">
                {company.name}
              </div>
              <div className="company-category">
                {company.category}
              </div>
            </div>
          </div>
        </VibeCard>

        <VibeModal
          show={showConfirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${company.name}?`}
          confirmProps={{
            text: 'Archive',
            color: 'error',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCancelArchive}
        />
      </div>
    );
  }
}

CompanyCard.propTypes = {
  className: PropTypes.string,
  company: PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    category: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  /** Allow the card to be clicked */
  allowClick: PropTypes.bool,
  /** Allow actions to the card */
  toggleSelected: PropTypes.func,
  onClick: PropTypes.func,
  /** When a card is archived */
  onArchive: PropTypes.func,
};

CompanyCard.defaultProps = {
  className: '',
  allowClick: false,
  disabled: false,
  selected: false,
  toggleSelected: () => {},
  onClick: () => {},
  onArchive: () => {},
};

export default CompanyCard;
