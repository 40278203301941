import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  isArray,
} from 'lodash';
import TableMessages from '../VibeTable/Tables/TableMessages';
import './MessagesAssigned.scss';

class MessagesAssigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      selected,
    } = props;

    this.state = {
      filteredMessages: selected,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      selected: messages,
    } = this.props;

    const {
      selected: prevMessages,
    } = prevProps;

    if (JSON.stringify(messages) !== JSON.stringify(prevMessages)) {
      this.setState({
        filteredMessages: messages,
      });
    }
  }

  onAdd = (item) => {
    const {
      onChange,
    } = this.props;

    this.setState((state) => {
      const messages = [
        ...state.filteredMessages,
        item,
      ];

      onChange(messages);

      return {
        filteredMessages: messages,
      };
    });
  };

  onRemove = (items) => {
    const {
      onChange,
    } = this.props;

    if (!isArray(items)) {
      items = [items];
    }

    const itemIds = items.map(item => item._rowId);

    this.setState((state) => {
      const messages = state.filteredMessages.filter(message => !itemIds.includes(message._rowId));
      onChange(messages);

      return {
        filteredMessages: messages,
      };
    });
  };

  render() {
    const {
      allowCustomOrder,
      allowChanges,
      customToolbar,
      onChange,
    } = this.props;

    const {
      filteredMessages,
    } = this.state;

    const columns = [
      'Message Name',
      'Company',
      'Last Updated',
      'Requester',
      'Start Date',
      'End Date',
      'Duration',
      // 'Script',
      '...',
    ];

    if (allowChanges) {
      columns.unshift('Add/Remove');
    }

    return (
      <div className="AssignedMessages">
        <div className="title-container">
          <div className="title">
            Assigned Messages
          </div>

          {customToolbar}
        </div>

        <div className="messages-container">
          <TableMessages
            columns={columns}
            collection={filteredMessages}
            defaultSortBy={{
              label: 'Last Updated',
              attr: 'modifiedDate',
              direction: 'desc',
            }}
            paginator
            paginatorProps={{
              label: 'Messages',
              urlPaging: false,
              urlFilters: false,
            }}
            emptyProps={{
              title: 'Add Messages to Your Message Block',
              // eslint-disable-next-line max-len
              description: 'Add messages like you would add songs to a playlist. Schedule this set of messages to play for any length of time at any of the locations you’ve chosen!',
            }}
            menuItems={(row) => {
              const isAd = row.messageType === 'ad'
                || row.messageType === 'ext-ad';

              return [{
                name: 'Archive',
                userCan: isAd
                  ? 'advertisement.delete'
                  : 'message.delete',
              }];
            }}
            draggable={allowCustomOrder}
            droppable={allowCustomOrder}
            disableSort={allowCustomOrder}
            assigned
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

MessagesAssigned.propTypes = {
  /** Allow custom order of messages in the table */
  allowCustomOrder: PropTypes.bool,
  /** Allow changes to the selection */
  allowChanges: PropTypes.bool,
  /** Custom toolbar */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  selected: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    messageType: PropTypes.string,
    url: PropTypes.string,
    active: PropTypes.bool,
  })),
  /** When the messages are changed */
  onChange: PropTypes.func,
};

MessagesAssigned.defaultProps = {
  allowCustomOrder: false,
  allowChanges: false,
  customToolbar: null,
  selected: [],
  onChange: () => {},
};

export default MessagesAssigned;
