import React from 'react';
import {
  color,
} from 'vibeguide';
import defaultImage from './assets/ism-avatar.png';

/**
 * Custom Error View for Users
 * This is shown instead of the blank white screen
 * @param {function} clearError // Use clearError to reset ErrorBoundary state and re-render child tree.
 */
function ErrorView() {
  return (
    <div
      className="ErrorView"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
      }}
    >
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            marginBottom: 24,
          }}
        >
          <img
            src={defaultImage}
            style={{
              width: 123,
              height: 123,
            }}
            alt="Error"
          />
        </div>

        <div
          style={{
            textAlign: 'center',
          }}
        >
          <div
            style={{
              marginBottom: 16,
              fontSize: 30,
              fontWeight: 'bold',
              color: color.primary,
            }}
          >
            Oops!
          </div>

          <div
            style={{
              fontSize: 24,
              color: color.manatee,
            }}
          >
            Something went wrong :(
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorView;
