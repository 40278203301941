import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  TimeUtil,
  Field2 as Field,
  MessageBlockCard,
  MessageModel,
  VibeButton,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';
import {
  Popover,
} from '@mui/material';
import ArchivedBanner from './ArchivedBanner';
import './MessageBlock.scss';

class MessageBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.itemRef = React.createRef();
    this.startTime = null;
    this.stopTime = null;

    this.state = {
      showDetails: false,
      invalidStartTime: false,
      invalidStopTime: false,
    };
  }

  onRemove = () => {
    const {
      id,
    } = this.props;

    document.dispatchEvent(new CustomEvent('onRemoveItem', {
      detail: {
        type: 'Message Block',
        _uniqueId: id,
      },
    }));
  };

  onChangeStartTime = (data) => {
    // time field has an error
    if (data.hasError) {
      this.setState({
        invalidStartTime: true,
      });

      return;
    }

    this.startTime = data.time24;

    // no error, allow saving if the stop time is also valid
    this.setState({
      invalidStartTime: false,
    });
  };

  onChangeStopTime = (data) => {
    // time field has an error
    if (data.hasError) {
      this.setState({
        invalidStopTime: true,
      });

      return;
    }

    let newStopTime = data.time24;

    const time = data.time24.split(':');
    const hour = parseInt(time[0], 10);

    if (hour < 0 || hour >= 24) {
      newStopTime = '24:00';
    }

    this.stopTime = newStopTime;

    // no error, allow saving if the start time is also valid
    this.setState({
      invalidStopTime: false,
    });
  };

  onClosePopover = () => {
    this.setState({
      showDetails: false,
    });

    this.startTime = null;
    this.stopTime = null;
  };

  onSave = () => {
    const {
      id,
    } = this.props;

    document.dispatchEvent(new CustomEvent('onChangeItemDuration', {
      detail: {
        type: 'Message Block',
        startTime: this.startTime,
        stopTime: this.stopTime,
        _uniqueId: id,
      },
    }));

    this.onClosePopover();
  };

  toggleActive = () => {
    this.setState((state) => {
      return {
        showDetails: !state.showDetails,
      };
    });
  };

  render() {
    const {
      messageBlock,
      startTime,
      stopTime,
      minStartTime,
      maxStopTime,
      isHover,
      locationId,
      companyId,
      archived,
      day,
      readOnly,
    } = this.props;

    const {
      showDetails,
      invalidStartTime,
      invalidStopTime,
    } = this.state;

    const {
      itemRef: {
        current: itemRef,
      },
    } = this;

    const itemStart = TimeUtil.convertToTwentyFourHour({
      time: startTime,
    });

    const itemStop = TimeUtil.convertToTwentyFourHour({
      time: stopTime,
    });

    const messages = get(messageBlock, 'messages', []);

    if (messages.length > 0 && !messages[0].expired) {
      for (let y = 0; y < messages.length; y++) {
        const message = messages[y];
        messages[y] = new MessageModel(message);
      }
    }

    if (isHover) {
      return (
        <div className="MessageBlock is-hover">
          {archived && (
            <ArchivedBanner />
          )}

          <div className="item-container">
            <div className="block-span">
              {itemStart} - {itemStop}
            </div>

            <div className="block-info">
              <MessageBlockCard
                message={messageBlock}
                locationId={locationId}
                companyId={companyId}
                width="auto"
                height={373}
                day={day}
                withoutCardStyle
              />
            </div>
          </div>
        </div>
      );
    }

    const itemRefRect = itemRef
      ? itemRef.getBoundingClientRect()
      : { top: 0, left: 0 };

    const top = itemRefRect.top - 20;
    const left = itemRefRect.left + (itemRefRect.width / 2);

    return (
      <div className={classNames('MessageBlock', { active: showDetails, archived })}>
        {!readOnly && (
          <VibeIcon
            className="block-remove"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={16}
            onClick={this.onRemove}
          />
        )}

        <div
          className="item-container"
          ref={this.itemRef}
          onClick={!readOnly
            ? this.toggleActive
            : null}
        >
          <div className="block-span">
            {itemStart} - {itemStop}
          </div>

          <div className="block-info">
            <MessageBlockCard
              message={messageBlock}
              locationId={locationId}
              companyId={companyId}
              width="auto"
              height="100%"
              day={day}
              withoutCardStyle
            />
          </div>
        </div>

        <Popover
          anchorReference="anchorPosition"
          anchorPosition={{
            top,
            left,
          }}
          open={showDetails}
          onClose={this.onClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          classes={{
            root: 'ItemPopperRoot',
            paper: 'ItemPopper',
          }}
        >
          <div className="item-details-container">
            <div className="item-details">
              <div className="details-toolbar">
                <VibeIcon
                  icon={viClose}
                  color={color.manatee}
                  hoverColor={color.obsidian}
                  onClick={!readOnly
                    ? this.toggleActive
                    : null}
                />
              </div>

              <div className="time-fields">
                <div className="start-time">
                  <Field
                    type="time"
                    label="Start Time"
                    placeholder="9:00am"
                    value={startTime}
                    tabIndex={1}
                    timeProps={{
                      // prevent overlapping items in the schedule
                      min: minStartTime,
                      max: stopTime,
                    }}
                    onChange={this.onChangeStartTime}
                  />
                </div>
                <div className="stop-time">
                  <Field
                    type="time"
                    label="Stop Time"
                    placeholder="9:00am"
                    value={stopTime}
                    tabIndex={2}
                    timeProps={{
                      min: startTime,
                      // prevent overlapping items in the schedule
                      max: maxStopTime,
                      endOfDay: true,
                    }}
                    onChange={this.onChangeStopTime}
                  />
                </div>
              </div>

              <div
                style={{
                  marginTop: 8,
                  textAlign: 'center',
                }}
              >
                <VibeButton
                  text="Save"
                  color="secondary"
                  disabled={invalidStartTime || invalidStopTime}
                  onClick={this.onSave}
                />
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

MessageBlock.propTypes = {
  /** Unique ID in the TimeSlot */
  id: PropTypes.string,
  messageBlock: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string,
    messageCount: PropTypes.number,
    messages: PropTypes.arrayOf(PropTypes.shape({
      durationSeconds: PropTypes.number,
      messageId: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    createdDate: PropTypes.string,
  }).isRequired,
  startTime: PropTypes.string,
  stopTime: PropTypes.string,
  /** Prevent starting before */
  minStartTime: PropTypes.string,
  /** Prevent stopping after */
  maxStopTime: PropTypes.string,
  locationId: PropTypes.string,
  companyId: PropTypes.string,
  archived: PropTypes.bool,
  /** Day of the week the message block is being shown */
  day: PropTypes.string,
  /** When the item is inside a hover popover */
  isHover: PropTypes.bool,
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
};

MessageBlock.defaultProps = {
  id: null,
  startTime: '',
  stopTime: '',
  minStartTime: '',
  maxStopTime: '',
  locationId: '*',
  companyId: '*',
  archived: false,
  day: null,
  isHover: false,
  readOnly: false,
};

export default MessageBlock;
