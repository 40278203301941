import React, { Component } from 'react';
import { Lock } from '@mui/icons-material';
import {
  VibeButton,
} from 'vibeguide';
import PasswordRequirements from '../Shared/Password/PasswordRequirements';

class FirstTimeLoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      isPasswordFocus: false,
    };
  }

  onPasswordChange = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const {
      passwordValidateChange,
    } = this.props;

    this.setState({
      password: value,
    });

    passwordValidateChange(e);
  };

  onFocusPassword = () => {
    this.setState({
      isPasswordFocus: true,
    });
  };

  onBlurPassword = () => {
    this.setState({
      isPasswordFocus: false,
    });
  };

  onKeyDown = (e) => {
    const {
      onFirstTimeChangePassword,
    } = this.props;

    if (e.key === 'Enter') {
      onFirstTimeChangePassword(e);
    }
  };

  render() {
    const {
      onFirstTimeChangePassword,
      errors,
    } = this.props;

    const {
      password,
      isPasswordFocus,
    } = this.state;

    return (
      <div className="ResetPasswordForm">
        <div className="form-title">
          First time logging in?

          <div className="form-subtitle">
            Please provide a new password.
          </div>
        </div>

        <form>
          <div className={`input-container ${isPasswordFocus ? 'active' : ''}`}>
            <input
              id="first_time_password"
              className="login-input"
              type="password"
              placeholder="New Password"
              value={password}
              onChange={this.onPasswordChange}
              onKeyDown={this.onKeyDown}
              onFocus={this.onFocusPassword}
              onBlur={this.onBlurPassword}
            />

            <Lock className="login-icon password-icon" />
          </div>
        </form>

        <PasswordRequirements errors={errors} />

        <VibeButton
          style={{
            width: '100%',
          }}
          text="Reset Password"
          color="primary"
          disabled={errors.errors}
          onClick={onFirstTimeChangePassword}
        />
      </div>
    );
  }
}

export default FirstTimeLoginForm;
