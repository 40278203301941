import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
  isEmpty,
} from 'lodash';
import {
  MimeTypeHelper,
  Field2 as Field,
  PlayControlPlayer,
  VibeIcon,
  viUpload,
  color,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import './Upload.scss';

function Upload({
  required,
  onUpdate,
}) {
  const [uploadItem, setUploadItem] = useState({
    file: {},
    data: null,
  });

  const onDrop = (fileObj) => {
    const file = get(fileObj, '[0]', {
      file: null,
    });

    if (file && file.error) {
      console.warn('File has an error', fileObj);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const {
        target: {
          result,
        },
      } = e;

      // Save file data to state to show as a preview
      setUploadItem({
        file: file.file,
        data: result,
      });

      onUpdate({
        file: file.file,
        dueDate: moment().endOf('day').format('YYYY-MM-DD'),
      });
    };

    reader.onabort = () => console.error('file reading was aborted');
    reader.onerror = () => console.error('file reading has failed');

    reader.readAsDataURL(file.file);
  };

  const hasUploadFile = !isEmpty(uploadItem.file);
  const disabled = false;
  let placeholderHtml;

  if (hasUploadFile) {
    placeholderHtml = (
      <div className="upload-container">
        <div className="left-col">
          <PlayControlPlayer
            src={uploadItem.data}
          />

          <div className="upload-name">
            {get(uploadItem, 'file.name', 'Unknown')}
          </div>
        </div>

        <div className="right-col">
          <VibeIcon
            icon={viUpload}
            color={color.primary}
            size={16}
          />

          <div className="upload-text">
            REPLACE FILE
          </div>
          {required && <span className="field-dot" />}
        </div>
      </div>
    );
  } else {
    placeholderHtml = (
      <div className="upload-container">
        <div className="left-col">
          <VibeIcon
            icon={viUpload}
            color={color.primary}
            size={16}
          />

          <div className="upload-text">
            UPLOAD AUDIO FILE
          </div>
          {required && <span className="field-dot" />}
        </div>

        <div className="right-col">
          Must import an audio file
        </div>
      </div>
    );
  }

  return (
    <div className="Upload">
      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid xs={12} item>
          <Field
            type="dropzone"
            dropzoneProps={{
              type: 'document',
              style: {
                height: 48,
                minHeight: 'auto',
              },
              placeholder: (
                <div className={classNames('upload-main', { disabled })}>
                  {placeholderHtml}
                </div>
              ),
              allowUpload: true,
              mimeTypes: MimeTypeHelper.music,
            }}
            onDrop={onDrop}
          />
        </Grid>
      </Grid>
    </div>
  );
}

Upload.propTypes = {
  required: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Upload.defaultProps = {
  required: false,
  onUpdate: () => {},
};

export default Upload;
