import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  useNavigate,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {
  AdProviderHelper,
  ProgrammaticBuyerHelper,
  Field2 as Field,
  viArrowOutward,
  color,
} from 'vibeguide';

function OrderInformation({
  className,
  style,
  opportunityName,
  opportunityId,
  dealId,
  extReferenceId,
  agency,
  deliverySystem,
  adProvider,
  advertiserName,
  programmaticBuyer,
  orderStartDate,
  orderEndDate,
  orderCancellationDate,
  orderStatus,
}) {
  const history = useNavigate();

  const titleStyle = {
    fontSize: 16,
    fontWeight: 'bold',
    color: color.secondary,
  };

  /**
   * When the user clicks to view all orders with an Opportunity ID
   */
  const onClickCustomButtonOpportunityId = () => {
    history(`/ad-ops/orders/all?opportunityId=${opportunityId}`);
  };

  /**
   * When the user clicks to view all orders with a Deal ID
   */
  const onClickCustomButtonDealId = () => {
    history(`/ad-ops/orders/all?dealId=${dealId}`);
  };

  return (
    <div
      className={classNames('OrderInformation', className)}
      style={style}
    >
      <Grid spacing={4} container>
        {/* Tracking & Reporting */}
        <Grid xs={6} item>
          <Grid spacing={2} container>
            <Grid xs={12} item>
              <div
                style={titleStyle}
              >
                Tracking &amp; Reporting
              </div>
            </Grid>

            <Grid xs={12} item>
              <Field
                type="text"
                label="Opportunity Name"
                name="opportunityName"
                placeholder="Opportunity Name"
                value={opportunityName}
                disabled
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="text"
                textProps={{
                  customButton: {
                    icon: viArrowOutward,
                    tooltip: opportunityId
                      ? 'View all orders with this Opportunity ID'
                      : '',
                    disabled: !opportunityId,
                    onClick: onClickCustomButtonOpportunityId,
                  },
                  helpText: 'Multiple orders can be tied to one Opportunity ID.',
                  helpTextStyle: {
                    color: color.gray500,
                  },
                }}
                label="Opportunity Salesforce ID"
                name="opportunityId"
                placeholder="Opportunity ID"
                value={opportunityId}
                disabled
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="text"
                textProps={{
                  customButton: {
                    icon: viArrowOutward,
                    tooltip: dealId
                      ? 'View all orders with this Deal ID'
                      : '',
                    disabled: !dealId,
                    onClick: onClickCustomButtonDealId,
                  },
                  helpText: 'Multiple orders can be tied to one Deal ID.',
                  helpTextStyle: {
                    color: color.gray500,
                  },
                }}
                label="Deal ID"
                name="dealId"
                placeholder="Deal ID"
                value={dealId}
                disabled
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="text"
                label="Order Reference ID"
                name="extReferenceId"
                placeholder="Order Reference ID"
                value={extReferenceId}
                disabled
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="text"
                label="Agency"
                name="agency"
                placeholder="Agency"
                value={agency}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Delivery */}
        <Grid xs={6} item>
          <Grid spacing={2} container>
            <Grid xs={12} item>
              <div
                style={titleStyle}
              >
                Delivery
              </div>
            </Grid>

            <Grid xs={6} item>
              <Field
                type="datepicker"
                label="Start Date"
                placeholder="YYYY-MM-DD"
                value={orderStartDate}
                style={{
                  width: '100%',
                }}
                dateProps={{
                  format: 'YYYY-MM-DD',
                  tooltipDate: moment(orderStartDate)
                    .startOf('day')
                    .format('YYYY-MM-DD [at] HH:mm:ss'),
                }}
                disabled
              />
            </Grid>
            <Grid xs={6} item>
              <Field
                type="datepicker"
                label="End Date"
                placeholder="YYYY-MM-DD"
                value={orderEndDate}
                dateProps={{
                  format: 'YYYY-MM-DD',
                  tooltipDate: moment(orderEndDate)
                    .subtract(1, 'second')
                    .format('YYYY-MM-DD [at] HH:mm:ss'),
                }}
                disabled
              />
            </Grid>

            {orderStatus === 'cancelled' && (
              <Grid
                xs={12}
                item
              >
                <div
                  style={{ width: 'calc(50% - 8px)' }}
                >
                  <Field
                    type="datepicker"
                    label="Cancelled Date"
                    placeholder="YYYY-MM-DD"
                    value={moment(orderCancellationDate)
                      .format('YYYY-MM-DD')}
                    dateProps={{
                      format: 'YYYY-MM-DD',
                      tooltipDate: moment(orderCancellationDate)
                        .format('YYYY-MM-DD [at] HH:mm:ss'),
                    }}
                    disabled
                  />
                </div>
              </Grid>
            )}

            <Grid xs={6} item>
              <Field
                type="dropdown"
                label="Delivery System"
                name="deliverySystem"
                value={deliverySystem}
                dropdownProps={{
                  type: 'delivery system',
                  attr: 'value',
                }}
                disabled
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="select"
                label="Ad Provider"
                name="adProvider"
                value={adProvider}
                options={[
                  {
                    label: '',
                    value: '',
                  },
                  ...AdProviderHelper.list.map((adProvider) => {
                    return {
                      label: adProvider.name,
                      value: adProvider.value,
                    };
                  }),
                ]}
                disabled
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="dropdown"
                label="Advertiser"
                name="advertiser"
                value={advertiserName}
                dropdownProps={{
                  type: 'advertiser',
                  attr: 'name',
                }}
                disabled
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="select"
                label="Programmatic Buyer"
                name="programmaticBuyer"
                value={programmaticBuyer}
                options={ProgrammaticBuyerHelper.list({ includeEmpty: true })}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

OrderInformation.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  opportunityName: PropTypes.string,
  opportunityId: PropTypes.string,
  dealId: PropTypes.string,
  extReferenceId: PropTypes.string,
  agency: PropTypes.string,
  deliverySystem: PropTypes.string,
  adProvider: PropTypes.string,
  advertiserName: PropTypes.string,
  programmaticBuyer: PropTypes.string,
  orderStartDate: PropTypes.string,
  orderEndDate: PropTypes.string,
  orderCancellationDate: PropTypes.string,
  orderStatus: PropTypes.string,
};

OrderInformation.defaultProps = {
  className: '',
  style: {},
  opportunityName: '',
  opportunityId: '',
  dealId: '',
  extReferenceId: '',
  agency: '',
  deliverySystem: '',
  adProvider: '',
  advertiserName: '',
  programmaticBuyer: '',
  orderStartDate: '',
  orderEndDate: '',
  orderCancellationDate: '',
  orderStatus: '',
};

export default OrderInformation;
