import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  SpotTypeHelper,
  VibeCheckbox,
  color,
} from 'vibeguide';

function PartnerSpotTypes({
  className,
  style,
  spotTypes,
  disableInput,
  onUpdate,
}) {
  const allSpotTypes = SpotTypeHelper.list();

  const onChange = ({
    name,
    checked,
  }) => {
    if (checked) {
      // add the spot type to the list of partner spot types
      onUpdate({
        spotTypes: [
          ...spotTypes,
          name,
        ],
      });
    } else {
      // remove the spot type from the list of partner spot types
      onUpdate({
        spotTypes: spotTypes.filter(spotType => spotType !== name),
      });
    }
  };

  return (
    <div
      className={classNames('PartnerSpotTypes', className)}
      style={style}
    >
      {allSpotTypes.map(spotType => (
        <VibeCheckbox
          key={`spot-type-${spotType.value}`}
          name={spotType.value}
          label={spotType.label}
          tooltip={spotType.description}
          size={16}
          color={color.primary}
          checked={spotTypes.includes(spotType.value)}
          disabled={disableInput}
          onChange={onChange}
        />
      ))}
    </div>
  );
}

PartnerSpotTypes.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  spotTypes: PropTypes.arrayOf(PropTypes.string),
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

PartnerSpotTypes.defaultProps = {
  className: '',
  style: {},
  spotTypes: [],
  disableInput: false,
  onUpdate: () => {},
};

export default PartnerSpotTypes;
