import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  SongSelection,
  GlobalActions,
  VibeIcon,
  VibeButton,
  viClose,
  viAdd,
  color,
} from 'vibeguide';
import './PlaylistSongs.scss';

class PlaylistSongs extends PureComponent {
  constructor(props) {
    super(props);

    const {
      songs,
    } = props;

    this.state = {
      assignedSongs: songs,
    };
  }

  /**
   * When the songs are changed
   */
  onChange = (songs) => {
    const {
      onUpdate,
    } = this.props;

    this.setState({
      assignedSongs: songs,
    });

    onUpdate({
      musicItems: songs,
      musicItemsCount: songs.length,
    });
  };

  /**
   * When the Songs assigned is clicked
   */
  onToggleAddSongs = () => {
    const {
      setPanel,
      onCloseAvailable,
    } = this.props;

    const {
      assignedSongs,
    } = this.state;

    setPanel({
      thirdPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <SongSelection
            selected={assignedSongs}
            customToolbar={(
              <VibeIcon
                className="close"
                icon={viClose}
                color={color.white}
                hoverColor={color.white}
                size={24}
                onClick={onCloseAvailable}
              />
            )}
            assigning
            allowChanges
            onChange={this.onChange}
          />
        ),
      },
    });
  };

  render() {
    const {
      averageDurationSeconds,
      playlistId,
      allowChanges,
      onCloseAssigned,
    } = this.props;

    const {
      assignedSongs,
    } = this.state;

    return (
      <div className="PlaylistSongs">
        <SongSelection
          selected={assignedSongs}
          customToolbar={(
            <div className="assigned-toolbar">
              {allowChanges && (
                <VibeButton
                  text="Assign Songs"
                  color="primary"
                  icon={viAdd}
                  onClick={this.onToggleAddSongs}
                />
              )}

              <VibeIcon
                className="close"
                icon={viClose}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={24}
                onClick={onCloseAssigned}
              />
            </div>
          )}
          playlistId={playlistId}
          averageDurationSeconds={averageDurationSeconds}
          allowChanges={allowChanges}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

PlaylistSongs.propTypes = {
  /* Playlist Id */
  playlistId: PropTypes.string,
  songs: PropTypes.arrayOf(PropTypes.shape),
  averageDurationSeconds: PropTypes.number,
  /** Allow changes to the selection */
  allowChanges: PropTypes.bool,
  /** When the assigned songs panel is closed */
  onCloseAssigned: PropTypes.func,
  /** When the available songs panel is closed */
  onCloseAvailable: PropTypes.func,
  onUpdate: PropTypes.func,
};

PlaylistSongs.defaultProps = {
  playlistId: '',
  songs: [],
  averageDurationSeconds: 0,
  allowChanges: false,
  onCloseAssigned: () => {},
  onCloseAvailable: () => {},
  onUpdate: () => {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(PlaylistSongs);
