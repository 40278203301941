import get from 'lodash/get';
import omit from 'lodash/omit';
import uniqueId from 'lodash/uniqueId';
import Company from '../../models/ExpandedLocations/Company';
import Http from '../Http';

const baseUrl = `${process.env.VAPI_BASE_URL}/partners`;

export default {
  list: async (data, options) => {
    const response = await Http.post(`${baseUrl}/search`, data, options);

    return options?.headers?.Accept === 'text/csv'
      ? response
      : {
        ...response,
        data: response.data.map(item => {
          return {
            ...item,
            imageUrl: Http.cloudfront(item.imageUrl),
          };
        }),
      };
  },
  get: async (id, options = {}) => {
    const partner = await Http.get(`${baseUrl}/${id}`, options);
    const reports = get(partner, 'tableauReporting.reports', []) || [];

    if (reports.length <= 0) {
      // ensure at least one report exists to display
      reports.push({
        name: '',
        url: '',
      });
    }

    // add a unique ID to each report
    reports.forEach(report => {
      report._id = uniqueId('report-');
    });

    // map partner access to assigned partners (for table column matching)
    const partnerAccess = get(partner, 'partnerAccess', []) || [];
    partner.partnersAssigned = partnerAccess.map((partner) => {
      return {
        _id: partner.partnerId,
        name: partner.partnerName,
        sensitiveData: partner.sensitiveData,
        active: true,
      };
    });

    return {
      ...partner,
      imageUrl: Http.cloudfront(partner.imageUrl),
    };
  },
  getCompanies: (data, options) => {
    // get the partner ID from the fetch locations filters, or use the standard _id
    const partnerId = get(data, 'filters.partnerId') ?? data._id;
    // remove userId/partnerId from data sent to the API
    const postData = omit(data, ['_id', 'filters.partnerId']);
    return Http.post(`${baseUrl}/${partnerId}/companies`, postData, options);
  },
  getLocations: (data, options) => {
    // get the partner ID from the fetch locations filters, or use the standard _id
    const partnerId = get(data, 'filters.partnerId') ?? data._id;
    // remove userId/partnerId from data sent to the API
    const postData = omit(data, ['_id', 'filters.partnerId']);
    return Http.post(`${baseUrl}/${partnerId}/locations`, postData, options);
  },
  getExpandedLocations: async (data, options) => {
    // get the partner ID from the fetch locations filters, or use the standard _id
    const partnerId = get(data, 'filters.partnerId') ?? data._id;
    // remove userId/partnerId from data sent to the API
    const postData = omit(data, ['_id', 'filters.partnerId']);
    const response = await Http.post(`${baseUrl}/${partnerId}/locations/expanded`, postData, options) ?? [];
    const companies = response.map(item => {
      const locations = item.locations ?? [];

      return {
        ...item,
        imageUrl: Http.cloudfront(item.imageUrl),
        locations: locations.map(location => {
          return {
            ...location,
            imageUrl: Http.cloudfront(location.imageUrl),
          };
        }),
      };
    });

    return companies.map(company => new Company(company));
  },
  getAdConfig: (data, options) => Http.get(`${baseUrl}/${data._id}/adConfig`, options),
  create: data => Http.post(baseUrl, data),
  modify: (data) => Http.put(`${baseUrl}/${data._id}`, data),
  uploadImage: (id, file) => Http.postFileUpload(`${baseUrl}/${id}/image`, file, 'image'),
  reactivate: (data, options) => Http.post(`${baseUrl}/${data._id}`, data, options),
  deactivate: (id) => Http.delete(`${baseUrl}/${id}`),

  // Partner Reports
  Reports: {
    list: async (id, options) => {
      const response = await Http.get(`${baseUrl}/${id}/reports`, options) || {};
      return get(response, 'reports', []) || [];
    },
    token: (id, options) => Http.get(`${baseUrl}/${id}/reports/token`, options),
  },
};
