import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Star,
} from '@mui/icons-material';
import CompanyLocations from './CompanyLocations';
import SwapButton from '../SwapButton/SwapButton';
import defaultImage from '../../assets/ism-avatar.png';
import './CompanyItem.scss';

class CompanyItem extends Component {
  constructor(props) {
    super(props);

    const {
      assign,
    } = props;

    this.headerRef = React.createRef();

    this.state = {
      isExpanded: !assign,
    };
  }

  // componentDidUpdate() {
  //   const {
  //     allowExpand,
  //   } = this.props;

  //   const {
  //     isExpanded,
  //   } = this.state;

  //   if (!allowExpand && isExpanded) {
  //     // This company can no longer be expanded
  //     console.warn('Looks like this causes a memory leak');
  //     this.setState({
  //       isExpanded: false,
  //     });
  //   }
  // }

  /**
   * Assign/Unassign the entire company (and locations)
   */
  onClickAssignCompany = () => {
    const {
      company,
      assign,
      onSelectLocation,
      onDeselectLocation,
    } = this.props;

    const locationData = {
      companyId: company._id,
      locationId: '*',
      company,
    };

    if (assign) {
      onSelectLocation(locationData);
    } else {
      onDeselectLocation(locationData);
    }
  };

  toggleExpand = () => {
    this.setState((state) => {
      return {
        isExpanded: !state.isExpanded,
      };
    });
  };

  render() {
    const {
      company,
      companyLocations,
      selectedLocations,
      selected,
      assign,
      allowChanges,
      allowExpand,
      allowAssignCompany,
      onSelectLocation,
      onDeselectLocation,
    } = this.props;

    const {
      isExpanded: stateIsExpanded,
    } = this.state;

    const {
      headerRef: {
        current: headerRef,
      },
    } = this;

    const isExpanded = allowExpand && stateIsExpanded;

    // Is all locations of the company chosen (ACAF)
    const isCompanyAllLocations = !allowExpand && !assign;
    // Number of assigned locations for the assigned company
    const locationCount = companyLocations.length;

    // Get header item width
    const headerWidth = (company.sticky || company.fixToBottom) && headerRef
      ? headerRef.getBoundingClientRect().width - 20
      : 'auto';

    return (
      <div className={classNames('CompanyItem', { selected })}>
        <div
          ref={this.headerRef}
          className="header-container"
        >
          <div
            className={classNames('company-item-header', {
              sticky: company.sticky && isExpanded,
              'fix-bottom': company.fixToBottom && isExpanded,
            })}
            style={{
              width: headerWidth,
            }}
          >
            <div className="company-image-container">
              <img
                src={company.imageUrl || defaultImage}
                className="company-image"
                alt={company.name}
              />
            </div>

            <div className="company-name">
              {company.name}

              {!assign ? (
                <div className="location-count">
                  {isCompanyAllLocations ? (
                    <span>
                      All Locations
                    </span>
                  ) : (
                    <span>
                      {locationCount} {locationCount === 1 ? 'Location' : 'Locations'}
                    </span>
                  )}
                </div>
              ) : null}
            </div>

            {allowChanges && !selected && allowAssignCompany ? (
              <SwapButton
                text={`${assign ? 'Assign' : 'Unassign'} Company`}
                arrowPlacement={assign ? 'right' : 'left'}
                arrowPosition={assign ? 'right' : 'left'}
                onClick={this.onClickAssignCompany}
              />
            ) : null}

            {allowExpand ? (
              <div
                className="expand-company"
                onClick={this.toggleExpand}
              >
                {isExpanded ? (
                  <KeyboardArrowUp className="expand-collapse-icon" />
                ) : (
                  <KeyboardArrowDown className="expand-collapse-icon" />
                )}
              </div>
            ) : null}

            {isCompanyAllLocations ? (
              <div className="star-company">
                <Star className="star-icon" />
              </div>
            ) : null}
          </div>
        </div>

        {isExpanded ? (
          <CompanyLocations
            company={company}
            assign={assign}
            allowChanges={allowChanges}
            companyLocations={companyLocations}
            selectedLocations={selectedLocations}
            onSelectLocation={onSelectLocation}
            onDeselectLocation={onDeselectLocation}
          />
        ) : null}
      </div>
    );
  }
}

CompanyItem.propTypes = {
  /** Company data */
  company: PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    category: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    // Is the company scroll in a spot where it should be made sticky?
    sticky: PropTypes.bool,
    // Is the company header fixed to the bottom of its container?
    fixToBottom: PropTypes.bool,
  }).isRequired,
  /** Company locations (Use only when showing currently assigned locations) */
  companyLocations: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    city: PropTypes.string,
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.string,
  })),
  /** Selected locations */
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({
    companyId: PropTypes.string,
    locationId: PropTypes.string,
  })),
  /** Is the company selected */
  selected: PropTypes.bool,
  /** Assign the company */
  assign: PropTypes.bool,
  /** Allow selection changes */
  allowChanges: PropTypes.bool,
  /** Allow the company to expand its locations */
  allowExpand: PropTypes.bool,
  /** Allow companies to be assigned */
  allowAssignCompany: PropTypes.bool,
  /** When a location is selected */
  onSelectLocation: PropTypes.func,
  /** Whe na location is deselected */
  onDeselectLocation: PropTypes.func,
};

CompanyItem.defaultProps = {
  companyLocations: [],
  selectedLocations: [],
  selected: false,
  assign: false,
  allowChanges: false,
  allowExpand: false,
  allowAssignCompany: false,
  onSelectLocation: () => {},
  onDeselectLocation: () => {},
};

export default CompanyItem;
