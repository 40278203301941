import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  API,
  SidePanelContainer,
  SidePanelContent,
  SidePanelFooter,
  VibeCheckbox,
  VibeSplash,
  VibeButton,
  color,
} from 'vibeguide';
import AlertEmailSettings from './AlertEmailSettings';

class AlertAudioConfig extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      configFetched: false,
      deviceUnplugged: false,
      emailFlaggedLocations: false,
      recipients: [],
      suppressNotificationMinutes: 0,
    };
  }

  componentDidMount() {
    this.getConfig();
  }

  /**
   * When the email settings are changed
   */
  onUpdateEmailSettings = (data) => {
    this.setState(data);
  };

  onChangeSelect = ({
    name,
    checked,
  }) => {
    this.setState({
      [name]: checked,
    });
  };

  getConfig = async () => {
    const config = await API.Location.Alerts.Audio.get();

    this.setState({
      configFetched: true,
      deviceUnplugged: get(config, 'disconnected.active', false),
      emailFlaggedLocations: get(config, 'emailNotification.emailFlaggedLocations', false),
      recipients: get(config, 'emailNotification.recipients', []),
      suppressNotificationMinutes: get(config, 'suppressNotificationMinutes', 0),
    });
  };

  save = async () => {
    const {
      onToast,
    } = this.props;

    const {
      deviceUnplugged,
      emailFlaggedLocations,
      recipients,
      suppressNotificationMinutes,
    } = this.state;

    const data = {
      disconnected: {
        active: deviceUnplugged,
      },
      emailNotification: {
        emailFlaggedLocations,
        recipients,
      },
      suppressNotificationMinutes,
    };

    onToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const saveResponse = await API.Location.Alerts.Audio.update(data);

    if (get(saveResponse, '[0].type') !== 'SYSTEMSETTING.UPDATED') {
      onToast({
        type: 'error',
        title: 'Error updating setting',
        timeout: 10,
        allowClose: true,
      });

      return;
    }

    onToast({
      type: 'success',
      title: 'Saved!',
      allowClose: true,
      delay: 500,
    });
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      configFetched,
      deviceUnplugged,
      emailFlaggedLocations,
      recipients,
      suppressNotificationMinutes,
    } = this.state;

    if (!configFetched) {
      return (
        <VibeSplash
          type="loading"
          size={64}
          style={{
            color: color.primary,
          }}
        />
      );
    }

    return (
      <SidePanelContainer className={classNames('AlertSidebarConfig', 'AlertAudioConfig', className)}>
        <SidePanelContent>
          <div className="section">
            <div className="section-title">
              Send alert when:
            </div>

            <div className="section-content">
              <div className="setting">
                <VibeCheckbox
                  name="deviceUnplugged"
                  label="Device is unplugged"
                  size={16}
                  color={color.success}
                  checked={deviceUnplugged}
                  onChange={this.onChangeSelect}
                />
              </div>
            </div>
          </div>

          <AlertEmailSettings
            emailFlaggedLocations={emailFlaggedLocations}
            recipients={recipients}
            suppressNotificationMinutes={suppressNotificationMinutes}
            onUpdate={this.onUpdateEmailSettings}
          />
        </SidePanelContent>

        <SidePanelFooter>
          <VibeButton
            text="Save Changes"
            color="secondary"
            onClick={this.save}
          />
        </SidePanelFooter>
      </SidePanelContainer>
    );
  }
}

AlertAudioConfig.propTypes = {
  className: PropTypes.string,
  onToast: PropTypes.func,
};

AlertAudioConfig.defaultProps = {
  className: '',
  onToast: () => {},
};

export default AlertAudioConfig;
