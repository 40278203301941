import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProfileContent from '../../../Locations/IndividualLocation/EditLocation/Profile/ProfileContent';
import TokenTable from './TokenTable/TokenTable';

function IntegrationToken({
  user,
  className,
}) {
  return (
    <div className={classNames('IntegrationToken', className)}>
      <ProfileContent
        style={{
          padding: 0,
          userSelect: 'auto',
        }}
      >
        <TokenTable user={user} />
      </ProfileContent>
    </div>
  );
}

IntegrationToken.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
};

IntegrationToken.defaultProps = {
  className: '',
  user: {},
};

export default IntegrationToken;
