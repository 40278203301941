import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEmpty,
  isEqual,
  sortBy,
  capitalize,
} from 'lodash';
import Popper from '@mui/material/Popper';
// import API from '../../api';
import Avatar from '../Avatar/Avatar';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeIcon from '../VibeIcon/VibeIcon';
import viCloseCircle from '../../icons/viCloseCircle';
import color from '../../sass/color.scss';
import './DropdownSelector.scss';

let onBlurTimeout = null;

/**
 * Store the previous values to compare on prop updates
 */
function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

function DropdownSelector({
  type,
  attr,
  collection,
  imageAttr,
  defaultImage,
  name,
  value,
  placeholder,
  style,
  inputStyle,
  companyId,
  disabled,
  tabIndex,
  autoFocus,
  multipleValues,
  canCreate,
  canCreateCustomItem,
  blockBlur,
  customItem,
  customHTML,
  successMessage,
  forceSelect,
  // focusAfterSelect,
  filters,
  fetch,
  fetchData,
  createData,
  create,
  onClick,
  onChange,
  onBlur,
  onFocus,
}) {
  const inputRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);

  // this field has multiple values
  const values = (value || '').split(',');
  const hasMultipleValues = multipleValues && values.length > 1;

  /**
   * Open the autocomplete menu
   */
  const onOpen = (useItems) => {
    setItems(useItems);
    setSelectedIndex(0);
    setOpen(true);
    setVisible(true);
  };

  /**
   * Close the autocomplete menu
   */
  const onClose = () => {
    setVisible(false);

    // Ensure blur fires after selecting an item
    clearTimeout(onBlurTimeout);
    onBlurTimeout = setTimeout(() => {
      setOpen(false);
      setItems(allItems);
      setSelectedIndex(0);
    }, 500);
  };

  /**
   * Send onChange to the parent component
   * Append the field name and if the value was changed
   * Store if the selector has a current selected item
   */
  const onChangeFieldData = ({
    id: itemId,
    value: fieldValue,
    // extra data to send back (if needed)
    data,
    changed = true,
    // was selected from the dropdown
    selected = false,
  }) => {
    // get the custom data item from the items array
    let customItemData = {};
    if (itemId === customItem._id) {
      customItemData = items.filter(item => item._id === customItem._id) || [];
    }

    onChange({
      type,
      name,
      id: itemId,
      value: fieldValue,
      data: itemId === customItem._id ? customItemData[0] : data,
      changed,
      selected,
    });

    // if (selected && focusAfterSelect) {
    //   // focus on the input field after the item was selected
    //   console.log('focus on the field');
    //   onOpen(allItems);
    //   inputRef.current.focus();
    // }
  };

  /**
   * Reset the dropdown items
   */
  const onReset = () => {
    setAllItems([]);
    setItems([]);
    setSelectedIndex(0);
  };

  /**
   * Get the value to show in the dropdown
   */
  const getValue = (item) => {
    return get(item, attr, '');
  };

  /**
   * Get the image value to show in the dropdown
   */
  const getImageValue = (item) => {
    const image = get(item, imageAttr, '');

    if (!image) {
      return defaultImage;
    }

    return image;
  };

  /**
   * Fetch all the items
   */
  const getAllItems = async () => {
    if (collection && collection.length > 0) {
      const sortedItems = sortBy(collection, attr);
      setAllItems(sortedItems);
      setItems(sortedItems);

      return sortedItems;
    }

    const data = {
      filters: {
        active: true,
        ...filters,
      },
      pageSize: -1,
      ...fetchData,
    };

    if (
      type === 'banner'
      || type === 'layout template'
      || type === 'loop template'
    ) {
      // include the Company ID for API calls
      data.companyId = companyId;
    }

    const response = await fetch(data);

    const allItems = type === 'screen resolution'
      || type === 'layout template'
      || type === 'loop template'
      || type === 'tag'
      || type === 'advertiser'
      || type === 'partner'
      || type === 'banner'
      // get the items from a paged response
      ? response.data
      // response is not paged
      : response;

    if (canCreate && !canCreateCustomItem) {
      allItems.push({
        _id: 'create',
        value: 'create',
      });
    }

    if (type === 'screen resolution') {
      // be able to search by the whole value
      // i.e. for Screen Resolutions in the Layout Templates, show the size next to the name
      // i.e. FHD - Full High Definition (1920 x 1080)
      allItems.forEach((item) => {
        item[attr] = `${item.name} (${get(item, 'size[0]', '0')} x ${get(item, 'size[1]', '0')})`;
      });
    }

    const sortedItems = sortBy(allItems, attr);

    // if canCreateCustomItem, add the custom item to the items/allItemss array
    if (canCreateCustomItem) {
      if (customItem) {
        sortedItems.unshift(customItem);
      }
    }

    setAllItems(sortedItems);
    setItems(sortedItems);

    return sortedItems;
  };

  /**
   * Fetch the details for each selected value
   */
  const getSelectedItems = async () => {
    if (!multipleValues) {
      // do not fetch each value if multiple values are not allowed
      return;
    }

    const useAllItems = allItems.length <= 0
      ? await getAllItems()
      : allItems;

    const valueData = values.map((item) => {
      const itemDetails = find(useAllItems, { [attr]: item });
      return itemDetails;
    }).filter(dataItem => dataItem !== undefined);

    setSelectedItems(valueData);

    if (valueData.length === 1) {
      // only one value selected, send back to the parent component
      const item = valueData[0];

      onChangeFieldData({
        id: item._id,
        value: getValue(item),
        // send so the parent component won't search off this change
        changed: false,
      });
    }
  };

  /**
   * Update the item arrays when the custom item data changes
   */
  const updateCustomItem = () => {
    const updatedItems = items.map((item) => {
      if (item._id === customItem._id) {
        return customItem;
      }
      return item;
    });
    const updatedAllItems = allItems.map((item) => {
      if (item._id === customItem._id) {
        return customItem;
      }
      return item;
    });

    setAllItems(updatedAllItems);
    setItems(updatedItems);
  };

  /**
   * Get Placeholder Text
   */
  const getPlaceholder = () => {
    if (!hasMultipleValues) {
      // don't use CSS text-transform or it will auto-capitalize while the user is typing
      return placeholder || `Search for a ${type.split(' ').map(word => capitalize(word)).join(' ')}...`;
    }

    return '';
  };

  useEffect(() => {
    if (value) {
      getSelectedItems();
    }
  }, [value]);

  /**
   * Update the item arrays when the custom item data changes
   */
  useEffect(() => {
    updateCustomItem();
  }, [customItem.size, customItem.value]);

  /**
   * When filters change, clear all items to fetch again using new filters
   */
  const prevFilters = usePrevious(filters);
  useEffect(() => {
    if (allItems.length > 0 && !isEqual(prevFilters, filters)) {
      setAllItems([]);
    }
  }, [filters]);

  /**
   * Listen for events
   */
  useEffect(() => {
    document.addEventListener('onResetDropdownSelector', onReset);

    return () => {
      document.removeEventListener('onResetDropdownSelector', onReset);
    };
  }, []);

  /**
   * Filter all items by a search query
   * Search by name
   */
  const filterItems = (search, useItems) => {
    if (!useItems) {
      useItems = allItems;
    }

    if (isEmpty(search)) {
      // search is empty, show all available items
      return useItems;
    }

    search = search.toLowerCase();

    // is there a selected item
    const exists = find(useItems, (item) => {
      return getValue(item).toLowerCase() === search;
    }) !== undefined;

    return useItems.filter(item => get(item, attr, '').toLowerCase().indexOf(search) >= 0
      || (!exists && item._id === 'create'));
  };

  /**
   * Create an item
   */
  const createItem = async () => {
    const data = {
      [attr]: value,
      ...createData,
    };

    if (type === 'banner') {
      // include the Company ID for company banner calls
      data.companyId = companyId;
    }

    const response = await create(data);
    const success = get(response, '[0].type') === successMessage;

    if (success) {
      // merge the created item data
      const item = {
        _id: get(response, '[0].documentId'),
        ...get(response, '[0].data', {}),
      };

      // add the created item to the list
      setAllItems(sortBy([
        ...allItems,
        item,
      ], attr));

      // select the created item
      onChangeFieldData({
        id: item._id,
        value: getValue(item),
        data: item,
        selected: true,
      });
    }
  };

  const onFocusField = async (e) => {
    const {
      target: {
        value = '',
      },
    } = e;

    // do not close the dropdown list if it's been queued
    clearTimeout(onBlurTimeout);

    const useAllItems = allItems.length <= 0
      ? await getAllItems()
      : allItems;

    // is there a selected item
    const exists = find(useAllItems, (item) => {
      return getValue(item).toLowerCase() === value.toLowerCase();
    }) !== undefined;

    if (value) {
      // if a value is in the input, filter for any matches by default
      const searchName = value.trim();
      const matchItems = filterItems(searchName, useAllItems);
      onOpen(matchItems.filter(item => item._id !== 'create' || (!exists && item._id === 'create')));
    } else {
      // input value is empty, show all items
      onOpen(useAllItems.filter(item => item._id !== 'create' || (!exists && item._id === 'create')));
    }

    onFocus(e);
  };

  const onChangeField = (e) => {
    const {
      target: {
        value = '',
      },
    } = e;

    const searchName = value.trim();
    const matchItems = filterItems(searchName);

    onOpen(matchItems);
    autoCompleteRef.current.scroll(0, 0);

    // only allow exact match assumption if we aren't requiring the user to select an item from the dropdown
    const exactMatch = !forceSelect
      && find(matchItems, { [attr]: value });

    if (exactMatch) {
      // if the search matches the item exactly, assume this is the one to select
      onChangeFieldData({
        id: exactMatch._id,
        value,
        data: exactMatch,
      });
    } else {
      // an item must be selected from the dropdown list
      onChangeFieldData({
        id: '',
        value,
      });
    }
  };

  const onBlurField = (e) => {
    // cancel blur if user is interacting with the custom item
    if (!blockBlur(e)) {
      const selectedItem = find(allItems, (item) => {
        return getValue(item).toLowerCase() === (value || '').toLowerCase();
      });

      if (!selectedItem && !isEmpty(value)) {
        // TODO: This causes an issue when creating an item. Value is cleared before it hits the createItem()
        // TODO: Also setting it behind a timeout causes issue by blanking the selection when using mouse or keyboard
        // field does not have a selected value, clear any input text
        // onChangeFieldData({
        //   id: '',
        //   value: '',
        // });
      } else if (value !== get(selectedItem, attr, '')) {
        // change the value of the field to match the casing of the selected item
        onChangeFieldData({
          id: get(selectedItem, '_id'),
          value: getValue(selectedItem),
        });
      }

      onClose();
      onBlur(e);
    }
  };

  const onKeyDownField = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const nextIndex = selectedIndex + 1;

      if (nextIndex > 2) {
        autoCompleteRef.current.scroll(0, (nextIndex * 40) - 80);
      }

      setSelectedIndex(nextIndex < items.length
        ? nextIndex
        : items.length - 1);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const prevIndex = selectedIndex - 1;
      autoCompleteRef.current.scroll(0, (prevIndex * 40) - 80);

      setSelectedIndex(prevIndex >= 0
        ? prevIndex
        : 0);
    } else if (e.key === 'Enter') {
      const item = items[selectedIndex];

      if (!item) {
        // no item found to select
        return;
      }

      onOpen([]);
      inputRef.current.blur();

      if (item._id === 'create') {
        // create the item
        createItem();
      } else {
        // change to the selected item
        onChangeFieldData({
          id: item._id,
          value: getValue(item),
          data: item,
          selected: true,
        });
      }
    }
  };

  const selectItem = (item) => {
    onChangeFieldData({
      id: item._id,
      value: getValue(item),
      data: item,
      selected: true,
    });

    onClose();
  };

  // style the input box when the autocomplete menu is open
  const inputStyleMenuOpen = visible
    ? {
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }
    : {};

  return (
    <div
      className="DropdownSelector"
      style={style}
      onClick={onClick}
    >
      <VibeTooltip
        title={hasMultipleValues ? (
          <div>
            {selectedItems.map((selectedItem) => (
              <div
                key={`selected-item-${selectedItem._id}`}
                style={{
                  padding: '4px 0',
                }}
              >
                {getValue(selectedItem)}
              </div>
            ))}
          </div>
        ) : ''}
        placement="bottom"
        arrow
      >
        <div className="input-container">
          <input
            ref={inputRef}
            tabIndex={tabIndex}
            className="field-input item-input"
            style={{
              ...inputStyle,
              ...inputStyleMenuOpen,
            }}
            type="text"
            placeholder={getPlaceholder()}
            name={name}
            value={!hasMultipleValues
              ? value
              : ''}
            onFocus={onFocusField}
            onChange={onChangeField}
            onBlur={onBlurField}
            onKeyDown={onKeyDownField}
            autoComplete="off"
            autoFocus={autoFocus}
            disabled={disabled || hasMultipleValues}
          />

          {hasMultipleValues && (
            <div className="multiple-values-container">
              <div className="multiple-values">
                <VibeIcon
                  icon={viCloseCircle}
                  color={color.primary}
                  hoverColor={color.secondary}
                  size={16}
                  style={{
                    marginRight: 4,
                  }}
                  onClick={() => onChange({ target: { value: '' } })}
                />

                <div className="text">
                  {values.length} Search Values
                </div>
              </div>
            </div>
          )}
        </div>
      </VibeTooltip>

      <Popper
        className="DropdownPopper"
        anchorEl={inputRef.current}
        open={open}
        placement="bottom-start"
        style={{
          width: get(inputRef, 'current.offsetWidth', 350),
          zIndex: 1302,
          opacity: visible
            ? 1
            : 0,
        }}
      >
        <div
          ref={autoCompleteRef}
          className="item-select"
        >
          {items.map((item, index) => {
            if (item._id === 'create') {
              return (
                <div
                  key={item._id ?? `dropdown-item-${index}`}
                  className={classNames('item', { selected: selectedIndex === index, hidden: isEmpty(value) })}
                  onClick={createItem}
                >
                  <div className="create">
                    Create:

                    <div className="create-value">
                      {value}
                    </div>
                  </div>
                </div>
              );
            }

            if (item._id === customItem._id) {
              return (
                <div
                  key={item._id ?? `dropdown-item-${index}`}
                  className={classNames('item', { selected: selectedIndex === index })}
                  data-id={item._id}
                  data-name={getValue(item)}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={selectItem.bind(this, item)}
                >
                  {customHTML || (
                    <div className="item-content">
                      {imageAttr ? (
                        <div className="item-image">
                          <Avatar
                            imageUrl={getImageValue(item)}
                            imageDefault={defaultImage}
                            text={getValue(item)}
                            tooltipProps={{
                              placement: 'left',
                              enterDelay: 500,
                            }}
                            displayText
                          />
                        </div>
                      ) : (
                        <div className="text">
                          {getValue(item)}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            }

            return (
              <div
                key={item._id ?? `dropdown-item-${index}`}
                className={classNames('item', { selected: selectedIndex === index })}
                data-id={item._id}
                data-name={getValue(item)}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={selectItem.bind(this, item)}
              >
                <div className="item-content">
                  {imageAttr ? (
                    <div className="item-image">
                      <Avatar
                        imageUrl={getImageValue(item)}
                        imageDefault={defaultImage}
                        text={getValue(item)}
                        tooltipProps={{
                          placement: 'left',
                          enterDelay: 500,
                        }}
                        displayText
                      />
                    </div>
                  ) : (
                    <div className="text">
                      {getValue(item)}
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {items.length <= 0 && !canCreate && (
            <div className="item disabled">
              Nothing found...
            </div>
          )}
        </div>
      </Popper>
    </div>
  );
}

DropdownSelector.propTypes = {
  /** Type of dropdown selector */
  type: PropTypes.string.isRequired,
  // attribute to use when showing data in the list
  attr: PropTypes.string.isRequired,
  // image attribute to show (optional)
  imageAttr: PropTypes.string,
  // default image to show when using an image
  defaultImage: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  /** Company ID for Company Banners */
  companyId: PropTypes.string,
  disabled: PropTypes.bool,
  /** Input tab index position */
  tabIndex: PropTypes.number,
  /** Auto focus on the input */
  autoFocus: PropTypes.bool,
  /** Allow multiple values for the field */
  multipleValues: PropTypes.bool,
  /** Can the user create items for this dropdown */
  canCreate: PropTypes.bool,
  canCreateCustomItem: PropTypes.bool,
  blockBlur: PropTypes.func,
  customItem: PropTypes.object,
  customHTML: PropTypes.element,
  /** API message when successfully creating an item */
  successMessage: PropTypes.string,
  /** Force the user to select an item from the dropdown instead of assuming if there is an exact match */
  forceSelect: PropTypes.bool,
  /** Focus on the field after selecting an item from the dropdown */
  // focusAfterSelect: PropTypes.bool,
  /** Filters to send with the fetch request */
  filters: PropTypes.object,
  /** Data to send with the create request */
  createData: PropTypes.object,
  fetch: PropTypes.func,
  /** Data to send with each fetch request */
  fetchData: PropTypes.object,
  /** Create item endpoint */
  create: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

DropdownSelector.defaultProps = {
  imageAttr: null,
  defaultImage: null,
  name: '',
  value: '',
  placeholder: '',
  style: {},
  inputStyle: {},
  companyId: '',
  disabled: false,
  tabIndex: 0,
  autoFocus: false,
  multipleValues: false,
  canCreate: false,
  canCreateCustomItem: false,
  blockBlur: () => { return false; },
  customItem: {},
  customHTML: null,
  successMessage: '',
  forceSelect: false,
  // focusAfterSelect: false,
  filters: {},
  createData: {},
  fetchData: {},
  fetch: () => {},
  create: () => {},
  onClick: () => {},
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

export default DropdownSelector;
