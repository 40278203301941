import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './EmptyState.scss';

function EmptyState({
  className,
  style,
  title,
  description,
  buttonHtml,
}) {
  // align the button position with the bottom of the empty state content area
  const [buttonPosition, setButtonPosition] = useState({
    bottom: 0,
    left: 0,
    marginLeft: 0,
    top: 0,
  });
  const contentRef = useRef(null);
  const buttonContainerRef = useRef(null);
  const buttonRef = useRef(null);

  /**
   * Set the button position
   */
  const setButton = () => {
    if (!buttonContainerRef
      || !buttonContainerRef.current
      || !buttonRef
      || !buttonRef.current
    ) {
      return;
    }

    const buttonContainerEl = buttonContainerRef.current.getBoundingClientRect();
    const buttonEl = buttonRef.current.getBoundingClientRect();

    // calculate the position of the button to be centered on the screen
    const left = buttonContainerEl.left - (buttonEl.width / 2);

    setButtonPosition({
      top: buttonContainerEl.y,
      left,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', setButton);

    return () => {
      window.removeEventListener('resize', setButton);
    };
  }, []);

  useEffect(() => {
    if (buttonHtml && contentRef && contentRef.current) {
      // set button position
      setButton();
    }

    window.addEventListener('resize', setButton);

    return () => {
      window.removeEventListener('resize', setButton);
    };
  }, [style.height]);

  return (
    <>
      <div
        className={classNames('EmptyState', className)}
        style={style}
      >
        <div
          ref={contentRef}
          className="empty-state-content"
        >
          <h2 className="title">
            {title}
          </h2>

          <div className="description">
            {description}
          </div>

          {buttonHtml !== null && (
            <div
              ref={buttonContainerRef}
              className="button"
            />
          )}
        </div>
      </div>

      {buttonHtml !== null && (
        <div
          ref={buttonRef}
          className="button-wrapper"
          style={{
            top: buttonPosition.top,
            left: buttonPosition.left,
          }}
        >
          {buttonHtml}
        </div>
      )}
    </>
  );
}

EmptyState.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Title */
  title: PropTypes.string.isRequired,
  /** Description */
  description: PropTypes.string.isRequired,
  /** Button HTML */
  buttonHtml: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

EmptyState.defaultProps = {
  className: '',
  style: {},
  buttonHtml: null,
};

export default EmptyState;
