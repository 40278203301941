import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  get,
  isEmpty,
} from 'lodash';
import {
  API,
  Field2 as Field,
  ProgressBar,
  ImportLocationsActions,
  VibeButton,
  VibeIcon,
  viCircleTwo,
  viCheckCircle,
  viError,
  color,
} from 'vibeguide';
import './ImportFile.scss';

class ImportFile extends PureComponent {
  constructor(props) {
    super(props);

    // When the upload completes delay showing the uploaded screen
    this.uploadCompleteTimeout = null;

    this.state = {
      uploading: false,
      uploaded: false,
      uploadProgress: 0,
      uploadFile: {},
    };
  }

  componentDidUpdate(prevProps) {
    const {
      bulkInformationConfirmed,
    } = this.props;

    const {
      bulkInformationConfirmed: prevBulkInformationConfirmed,
    } = prevProps;

    if (bulkInformationConfirmed && prevBulkInformationConfirmed === false) {
      // A new job was created, upload the file again
      this.upload();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.uploadCompleteTimeout);
  }

  onDrop = async (fileObj) => {
    const {
      createImportJob,
    } = this.props;

    const {
      uploaded,
    } = this.state;

    const file = get(fileObj, '[0]', {
      file: null,
    });

    if (file && file.error) {
      console.warn('File has an error', fileObj);
      return;
    }

    // A file has already been uploaded, create a new job
    if (uploaded) {
      await createImportJob();
    }

    // Show the upload has started
    this.setState({
      uploadFile: file.file,
    }, this.upload);
  };

  /**
   * Upload a file
   */
  upload = async () => {
    const {
      importJobId,
      setData,
    } = this.props;

    const {
      uploadFile,
    } = this.state;

    console.log('uploadFile', uploadFile);

    // No file is ready to upload
    if (isEmpty(uploadFile)) {
      return;
    }

    // Start the upload
    this.setState({
      uploading: true,
      uploaded: false,
      uploadProgress: 0,
    });

    const uploadResponse = await API.Location.Import.uploadData(importJobId, uploadFile, (progress) => {
      this.setState({
        uploadProgress: progress >= 0
          ? progress
          : 100,
      });
    });

    const uploadSuccess = get(uploadResponse, '[0].type') === 'LOCATIONIMPORT.DATA_UPLOADED';
    const data = get(uploadResponse, '[0].data');

    if (!uploadSuccess) {
      this.uploadCompleteTimeout = setTimeout(() => {
        setData({
          status: 'error',
          ...data,
        });

        this.setState({
          uploading: false,
          uploaded: true,
          uploadProgress: 0,
          uploadFile: {},
        });
      }, 1000);
    } else {
      this.uploadCompleteTimeout = setTimeout(() => {
        setData(data);

        this.setState({
          uploading: false,
          uploaded: true,
          uploadProgress: 0,
        });
      }, 1000);
    }
  };

  render() {
    const {
      user,
      status,
      bulkInformationConfirmed,
    } = this.props;

    const {
      uploading,
      uploaded,
      uploadProgress,
      uploadFile,
    } = this.state;

    const disabled = !bulkInformationConfirmed;
    let placeholderHtml;

    if (uploading) {
      placeholderHtml = (
        <div>
          Uploading <strong>{uploadFile.name}</strong>... {uploadProgress}%

          <ProgressBar
            className="upload-progress"
            progress={uploadProgress}
            color={color.success}
            height={4}
          />
        </div>
      );
    } else if (uploaded && status !== 'error') {
      placeholderHtml = (
        <div>
          <div className="upload-success">
            <VibeIcon
              className="upload-success-icon"
              icon={viCheckCircle}
              color={color.success}
              size={16}
            />

            <div>
              Uploaded <strong>{uploadFile.name}</strong> successfully!
            </div>
          </div>

          <div className="btn-container">
            <VibeButton
              className="btn-replace-file"
              variant="text"
              text="Replace File..."
              color="secondary"
            />
          </div>
        </div>
      );
    } else {
      placeholderHtml = (
        <div>
          <div className="drop-title">
            Drag and Drop or Select File
          </div>

          <div className="drop-description">
            {status === 'error' ? (
              <div className="error">
                <VibeIcon
                  className="error-icon"
                  icon={viError}
                  color={color.error}
                  size={16}
                />

                <div>
                  Import failed. Must import a CSV file. Please try again.
                </div>
              </div>
            ) : (
              <div>
                Must import a CSV file.
              </div>
            )}
          </div>

          <div className="btn-container">
            <VibeButton
              className="btn-choose-file"
              text="Choose File..."
              icon={viCircleTwo}
              iconProps={{
                size: 24,
              }}
              color="primary"
            />
          </div>
        </div>
      );
    }

    return (
      <div className="ImportFile">
        <Field
          type="dropzone"
          dropzoneProps={{
            type: 'document',
            style: {
              height: 128,
              minHeight: 'auto',
              border: uploading || uploaded
                ? `1px solid ${color.success}`
                : `1px dashed ${color.whiteSmoke}`,
            },
            dragOverStyle: {
              background: color.lightGray,
            },
            placeholder: (
              <div className={classNames({ disabled })}>
                {placeholderHtml}
              </div>
            ),
            allowUpload: user.can('location.create'),
          }}
          onDrop={this.onDrop}
        />

        {disabled ? (
          <div className="disabled-overlay" />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    importJobId: state.import.locations._id,
    status: state.import.locations.data.status,
    bulkInformationConfirmed: state.import.locations.bulkInformationConfirmed,
  };
}

const mapDispatchToProps = {
  createImportJob: ImportLocationsActions.createImportJob,
  setData: ImportLocationsActions.setData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportFile);
