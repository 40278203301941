import { createAction } from 'redux-actions';
import map from 'lodash/map';
import API from '../../api';

export const setCurrencies = createAction('SET_CURRENCIES');
export const setCurrenciesFetched = createAction('SET_CURRENCIES_FETCHED');

/**
 * Get Supported Currencies
 */
export function getCurrencies() {
  return async (dispatch, getState) => {
    const {
      currency: {
        list = [],
        fetched = false,
      },
    } = getState();

    if (list.length <= 0 && !fetched) {
      // only fetch currencies once
      dispatch(setCurrenciesFetched(true));

      const currencyListResponse = await API.Currency.list();
      const currencies = map(currencyListResponse, (currency, key) => {
        return {
          key,
          label: `${currency.symbol} ${key}`,
          ...currency,
        };
      });

      dispatch(setCurrencies(currencies));
    }
  };
}

export default {
  getCurrencies,
};
