// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VibeButton {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 12px;
  letter-spacing: 0.8px;
  user-select: none;
}
.VibeButton.disabled {
  cursor: default;
  opacity: 0.5;
}
.VibeButton > .icon-left {
  margin-right: 4px;
}
.VibeButton > .icon-right {
  margin-left: 4px;
}
.VibeButton > .text.variant-text {
  font-size: 100%;
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/VibeButton/VibeButton.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,qBAAA;EACA,iBAAA;AACF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAMI;EACE,eAAA;EACA,mBAAA;AAJN","sourcesContent":[".VibeButton {\n  cursor: pointer;\n  position: relative;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 16px;\n  border-radius: 4px;\n  box-sizing: border-box;\n  font-size: 12px;\n  letter-spacing: 0.8px;\n  user-select: none;\n\n  &.disabled {\n    cursor: default;\n    opacity: 0.5;\n  }\n\n  > .icon-left {\n    margin-right: 4px;\n  }\n\n  > .icon-right {\n    margin-left: 4px;\n  }\n\n  > .text {\n\n    &.variant-text {\n      font-size: 100%;\n      font-weight: normal;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
