import React, { Component } from 'react';
import classNames from 'classnames';
import { Navigate } from 'react-router-dom';
import {
  VibeButton,
} from 'vibeguide';
import { AccountCircle } from '@mui/icons-material';
import FormHelperText from '@mui/material/FormHelperText';

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccountFocus: false,
      redirect: false,
    };
  }

  onUsernameChange = (e) => {
    const {
      onUsernameChange,
    } = this.props;

    onUsernameChange(e);
  };

  onFocusAccount = () => {
    this.setState({
      isAccountFocus: true,
    });
  };

  onBlurAccount = () => {
    this.setState({
      isAccountFocus: false,
    });
  };

  sendActivationCode = (e) => {
    const {
      sendActivationCode,
    } = this.props;

    this.setState({
      redirect: true,
    });

    sendActivationCode(e);
  };

  render() {
    const {
      username,
      forgotPasswordError,
      forgotPasswordErrorMessage,
    } = this.props;

    const {
      isAccountFocus,
      redirect,
    } = this.state;

    if (redirect) {
      return (
        <Navigate
          to={{
            pathname: '/reset',
          }}
        />
      );
    }

    return (
      <div
        className="ForgotPasswordForm"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}
      >
        <div
          className="form-title"
          style={{
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Forgot Password?

          <div
            className="form-subtitle"
            style={{
              marginTop: 16,
              fontSize: 12,
              fontWeight: 'normal',
            }}
          >
            To reset your password, confirm your e-mail below. We will send you a code to create a new password.
          </div>
        </div>

        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
          }}
        >
          <div
            className={classNames('input-container', { active: isAccountFocus, 'has-error': forgotPasswordError })}
          >
            <input
              className="login-input"
              type="email"
              placeholder="Username"
              value={username}
              // defaultValue={username}
              onChange={this.onUsernameChange}
              onKeyDown={this.onKeyDown}
              onFocus={this.onFocusAccount}
              onBlur={this.onBlurAccount}
              autoFocus
            />

            <AccountCircle className="login-icon account-icon" />

            {forgotPasswordError && (
              <FormHelperText className="login-error">
                {forgotPasswordErrorMessage}
              </FormHelperText>
            )}
          </div>

          <VibeButton
            style={{
              width: '100%',
              height: 48,
              fontSize: 16,
            }}
            text="Send Activation Code"
            color="primary"
            disabled={!username}
            onClick={this.sendActivationCode}
          />
        </form>
      </div>
    );
  }
}

export default ForgotPasswordForm;
