import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  get,
} from 'lodash';
import {
  API,
  GlobalActions,
  NavigationHelper,
  TablePlaylists,
  VibeButton,
  viAdd,
  withRouter,
} from 'vibeguide';
import PlaylistSidebar from '../../../../Music/Playlists/Sidebar/PlaylistSidebar';
import './Playlists.scss';

class Playlists extends PureComponent {
  constructor(props) {
    super(props);

    this.sidebarTimeout = null;
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams();
    const isNew = qs.type === 'new';
    const isView = qs.playlistId && qs.type !== 'new';

    if (isNew || isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.playlistId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.playlistId || qs.type === 'new') {
        this.sidebar(qs.type, qs.playlistId);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.sidebarTimeout);
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      playlistId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  /**
   * Determine whether or not to show or hide the sidebar
   */
  sidebar = (type, playlistId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      children: (
        <PlaylistSidebar
          playlistId={playlistId}
          isNew={type === 'new'}
          isDuplicate={type === 'new' && playlistId !== null}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      currentLocation,
      user,
    } = this.props;

    const locationId = get(currentLocation, '_id', null);

    return (
      <div className="Playlists">
        <TablePlaylists
          columns={[
            '.',
            'Name',
            'Company',
            'Songs',
            'Duration',
            'Avg. Song',
            'Genre',
            'Tags',
            '...',
          ]}
          fetch={API.Location.listPlaylists}
          fetchProps={{
            filters: {
              _id: locationId,
            },
          }}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Playlists',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Playlists',
            tooltip: true,
          }}
          bulk
          bulkProps={{
            edit: false,
            add: false,
            block: false,
            remove: false,
            archive: true,
          }}
          rowLink={{
            playlistId: '_id',
          }}
          menuItems={[
            { name: 'Archive', userCan: 'playlist.delete' },
          ]}
          toolbarProps={{
            customButtons: user.can('playlist.create')
              ? [
                (
                  <VibeButton
                    key="button-new-playlist"
                    text="New Playlist"
                    color="primary"
                    icon={viAdd}
                    tooltip="New Playlist"
                    tooltipProps={{
                      placement: 'top',
                    }}
                    link={NavigationHelper.updateParams({
                      type: 'new',
                    })}
                  />
                ),
              ]
              : [],
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Playlists));
