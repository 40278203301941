import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/integrationusers`;

export default {
  list: async (data, options) => {
    const response = await Http.post(`${baseUrl}/search`, data, options);

    return {
      ...response,
      data: response?.data?.map(item => {
        return {
          ...item,
          imageUrl: Http.cloudfront(item.imageUrl),
        };
      }),
    };
  },
  getById: async (id, options) => {
    const response = await Http.get(`${baseUrl}/${id}`, options);

    return {
      ...response,
      imageUrl: Http.cloudfront(response.imageUrl),
    };
  },
  cycleToken: (id, options) => Http.post(`${baseUrl}/${id}/tokens/cycle`, options),
  deactivateToken: (id, data, options) => Http.delete(`${baseUrl}/${id}/tokens`, data, options),
  getLocations: (data, options) => Http.post(`${baseUrl}/${data._id}/locations`, data, options),
  getExpandedLocations: async (id, options) => {
    const response = await Http.get(`${baseUrl}/${id}/locations/expanded`, options) ?? [];

    return response.map(item => {
      const locations = item.locations ?? [];

      return {
        ...item,
        imageUrl: Http.cloudfront(item.imageUrl),
        locations: locations.map(location => {
          return {
            ...location,
            imageUrl: Http.cloudfront(location.imageUrl),
          };
        }),
      };
    });
  },
  create: data => Http.post(baseUrl, data),
  modify: (data) => Http.put(`${baseUrl}/${data._id}`, data),
  uploadImage: (id, file) => Http.postFileUpload(`${baseUrl}/${id}/image`, file, 'image'),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),

  // Get all users matching permissions
  Permission: {
    // match "any" permission
    getAny: (permissions, options) => {
      const data = {
        pageSize: -1,
        filters: {
          active: true,
          permissions: {
            matchType: 'any',
            permissions,
          },
        },
      };

      return Http.post(`${baseUrl}/search`, data, options);
    },
    // match "all" permissions
    getAll: (permissions, options) => {
      const data = {
        pageSize: -1,
        filters: {
          active: true,
          permissions: {
            matchType: 'all',
            permissions,
          },
        },
      };

      return Http.post(`${baseUrl}/search`, data, options);
    },
  },
};
