import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  CurrencyUtil,
  Tag,
  VibeIcon,
  viSpeaker,
  viTv,
  color,
} from 'vibeguide';
import Skeleton from '@mui/material/Skeleton';

function FlightRollupItem({
  className,
  style,
  title,
  loading,
  total,
  totalAudio,
  totalVisual,
  currencyCode,
}) {
  const localeValue = (value) => {
    if (loading) {
      return '--';
    }

    if (currencyCode) {
      return CurrencyUtil.format(value, currencyCode);
    }

    return value.toLocaleString();
  };

  return (
    <div
      className={classNames('FlightRollupItem', className)}
      style={{
        position: 'relative',
        width: 150,
        marginRight: 16,
        padding: 16,
        backgroundColor: color.white,
        ...style,
      }}
    >
      {loading && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      )}

      <div
        className="title"
        style={{
          marginBottom: 8,
          fontSize: 16,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {title}
      </div>

      <div
        className="total"
        style={{
          display: 'inline-block',
          marginBottom: 8,
        }}
      >
        <Tag
          tag={{
            name: localeValue(total),
          }}
          style={{
            background: color.primary16,
            color: color.primary,
          }}
        />
      </div>

      <div
        className="total-audio"
        style={{
          marginBottom: 8,
        }}
      >
        <VibeIcon
          style={{
            marginRight: 8,
          }}
          icon={viSpeaker}
          color={color.manatee}
          tooltip="Audio"
          tooltipProps={{
            placement: 'left',
          }}
          size={16}
        />

        {localeValue(totalAudio)}
      </div>

      <div className="total-visual">
        <VibeIcon
          style={{
            marginRight: 8,
          }}
          icon={viTv}
          color={color.manatee}
          tooltip="Visual"
          tooltipProps={{
            placement: 'left',
          }}
          size={16}
        />

        {localeValue(totalVisual)}
      </div>
    </div>
  );
}

FlightRollupItem.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  total: PropTypes.number,
  totalAudio: PropTypes.number,
  totalVisual: PropTypes.number,
  currencyCode: PropTypes.string,
};

FlightRollupItem.defaultProps = {
  className: '',
  style: {},
  loading: false,
  total: 0,
  totalAudio: 0,
  totalVisual: 0,
  currencyCode: null,
};

export default FlightRollupItem;
