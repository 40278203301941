import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useNavigate,
} from 'react-router-dom';
import {
  find,
  get,
} from 'lodash';
import {
  API,
  NavigationHelper,
  ToastActions,
  SidePanelContainer,
  SidePanelContent,
  SidePanelFooter,
  Field2 as Field,
  VibeModal,
  VibeButton,
  VibeIcon,
  viArchive,
  viUnarchive,
  color,
} from 'vibeguide';

function CategorySidebar({
  className,
  style,
  categoryId,
  user,
  queueToast,
}) {
  const [category, setCategory] = useState({});
  const [confirmArchive, setConfirmArchive] = useState(false);

  const history = useNavigate();

  const showArchive = category._id && user.can('company_category.delete');
  const isArchived = category._id && !category.active;

  const disableSave = !category.value;
  const disableInput = (category._id && !user.can('company_category.modify'))
    || (!category._id && !user.can('company_category.create'));

  /**
   * User clicks Archive
   */
  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  /**
   * User clicks Unarchive
   */
  const onClickUnarchive = async () => {
    await API.Company.Category.reactivate({
      _id: category._id,
    });

    setCategory({
      ...category,
      active: true,
    });

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveCompanyCategory'));
  };

  /**
   * User confirms archiving the category
   */
  const onConfirmArchive = async () => {
    setConfirmArchive(false);
    await API.Company.Category.deactivate(category._id);

    setCategory({
      ...category,
      active: false,
    });

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveCompanyCategory'));
  };

  /**
   * User cancels archiving the category
   */
  const onCancelArchive = () => {
    setConfirmArchive(false);
  };

  /**
   * User changes the input box
   */
  const onChange = ({
    target: {
      name,
      value,
    },
  }) => {
    setCategory({
      ...category,
      [name]: value,
    });
  };

  /**
   * Save the category
   */
  const onSave = async () => {
    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const data = {
      value: category.value,
    };

    if (category._id) {
      data._id = category._id;
    }

    try {
      const response = category._id
        ? await API.Company.Category.update(data)
        : await API.Company.Category.create(data);

      const categoryId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = category._id
        ? responseType === 'COMPANYCATEGORY.UPDATED'
        : responseType === 'COMPANYCATEGORY.CREATED';

      if (success) {
        // Successfully saved the baseline
        const saveMessage = category._id
          ? 'Category Updated!'
          : 'Category Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });
      }

      // Refresh the user details and redirect to their edit screen
      if (success && !category._id) {
        const redirectUrl = NavigationHelper.updateParams({
          categoryId,
          type: null,
        });

        history(redirectUrl);
      }

      document.dispatchEvent(new Event('onSaveCompanyCategory'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveCompanyCategoryError'));
    }
  };

  /**
   * Get the category from the list of categories
   */
  const getCategory = async () => {
    const categories = await API.Company.Category.list({
      filters: {
        active: true,
      },
    });

    let useCategory = find(categories, { _id: categoryId });

    // no "active" category was found with this ID, check archived ones
    if (!useCategory) {
      const archiveCategories = await API.Company.Category.list({
        filters: {
          active: false,
        },
      });

      useCategory = find(archiveCategories, { _id: categoryId });
    }

    setCategory(useCategory || {});
  };

  useEffect(() => {
    if (categoryId) {
      getCategory();
    }
  }, [categoryId]);

  return (
    <SidePanelContainer
      className={classNames('CategorySidebar', className)}
      style={style}
    >
      <SidePanelContent>
        <div>
          <Field
            type="text"
            label="Category"
            placeholder="Category"
            name="value"
            value={category.value}
            tabIndex={1}
            disabled={disableInput}
            onChange={onChange}
            required
            autoFocus
          />
        </div>
      </SidePanelContent>

      <SidePanelFooter>
        <VibeButton
          text="Save Changes"
          color="secondary"
          disabled={disableSave || disableInput}
          onClick={onSave}
        />

        {category._id && (
          <div>
            {showArchive && !isArchived && (
              <VibeIcon
                icon={viArchive}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.error,
                  style: {
                    marginLeft: 8,
                  },
                }}
                tooltip="Archive"
                color={color.error}
                size={20}
                onClick={onClickArchive}
              />
            )}

            {showArchive && isArchived && (
              <VibeIcon
                icon={viUnarchive}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.success,
                  style: {
                    marginLeft: 8,
                  },
                }}
                tooltip="Unarchive"
                color={color.success}
                size={20}
                onClick={onClickUnarchive}
              />
            )}
          </div>
        )}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text={`Are you sure you want to archive ${category.value}?`}
        confirmProps={{
          text: 'Archive',
          color: color.error,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCancelArchive}
      />
    </SidePanelContainer>
  );
}

CategorySidebar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  categoryId: PropTypes.string,
};

CategorySidebar.defaultProps = {
  className: '',
  style: {},
  categoryId: '',
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySidebar);
