import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  API,
  GlobalActions,
  NavigationHelper,
  TableFlights,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';
import SegmentSidebar from './Sidebar/SegmentSidebar';
import './FlightContainer.scss';

function FlightContainer({
  className,
  user,
  setPanel,
  history,
}) {
  const qs = NavigationHelper.getParams() || {};

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      segmentId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, segmentId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 400,
      children: (
        <SegmentSidebar
          type="flight"
          segmentId={segmentId}
          isNew={type === 'new'}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const isNew = qs.type === 'new';
    const isView = qs.segmentId && qs.type !== 'new';

    if (isNew || isView) {
      sidebar(qs.type, qs.segmentId);
    }
  }, [qs.type, qs.segmentId]);

  return (
    <div className={classNames('FlightContainer', className)}>
      <SubNavigation
        title="Flights"
        buttons={[
          {
            text: 'New Segment',
            icon: viAdd,
            userCan: [
              'flight_segment.create',
            ],
            color: 'primary',
            url: NavigationHelper.updateParams({
              type: 'new',
            }),
          },
        ]}
      />

      <TableFlights
        columns={[
          'Name',
          'Status',
          'Creative',
          'Opportunity',
          'Opportunity ID',
          'Deal ID',
          'Company',
          'Order Reference ID',
          'Delivery System',
          'Ad Provider',
          'Currency',
          'Start Date',
          'End Date',
          'Created By',
          'Created Date',
          '...',
        ]}
        fetch={API.Flight.list}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Flights',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Flights',
          tooltip: true,
        }}
        rowLink="/ad-ops/flight/{_id}"
        menuItems={[
          {
            name: 'Archive',
            // Allow archive if the user has permission and the row is unconfirmed
            userCan: (row) => user.can('flight.delete') && get(row, 'status') === 'unconfirmed',
          },
        ]}
      />
    </div>
  );
}

FlightContainer.propTypes = {
  className: PropTypes.string,
};

FlightContainer.defaultProps = {
  className: '',
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FlightContainer));
