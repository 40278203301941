import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  isEqual,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import VibeTable from '../VibeTable';
import VibeButton from '../../VibeButton/VibeButton';
import CellTooltip from '../CellTypes/CellTooltip';
import IconCell from '../CellTypes/IconCell';
import viCancelCircle from '../../../icons/viCancelCircle';
import viDownload from '../../../icons/viDownload';
import color from '../../../sass/color.scss';

const tableId = 'table:locations:excluded';

class TableLocationsExcluded extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: '...',
      defaultWidth: 72,
    },
    {
      name: 'Location Name',
      searchAttr: 'name',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Company',
      searchAttr: 'companyName',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Volume',
      searchAttr: 'activeAlerts.volume',
      valueAttr: 'volume',
      defaultWidth: 120,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Excluded',
          value: 'false',
        },
        {
          label: 'Included',
          value: 'true',
        },
      ],
    },
    {
      name: 'Audio',
      searchAttr: 'activeAlerts.audio',
      valueAttr: 'audio',
      defaultWidth: 120,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Excluded',
          value: 'false',
        },
        {
          label: 'Included',
          value: 'true',
        },
      ],
    },
    {
      name: 'Battery',
      searchAttr: 'activeAlerts.battery',
      valueAttr: 'battery',
      defaultWidth: 120,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Excluded',
          value: 'false',
        },
        {
          label: 'Included',
          value: 'true',
        },
      ],
    },
    {
      name: 'Memory',
      searchAttr: 'activeAlerts.memory',
      valueAttr: 'memory',
      defaultWidth: 120,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Excluded',
          value: 'false',
        },
        {
          label: 'Included',
          value: 'true',
        },
      ],
    },
    {
      name: 'Network',
      searchAttr: 'activeAlerts.network',
      valueAttr: 'network',
      defaultWidth: 120,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Excluded',
          value: 'false',
        },
        {
          label: 'Included',
          value: 'true',
        },
      ],
    },
    {
      name: 'Offline',
      searchAttr: 'activeAlerts.offline',
      valueAttr: 'offline',
      defaultWidth: 120,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Excluded',
          value: 'false',
        },
        {
          label: 'Included',
          value: 'true',
        },
      ],
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveLocationExcluded', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveLocationExcluded', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  onExport = () => {
    this.getData({
      export: true,
    });
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  renderCell = ({
    column,
    row,
  }) => {
    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Volume':
      case 'Audio':
      case 'Battery':
      case 'Memory':
      case 'Network':
      case 'Offline':
        return (
          <IconCell
            icon={viCancelCircle}
            color={value
              ? color.white
              : color.error}
          />
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
      toolbarProps,
      csv,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
    } = this.state;

    const customButtons = csv
      ? [(
        <VibeButton
          key="btn-export"
          variant="outlined"
          text="Export"
          color="secondary"
          icon={viDownload}
          tooltip={`Export ${totalItems} Locations to CSV`}
          tooltipProps={{
            placement: 'top',
          }}
          onClick={this.onExport}
        />
      )]
      : [];

    // add export button
    const newToolbarProps = {
      ...toolbarProps,
      customButtons: [
        ...toolbarProps.customButtons,
        ...customButtons,
      ],
    };

    return (
      <div className={classNames('Table', 'TableLocationsExcluded', className)}>
        <VibeTable
          {...this.props}
          toolbarProps={newToolbarProps}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />
      </div>
    );
  }
}

TableLocationsExcluded.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableLocationsExcluded.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableLocationsExcluded);
