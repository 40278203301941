import React from 'react';

const viZone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    style={{
      width: '1em',
      height: '1em',
      overflow: 'visible',
    }}
  >
    <g fill="none" fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path fill="currentColor" d="M3 3.5H3.01M3 12.5H3.01M3 21.5H3.01M3 17H3.01M3 8H3.01M7.5 3.5H7.51M7.5 12.5H7.51M7.5 21.5H7.51M16.5 3.5H16.51M16.5 12.5H16.51M16.5 21.5H16.51M21 3.5H21.01M21 12.5H21.01M21 21.5H21.01M21 17H21.01M21 8H21.01M12 21.5V3.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);

export default viZone;
