import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  Field2 as Field,
} from 'vibeguide';
import {
  isEmpty,
} from 'lodash';
import {
  Grid,
} from '@mui/material';

function Information({
  user,
  firstName,
  lastName,
  companyName,
  companyId,
  companyId: currCompanyId,
  partnerName,
  email,
  title,
  phone,
  systemIntegrator,
  isIntegrationAccount,
  disableInput,
  disableCompany,
  permissionPrefix,
  tags,
  isNew,
  isPartner,
  onUpdate,
}) {
  const {
    ADMIN_COMPANY_ID: adminCompanyId,
  } = process.env;

  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  const onSetCompany = ({ id: companyId, value: companyName }) => {
    const data = !companyId
      ? {
        allLocations: false,
        locations: [],
        locationsData: {
          companies: [],
          locations: [],
        },
        tags: currCompanyId !== companyId
          ? tags.filter(tag => tag.type === 'admin')
          : tags,
      }
      : {};

    if (!isEmpty(companyName) || companyName === '' || isNew) {
      onUpdate({
        companyId,
        companyName,
        ...data,
      });
    }
  };

  const onChangePartnerField = (data) => {
    const {
      id,
      value,
    } = data;

    const useData = {};

    if (id) {
      // use admin company ID to allow assigning ACAF company locations
      useData.companyId = adminCompanyId;
    } else {
      // no partner ID, reset locations data
      useData.companyId = '';
      useData.allLocations = false;
      useData.locations = [];
      useData.locationsData = {
        companies: [],
        locations: [],
      };
    }

    onUpdate({
      partnerId: id,
      partnerName: value,
      ...useData,
    });
  };

  const onChangeTag = (data) => {
    onUpdate(data);
  };

  /**
   * User can't list partners and is creating a new partner
   */
  useEffect(() => {
    if (isPartner && isNew && !user.can('partner.list')) {
      // Set the partner to the users partner
      onUpdate({
        partnerId: user.partnerId,
        partnerName: user.partnerName,
      });
    }
  }, []);

  return (
    <div className="Information info-user">
      {isIntegrationAccount ? (
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={6} item>
            <Field
              type="text"
              label="System Integrator"
              name="sysIntegrator"
              placeholder="System Integrator"
              value={systemIntegrator}
              tabIndex={1}
              disabled={disableInput}
              onChange={onChange}
              required
              autoFocus
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            className="row"
            spacing={2}
            container
          >
            <Grid xs={6} item>
              <Field
                type="text"
                label="First Name"
                name="fname"
                placeholder="First Name"
                value={firstName}
                tabIndex={1}
                disabled={disableInput}
                onChange={onChange}
                required
                autoFocus
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="text"
                label="Last Name"
                name="lname"
                placeholder="Last Name"
                value={lastName}
                tabIndex={2}
                disabled={disableInput}
                onChange={onChange}
                required
              />
            </Grid>
          </Grid>

          <Grid
            className="row"
            spacing={2}
            container
          >
            <Grid xs={6} item>
              {!isPartner ? (
                <Field
                  type="company"
                  label="Company"
                  name="companyName"
                  value={companyName}
                  tabIndex={3}
                  companyProps={{
                    companyId,
                    companyName,
                  }}
                  disabled={disableCompany || disableInput}
                  onChange={onSetCompany}
                  required
                />
              ) : (
                <Field
                  type="dropdown"
                  label="Partner"
                  name="partnerName"
                  value={partnerName || ''}
                  tabIndex={3}
                  dropdownProps={{
                    type: 'partner',
                    attr: 'name',
                    fetch: API.Partner.list,
                  }}
                  disabled={disableInput || !user.can('partner.view') || !isNew}
                  onChange={onChangePartnerField}
                  required
                />
              )}
            </Grid>

            <Grid xs={6} item>
              <Field
                type="text"
                label="Email"
                name="email"
                placeholder="user@example.com"
                value={email}
                tabIndex={4}
                disabled={disableInput}
                onChange={onChange}
                required
              />
            </Grid>
          </Grid>

          <Grid
            className="row"
            spacing={2}
            container
          >
            <Grid xs={6} item>
              <Field
                type="text"
                label="Title"
                name="title"
                placeholder="Title"
                value={title}
                tabIndex={5}
                disabled={disableInput}
                onChange={onChange}
                required={isPartner}
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="phone"
                label="Phone"
                name="phone"
                value={phone}
                tabIndex={6}
                disabled={disableInput}
                onChange={onChange}
              />
            </Grid>
          </Grid>

          <Grid
            className="row"
            spacing={2}
            container
          >
            <Grid xs={12} item>
              <Field
                type="tags"
                label="Tags"
                name="tags"
                tagProps={{
                  companyId,
                  tags,
                  allowTypes: !isPartner
                    ? ['client', 'admin']
                    // only allow admin tags for partner users
                    : ['admin'],
                  showAs: 'list',
                  assign: user.can(`${permissionPrefix}.assign_tag`),
                }}
                disabled={disableInput}
                onChange={onChangeTag}
                tabIndex={7}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

Information.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  partnerName: PropTypes.string,
  email: PropTypes.string,
  title: PropTypes.string,
  phone: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  disableCompany: PropTypes.bool,
  permissionPrefix: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  isPartner: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  firstName: '',
  lastName: '',
  companyId: '',
  companyName: '',
  partnerName: '',
  email: '',
  title: '',
  phone: '',
  tags: [],
  disableInput: false,
  disableCompany: false,
  isNew: false,
  isPartner: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
