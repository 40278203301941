import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  Field2 as Field,
  VibeButton,
  API,
  GlobalActions,
  ToastActions,
  NavigationHelper,
  SidePanelContainer,
  SidePanelContent,
  SidePanelFooter,
  Assignments,
  withRouter,
} from 'vibeguide';
import PermissionList from '../Sidebar/Content/PermissionList/PermissionList';
import PermissionSetBulkUsers from './PermissionSetBulkUsers';
import './NewPermissionSetContainer.scss';

class NewPermissionSetContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      permissionIds: [],
      updatePermissions: [],
    };
  }

  componentDidMount() {
    const {
      permissionSetId,
      user,
    } = this.props;

    if (user.can('permissionset.view') && permissionSetId) {
      this.getPermissionSet();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      permissionSetId,
    } = this.props;

    const {
      permissionSetId: prevPermissionSetId,
    } = prevProps;

    if (permissionSetId && permissionSetId !== prevPermissionSetId) {
      this.getPermissionSet();
    }
  }

  /**
   * When the bulk assign users is clicked
   */
  onClickUsers = () => {
    const {
      permissionSetId,
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <PermissionSetBulkUsers
            permissionSetId={permissionSetId}
            onClose={this.onCloseExtra}
          />
        ),
      },
    });
  };

  /**
   * Close the extra sidebar
   */
  onCloseExtra = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * When permission IDs are changed
   */
  onUpdate = ({
    permissionIds,
    updatePermissions,
  }) => {
    this.setState({
      permissionIds,
      updatePermissions,
    });
  };

  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    this.setState({
      [name]: value,
    });
  };

  /**
   * Get the permission set
   */
  getPermissionSet = async () => {
    const {
      permissionSetId,
    } = this.props;

    const permissionSet = await API.PermissionSet.getById(permissionSetId);

    if (!permissionSet) {
      console.error('Error retrieving permission set', permissionSet);
      return;
    }

    this.setState({
      name: permissionSet.name,
      description: permissionSet.description,
    });
  };

  bulkUpdateSets = async () => {
    const {
      selectedSets,
      queueToast,
    } = this.props;

    const {
      updatePermissions,
    } = this.state;

    document.dispatchEvent(new Event('onSavePermissionSetStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const permissionSetIds = selectedSets.map(permissionSet => permissionSet._id);

    const data = {
      permissionSets: permissionSetIds,
      permissions: updatePermissions,
    };

    try {
      const response = await API.PermissionSet.updateBulk(data);
      let success = true;

      response.forEach((result) => {
        if (result.type !== 'PERMISSIONSET.UPDATED') {
          success = false;
        }
      });

      if (success) {
        // Successfully saved the permission set
        const saveMessage = 'Permission Sets Updated!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });

        // tell listening components the object was saved
        document.dispatchEvent(new Event('onSavePermissionSet'));
      }
    } catch (err) {
      document.dispatchEvent(new Event('onSavePermissionSetError'));
    }
  };

  saveSet = async () => {
    const {
      permissionSetId,
      isNew,
      queueToast,
      history,
    } = this.props;

    const {
      name,
      description,
      permissionIds,
    } = this.state;

    document.dispatchEvent(new Event('onSavePermissionSetStart'));

    const data = {
      name,
      description,
      permissions: permissionIds,
    };

    if (permissionSetId) {
      data._id = permissionSetId;
    }

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const response = permissionSetId
        ? await API.PermissionSet.modify(data)
        : await API.PermissionSet.create(data);

      const responseType = get(response, '[0].type');
      const responseId = get(response, '[0].documentId');
      const success = permissionSetId
        ? responseType === 'PERMISSIONSET.UPDATED'
        : responseType === 'PERMISSIONSET.CREATED';

      if (success) {
        // Successfully saved the permission set
        const saveMessage = permissionSetId
          ? 'Permission Set Updated!'
          : 'Permission Set Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });

        // tell listening components the object was saved
        document.dispatchEvent(new Event('onSavePermissionSet'));
      }

      // Refresh the permission set and redirect to the edit screen
      if (success && isNew) {
        const redirectUrl = NavigationHelper.updateParams({
          permissionSetId: responseId,
          type: null,
        });

        history(redirectUrl);
      }
    } catch (err) {
      document.dispatchEvent(new Event('onSavePermissionSetError'));
    }
  };

  render() {
    const {
      user,
      permissionSetId,
      isBulkUpdate,
      selectedSets,
    } = this.props;

    const {
      name,
      description,
      permissionIds,
      updatePermissions,
    } = this.state;

    let formattedSelected = '';

    selectedSets.forEach((selected) => {
      formattedSelected += `${selected.name}, \n`;
    });

    return (
      <SidePanelContainer className="NewPermissionSetContainer">
        <SidePanelContent>
          {!isBulkUpdate ? (
            <span>
              <Field
                type="text"
                label="Permission Set Name"
                placeholder="Name"
                name="name"
                value={name}
                tabIndex={1}
                marginBottom={16}
                onChange={this.onChange}
                required
                autoFocus
              />

              <Field
                className="description-textarea"
                type="textarea"
                label="Description"
                placeholder="Description"
                name="description"
                value={description}
                tabIndex={2}
                marginBottom={16}
                onChange={this.onChange}
              />
            </span>
          ) : (
            <Field
              className="description-textarea"
              type="textarea"
              label="Permission Sets To Update"
              placeholder="None selected"
              name="name"
              value={formattedSelected}
              tabIndex={1}
              marginBottom={16}
              readOnly
            />
          )}

          <PermissionList
            permissionSetId={permissionSetId}
            permissionIds={permissionIds}
            updatePermissions={updatePermissions}
            bulk={isBulkUpdate}
            onUpdate={this.onUpdate}
          />

          {user.can('user.modify') && !isBulkUpdate ? (
            <Assignments
              items={[{
                label: 'Users',
                onClick: this.onClickUsers,
              }]}
            />
          ) : null}
        </SidePanelContent>

        <SidePanelFooter>
          <VibeButton
            variant="contained"
            text={`${permissionSetId || isBulkUpdate ? 'Update' : 'Create'} Permission Set${isBulkUpdate ? 's' : ''}`}
            color="secondary"
            loadingEvent="onSavePermissionSet"
            onClick={isBulkUpdate ? this.bulkUpdateSets : this.saveSet}
          />
        </SidePanelFooter>
      </SidePanelContainer>
    );
  }
}

NewPermissionSetContainer.propTypes = {
  permissionSetId: PropTypes.string,
  isNew: PropTypes.bool,
  isBulkUpdate: PropTypes.bool,
  selectedSets: PropTypes.arrayOf(PropTypes.object),
};

NewPermissionSetContainer.defaultProps = {
  permissionSetId: null,
  isNew: false,
  isBulkUpdate: false,
  selectedSets: [],
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPermissionSetContainer));
