import React, { useState, useEffect } from 'react';
import capitalize from 'lodash/capitalize';
import {
  useParams,
} from 'react-router-dom';
import {
  API,
  ColumnFilter,
  NavigationHelper,
  TableOrders,
  VibeButton,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';

function OrderList() {
  const {
    status,
    orderType,
  } = useParams();

  const qs = NavigationHelper.getParams() || {};

  const defaultColumns = [
    'Name',
    'Creative',
    'Status',
    'Impression Progress',
    'Opportunity Name',
    'Opportunity ID',
    'Deal ID',
    'Company',
    'Order Reference ID',
    'Advertiser',
    'Ad Provider',
    'Delivery System',
    'Media Format',
    'Currency',
    'Start Date',
    'End Date',
    'Modified Date',
    'Created Date',
    'Created By',
    '...',
  ];

  // Require a Search Filter for All Orders (not any pre-filtered route)
  const [requireFilter, setRequireFilter] = useState(status === 'all');
  // show Cancelled Date field for /cancelled/past table
  const [columns, setColumns] = useState(defaultColumns);

  const breadcrumbs = [
    {
      title: 'Dashboard',
      linkUrl: '/ad-ops/orders/dashboard',
    },
  ];

  const updateTitle = (status) => {
    const statusTitle = status !== 'overview' && status !== 'all'
      ? status
      : null;

    if (statusTitle) {
      breadcrumbs[0] = {
        title: `${capitalize(statusTitle)} Dashboard`,
        linkUrl: `/ad-ops/orders/dashboard/${statusTitle.toLowerCase()}`,
      };
    }
  };

  const defineApiRoute = () => {
    let apiRoute;
    let title;
    let linkUrl;

    updateTitle(status);

    switch (true) {
      case orderType === 'awaitingcreative':
        if (status === 'overview') {
          title = 'Awaiting Creatives';
          linkUrl = '../awaitingcreative';
          apiRoute = API.Order.Reporting.Lists.Overview.missingCreative;
        }
        break;
      // UNDERPACING ORDERS
      case orderType === 'underpacing':
        if (status === 'overview') {
          title = 'Under Pacing';
          linkUrl = '../underpacing';
          apiRoute = API.Order.Reporting.Lists.Overview.underPacing;
        }
        break;
      // ENDING ORDERS
      case orderType === 'ending':
        if (status === 'overview') {
          title = 'Ends in 30 Days or Less';
          linkUrl = '../ending';
          apiRoute = API.Order.Reporting.Lists.Overview.ending;
        } else if (status === 'live') {
          title = 'Ends in 30 Days or Less';
          linkUrl = '../ending';
          apiRoute = API.Order.Reporting.Lists.Live.ending;
        } else if (status === 'paused') {
          title = 'Ends in 30 Days or Less';
          linkUrl = '../ending';
          apiRoute = API.Order.Reporting.Lists.Paused.ending;
        }
        break;
      // SERVING ORDERS
      case orderType === 'serving':
        if (status === 'live') {
          title = 'Serving in the next 7 Days';
          linkUrl = '../serving';
          apiRoute = API.Order.Reporting.Lists.Live.serving;
        }
        break;
      // UPCOMING ORDERS
      case orderType === 'upcoming':
        if (status === 'overview') {
          title = 'Upcoming in the Next 30 Days';
          linkUrl = '../upcoming';
          apiRoute = API.Order.Reporting.Lists.Pending.upcoming;
        } else if (status === 'pending') {
          title = 'Upcoming in the Next 30 Days';
          linkUrl = '../upcoming';
          apiRoute = API.Order.Reporting.Lists.Pending.upcoming;
        }
        break;
      // CURRENT ORDERS
      case orderType === 'current':
        if (status === 'paused') {
          title = 'Running in the Next 7 Days';
          linkUrl = '../current';
          apiRoute = API.Order.Reporting.Lists.Paused.current;
        }
        break;
      // PAST ORDERS
      case orderType === 'past':
        if (status === 'completed') {
          title = 'Completed in the Last 30 Days';
          linkUrl = '../past';
          apiRoute = API.Order.Reporting.Lists.Completed.past;
        } else if (status === 'cancelled') {
          title = 'Cancelled in the Last 30 Days';
          linkUrl = '../past';
          apiRoute = API.Order.Reporting.Lists.Cancelled.past;
        }
        break;
      // ALL ORDERS
      default:
        title = 'All Orders';
        linkUrl = '../all';
        apiRoute = API.Order.list;
        break;
    }

    breadcrumbs.push({ title, linkUrl });

    return apiRoute;
  };

  /**
   * Ensure a filter is provided if required
   */
  const onFetchComplete = ({
    requireFilter = false,
  }) => {
    setRequireFilter(requireFilter);
  };

  /**
   * Fetch orders when the force flag is changed
   */
  useEffect(() => {
    const forceResults = qs.force && qs.force.toLowerCase() === 'true';

    if (forceResults) {
      setRequireFilter(false);
      document.dispatchEvent(new Event('onSaveOrder'));
    }
  }, [qs.force]);

  /**
   * Add Cancelled Date column when filtering by 'cancelled' status
   */
  useEffect(() => {
    if (!qs.status) {
      setColumns(ColumnFilter.filterColumns(defaultColumns, [], 16, [{ status, orderType }]));
    }
  }, [status, orderType]);

  /**
   * Add Cancelled Date column when filtering by 'cancelled' status
   */
  useEffect(() => {
    if (!orderType) {
      setColumns(ColumnFilter.filterColumns(defaultColumns, [], 16, [{ status: qs.status, orderType }]));
    }
  }, [qs.status]);

  return (
    <div
      className="OrderList"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <SubNavigation
        breadcrumbs={breadcrumbs}
      />

      <TableOrders
        columns={columns}
        fetch={defineApiRoute()}
        fetchProps={{
          // only fetch when a filter is supplied
          requireFilter,
        }}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Orders',
          urlPaging: true,
          urlFilters: true,
        }}
        rowLink="/ad-ops/order/{_id}/details"
        emptyProps={{
          title: !requireFilter
            ? 'No Results'
            : 'A Search Query is Required',
          description: !requireFilter
            ? 'Nothing was found, try changing your filters'
            : 'You must include at least one filter to view results',
          buttonHtml: !requireFilter
            ? null
            : (
              <VibeButton
                text="View All Orders"
                color="secondary"
                link={NavigationHelper.updateParams({
                  force: true,
                })}
              />
            ),
        }}
        menuItems={[
          {
            name: 'Copy',
            userCan: 'flight.create',
          },
          {
            name: 'Pause',
            // Allow pause if the user has permission
            userCan: 'order.pause',
            disabled: (row) => {
              return row.status !== 'live';
            },
          },
          {
            name: 'Resume',
            // Allow resume if the user has permission (currently no order.resume permission)
            userCan: 'order.pause',
            disabled: (row) => {
              return row.status !== 'paused';
            },
          },
          {
            name: 'Cancel',
            userCan: 'order.cancel',
            disabled: (row) => {
              return row.status === 'cancelled' || row.status === 'completed';
            },
          },
        ]}
        onFetchComplete={onFetchComplete}
      />
    </div>
  );
}

export default withRouter(OrderList);
