import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
} from 'lodash';
import {
  API,
  Field2 as Field,
  VibeModal,
  VibeIcon,
  viCalendarEventFilled,
  viCalendarUnschedule,
  viStoreCopy,
  color,
} from 'vibeguide';
import CopyItemLocations from './CopyItemLocations';
import './ScheduledItem.scss';

class ScheduledItem extends PureComponent {
  constructor(props) {
    super(props);

    const {
      startDate,
    } = props;

    const currDate = new moment();

    this.state = {
      startDay: new moment(startDate).format('D'),
      // is this baseline scheduled in the past?
      isPast: currDate.isAfter(startDate),
      showUnschedule: false,
      showCopy: false,
      showConfirm: false,
      showCopyLocations: false,
      unscheduleAllLocations: false,
      copyAllLocations: false,
      copyLocations: [],
    };
  }

  componentDidUpdate(prevProps) {
    const {
      startDate,
    } = this.props;

    const {
      startDate: prevStartDate,
    } = prevProps;

    if (startDate !== prevStartDate) {
      const currDate = new moment();

      this.setState({
        startDay: new moment(startDate).format('D'),
        isPast: currDate.isAfter(startDate),
      });
    }
  }

  /**
   * When the unschedule icon is clicked
   */
  onClickUnschedule = () => {
    this.setState({
      showUnschedule: true,
    });
  };

  /**
   * When the copy icon is clicked
   */
  onClickCopy = () => {
    this.setState({
      showCopy: true,
    });
  };

  /**
   * When the modal is closed
   */
  onCloseModal = () => {
    this.setState({
      showUnschedule: false,
      showCopy: false,
      showConfirm: false,
      unscheduleAllLocations: false,
      copyAllLocations: false,
      copyLocations: [],
    });
  };

  /**
   * When the location selection is closed
   */
  onCloseCopyLocations = () => {
    this.setState({
      showCopyLocations: false,
      copyLocations: [],
    });
  };

  /**
   * When the location selection is saved
   */
  onSaveCopyLocations = (copyLocations) => {
    this.setState({
      copyLocations,
      showCopyLocations: false,
    });
  };

  /**
   * Set the option to unschedule this location
   */
  setOptionUnscheduleThisLocation = () => {
    this.setState({
      unscheduleAllLocations: false,
    });
  };

  /**
   * Set the option to unschedule all locations
   */
  setOptionUnscheduleAllLocations = () => {
    this.setState({
      unscheduleAllLocations: true,
    });
  };

  /**
   * Set the option to copy all locations
   */
  setOptionCopyAllLocations = () => {
    this.setState({
      copyAllLocations: true,
      copyLocations: [],
    });
  };

  /**
   * Set the option to copy selected locations
   */
  setOptionCopySelectLocations = () => {
    this.setState({
      copyAllLocations: false,
      showCopyLocations: true,
    });
  };

  /**
   * Check whether or not to check for secondary confirmation
   */
  confirm = () => {
    const {
      bulk,
    } = this.props;

    const {
      unscheduleAllLocations,
    } = this.state;

    if (unscheduleAllLocations || bulk) {
      this.setState({
        showConfirm: true,
        showUnschedule: false,
      });
      return;
    }

    this.unschedule();
  };

  /**
   * When the unschedule confirmation is clicked
   */
  unschedule = async () => {
    const {
      _id,
      startDate,
      baselines,
      bulk,
      user,
      currentLocation: {
        companyId,
        _id: locationId,
        timeZone,
      },
      onUnschedule,
    } = this.props;

    const {
      unscheduleAllLocations,
    } = this.state;

    const locations = unscheduleAllLocations
      ? user.locationsAllowed
      : [{
        companyId,
        locationId,
      }];

    const data = {
      action: 'remove',
      baselines: bulk
        ? baselines.map((baseline) => {
          return {
            _id: baseline._id,
            startDate: baseline.startDate,
            originTimeZone: timeZone,
          };
        })
        : [{
          _id,
          startDate,
          originTimeZone: timeZone,
        }],
      locations,
    };

    const unscheduleResponse = await API.Location.updateSchedule(data);
    const success = get(unscheduleResponse, '[0].type') === 'LOCATION.SCHEDULE_UPDATED';

    if (!success) {
      console.error('Error unscheduling baseline', unscheduleResponse);
      return;
    }

    this.onCloseModal();
    onUnschedule();
  };

  /**
   * When the copy confirmation is clicked
   */
  copy = async () => {
    const {
      _id,
      startDate,
      baselines,
      bulk,
      user,
      currentLocation: {
        timeZone,
      },
      onCopy,
    } = this.props;

    const {
      copyLocations,
      copyAllLocations,
    } = this.state;

    if (!copyAllLocations && copyLocations.length <= 0) {
      console.warn('No locations selected to copy');
      return;
    }

    const data = {
      action: 'add',
      baselines: bulk
        ? baselines.map((baseline) => {
          return {
            _id: baseline._id,
            startDate: baseline.startDate,
            originTimeZone: timeZone,
          };
        })
        : [{
          _id,
          startDate,
          originTimeZone: timeZone,
        }],
      locations: copyAllLocations
        ? user.locationsAllowed
        : copyLocations,
    };

    const copyResponse = await API.Location.updateSchedule(data);
    const success = get(copyResponse, '[0].type') === 'LOCATION.SCHEDULE_UPDATED';

    if (!success) {
      console.error('Error copying baseline schedule', copyResponse);
      return;
    }

    this.onCloseModal();
    onCopy();
  };

  render() {
    const {
      user,
      name,
      bulk,
    } = this.props;

    const {
      startDay,
      isPast,
      showUnschedule,
      showCopy,
      showCopyLocations,
      showConfirm,
      unscheduleAllLocations,
      copyAllLocations,
      copyLocations,
    } = this.state;

    if (bulk && !unscheduleAllLocations && showConfirm) {
      console.warn('Bulk Unschedule at 1 Location');
    }

    return (
      <div className={classNames('ScheduledItem', { bulk })}>
        <div className="scheduled-content">
          {!bulk ? (
            <div className="baseline-day">
              <VibeIcon
                className="day-icon"
                icon={viCalendarEventFilled}
                color={color.secondary}
                size={40}
              />

              <div className="day-number">
                {startDay}
              </div>
            </div>
          ) : null}

          <div
            className="baseline-name"
            style={{
              color:
                !bulk
                  ? color.obsidian
                  : color.white,
            }}
          >
            {!bulk
              ? name
              : 'Bulk Actions'}
          </div>

          {!isPast && user.can('location.schedule') ? (
            <div className="baseline-actions">
              <VibeIcon
                className="action-icon"
                icon={viStoreCopy}
                color={!bulk
                  ? color.manatee
                  : color.white}
                hoverColor={!bulk
                  ? color.manatee50
                  : color.whiteSmoke}
                size={24}
                tooltip="Copy"
                onClick={this.onClickCopy}
              />

              <VibeIcon
                className="action-icon"
                icon={viCalendarUnschedule}
                color={!bulk
                  ? color.error
                  : color.white}
                hoverColor={!bulk
                  ? color.error50
                  : color.whiteSmoke}
                size={24}
                tooltip="Unschedule"
                onClick={this.onClickUnschedule}
              />
            </div>
          ) : null}
        </div>

        <VibeModal
          show={showUnschedule}
          type="confirm"
          title="Unschedule baseline(s) from..."
          confirmProps={{
            text: 'Unschedule',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          onConfirm={this.confirm}
          onClose={this.onCloseModal}
        >
          <div>
            <Field
              type="radio"
              label="This location"
              radioProps={{
                selected: !unscheduleAllLocations,
              }}
              onClick={this.setOptionUnscheduleThisLocation}
            />

            <Field
              type="radio"
              label="All my locations for this baseline"
              radioProps={{
                selected: unscheduleAllLocations,
              }}
              onClick={this.setOptionUnscheduleAllLocations}
            />
          </div>
        </VibeModal>

        <VibeModal
          show={showCopy}
          type="confirm"
          title="Copy baseline schedule(s) to..."
          confirmProps={{
            text: 'Copy',
            disabled: copyLocations.length <= 0 && !copyAllLocations,
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          onConfirm={this.copy}
          onClose={this.onCloseModal}
        >
          <div>
            <Field
              type="radio"
              label="Select Locations"
              radioProps={{
                selected: !copyAllLocations,
              }}
              onClick={this.setOptionCopySelectLocations}
            />

            <Field
              type="radio"
              label="All my locations for this baseline"
              radioProps={{
                selected: copyAllLocations,
              }}
              onClick={this.setOptionCopyAllLocations}
            />
          </div>
        </VibeModal>

        {showCopyLocations ? (
          <CopyItemLocations
            onClose={this.onCloseCopyLocations}
            onSave={this.onSaveCopyLocations}
          />
        ) : null}

        <VibeModal
          show={showConfirm}
          type="confirm"
          title="Overwrite Schedules"
          confirmProps={{
            text: 'Confirm',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          onConfirm={this.unschedule}
          onClose={this.onCloseModal}
        >
          <div>
            {bulk && !unscheduleAllLocations
              // eslint-disable-next-line max-len
              ? 'This will overwrite schedules for multiple days on this location. This cannot be undone. Are you sure you want to proceed?'
              // eslint-disable-next-line max-len
              : 'This will overwrite schedules for all locations you and this baseline have access to. This cannot be undone. Are you sure you want to proceed?'}
          </div>
        </VibeModal>
      </div>
    );
  }
}

ScheduledItem.propTypes = {
  _id: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  /** Baseline array (only used for bulk) */
  baselines: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
  })),
  bulk: PropTypes.bool,
  /** When the baseline(s) complete unscheduling */
  onUnschedule: PropTypes.func,
  /** When the baseline(s) complete copying */
  onCopy: PropTypes.func,
};

ScheduledItem.defaultProps = {
  _id: null,
  name: null,
  startDate: null,
  baselines: [],
  bulk: false,
  onUnschedule: () => {},
  onCopy: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default connect(mapStateToProps)(ScheduledItem);
