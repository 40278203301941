/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function PrivacyPolicy({
  className,
  style,
}) {
  return (
    <div
      className={classNames('PrivacyPolicy', className)}
      style={{
        padding: '2rem',
        ...style,
      }}
    >
      <div
        style={{
          marginBottom: 24,
          fontSize: 24,
          fontWeight: 'bold',
        }}
      >
        Privacy Policy
      </div>

      <div
        style={{
          whiteSpace: 'pre-wrap',
        }}
      >
{`
Dated: 08/08/2024

In-Store Marketplace, LLC is a Delaware limited liability company with a number of affiliates around the globe (collectively, “In-Store Marketplace”; referred to also as “we” or “us”).  In-Store Marketplace’s affiliates are listed in the “In-Store Marketplace Entities” section of this Privacy Policy.  This Privacy Policy describes In-Store Marketplace’s practices regarding the collection, use and disclosure of personal information we collect about you when you interact with our Call Center or use our website (www.instoremarketplace.com); including our subdomains such as our blog, the customer portal and other customer service and customer billing and payment systems, web applications and other web properties (collectively, “Sites”).  By using the Sites and Contact Center, you agree to our Privacy Policy and our Terms of Service. 

Personal Information We Collect
“Contact information” including your name, email address, postal address and telephone number;
“Technical information” including information from or about your device such as model, operating system, unique IDs, IP address, and general location information such as state or country, and your usage of our Sites such as areas you visit, referring URL, content that you view, download or submit, and communications including chat features;
“Billing Information” including transaction type, transaction amount, payment, shipping and billing information as well as the nature, quantity and price of the goods or services you exchange and the individuals or entities with whom you communicate or transact business; provided, however, that In-Store Marketplace uses a third party to process your credit card information; 
“Advertising and Cookie and Tracking Information” as described below. 
“Audio and Call Data” Our customers may choose to interact with our Contact Centers and, in doing so, will be subject to the automated collection of data such as phone numbers. Callers may share personal data during the course of the call including contact details, business details and location. As some of the calls are subject to consensual audio recording, callers may also share voice data with us.
“Information We Process on Behalf of Our Business Customers” Our business customers may choose to use our Site and services to process certain data of their own, which may contain personal information (“Business Services”). The data that we process through our Business Services is processed by us on behalf of our business customers, and our privacy practices will be governed by the contracts that we have in place with our business customers—not this privacy policy. Our business customers control the personal information in these cases and determine the security settings within the account, its access controls and credentials. We will, however, provide assistance to our business customers to address any concerns you may have, in accordance with the terms of our contract with them. If you have any questions or concerns about how such data is handled or would like to exercise your rights, you should contact the person or entity (i.e., the data controller) who has contracted with us to use the Business Service; and
“Other Information” including areas of interest, a unique individual password, and any other information you submit to us.

Automatic Collection 
When you visit the In-Store Marketplace Sites, like when you visit most other websites, certain information about your visit is automatically logged, which may include information about the type of browser you use, the server name and IP address through which you access the Internet, the date and time you access the Sites, the pages you access while at the Sites, and the Internet address of the Sites, if any, from which you linked directly to the Sites. We also collect information about your device such as operating systems and platforms, internet service providers, and social media. In some jurisdictions, this is considered personal information.

How We Collect Personal Information
In-Store Marketplace may collect personal information about you from its Sites by methods such as the following:
Registration Forms. If you are offered the opportunity to enter a promotion, to become a registered user of the Sites, or to opt-in to receive In-Store Marketplace information through this or another website, you must apply by filling out the registration form on the Sites. This form requires submission of certain Personal  Information including Basic, Contact and Other Information.
Transactions and Activity. If you become a registered user or if you conduct transactions through the Sites, In-Store Marketplace collects information about the transactions you engage in while on the Sites and your other activity on the Sites. This information may include Technical and Billing Information.
Email and Other Communications. You may communicate with In-Store Marketplace through email, via our Sites, by telephone, in writing, or through other means. In-Store Marketplace collects the information in these communications, and such information may be 
Personal  Information in your jurisdiction.
Calls placed to our Contact Center. You may place a call to our Contact Centre. In-Store Marketplace may record the call and perform live monitoring of the call. You will be prompted to consent to such processing during the welcome message. Should you state your refusal to have the conversation recorded and/or live monitored by pressing 2, the recording and monitoring is to be automatically interrupted. With regard to outbound calls (i.e. calls initiated by the Agents), the calling Agent will seek your approval for the call recording. The calls may be “live monitored” (i.e. listened in real-time). Such live monitoring can only be performed by Quality Assurance authorized users. Live monitoring is made only under specific circumstances related to the quality of services. In-Store Marketplace does not live monitor the calls made by EU Clients.
Event Information. We collect personal information at trade shows and other events (e.g., to our employees or other representatives). 
Public records and other sources. We may also collect information from public sources, from business partners, from social media and from third parties such as advertising, analytics and measurement companies. Our collection of personal information about you is governed by the social media and other third party privacy policies and not this privacy policy. 

In addition to personal information we collect directly from users or visitors, we may also receive certain information from third parties, and we may combine this information with other information we have collected.

How We Use Personal Information. In-Store Marketplace may use the Personal Information collected through its Sites for such purposes as: 
Helping to establish and verify the identity of users;
Opening, maintaining, administering and servicing users’ accounts;
Processing, servicing or enforcing transactions and sending related communications;
Marketing and advertising purposes; 
Providing services and support to users;
Improving the Sites, services and customer experience, including tailoring them to users’ preferences- and to understand what parts of our Sites are most popular or to improve upon such Sites;
Providing users with product or service updates, promotional notices and offers, and other information about In-Store Marketplace and its affiliates;
Research and development purposes, including to understand our customer demographics and for other research and analytical purposes;
Legal compliance including to comply with applicable legal or regulatory obligations, including to respond to a subpoena or court order, to cooperate with law enforcement or other governmental investigations and as part of legal proceedings;
Responding to users’ questions, inquiries, comments and instructions;
Protecting you, us and others:  where we believe it is necessary to protect you, us and others, including, without limitation to detect, prevent, and respond to fraud, security incidents, or other issues and to maintain the security and integrity of our systems; and
Any other purpose for which a user has been notified and/or has given consent.

Cookies and Tracking
We and third parties use cookies and other mechanisms to track personal information about the use of our Sites.
Cookies. We and third parties may use cookies to track visitor activity on our Sites. A cookie is a text file that a website transfers to your device. We and third parties may use cookies and other technologies to track user activities on our Sites, such as the web pages you view and time you spend on our Sites. The help section on most browsers will tell you how to prevent your device from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. 
Clear GIFs, Pixel Tags and Other Technologies. Clear GIFs are tiny graphics with a unique identifier, similar in functionality to cookies, which are embedded invisibly on web pages. We and third parties may use clear GIFs (also known as web beacons, web bugs, pixel tags, etc.), in connection with our Sites to perform functions like tracking the activities of visitors to our Sites, helping us manage content, and compiling statistics about usage of our Sites. We and third parties may also use clear GIFs in emails to help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded. 
Embedded Scripts. We may use embedded scripts, which is code designed to collect information about how visitors interact with a website, such as the website which linked them to our Sites.  
Analytics and Advertising. We and third parties, such as Google Analytics and Adobe/Marketo, use cookies and tracking technologies use to help administer and improve the Sites and for advertising, analytics, measurement and other legal purposes. In-Store Marketplace and third party advertisers, analytics, measurement, and other unaffiliated entities. 
In-Store Marketplace may engage third-party analytics providers such as Google Analytics and Adobe to use cookies and other technology to collect information about use of the Sites. Please review the third-parties’ websites for information about how they collect and use information and for your options. To learn more about Google’s privacy practices, please review the Google Privacy Policy at https://www.google.com/policies/privacy/. You can also download the Google Analytics Opt-out Browser Add-on to prevent their data from being used by Google Analytics at https://tools.google.com/dlpage/gaoptout. For Marketo, visit: https://www.adobe.com/privacy/opt-out.html.
In addition, we use third parties such as Hotjar to understand how users interact with our Site. You can opt-out of Hotjar here: https://help.hotjar.com/hc/en-us/articles/360002735873-How-to-Stop-Hotjar-From-Collecting-your-Data.
Social Media Integration. Our Sites include social media features. These features may collect your IP address, which page you are visiting on our Sites, and may set cookies to enable the feature to function properly. Your interactions with these features are governed by the privacy policy of the company providing it.

Disclosure of Personal Information To Others
We may disclose your personal information with the following entities:
Corporate Affiliates and Related Parties. In-Store Marketplace is a global company with a presence in a number of countries. We may share your personal information with any current or future parent company, affiliate, subsidiary or division. 
Service Providers. We share personal information with our service providers or others who perform functions on our behalf. For example, we share your personal information with service providers who host our Sites, coordinate mailings and other communications, provide chat bot services, assist us in detecting and deterring fraud, security incidents, or other unlawful activity, and for other business purposes as described above.
Third Party Advertisers, Analytics and Measurement Companies. We also permit the collection of personal information online through cookies and other tracking tools including session replay cookies and chat bots. As set forth above, we share personal information with analytics and measurement providers and advertisers, which may be used to target advertising to you. 
Carefully Selected Partners. If you choose to enter a joint promotion, then we will share your contact information (and other personal information that you provide to us in connection with that promotion) with the other party. The promotions may be subject to an additional or different privacy notice.
Site Users and Visitors. We may share your personal information with other users of our Sites. For example, if you post a review, pictures, or other content, the contents of your post (including any pictures that you post) as well as other personal information, such as your username, may be available publicly to all visitors to our Sites. 
We may also share your information in the following ways:
Business Transfers. As In-Store Marketplace’s business develops, it may sell or buy corporate assets, and in such transactions, user or visitor information may be one of the transferred business assets. Accordingly, we may share your information with another entity if we or any of our corporate entities are acquired by or merged with another legal entity, if substantially all of our assets or the assets of one of our corporate relatives are sold or transferred to another legal entity, as part of a bankruptcy proceeding, or as part of a sale of a business or a similar transfer of assets.
In Response to Legal Process. In-Store Marketplace may disclose personal information about you in connection with legal requirements, as permitted by law,  when we believe it is appropriate, to comply with the law or a regulatory requirement, to comply with governmental, administrative, or judicial process or other similar requirement or order, such as a subpoena or court order, to cooperate with law enforcement or other governmental investigations, or as evidence in litigation. We also reserve the right to report to appropriate law enforcement or governmental agencies any activities that we believe are in violation of applicable laws, rules, or regulations without providing notice. 
To Protect Us and Others. We share your information where we believe it is necessary to prevent or investigate a possible crime, such as fraud or identity theft; to enforce a contract; to protect the legal rights, the Site, or safety of you, us, our corporate entities, and their respective employees, clients, partners, agents, other users, or the public in general; to protect our intellectual property, to prevent physical harm to persons or property, to support audit, compliance and corporate governance functions; to enforce our terms or this privacy policy; and to monitor and remediate security issues. 
Aggregated and De-Identified Information. We may disclose aggregate and de-identified information for marketing, advertising, research, or any other purposes.  
Business Partners. If you are a Pandora for Business subscriber, In-Store Marketplace may also disclose your Personal Information to Pandora.   

Security
We maintain an information security program intended to keep your information safe. Please be aware that despite our efforts, we cannot guarantee the security of information we have collected about you, and your use of our Sites indicates that you understand and accept this risk.

You should take steps to protect your account by, among other things, choosing a strong, unique password for each account that nobody else knows or can easily guess and keeping your log-in and password information private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your account via unauthorized password activity. Audio recordings are AES 256 encrypted.

Do Not Track (“DNT”) is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers. Please see below regarding how we respond to Global Privacy Control signals.

Your Choices and Rights Regarding Your Personal Information
You have certain choices regarding the personal information we collect about you. For example, you may:
Opt Out of Promotional Email Communications. You may opt out of promotional emails by clicking on the unsubscribe link in the email, or by emailing us and providing the email that you would like to unsubscribe from. Please note that even if you opt out of receiving marketing communications from us, we may still send you communications about your account or any products or services you have purchased from us, and we will still respond to your inquiries and requests for information. 
Opt Out of Interest-Based Advertising. We and third parties advertise to you based on interests that you have expressed on our Sites or elsewhere (“Interest-Based Ads”). Any tracking done by third parties through cookies and other mechanisms are subject to those parties own privacy policies. You may manage Google cookies by clicking on: www.google.com/settings/ads/onweb/optout?hl=en.  
You also may opt out of receiving Interest-Based Ads from participating third-party ad networks, such as those provided by members of the Digital Advertising Alliance (“DAA”). For more information regarding Interest-Based Ads by DAA members, including how to opt out of Interest-Based Ads provided by DAA members, please visit:
US: you can obtain more information and opt out of receiving targeted ads from participating third-party ad networks at aboutads.info/choices (Digital Advertising Alliance). You may also download the DAA AppChoices (https://youradchoices.com/appchoices) tool in order to help control interest-based advertising on apps on your mobile device.   
EU: youronlinechoices.eu (European Interactive Digital Advertising Alliance).
Canada: youradchoices.ca/choices/ (Digital Advertising Alliance of Canada).
Japan: http://www.ddai.info/optout (Data Driven Advertising Initiative in Japan).
You must opt out on each browser and on each device. If you clear your cookies, you will need to update your choices. Please note that opting out of participating ad networks does not mean that you will not see advertisements at all, and you will continue to receive advertisements on other websites and from companies that do not participate in the above programs. Even if you opt out of interest-based ads, you may still see ads based on factors such as your general location derived from your IP address, your browser type and recent, previous searches related to your current search. 
Cross-Device Use. We and our third-party providers may use the information that we collect about you within our Services and on other third-party sites and services to help us and our third-party service providers identify other devices that you use (e.g., a mobile phone, tablet, other computer, etc.). 
Custom Lists and Matching. Unless you have opted out, we may share certain hashed customer list information (such as your name, email address and other contact information) with third parties so that we can better target ads and content to our users, and others with similar interests, within third party sites, platforms, and services. You may opt out of being targeted by us in this manner by contacting us as at optout@moodmedia.com. 
Facebook Custom Audiences. Certain of our Sites may use Facebook advertising, including Facebook Custom Audience. As set forth above and in the Cookies and Tracking section below, we may disclose certain information (such as your email address) to Facebook Custom Audiences (for more information on Facebook Custom Audience go to https://www.facebook.com/help/568137493302217) so that we can better target ads and content to you and others with similar interests on third parties’ websites or media (“Custom Audiences”). We may also work with other ad networks and marketing platforms that enable us and other participants to target ads to Custom Audiences submitted by us and others. You may also control how Facebook and other third parties display certain ads to you, as explained further in their respective privacy policies or by using the opt-outs described in this paragraph. 
Access and Other Privacy Requests. Consistent with applicable data protection laws and other applicable laws, upon request, we will provide you with a copy of your personal information that we have collected in our role as a controller or business under applicable law. We will give you the opportunity to correct any errors and to restrict our rights to use your data, up to and including deletion of your personal information consistent with this policy. Any requests to review, modify or delete your personal information should be addressed to optout@moodmedia.com. 
If you have not opposed the use of your personal information (either at the time of collection of such information or since that time), In-Store Marketplace may occasionally send you messages or updates about instoremarketplace.com, its affiliates, or their promotions and other activities. If you do not wish to receive any marketing related communications from In-Store Marketplace and its affiliates, you may always opt out by clicking on the “unsubscribe” link at the bottom of the communication or by sending an email containing your request to optout@moodmedia.com.
We will not discriminate against you for exercise your privacy rights under applicable law or prohibit you from making a complaint regarding In-Store Marketplace’s practices with regulatory and supervisory authorities.  
Retaining Personal Information
We retain personal information we collect as reasonably necessary for the purposes described above or otherwise disclosed to you. For example, we will retain your account data for as long as you have an  account with us, transactional data to comply with our tax, accounting and recordkeeping obligations, and for research, development and safety purposes, as well as an additional period of time as necessary to protect, defend or establish our rights, defend against potential claims, manage our back up and disaster recovery systems or comply with legal obligations. 

Children’s Privacy
The Sites are directed toward and designed for use by general audiences and are not targeted to children or minors. If In-Store Marketplace nevertheless discovers that it has received personal information from a child or minor, then In-Store Marketplace will delete such information from its systems. Additionally, a child or minor’s parent or legal guardian may request that the child or minor’s information be deleted from In-Store Marketplace’s files by contacting In-Store Marketplace at optout@moodmedia.com. 

External Links
The Sites may contain links to other websites, including those of advertisers or third party content providers who offer downloads on instoremarketplace.com. Any links are intended to be of interest to the user only; In-Store Marketplace will remove any link from its Sites upon written request from the linked entity.  Please be aware that In-Store Marketplace is not responsible for the privacy practices or the content of other websites. Any websites that are accessible by hyperlinks from the Sites may use cookies (see “Cookies” section above). In-Store Marketplace encourages you to read the privacy statements provided by other websites before you provide Personally Identifiable Information to them.

Changes to this Privacy Policy
In-Store Marketplace reserves the right to modify or supplement this privacy policy at any time. If a material change to the terms of this policy is made, In-Store Marketplace will post a notice on its homepage.

Data Privacy Framework (DPF) Program Statement
EU-U.S. Data Privacy Framework (for personal information transferred from the EU to the U.S.)
UK Extension to the EU-U.S. Data Privacy Framework (for personal information transferred from the UK and Gibraltar to the U.S.)

In-Store Marketplace complies with the EU-U.S. Data Privacy Framework (“EU-U.S. DPF”) and the UK Extension to the EU-U.S. DPF as set forth by the U.S. Department of Commerce (collectively, the “Frameworks”). In-Store Marketplace has certified to the U.S. Department of Commerce that it adheres to the EU-U.S. Data Privacy Framework Principles (“EU-U.S. DPF Principles”) regarding the processing of personal information received from the European Union in reliance on the EU-U.S. DPF and from the United Kingdom (and Gibraltar) in reliance on the UK Extension to the EU-U.S. DPF (the “Principles”).  If there is any conflict between the terms in this privacy policy and the Principles, the relevant Principles shall govern.  To learn more about the Data Privacy Framework program, and to view our certification, please visit https://www.dataprivacyframework.gov/.   

Personal Information We Process. We collect the categories of personal information described in the “Personal Information We Collect” section of our privacy policy. We process this personal information for the purposes stated in the “How We Use Personal Information” section of our privacy policy. We commit to processing the personal information we receive under the Frameworks in accordance with the Principles.

U.S. Entities. The following In-Store Marketplace entities adhere to the Principles: 
Mood Media Borrower, LLC
Mood Media Co-Issuer, Inc.
Mood Media Holdings, LLC
Mood Media LLC
Mood Media North America Holdings Corp.
Mood Media North America, LLC
Muzak LLC
DMX, LLC
PlayNetwork,LLC
Vibenomics, LLC

Your Choices. Pursuant to the Frameworks, EU and UK individuals (“you”) have the right to obtain confirmation of whether we maintain your personal information in the United States.  Upon your verifiable request, we will provide you with access to the personal information that we hold about you, and you may also request that we correct, amend, or delete personal information where it is inaccurate, or has been processed in violation of the Principles, except where the burden or expense of providing access would be disproportionate to the risks to your privacy in the case in question, or where the rights of other persons would be violated.

You may also opt out of (i) our disclosure of your personal information to a third-party, who is not an agent or operating upon our instructions pursuant to a contract (“Third Party”), or (ii) the use of your personal information for a purpose that is materially different from the purpose(s) for which the personal information was originally collected or subsequently authorized by you. To do so, please contact us at optout@moodmedia.com.

Sensitive Personal Information. For any sensitive personal information we collect about you (i.e., personal information specifying medical or health conditions, racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership or information specifying the sex life of the individual), we will obtain affirmative express consent (i.e., opt in) from you if such data will be (i) disclosed to a Third Party or (ii) used for a purpose other than those for which it was originally collected or subsequently authorized by you.  In addition, we will treat as sensitive any personal information received from a third party where the third party identifies and treats it as sensitive.

Transfers to Third Parties. As described in more detail in the “Disclosure of Personal Information To Others” section of our privacy policy, we transfer personal information to Third Parties, such as agents or service providers, non-affiliated parties, and to appropriate vendors (e.g., to verify identity and to investigate and prevent fraud, potential threats to safety, illegal activities, and violations of terms or this privacy policy). We contractually require Third Parties to whom we transfer personal information to provide equivalent levels of protections.  
Contacting Us, Complaints, and Dispute Resolution. In compliance with the Frameworks, In-Store Marketplace commits to resolve complaints about our collection and use of your personal information. If you have inquiries or complaints regarding our handling of personal information received in reliance on the Frameworks, you should first contact us at In-Store Marketplace at 2100 S. IH 35, Suite 201, Austin, Texas 787048700 North Street, Suite 200, Fishers, IN 46038, optout@moodmedia.com or call 1-800.345.50317-863-282400.  

Non-HR Data. In compliance with the Frameworks, In-Store Marketplace commits to refer unresolved complaints concerning our handling of personal information received in reliance on the Frameworks to the International Centre for Dispute Resolution, a division of the American Arbitration Association (the “ICDR/AAA”), an alternative dispute resolution provider located in the United States. If you do not receive timely acknowledgment of your DPF Principles-related complaint from us, or if we have not addressed your DPF Principles-related complaint to your satisfaction, please visit ICDR/AAA at icdr.org/dpf for more information or to file a complaint. The services of ICDR/AAA are provided at no cost to you.

Binding Arbitration. If your complaint cannot be resolved through the above channels, there may be a possibility, under certain conditions, for you to invoke binding arbitration for complaints regarding DPF compliance not resolved by any of the other DPF mechanisms and then link to Annex I for additional information: https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2. 

FTC Enforcement. The U.S. Federal Trade Commission has jurisdiction over In-Store Marketplace’s compliance with the Frameworks.
Law Enforcement or Public Authority Requests. In accordance with our legal obligations, we may be obligated to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. 

Liability for Onward Transfers. In the context of an onward transfer, In-Store Marketplace has responsibility for the processing of personal information it receives under the DPF Principles and subsequently transfers to a third party acting as an agent on its behalf. In-Store Marketplace shall remain liable under the DPF Principles if its agent processes such personal information in a manner inconsistent with the DPF Principles, unless In-Store Marketplace proves that it is not responsible for the event giving rise to the damage.

Notice at Collection and Additional Information for California Residents
Last Updated: 11/09/2023
This section of the privacy policy provides additional information for California residents and describes our information practices pursuant to the California Consumer Privacy Act 2018, as amended by the California Privacy Rights Act 2020, and its implementing regulations (the “CCPA”). Depending on how you interact or engage with us, we may provide you with other privacy notices with additional details about our privacy practices. 
This section does not address or apply to our handling of personal information that is exempt under the CCPA, such as publicly available information. In-Store Marketplace is committed to maintaining and using deidentified information in compliance with the CCPA.
Personal Information We Collect. Depending on how you interact with us, we have collected the following categories of personal information in the prior 12 months:
Identifiers. Such as name, alias, email, phone number, billing and shipping address, unique personal identifier, online identifier, IP address, account name, or other similar identifiers.
Internet or Other Electronic Network Activity Information. Such as browsing history, clickstream data, search history, and information regarding interactions with our websites, mobile application, advertisements, or emails, including other usage data related to your use of any of our websites.
Geolocation Data. Such as location information about a particular individual or device derived from IP addresses.
Audio, Electronic, Visual, or Similar Information. Such as information collected via call recordings if you are interacting with us in a customer service capacity or if you call us on a recorded line, recorded meetings and webinars, videos, photographs, and security camera footage to secure our offices and premises.
Professional or Employment Related Information. Such as your job title, employer, work email, work phone, and work address.
Inferences. Such as inferences drawn from any of the information described in this section about a consumer including inferences reflecting the consumer’s preferences, characteristics, behaviors, attitudes, abilities, and aptitudes.
Sources of Personal Information. We generally collect personal information from the following categories of sources:
Directly or indirectly from you; 
Our affiliates and subsidiaries;
Our business partners;
Customers and clients;
Social networks;
Internet service providers;
Operating systems and platforms; and
Our vendors and service providers.
Purposes for Collecting and Disclosing Personal Information. As described in the “How We Use Personal Information” section above, in general, we collect and otherwise process the personal information we collect for the following business or commercial purposes:
Services and support;
Responding to inquiries;
Analytics and improvement;
Customization and personalization;
Marketing and advertising;
Planning and managing events;
Research and surveys;
Security and protection of rights; 
Legal proceedings and obligations; 
General business and operational support; and
Certain other purposes as notified.

Sensitive Personal Information. Notwithstanding the purposes described above, we do not collect, use, or disclose “sensitive personal information” beyond the purposes authorized by the CCPA.
Retention of Personal Information. We retain your personal information for as long as needed or permitted, based on the reason we obtained it (consistent with applicable law). When deciding how long to keep your personal information, we consider whether we are subject to any legal obligations (e.g., any laws that require us to keep records for a certain period before we can delete them) or whether we have taken any legal positions (e.g., issued any legal holds or otherwise need to preserve the information). Rather than delete your data, we may also deidentify it by removing identifying details. Where we have committed to maintaining and using personal information in a deidentified form, we agree not to reidentify deidentified data except as permitted by applicable law.
Disclosure of Personal Information to Third Parties and Other Recipients. The categories of personal information we have disclosed for a business purpose in the preceding twelve (12) months include: identifiers, Internet or other electronic network activity information, geolocation data, audio, video, and other electronic data, and inferences. 
The categories of third parties and other recipients to whom we may disclose personal information for a business purpose may include: affiliates, subsidiaries, and business partners, vendors and service providers, acquirers of business assets, advertising networks, internet service providers, data analytics providers, government entities, operating systems and platforms, and social networks.
Sales and Sharing of Personal Information. The CCPA defines “sale” as disclosing or making available personal information to a third-party in exchange for monetary or other valuable consideration, and “sharing” includes disclosing or making available personal information to a third-party for purposes of cross-contextual behavioral advertising. While we do not disclose personal information to third parties in exchange for monetary compensation, we may “sell” or “share” the following categories of personal information for our own purposes (i.e., to advertise our services): Identifiers, and Internet or other electronic network activity information. We disclose these categories to third-party advertising networks, analytics providers, and social networks for purposes of marketing and advertising. We do not sell or share personal information about individuals we know are under age 16.
Your CCPA Rights. The CCPA provides California residents with certain rights regarding their personal information. Please note that these rights only apply to personal information we collect and process under this privacy policy as a business or controller. For personal information we process on behalf of our clients in our capacity as a service provider or processor, please submit your request directly to the client with whom you have a relationship, and we will provide reasonable assistance to that business client as necessary to enable them to respond to your requests to exercise your privacy rights. 
In general, California residents, including our clients’ employees who reside in California, have the following rights with respect to their personal information, subject to certain exceptions:
Right to Know/Access. With respect to the personal information we have collected about you, you have the right to request:
The categories or personal information we collected about you;
The categories of sources from which the personal information is collected;
Our business or commercial purposes for collecting, selling, or sharing personal information;
The categories of third parties to whom we have disclosed personal information; and
A copy of the specific pieces of personal information we have collected about you.
Right to Correct. You have the right to request that we correct inaccuracies in your personal information.
Right to Delete. You have the right to request we delete your personal information.
Right to Opt-Out of Sales and Sharing. You have the right to opt-out of “sales” and “sharing” of your personal information, as those terms are defined under the CCPA. While we do not “sell” personal information in the traditional sense (i.e., for money), our use of third-party analytics and advertising cookies may be considered “selling” and “sharing” under CCPA. To exercise your right to opt-out of the “sale” or “sharing” of your personal information, use the link at the bottom of our website. You also have the right to opt-out of “sales” and “sharing” of your personal information through the use of an opt-out preference signal. If our Sites detects that your browser or device is transmitting an opt-out preference signal, such as the “global privacy control”—or GPC— signal, we will opt that browser or device out of cookies on our Sites that result in a “sale” or “sharing” of your personal information. If you come to our Sites from a different device or from a different browser on the same device, you will need to opt-out, or use an opt-out preference signal, for that browser and/or device as well.
Right to Limit Use. We do not engage in uses or disclosures of “sensitive personal information” that would trigger the right to limit use of sensitive personal information under the CCPA.
Right to Non-Discrimination. We will not discriminate against you in terms of price or service level for exercising any of the rights described in this section.
Exercising Your CCPA Rights. If you are a California resident and would like to exercise your CCPA rights, contact us at In-Store Marketplace at 8700 North Street, Suite 200, Fishers, IN 460382100 S. IH 35, Suite 201, Austin, Texas 78704, optout@moodmedia.com, or 1-1-800.345.5000317-863-2824.
Verification. Before responding to your request, we must first verify your identity using the personal information you recently provided to us. You must provide us with your name and the email you primarily use to interact with us. We will take steps to verify your request by matching the information provided by you with the information we have in our records. In some cases, we may request additional information to verify your identity, or where necessary to process your request. If we are unable to verify your identity after a good faith attempt, we may deny the request and, if so, will explain the basis for the denial.
Authorized Agent. You may designate someone as an authorized agent to submit requests and act on 
your behalf. Authorized agents will be required to provide proof of their authorization in their first communication with us, and we may also require that the relevant consumer directly verify their identity and the authority of the authorized agent.

Contacting Us. If you have questions about this privacy policy, the information practices of In-Store Marketplace or your dealings with In-Store Marketplace, please contact In-Store Marketplace at 2100 S IH 35, Suite 201, Austin, Texas 787048700 North Street, Suite 200, Fishers, IN 46038, email optout@moodmedia.com, call 1-317-863-2824800.345.5000.
`}
      </div>
    </div>
  );
}

PrivacyPolicy.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

PrivacyPolicy.defaultProps = {
  className: '',
  style: {},
};

export default PrivacyPolicy;
