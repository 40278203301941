import React from 'react';
import PropTypes from 'prop-types';
// import toNumber from 'lodash/toNumber';
import {
  color,
  PlayControl,
  VibeButton,
  Field2 as Field,
} from 'vibeguide';
import moment from 'moment';
import './DayPartCreative.scss';

function DayPartCreative({
  index,
  dateRangeIndex,
  creative,
  newTotalCreativeWeight,
  onUpdate,
  onRemove,
}) {
  const onDeleteCreative = () => {
    onRemove(creative, dateRangeIndex);
  };

  const onChangeWeightField = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const newValue = value === '' ? '' : parseFloat(value);

    onUpdate({
      ...creative,
      [name]: newValue,
    },
    dateRangeIndex);
  };

  const handleIntegerInput = (e) => {
    e.target.value = e.target.value.replace(/\D/g, '');
  };

  const duration = moment.duration(creative.durationSeconds, 'seconds');

  return (
    <div className="DayPartCreative">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 4,
        }}
      >
        <div className="creative-title">
          <PlayControl
            id={`creative-${index}`}
            name={creative.name}
            src={creative.url}
            disabled={!creative.url}
            color={color.success}
            durationSeconds={creative.durationSeconds}
            playText="Play Media"
            pauseText="Pause Media"
          />

          {creative.name}

          <span
            style={{
              marginLeft: 4,
              fontSize: 12,
              color: color.manatee,
            }}
          >
            ({moment.utc(duration.asMilliseconds()).format('mm:ss')})
          </span>
        </div>

        <VibeButton
          text="Delete"
          variant="text"
          color="error"
          onClick={onDeleteCreative}
        />
      </div>

      <div className="creative-weight">
        <div className="weight-label">
          Weight
        </div>
        <div className="input-percentage">
          <Field
            style={{
              width: 70,
            }}
            type="number"
            dataId={`creative-weight-${index}`}
            name="weight"
            placeholder="Add Weight..."
            value={creative.weight}
            onChange={onChangeWeightField}
            numberProps={{
              min: 0,
              max: 100,
              step: 5,
            }}
            error={newTotalCreativeWeight()}
            validation={{
              check: true,
              checkEmpty: true,
              events: [
                'onBlur',
              ],
            }}
            onInput={handleIntegerInput}
            required
          />
        </div>
      </div>
    </div>
  );
}

DayPartCreative.propTypes = {
  index: PropTypes.number.isRequired,
  dateRangeIndex: PropTypes.number,
  creative: PropTypes.object,
  newTotalCreativeWeight: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
};

DayPartCreative.defaultProps = {
  dateRangeIndex: 0,
  creative: {},
  newTotalCreativeWeight: () => {},
  onUpdate: () => {},
  onRemove: () => {},
};

export default DayPartCreative;
