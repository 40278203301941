import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  GlobalActions,
  MessageRequestModel,
  MessageModel,
  ExpandedLocationsHelper,
  LoadingContent,
} from 'vibeguide';
import MessageDetails from './MessageDetails';
import MessageChat from './MessageChat';
import MessageHistory from './MessageHistory';
import './MessageSidebar.scss';

class MessageSidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      message: new MessageRequestModel(),
      activeSection: 'details',
      copying: false,
      copiedMessage: false,
    };
  }

  componentDidMount() {
    const {
      messageId,
    } = this.props;

    if (messageId && messageId !== 'new') {
      this.getMessage();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      messageId,
      isNew,
      isCopy,
    } = this.props;

    const {
      copiedMessage,
    } = this.state;

    const {
      messageId: prevMessageId,
    } = prevProps;

    if (messageId && !isNew && !isCopy && messageId !== prevMessageId) {
      this.getMessage();
    }

    if (isCopy && !copiedMessage) {
      this.getMessageCopy();
    }
  }

  /**
   * Update the message
   */
  onUpdate = (data) => {
    this.setState((state) => {
      return {
        message: {
          ...state.message,
          ...data,
        },
      };
    });
  };

  /**
   * Close the sidebar
   */
  onClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  /**
   * Toggle the details section
   */
  onToggleDetails = () => {
    this.setState({
      activeSection: 'details',
    });
  };

  /**
   * Toggle the chat section
   */
  onToggleChat = () => {
    this.setState((state) => {
      return {
        activeSection: state.activeSection !== 'chat'
          ? 'chat'
          : 'details',
      };
    });
  };

  /**
   * Toggle the history section
   */
  onToggleHistory = () => {
    this.setState((state) => {
      return {
        activeSection: state.activeSection !== 'history'
          ? 'history'
          : 'details',
      };
    });
  };

  getMessage = async () => {
    const {
      type,
      messageId,
    } = this.props;

    const message = type === 'request'
      ? await API.MessageRequest.getById(messageId, {
        expand: 'messageblocks',
      })
      : await API.Message.getById(messageId);

    const requestResponse = type === 'library'
      ? await API.MessageRequest.getById(message.requestId)
      : null;

    const request = requestResponse
      ? new MessageRequestModel(requestResponse)
      : {};

    const expandedLocations = type === 'request'
      ? await API.MessageRequest.getExpandedLocations(messageId)
      : await API.Message.getExpandedLocations(messageId);

    // Set the location data
    message.locationsData = ExpandedLocationsHelper.getLocationsData(expandedLocations);

    const messageModel = type === 'request'
      ? new MessageRequestModel(message)
      : new MessageModel({
        ...message,
        script: request.script,
      });

    const combinedData = {
      ...request,
      ...messageModel,
    };

    this.setState({
      message: combinedData,
      copying: false,
      copiedMessage: false,
    });
  };

  // Create copy of source message
  getMessageCopy = async () => {
    const {
      type,
      messageId,
    } = this.props;

    const message = type === 'request'
      ? await API.MessageRequest.getById(messageId, {
        expand: 'messageblocks',
      })
      : await API.Message.getById(messageId);

    message.mediaSourceRequestId = message._id;
    message._id = '';
    message.name += ' Copy';
    message.partnerApproval = {};
    message.locations = [];

    const requestResponse = type === 'library'
      ? await API.MessageRequest.getById(message.requestId)
      : null;

    const request = requestResponse
      ? new MessageRequestModel(requestResponse)
      : {};

    const messageModel = type === 'request'
      ? new MessageRequestModel(message)
      : new MessageModel({
        ...message,
        script: request.script,
      });

    const combinedData = {
      ...request,
      ...messageModel,
    };

    this.setState({
      message: combinedData,
      copiedMessage: true,
      copying: true,
    });
  };

  // show loader based on type and message state
  showLoader = () => {
    const {
      messageId,
      isCopy,
      isNew,
    } = this.props;

    const {
      message,
    } = this.state;

    if (isNew || isCopy) {
      return false;
    }

    if (messageId && message._id && !isCopy) {
      return false;
    }

    return true;
  };

  render() {
    const {
      type,
      isNew,
      isCopy,
      isDuplicate,
    } = this.props;

    const {
      message,
      activeSection,
      copying,
    } = this.state;

    return (
      <div className="MessageSidebar">
        {activeSection === 'details' ? (
          <MessageDetails
            type={type}
            message={message}
            isNew={isNew}
            isCopy={isCopy}
            copying={copying}
            isDuplicate={isDuplicate}
            onClose={this.onClose}
            onToggleChat={this.onToggleChat}
            onToggleHistory={this.onToggleHistory}
            onUpdate={this.onUpdate}
            onRefresh={this.getMessage}
          />
        ) : null}

        {activeSection === 'chat' ? (
          <MessageChat
            type={type}
            message={message}
            onClose={this.onClose}
            onToggleDetails={this.onToggleDetails}
            onToggleHistory={this.onToggleHistory}
            onRefresh={this.getMessage}
          />
        ) : null}

        {activeSection === 'history' ? (
          <MessageHistory
            message={message}
            onClose={this.onClose}
            onToggleDetails={this.onToggleDetails}
            onToggleChat={this.onToggleChat}
          />
        ) : null}

        {this.showLoader() && (
          <LoadingContent />
        )}
      </div>
    );
  }
}

MessageSidebar.propTypes = {
  type: PropTypes.oneOf([
    'request',
    'library',
  ]).isRequired,
  messageId: PropTypes.string,
  /** New Message */
  isNew: PropTypes.bool,
  isCopy: PropTypes.bool,
  /** Duplicate Message */
  isDuplicate: PropTypes.bool,
};

MessageSidebar.defaultProps = {
  messageId: null,
  isNew: false,
  isCopy: false,
  isDuplicate: false,
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(MessageSidebar);
