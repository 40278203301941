import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  find,
} from 'lodash';
import {
  ExpandedLocationsHelper,
  LocationSelection,
  VibeButton,
  VibeDialog,
  API,
} from 'vibeguide';
import './CopyItemLocations.scss';

class CopyItemLocations extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      allLocations: false,
      locationsData: {
        companies: [],
        locations: [],
      },
      locations: [],
      // companyId: null,
    };
  }

  onSelectAll = (selectAll) => {
    this.setState({
      allLocations: selectAll,
      locations: [],
    });
  };

  onSelect = (location) => {
    this.setState((state) => {
      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      let {
        locations: stateLocations,
      } = state;

      if (location.locationId === '*') {
        // Add an entire company
        // Remove all individual company locations
        stateLocations = stateLocations.filter(loc => loc.companyId !== location.companyId);
      }

      return {
        ...state,
        locationsData: ExpandedLocationsHelper.handleLocationsData({
          type: 'add',
          data: location,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: [...stateLocations, location],
      };
    });
  };

  onDeselect = (locationData) => {
    this.setState((state) => {
      const locationItem = find(state.locations, {
        companyId: locationData.companyId,
        locationId: locationData.locationId,
      });

      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      return {
        ...state,
        locationsData: ExpandedLocationsHelper.handleLocationsData({
          type: 'remove',
          data: locationData,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: locationData.locationId === '*'
          // Remove all locations for this company
          ? state.locations.filter(location => locationData.companyId !== location.companyId)
          // Remove single location
          : state.locations.filter(location => location !== locationItem),
      };
    });
  };

  onDeselectAll = () => {
    this.setState({
      locationsData: {
        companies: [],
        locations: [],
      },
      locations: [],
    });
  };

  saveChanges = () => {
    const {
      onSave,
    } = this.props;

    const {
      locations,
    } = this.state;

    const selectedLocations = locations.map((location) => {
      return {
        companyId: location.companyId,
        locationId: location.locationId,
      };
    });

    onSave(selectedLocations);
  };

  render() {
    const {
      user,
      currentLocation: {
        companyId,
      },
      onClose,
    } = this.props;

    const {
      name,
      allLocations,
      locationsData,
      locations,
    } = this.state;

    if (!companyId) {
      return null;
    }

    const locationFunc = user.getLocationFunc(companyId) === 'user'
      ? API.User.getLocations
      : API.Company.getLocations;

    return (
      <VibeDialog
        title="Manage Locations"
        open
        fullScreen
        onClose={onClose}
      >
        <div className="CopyItemLocations">
          <div className="message-header-container">
            <div className="message-header">
              <div className="message-details">
                <div className="title">
                  {name}
                </div>
              </div>

              <div className="message-toolbar">
                <div className="message-toolbar-items">
                  <VibeButton
                    className="item item-publish"
                    text="Save Changes"
                    color="secondary"
                    disabled={!allLocations && locations.length <= 0}
                    onClick={this.saveChanges}
                  />
                </div>
              </div>
            </div>
          </div>

          <LocationSelection
            companyId={companyId}
            selectedLocations={locations}
            locationsData={locationsData}
            selectAll={allLocations}
            filterLocations={locationFunc}
            filterCompanies={API.User.getCompanies}
            onSelect={this.onSelect}
            onSelectAll={this.onSelectAll}
            onDeselect={this.onDeselect}
            onDeselectAll={this.onDeselectAll}
            // stickyScroll
            allowChanges
            allowPaginator
            // allowACAF
            allowCompanies
            // allowAssignCompany
            // required
          />
        </div>
      </VibeDialog>
    );
  }
}

CopyItemLocations.propTypes = {
  /** When the selection is closed */
  onClose: PropTypes.func,
  /** When the selection is saved */
  onSave: PropTypes.func,
};

CopyItemLocations.defaultProps = {
  onClose: () => {},
  onSave: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default connect(mapStateToProps)(CopyItemLocations);
