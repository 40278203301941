import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  MediaFormatHelper,
  Field2 as Field,
  VibeModal,
} from 'vibeguide';
import Grid from '@mui/material/Grid';

function Information({
  className,
  name,
  mediaFormat,
  locationSpec,
  disableInput,
  onUpdate,
}) {
  const [confirmChangeMediaFormat, setConfirmChangeMediaFormat] = useState(false);
  const [newMediaFormat, setNewMediaFormat] = useState(null);

  /**
   * When the Media Format field is changed
   * ask to confirm removing all locations for the segment
   */
  const onChangeMediaFormat = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    if (locationSpec.length > 0) {
      // show confirmation modal that the locations will be reset
      setConfirmChangeMediaFormat(true);
      setNewMediaFormat(value);
    } else {
      // segment has no locations, allow changing without confirmation
      onUpdate({
        mediaFormat: value,
      });
    }
  };

  /**
   * Confirm changing the Media Format
   */
  const onConfirmChangeMediaFormat = () => {
    onUpdate({
      mediaFormat: newMediaFormat,
      // reset the locations when the media format is changed
      locations: {},
      locationSpec: [],
      locationsData: {
        companies: [],
        locations: [],
      },
      numberOfLocations: 0,
    });

    setConfirmChangeMediaFormat(false);
    setNewMediaFormat(null);
  };

  /**
   * Cancel changing the Media Format
   */
  const onCancelChangeMediaFormat = () => {
    setConfirmChangeMediaFormat(false);
    setNewMediaFormat(null);
  };

  /**
   * When a field is changed
   */
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  return (
    <div className={classNames('Information', 'target-group', className)}>
      <Grid
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={6}
          item
        >
          Information
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          xs={12}
          item
        >
          <Field
            type="text"
            label="Name"
            name="name"
            placeholder="Name"
            value={name}
            disabled={disableInput}
            tabIndex={10}
            onChange={onChange}
            autoFocus
            required
          />
        </Grid>

        <Grid xs={12} item>
          <Field
            type="select"
            label="Media Format"
            name="mediaFormat"
            value={mediaFormat}
            options={MediaFormatHelper.list({ includeEmpty: true })}
            disabled={disableInput}
            tabIndex={11}
            onChange={onChangeMediaFormat}
            required
          />
        </Grid>
      </Grid>

      <VibeModal
        show={confirmChangeMediaFormat}
        type="confirm"
        title="Change Media Format"
        text={(
          <div>
            Changing the media format will reset the assigned locations for this target group
            <br />
            <br />
            This action cannot be undone.
          </div>
        )}
        confirmProps={{
          text: 'Confirm',
          color: 'primary',
        }}
        cancelProps={{
          text: 'Cancel',
        }}
        onConfirm={onConfirmChangeMediaFormat}
        onClose={onCancelChangeMediaFormat}
      />
    </div>
  );
}

Information.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  mediaFormat: PropTypes.string,
  locationSpec: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  className: '',
  name: '',
  mediaFormat: '',
  locationSpec: [],
  disableInput: false,
  onUpdate: () => {},
};

export default Information;
