/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function TermsOfService({
  className,
  style,
}) {
  return (
    <div
      className={classNames('TermsOfService', className)}
      style={{
        padding: '2rem',
        ...style,
      }}
    >
      <div
        style={{
          marginBottom: 24,
          fontSize: 24,
          fontWeight: 'bold',
        }}
      >
        Terms of Service
      </div>

      <div
        style={{
          whiteSpace: 'pre-wrap',
        }}
      >
{`
This page sets forth the terms and conditions (the “Agreement”) under which you may use the In-Store Marketplace web site (the “Site”), which is controlled, maintained, owned and operated by In-Store Marketplace, LLC (the “Company”). Use of the Site is offered to you conditioned on your complete acceptance of the terms and conditions of this Agreement. By using the Site, you are agreeing to be contractually bound by the terms and conditions of the Agreement as they are stated at the time of your use of the Site. By accessing and using the Site, you represent and warrant that you are of the age of majority under applicable law.

IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, YOU MAY NOT VEIW OR OTHERWISE USE THE SITE.

Use of the Site
You are granted a non-exclusive, non-assignable and non-transferable license to use the Site only in accordance with this Agreement. This license is not a sale of any of the Company’s rights.

Notice of Proprietary Rights
The Site contains copyrighted material, trademarks and other proprietary material and information, including, but not limited to, text, software, images, graphics, video, music and/or sound that is protected under both United States and foreign laws (the “Material”). The Material is owned by or licensed to the Company or its affiliates or suppliers or is the property of its respective owner. Unauthorized use of the Material may violate copyright, trademark and other laws. You must retain all copyright and other proprietary notices contained in the original Material on any copy that you make of the Material. You may not sell or modify the Material or reproduce, display, publicly perform, distribute or otherwise use the Material in any way for any public or commercial purpose. The use of the Material on any other web site or in a networked computer environment for any purpose is prohibited. You may not copy or adapt the HTML code that the Company creates to generate the pages of the Site, which code is also protected by the Company’s copyright. If you violate any of the terms of this Agreement, your permission to use the Material automatically terminates and you must immediately destroy any copies you have made of the Material.

Errors and Corrections
The information and services included in or available through the Site may include inaccuracies and/or typographical errors. Changes are periodically made to the Site and to information contained in the database(s) from which information on the Site is derived. Use of the Site is at your own risk, and you agree that neither the Company nor its suppliers are liable for any inaccurate information contained in or derived from the Site. The Company and its suppliers may make improvements and/or changes to the Site at any time.
Information contained in or derived from the Site is not to be relied on or utilized as a source of financial, tax, legal, accounting, economic, business or other professional advice. Information contained in or derived from the Site regarding usage of music or media is not to be used to calculate or challenge royalty payments paid or to be paid from Company to you or any third party.

Company’s Liability
YOUR USE OF THE SITE IS AT YOUR OWN RISK.

THE COMPANY DOES NOT WARRANT THAT THE SITE WILL OPERATE ERROR-FREE OR THAT THE SITE OR ITS SERVER ARE FREE OF COMPUTER VIRUSES OR OTHER HARMFUL MATERIAL OR GOODS. IF YOUR USE OF THE SITE OR THE MATERIAL RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, THE COMPANY IS NOT RESPONSIBLE FOR THE COSTS ASSOCIATED WTH SUCH SERVICING OR REPLACEMENT.

THE SITE AND THE MATERIAL ARE PROVIDED ON AN “AS-IS” BASIS WITHOUT ANY WARRANTIES OF ANY KIND. THE COMPANY AND ITS SUPPLIERS, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIM ALL WARRANTIES, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS. THE COMPANY AND ITS SUPPLIERS MAKE NO WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS OR TIMELINESS OF THE MATERIAL, SERVICES, SOFTWARE, TEXT, GRAPHICS OR LINKS CONTAINED WITHIN THE SITE; THAT THE SITE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; THAT ANY DEFECTS OR ERRORS CONTAINED ON THE SITE WILL BE CORRECTED; OR THAT THE SITE WILL BE FREE FROM VIRUSES OR OTHER HARMFUL COMPUTER COMPONENTS.

YOU SPECIFICALLY AGREE THAT THE COMPANY AND/OR ITS RESPECTIVE AFFILIATES AND SUPPLIERS SHALL NOT BE RESPONSIBLE FOR UNAUTHORIZED ACCESS OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, ANY MATERIAL OR DATA SENT OR RECEIVED OR NOT SENT OR RECEIVED, OR ANY TRANSACTIONS ENTERED INTO THROUGH THE SITE. YOU SPECIFICALLY AGREE THAT THE COMPANY AND/OR ITS RESPECTIVE AFFILIATES AND SUPPLIERS ARE NOT RESPONSIBLE OR LIABLE FOR ANY THREATENING, DEFAMATORY, OBSCENE, OFFENSIVE OR ILLEGAL CONTENT OR CONDUCT OF ANY OTHER PARTY OR ANY INFRINGEMENT OR ANOTHER’S RIGHTS, INCLUDING INTELLECTUAL PROPERTY RIGHTS. YOU SPECIFICALLY AGREE THAT THE COMPANY AND/OR ITS RESPECTIVE AFFILIATES AND SUPPLIERS ARE NOT RESPONSIBLE FOR ANY CONTENT SENT USING AND/OR INCLUDED ON THE SITE BY ANY THIRD PARTY. IN NO EVENT SHALL COMPANY, ITS AFFILIATES, ITS SUPPLIERS, AND/OR ANY THIRD PARTIES MENTIONED ON THE SITE BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING IN ANY WAY FROM THE PERFORMANCE, USE, OR INABILITY TO USE THE SITE AND/OR THE MATERIAL, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT SUCH DAMAGES ARE FORESEEABLE OR THE COMPANY OR ANY OF ITS AFFILIATES OR SUPPLIERS IS ADVISED OF THE POSSIBILTY OF SUCH DAMAGES. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE OR WITH ANY OF THESE TERMS AND CONDITIONS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE. BECAUSE SOME STATES OR JUSRISDICTIONS DO NOT ALLOW AN EXCLUSION OR LIMITATION FOR CONSEQUENTIAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF THIS LIMITATION OF LIABILITY OR THE EXCLUSION OF WARRANTY SET FORTH ABOVE IS HELD TO BE INAPPLICABLE OR UNENFORCEABLE FOR ANY REASON, THEN THE COMPANY’S MAXIMUM LIABILITY FOR ANY TYPE OF DAMAGES HEREUNDER SHALL BE LIMITED TO $100.

International Use of the Site
The Company makes no representation that the Site (including content or services provided on or available through the Site) is appropriate for or available to locations outside of the United States of America. Use of the Site from territories where it is illegal to do so is prohibited. Use of the Site outside of the United States of America is done at your own risk, and you are solely responsible for compliance with all local laws and regulations, including restrictions and controls on the importation of computer software or data, as well as all export control laws and regulations of the United States of America. Use of the Site in contravention of any applicable law or regulation is strictly prohibited.

Links
The Site may contain links to third party web sites (the “Linked Sites”). The Linked Sites are not under the control of the Company and the Company is not responsible for the content of any Linked Site, including, without limitation, any link contained in a Linked Site, or any changes or updates to a Linked Site. The Company is not responsible if a Linked Site is not working properly, and you are solely responsible for viewing and abiding by any privacy statement or terms of service posted on any Linked Site. Links to Linked Sites on the Site are provided solely as a convenience, and the inclusion of any such link does not imply endorsement by the Company of the site or any association with its operators or any goods or services offered or described on any Linked Site.

Site Security Rules
You are prohibited from violating or attempting to violate the security of the Site, including, without limitation: (a) accessing or attempting to access data not intended for your use or logging into an account that you are not authorized to access; (b) attempting to probe, scan or test the vulnerability of the Site or its network; (c) forging any TCP/IP packet header or any part of the header information in any e-mail or other posting to the Site. Violations of the Site’s system or network security may result in civil or criminal liability. The Company may investigate occurrences that it believes involve such violations and involve and cooperate with law enforcement authorities in prosecuting users of the Site who are involved in such violations.
In the event that you inadvertently obtain access to information belonging to or intended for a third party through the Site, you agree not produce any physical copy of such information, to disregard such information and immediately inform the Company of such inadvertent access, including the all of the circumstances leading to and surrounding such access and any dissemination of such third party information beyond the Site and all step taken by you or on your behalf to prevent the further dissemination of such third party information.

Indemnity
You agree to defend, indemnify, and hold the Company, its officers, directors, employees and agents, harmless from and against any and all third-party claims, actions, demands, suits, liability, damages, costs and/or proceedings, including, without limitation, reasonable legal, accounting or other professional fees, arising or resulting from your use of the Site, including your violation of the terms of this Agreement. The Company shall provide you notice of any such claim, action, demand, suit or proceeding, and shall assist you, at your expense, in defending against any such claim, action, demand, suit or proceeding.

Change or Discontinuation of the Site
The Company may change, suspend, or discontinue all or any portion or aspect of the Site at any time without notice, including the availability of any feature, database, content of other Material contained on or accessed through the Site. The Company may also impose limits on any feature or service or restrict user access to any or all parts of the Site without notice or liability.

Relationship of the Parties
You agree that no joint venture, partnership, employment or agency relationship exists between you and the Company (or any other user of the Site) as a result of this Agreement or your use of the Site.

Jurisdiction and Governing Law/Dispute Resolution
This Agreement is governed by the substantive laws of the State of South Carolina, without regard to its conflict of law principles. You agree and consent to the exclusive jurisdiction and venue of the state courts located in York County, South Carolina, U.S.A and the federal court located in Columbia, South Carolina, U.S.A.

Company Contact Information
If you need to contact the Company, you may do so as follows (or as may be otherwise set forth on the Site from time to time):
In-Store Marketplace
8700 North Street
Suite 200
Fishers, IN 46038
317.863.2824

General
If any part of this Agreement is determined to be invalid or unenforceable pursuant to applicable law, including, without limitation, the warranty disclaimers and limitations on liability or actions set forth herein, then the invalid or unenforceable provision will be construed in a manner consistent with applicable law to reflect, as nearly as possible, the intent of the original provision, and the remainder of the agreement shall continue in effect.

Regardless of the any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Site or this Agreement must be filed within one (1) year after such claim or cause of action arose or such claim or cause of action will be forever barred.

Unless otherwise specified herein, this Agreement constitutes the entire agreement between you and the Company with respect to your use of the Site, and it supersedes all prior or contemporaneous communications and proposals, whether verbal or written (electronic or otherwise), between you and the Company with respect to the Site. Any changes or amendments to this Agreement, other than revised versions of this Agreement posted on the Site from time to time, must be made in writing and signed by an authorized representative of the Company.

A printed version of this Agreement and or any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.

Privacy Statement
Please see https://us.moodmedia.com/company/privacy-policy/

Dated: December 5, 2024
`}
      </div>
    </div>
  );
}

TermsOfService.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

TermsOfService.defaultProps = {
  className: '',
  style: {},
};

export default TermsOfService;
