import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  GlobalActions,
  TableVisualLayoutTemplates,
  VibeButton,
  VibeIcon,
  viClose,
  viAdd,
  color,
} from 'vibeguide';
import CompanyLayoutTemplateEdit from './CompanyLayoutTemplateEdit';
import './CompanyLayoutTemplates.scss';

function CompanyLayoutTemplates({
  user,
  companyId,
  setPanel,
  onClose,
}) {
  /**
   * When the row is clicked
   */
  const onClickRow = (row) => {
    setPanel({
      thirdPanel: {
        width: 650,
        show: true,
        title: row._id
          ? row.name
          : 'New Layout Template',
        children: (
          <CompanyLayoutTemplateEdit
            templateId={row._id}
            companyId={row.companyId}
          />
        ),
      },
    });
  };

  return (
    <div className="CompanyLayoutTemplates">
      <div className="title-container">
        <div className="title">
          Layout Templates
        </div>

        <VibeButton
          style={{
            marginRight: 16,
          }}
          text="New Layout Template"
          icon={viAdd}
          color="primary"
          disabled={!user.can('visual_layout_template.create')}
          onClick={() => {
            onClickRow({
              companyId,
            });
          }}
        />

        <VibeIcon
          className="close"
          icon={viClose}
          color={color.manatee}
          hoverColor={color.obsidian}
          size={24}
          onClick={onClose}
        />
      </div>

      <TableVisualLayoutTemplates
        columns={[
          'Name',
          'Width',
          'Height',
          'Zones',
          '...',
        ]}
        fetch={API.Company.LayoutTemplate.list}
        fetchProps={{
          filters: {
            companyId,
          },
        }}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Layout Templates',
          urlPaging: false,
          urlFilters: false,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Layout Templates',
          tooltip: true,
        }}
        menuItems={[
          { name: 'Archive', userCan: 'visual_layout_template.delete' },
        ]}
        onClickRow={onClickRow}
      />
    </div>
  );
}

CompanyLayoutTemplates.propTypes = {
  companyId: PropTypes.string,
  onClose: PropTypes.func,
};

CompanyLayoutTemplates.defaultProps = {
  companyId: '',
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLayoutTemplates);
