import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/users`;

export default {
  list: async (data, options) => {
    const response = await Http.post(`${baseUrl}/search`, data, options);

    return {
      ...response,
      data: response.data.map(item => {
        return {
          ...item,
          imageUrl: Http.cloudfront(item.imageUrl),
        };
      }),
    };
  },
  getById: async (id, options) => {
    const response = await Http.get(`${baseUrl}/${id}`, options);

    return {
      ...response,
      imageUrl: Http.cloudfront(response.imageUrl),
    };
  },
  getLocations: async (data, options) => {
    const response = await Http.post(`${baseUrl}/${data._id}/locations`, data, options);

    return options?.headers?.Accept === 'text/csv'
      ? response
      : {
        ...response,
        data: response.data.map(item => {
          return {
            ...item,
            imageUrl: Http.cloudfront(item.imageUrl),
          };
        }),
      };
  },
  getCompanies: async (data, options) => {
    const response = await Http.post(`${baseUrl}/${data._id}/companies`, data, options);

    return options?.headers?.Accept === 'text/csv'
      ? response
      : {
        ...response,
        data: response.data.map(item => {
          return {
            ...item,
            imageUrl: Http.cloudfront(item.imageUrl),
          };
        }),
      };
  },
  getExpandedLocations: async (id, options) => {
    const response = await Http.get(`${baseUrl}/${id}/locations/expanded`, options) ?? [];

    return response.map(item => {
      const locations = item.locations ?? [];

      return {
        ...item,
        imageUrl: Http.cloudfront(item.imageUrl),
        locations: locations.map(location => {
          return {
            ...location,
            imageUrl: Http.cloudfront(location.imageUrl),
          };
        }),
      };
    });
  },
  create: (data, options) => {
    options = addVersion('8.8.0', options);
    return Http.post(baseUrl, data, options);
  },
  modify: (data) => Http.put(`${baseUrl}/${data._id}`, data),
  changePassword: (data) => Http.post(`${baseUrl}/${data._id}/password`, data),
  uploadImage: (id, file) => Http.postFileUpload(`${baseUrl}/${id}/image`, file, 'image'),
  listVoiceTalent: async (options) => {
    const response = await Http.get(`${baseUrl}/voicetalent`, options);

    return options?.headers?.Accept === 'text/csv'
      ? response
      : response.map((item) => {
        return {
          ...item,
          imageUrl: Http.cloudfront(item.imageUrl),
        };
      });
  },
  bulkAssignPermissionSet: (data) => Http.put(`${baseUrl}/permissions`, data),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (users) => Http.delete(baseUrl, {
    users,
  }),

  // Get all users matching permissions
  Permission: {
    // match "any" permission
    getAny: (permissions, options) => {
      const data = {
        pageSize: -1,
        filters: {
          active: true,
          permissions: {
            matchType: 'any',
            permissions,
          },
        },
      };

      return Http.post(`${baseUrl}/search`, data, options);
    },
    // match "all" permissions
    getAll: (permissions, options) => {
      const data = {
        pageSize: -1,
        filters: {
          active: true,
          permissions: {
            matchType: 'all',
            permissions,
          },
        },
      };

      return Http.post(`${baseUrl}/search`, data, options);
    },
  },
};
