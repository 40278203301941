import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  API,
  GlobalActions,
  LocationHelper,
  NavigationHelper,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Assignments,
  ToastActions,
  VibeModal,
  VibeTooltip,
  VibeButton,
  VibeIcon,
  viClose,
  viArchive,
  viCopy,
  viUnarchive,
  withRouter,
  color,
} from 'vibeguide';
import Information from './Information';
import PlaylistSongs from './PlaylistSongs';
import PlaylistLocations from './PlaylistLocations';
import './PlaylistDetails.scss';

class PlaylistDetails extends PureComponent {
  constructor(props) {
    super(props);

    // Store which values are updated that require an endpoint
    this.updated = {};
    this.origPlaylist = {};
    this.origPlaylistSaved = false;
    this.playlistImageFile = null;

    this.state = {
      confirmArchive: false,
    };
  }

  componentDidUpdate() {
    const {
      playlist,
      isDuplicate,
    } = this.props;

    if (isDuplicate && playlist.tags.length > 0) {
      playlist.tags.forEach((tag) => {
        tag.status = 'add';
      });
    }

    if (!this.origPlaylistSaved && playlist._id) {
      // Save the original playlist
      this.origPlaylist = playlist;
      this.origPlaylistSaved = true;
    }

    const {
      origPlaylist,
    } = this;

    if (this.origPlaylistSaved) {
      // Only track what fields are updated once the original playlist is saved
      if (playlist.playlists !== origPlaylist.playlists) {
        this.updated.playlists = true;
      }
    }
  }

  /**
   * When the locations assigned is clicked
   */
  onClickLocations = () => {
    const {
      user,
      playlist: {
        _id,
        companyId,
        locations,
        locationsData,
      },
      setPanel,
      onUpdate,
    } = this.props;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.can('playlist.assign_locations')
      && ((_id && user.can('playlist.modify'))
        || (!_id && user.can('playlist.create')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <PlaylistLocations
            companyId={companyId}
            locations={locations}
            locationsData={locationsData}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * When the Songs assigned is clicked
   */
  onClickSongs = () => {
    const {
      user,
      playlist: {
        _id,
        companyId,
        name,
        musicItems,
        averageDurationSeconds,
        locations,
      },
      setPanel,
      onCloseThirdPanel,
      onUpdate,
    } = this.props;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.exceedsLocations(locations)
      && user.can('music.view')
      && ((_id && user.can('playlist.modify'))
        || (!_id && user.can('playlist.create')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <PlaylistSongs
            playlistId={_id}
            title={name}
            songs={musicItems}
            averageDurationSeconds={averageDurationSeconds}
            allowChanges={allowChanges}
            onCloseAssigned={this.onCloseExtraPanel}
            onCloseAvailable={onCloseThirdPanel}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * When the extra panel is closed
   */
  onCloseExtraPanel = () => {
    const {
      onCloseExtraPanel,
    } = this.props;

    onCloseExtraPanel();
  };

  /**
   * When the archive button is clicked
   */
  onClickArchive = () => {
    this.setState({
      confirmArchive: true,
    });
  };

  /**
   * When the unarchive button is clicked
   */
  onClickUnarchive = async () => {
    const {
      playlist: {
        _id,
        locations,
      },
      onClose,
    } = this.props;

    await API.Playlist.reactivate({
      _id,
      locations,
    });
    onClose();

    document.dispatchEvent(new Event('onSavePlaylist'));
  };

  /**
   * When the archive modal is closed
   */
  onCloseArchive = () => {
    this.setState({
      confirmArchive: false,
    });
  };

  /**
   * When the archive modal is confirmed
   */
  onConfirmArchive = async () => {
    const {
      playlist: {
        _id,
      },
      onClose,
    } = this.props;

    await API.Playlist.deactivate(_id);
    this.onCloseArchive();
    onClose();

    document.dispatchEvent(new Event('onSavePlaylist'));
  };

  onUploadImage = (file) => {
    this.playlistImageFile = file;
  };

  /**
   * Save the Playlist
   */
  onSave = async () => {
    const {
      // user,
      playlist: {
        _id,
        // allLocations,
        name,
        category,
        companyId,
        description,
        locations,
        musicItems: songs,
        tags = [],
      },
      history,
      isNew,
      queueToast,
    } = this.props;

    document.dispatchEvent(new Event('onSavePlaylistStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const songIds = songs.map(song => song._id);
    const locationData = locations;

    // Get tags to add/remove
    const modifyTags = tags.filter(tag => tag.status === 'add' || tag.status === 'remove');
    const playlistId = _id;

    // Data to create playlist
    const data = {
      name,
      companyId,
      locations: locationData,
      musicItems: songIds,
      description,
      category,
      tags: modifyTags.map((tag) => {
        return {
          _id: tag._id,
          action: tag.status,
        };
      }),
    };

    let modify = false;

    if (playlistId && !isNew) {
      // Editing or saving an item
      data._id = playlistId;
      modify = true;
    }

    try {
      const playlistResponse = modify
        ? await API.Playlist.update(playlistId, data)
        : await API.Playlist.create(data);
      const newPlaylistId = get(playlistResponse, '[0].documentId', null);

      if (this.playlistImageFile) {
        // Upload playlist image
        const uploadPlaylistImage = await API.Playlist.uploadImage(newPlaylistId, this.playlistImageFile);
        const uploadPlaylistImageId = get(uploadPlaylistImage, '[0].documentId', null);

        if (uploadPlaylistImageId) {
          // Image was successful
          queueToast({
            type: 'success',
            title: 'Saved!',
            allowClose: true,
          });
        } else {
          queueToast({
            type: 'error',
            title: 'Error Saving Playlist',
            timeout: 10,
            allowClose: true,
          });
        }
      } else {
        // No image to upload
        queueToast({
          type: 'success',
          title: 'Saved!',
          allowClose: true,
        });
      }

      if (isNew) {
        // Redirect user to the edit page for the new item
        history(`/music/playlists?playlistId=${newPlaylistId}`);
      }

      // tell listening components the object was saved
      document.dispatchEvent(new Event('onSavePlaylist'));
    } catch (err) {
      document.dispatchEvent(new Event('onSavePlaylistError'));
    }
  };

  render() {
    const {
      user,
      playlist,
      onClose,
      onUpdate,
      isNew,
      isDuplicate,
    } = this.props;

    const {
      confirmArchive,
    } = this.state;

    // ACAF match
    const isACAF = user.isAllLocations({
      companyId: playlist.companyId,
      locations: playlist.locations,
    });

    const locationCount = LocationHelper.getLocationCount(get(playlist, 'locationsData.companies', []), isACAF);
    const exceedsLocations = user.exceedsLocations(playlist.locations);

    const disableSave = !playlist.name
      || !playlist.companyId
      || !playlist.category
      || !playlist.description
      || get(playlist, 'locations.length', 0) <= 0
      || get(playlist, 'musicItems.length', 0) <= 0;

    const disableInput = !user.hasAccessToCompany(playlist.companyId)
      || !exceedsLocations
      || (playlist._id && !user.can('playlist.modify'))
      || (!playlist._id && !user.can('playlist.create'));

    const showDuplicate = user.can('playlist.create')
      && user.exceedsLocations(playlist.locations);

    const showArchive = user.can('playlist.delete')
      && user.hasAccessToCompany(playlist.companyId)
      && exceedsLocations;
    const isArchived = !playlist.active;

    return (
      <SidePanelContainer className="PlaylistDetails">
        <SidePanelHeader
          icons={(
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onClose}
            />
          )}
        >
          {playlist._id ? (
            <div className="flex-horizontal">
              <div className="title">
                <VibeTooltip title={playlist.name}>
                  <span>
                    {playlist.name}
                  </span>
                </VibeTooltip>
              </div>
            </div>
          ) : (
            <div className="title">
              New Playlist
            </div>
          )}
        </SidePanelHeader>

        <SidePanelContent>
          <Information
            name={playlist.name}
            category={playlist.category}
            description={playlist.description}
            image={!isDuplicate
              ? playlist.imageUrl
              : null}
            isNew={isNew}
            isDuplicate={isDuplicate}
            companyName={playlist.companyName}
            companyId={playlist.companyId}
            tags={playlist.tags}
            disableInput={disableInput}
            onUpdate={onUpdate}
            onUploadImage={this.onUploadImage}
          />

          <Assignments
            items={[{
              label: 'Locations Assigned',
              count: locationCount.display.element,
              tooltip: locationCount.display.tooltip,
              required: true,
              disabled: !playlist.companyId
                || !exceedsLocations,
              warning: !exceedsLocations
                ? 'You do not have access to all the locations assigned to this object'
                : null,
              onClick: playlist.companyId && exceedsLocations
                ? this.onClickLocations
                : null,
            },
            {
              label: 'Songs Assigned',
              count: playlist.musicItemsCount,
              required: true,
              onClick: this.onClickSongs,
            }]}
          />
        </SidePanelContent>

        <SidePanelFooter>
          <VibeButton
            text="Save Changes"
            color="secondary"
            loadingEvent="onSavePlaylist"
            disabled={disableSave || disableInput}
            onClick={this.onSave}
          />

          {!isNew && (
            <>
              {showDuplicate && (
                <VibeIcon
                  icon={viCopy}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.primary,
                  }}
                  color={color.primary}
                  size={16}
                  tooltip="Duplicate"
                  link={NavigationHelper.updateParams({
                    type: 'new',
                  })}
                />
              )}

              {showArchive && !isArchived && (
                <VibeIcon
                  icon={viArchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.error,
                  }}
                  tooltip="Archive"
                  color={color.error}
                  size={20}
                  onClick={this.onClickArchive}
                />
              )}

              {showArchive && isArchived && (
                <VibeIcon
                  icon={viUnarchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.success,
                  }}
                  tooltip={locationCount.total > 0
                    ? 'Unarchive'
                    : 'Must have at least 1 active location to unarchive'}
                  color={color.success}
                  size={20}
                  disabled={locationCount.total <= 0}
                  onClick={this.onClickUnarchive}
                />
              )}
            </>
          )}
        </SidePanelFooter>

        <VibeModal
          show={confirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${playlist.name}?`}
          confirmProps={{
            text: 'Archive',
            color: 'error',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCloseArchive}
        />
      </SidePanelContainer>
    );
  }
}

PlaylistDetails.propTypes = {
  /** New Playlist */
  isNew: PropTypes.bool,
  /** Duplicate Playlist */
  isDuplicate: PropTypes.bool,
  playlist: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onCloseExtraPanel: PropTypes.func,
  onCloseThirdPanel: PropTypes.func,
  onUpdate: PropTypes.func,
};

PlaylistDetails.defaultProps = {
  isNew: false,
  isDuplicate: false,
  playlist: {},
  onClose: () => {},
  onCloseExtraPanel: () => {},
  onCloseThirdPanel: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlaylistDetails));
