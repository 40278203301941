import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEqual,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
  updateParams,
} from '../../../helpers/Navigation';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeButton from '../../VibeButton/VibeButton';
import CellTooltip from '../CellTypes/CellTooltip';
import IconCell from '../CellTypes/IconCell';
import TimestampCell from '../CellTypes/TimestampCell';
import ContentLabel from '../CellTypes/ContentLabel';
import viFlag from '../../../icons/viFlag';
import viFlagSlash from '../../../icons/viFlagSlash';
import viDownload from '../../../icons/viDownload';
import color from '../../../sass/color.scss';

const tableId = 'table:alerts';
const permissionPrefix = 'alert';

class TableAlerts extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: '...',
      defaultWidth: 72,
    },
    {
      name: 'Alert Category',
      searchAttr: 'category',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Volume',
          value: 'volume',
        },
        {
          label: 'Audio',
          value: 'audio',
        },
        {
          label: 'Battery',
          value: 'battery',
        },
        {
          label: 'Memory',
          value: 'memory',
        },
        {
          label: 'Network',
          value: 'network',
        },
        {
          label: 'Offline',
          value: 'offline',
        },
      ],
    },
    {
      name: 'Alert Type',
      searchAttr: 'alertType',
      defaultWidth: 275,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Company',
      searchAttr: 'companyName',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Location',
      searchAttr: 'locationName',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Time Generated',
      searchAttr: 'createdDate',
      defaultWidth: 245,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Time Resolved',
      searchAttr: 'resolvedDate',
      defaultWidth: 245,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Flagged',
      searchAttr: 'flagEnabled',
      icon: viFlag,
      defaultWidth: 120,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Flagged',
          value: 'true',
        },
        {
          label: 'Not Flagged',
          value: 'false',
        },
      ],
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
    };

    // listen for data changes
    document.addEventListener('onUpdateTableAlerts', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onUpdateTableAlerts', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  /**
   * When the Flag icon is clicked
   */
  onClickFlag = (e, _rowId) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      history,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    const {
      locationId,
    } = row;

    const url = updateParams({
      locationId,
      type: 'flags',
    });

    history(url);
  };

  onExport = () => {
    this.getData({
      export: true,
    });
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Get label style for the cell category
   */
  getLabelStyle = (type) => {
    switch (type) {
      case 'volume':
        return {
          text: 'Volume',
          textStyle: {
            background: color.success16,
            color: color.success,
          },
        };

      case 'battery':
        return {
          text: 'Battery',
          textStyle: {
            background: color.error16,
            color: color.error,
          },
        };

      case 'audio':
        return {
          text: 'Audio',
          textStyle: {
            background: color.orange16,
            color: color.orange,
          },
        };

      case 'memory':
        return {
          text: 'Memory',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      case 'network':
        return {
          text: 'Network',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      case 'offline':
        return {
          text: 'Offline',
          textStyle: {
            background: color.error16,
            color: color.error,
          },
        };

      default:
        return {
          text: type,
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };
    }
  };

  /**
   * Get the alert description based on the alert type
   */
  getAlertDescription = (alertType) => {
    // capitalize each word in the type and replace all "_" with spaces
    return (alertType || '').replace(/_/g, ' ');
  };

  renderCell = ({
    column,
    row,
  }) => {
    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Alert Category': {
        const {
          text,
          textStyle,
        } = this.getLabelStyle(value);

        return (
          <ContentLabel
            className="cell-content"
            text={text}
            textStyle={textStyle}
          />
        );
      }

      case 'Alert Type': {
        const description = this.getAlertDescription(value);

        return (
          <CellTooltip title={description}>
            <div
              className="cell-content"
              style={{
                textTransform: 'capitalize',
              }}
            >
              {description}
            </div>
          </CellTooltip>
        );
      }

      case 'Time Generated':
      case 'Time Resolved':
        return (
          <TimestampCell
            time={value}
          />
        );

      case 'Flagged':
        return (
          <IconCell
            rowId={row._rowId}
            icon={row.flagEnabled
              ? viFlag
              : viFlagSlash}
            color={row.flagEnabled
              ? color.error
              : color.manatee}
            hoverColor={row.flagEnabled
              ? color.error75
              : color.secondary}
            onClick={this.onClickFlag}
          />
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
      toolbarProps,
      csv,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
    } = this.state;

    const customButtons = csv
      ? [(
        <VibeButton
          key="btn-export"
          variant="outlined"
          text="Export"
          color="secondary"
          icon={viDownload}
          tooltip={`Export ${totalItems} Alerts to CSV`}
          tooltipProps={{
            placement: 'top',
          }}
          onClick={this.onExport}
        />
      )]
      : [];

    // add export button
    const newToolbarProps = {
      ...toolbarProps,
      customButtons: [
        ...toolbarProps.customButtons,
        ...customButtons,
      ],
    };

    return (
      <div className={classNames('Table', 'TableAlerts', className)}>
        <VibeTable
          {...this.props}
          toolbarProps={newToolbarProps}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          bulkArchive={API.Company.deactivateBulk}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />
      </div>
    );
  }
}

TableAlerts.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableAlerts.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableAlerts);
