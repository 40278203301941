import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  sortBy,
} from 'lodash';
import {
  API,
  ToastActions,
  Field2 as Field,
  Dropdown,
  VibeModal,
  VibeIcon,
  viRefresh,
  viAdd,
  color,
} from 'vibeguide';
import './PermissionRole.scss';

class PermissionRole extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      permissionSets: [],
      permissionSetId: null,
      showSaveNewConfirm: false,
      newPermissionSetError: false,
      newPermissionSetName: '',
      newPermissionSetDesc: '',
    };
  }

  componentDidMount() {
    const {
      disabled,
    } = this.props;

    if (!disabled) {
      this.getPermissionSets();
    }
  }

  onChange = (permissionSetId) => {
    const {
      onChange,
    } = this.props;

    this.setState({
      permissionSetId,
    });

    onChange(permissionSetId);
  };

  onUpdatePermissionSet = async () => {
    const {
      permissionIds,
      queueToast,
    } = this.props;

    const {
      permissionSetId,
    } = this.state;

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const response = await API.PermissionSet.modify({
      _id: permissionSetId,
      permissions: permissionIds,
    });

    const success = get(response, '[0].type') === 'PERMISSIONSET.UPDATED';

    if (success) {
      queueToast({
        type: 'success',
        title: 'Permission Set Updated!',
        allowClose: true,
        delay: 500,
      });
    }
  };

  onSaveNewPermissionSet = () => {
    this.setState({
      showSaveNewConfirm: true,
    });
  };

  onCancelSaveNewPermissionSet = () => {
    this.setState({
      showSaveNewConfirm: false,
      newPermissionSetError: false,
      newPermissionSetName: '',
      newPermissionSetDesc: '',
    });
  };

  onConfirmSaveNewPermissionSet = async () => {
    const {
      permissionIds,
      queueToast,
    } = this.props;

    const {
      newPermissionSetName,
      newPermissionSetDesc,
    } = this.state;

    if (!newPermissionSetName) {
      console.error('Permission Set Name is empty');

      this.setState({
        newPermissionSetError: true,
      });

      return;
    }

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const response = await API.PermissionSet.create({
      name: newPermissionSetName,
      description: newPermissionSetDesc,
      permissions: permissionIds,
    });

    const success = get(response, '[0].type') === 'PERMISSIONSET.CREATED';
    const permissionSetId = get(response, '[0].documentId', null);

    if (success) {
      queueToast({
        type: 'success',
        title: 'Permission Set Created!',
        allowClose: true,
        delay: 500,
      });
    }

    this.setState((state) => {
      const permissionSets = success
        ? [
          ...state.permissionSets,
          {
            _id: permissionSetId,
            name: newPermissionSetName,
            active: true,
          },
        ]
        : state.permissionSets;

      return {
        // add the permission set if it was successful
        permissionSets: sortBy(permissionSets, 'name'),
        showSaveNewConfirm: false,
        newPermissionSetError: false,
        newPermissionSetName: '',
        newPermissionSetDesc: '',
        permissionSetId,
      };
    });
  };

  onChangeNewPermissionSetData = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    this.setState((state) => {
      return {
        [name]: value,
        newPermissionSetError: !state.newPermissionSetName && name !== 'newPermissionSetName',
      };
    });
  };

  getPermissionSets = async () => {
    const permissionSets = await API.PermissionSet.list();

    this.setState({
      permissionSets: sortBy(permissionSets, 'name'),
    });
  };

  render() {
    const {
      user,
      className,
      disabled,
      allowActions,
    } = this.props;

    const {
      permissionSets,
      permissionSetId,
      showSaveNewConfirm,
      newPermissionSetName,
      newPermissionSetDesc,
      newPermissionSetError,
    } = this.state;

    const disableModify = disabled || !permissionSetId;

    return (
      <div className={classNames('PermissionRole', className)}>
        <div className="permission-row">
          <Field
            rootClassName="permission-dropdown"
            type="custom"
            label="Permission Set"
            formProps={{
              style: {
                flexGrow: 1,
              },
            }}
          >
            <Dropdown
              title="Choose..."
              items={permissionSets.map((permissionSet) => {
                return {
                  name: permissionSet.name,
                  value: permissionSet._id,
                };
              })}
              value={permissionSetId}
              disabled={disabled}
              onChange={this.onChange}
            />
          </Field>

          {allowActions && user.can('permissionset.modify') ? (
            <div
              className={classNames('permission-action', 'action-update', { disabled: disableModify })}
              onClick={!disableModify
                ? this.onUpdatePermissionSet
                : null}
            >
              <div className="action-item">
                <VibeIcon
                  icon={viRefresh}
                  color={color.primary}
                  size={16}
                />

                <div className="action-name">
                  Update
                </div>
              </div>
            </div>
          ) : null}

          {allowActions && user.can('permissionset.create') ? (
            <div
              className={classNames('permission-action', 'action-save', { disabled })}
              onClick={!disabled
                ? this.onSaveNewPermissionSet
                : null}
            >
              <div className="action-item">
                <VibeIcon
                  icon={viAdd}
                  color={color.success}
                  size={16}
                />

                <div className="action-name">
                  Save as New
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <VibeModal
          show={showSaveNewConfirm}
          type="confirm"
          confirmProps={{
            text: 'Save Permission Set',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          title="Save New Permission Set"
          text={(
            <div>
              <Field
                rootClassName={newPermissionSetError
                  ? 'has-error'
                  : ''}
                type="text"
                label="Name"
                placeholder="Permission Set Name"
                name="newPermissionSetName"
                value={newPermissionSetName}
                tabIndex={1}
                marginTop={8}
                marginBottom={16}
                onChange={this.onChangeNewPermissionSetData}
                autoFocus
                required
              />

              <Field
                className="permission-set-desc"
                type="textarea"
                label="Description"
                placeholder="What is this permission set?"
                name="newPermissionSetDesc"
                value={newPermissionSetDesc}
                tabIndex={2}
                onChange={this.onChangeNewPermissionSetData}
              />
            </div>
          )}
          onConfirm={this.onConfirmSaveNewPermissionSet}
          onClose={this.onCancelSaveNewPermissionSet}
        />
      </div>
    );
  }
}

PermissionRole.propTypes = {
  className: PropTypes.string,
  permissionIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  /** Show the update/save actions */
  allowActions: PropTypes.bool,
  onChange: PropTypes.func,
};

PermissionRole.defaultProps = {
  className: '',
  permissionIds: [],
  disabled: false,
  allowActions: false,
  onChange: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionRole);
