import request from 'superagent';
import moment from 'moment';
import jwt from 'jsonwebtoken';
import queryString from 'query-string';
import {
  get,
} from 'lodash';
import {
  store,
} from '../lib/styleguide/Wrapper';
import {
  doAuthenticateUser,
} from '../actions/Login/LoginActions';
import {
  setPanel,
} from '../actions/Global/GlobalActions';
import {
  getCookie,
  setCookie,
  removeCookie,
} from './CookieUtil';
import pkg from '../../../../package.json';

const appEnv = process.env.APP_ENV;
const local = appEnv === 'local';
const development = appEnv === 'development';
const clientHeader = local || development
  ? `web/Dev V${pkg.version}`
  : `web/${pkg.version}`;

/**
 * Check if the user session is valid
 */
function isValidSession() {
  const idToken = getCookie('app:user:idToken');
  const idPayload = jwt.decode(idToken);

  if (idPayload) {
    const idExp = moment.unix(idPayload.exp);
    const now = moment();

    return idExp.isAfter(now);
  }

  return false;
}

/**
 * Authenticate the user
 */
export async function authenticate(authData) {
  return new Promise((resolve, reject) => {
    request
      .post(`${process.env.VAPI_BASE_URL}/authentication/authenticate`)
      .send(authData)
      .set('Accept', 'application/json')
      .set('VAPI-Client', clientHeader)
      .end((err, res) => {
        const errorMessage = get(res, 'body.@@errorMessage');

        if (errorMessage) {
          reject(new Error(errorMessage));
        } else {
          resolve(res.body);
        }
      });
  });
}

/**
 * Log the user out of the application
 */
export function logout() {
  // Clear the cookies
  removeCookie('app:user:idToken');
  removeCookie('app:user:accessToken');
  removeCookie('app:user:refreshToken');
  removeCookie('app:media:params');
  // removeCookie('CloudFront-Domain');
  // removeCookie('CloudFront-Expires');
  removeCookie('CloudFront-Key-Pair-Id');
  removeCookie('CloudFront-Policy');
  removeCookie('CloudFront-Signature');

  // force the user to logout
  store.dispatch(doAuthenticateUser(false));

  // close the sidepanel (and secondary/tertiary panels)
  store.dispatch(setPanel({
    show: false,
    extraPanel: {
      show: false,
    },
    thirdPanel: {
      show: false,
    },
    fourthPanel: {
      show: false,
    },
  }));

  // Reset the sidepanel children after it's been hidden
  setTimeout(() => {
    setPanel({
      children: null,
      extraPanel: {
        children: null,
      },
      thirdPanel: {
        children: null,
      },
    });
  }, 900);
}

/**
 * Save all tokens to cookies
 */
export function saveTokens({
  idToken,
  accessToken,
  refreshToken,
}) {
  const idPayload = jwt.decode(idToken);
  const accessPayload = jwt.decode(accessToken);

  if (idPayload && accessPayload) {
    const idTokenExpiresAt = moment.unix(idPayload.exp);
    const accessTokenExpiresAt = moment.unix(accessPayload.exp);

    const idExp = idTokenExpiresAt
      .add(1, 'week')
      .toDate()
      .toUTCString();

    const accessExp = accessTokenExpiresAt
      .add(1, 'week')
      .toDate()
      .toUTCString();

    // Store the user token as a cookie
    setCookie({
      key: 'app:user:idToken',
      value: idToken,
      expireDate: idExp,
    });

    // Store the access token as a cookie
    setCookie({
      key: 'app:user:accessToken',
      value: accessToken,
      expireDate: accessExp,
    });

    if (refreshToken) {
      // Store the refresh token as a cookie
      const refreshExpireDate = new moment()
        .add(1, 'month')
        .toDate()
        .toUTCString();

      setCookie({
        key: 'app:user:refreshToken',
        value: refreshToken,
        expireDate: refreshExpireDate,
      });
    }
  }
}

/**
 * Refresh the hosted content cookies (CloudFront)
 */
export async function refreshHostedContent({
  idToken,
}) {
  // const {
  //   HOSTED_CONTENT_URL: hostedContentUrl,
  // } = process.env;

  const response = await request
    .get(`${process.env.VAPI_BASE_URL}/hostedcontent`)
    .set('Authorization', `Bearer ${idToken}`)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('Access-Control-Allow-Methods', 'GET')
    .set('Content-Type', 'application/json')
    .set('VAPI-Client', clientHeader);

  const cookies = response.body || {};

  const data = {
    keyPairId: cookies['CloudFront-Key-Pair-Id'],
    policy: cookies['CloudFront-Policy'],
    signature: cookies['CloudFront-Signature'],
    expiresAt: moment(cookies.ExpiresAt),
  };

  // When the cookies actually expire
  const expireDate = data.expiresAt.toDate().toUTCString();

  // When to check for new (subtract a few minutes to make sure the cookies are fetched before they expire)
  // const expireCheckDate = data.expiresAt.subtract(5, 'minutes').toISOString();

  const cloudfrontCookies = {
    Policy: data.policy,
    Signature: data.signature,
    'Key-Pair-Id': data.keyPairId,
  };

  // TO DO: Make setCookies() function to handle all of these without individual calls
  // setCookie({
  //   key: 'CloudFront-Domain',
  //   value: hostedContentUrl,
  //   expireDate,
  // });

  // setCookie({
  //   key: 'CloudFront-Expires',
  //   value: expireCheckDate,
  //   expireDate,
  // });

  setCookie({
    key: 'CloudFront-Policy',
    value: data.policy,
    expireDate,
  });

  setCookie({
    key: 'CloudFront-Signature',
    value: data.signature,
    expireDate,
  });

  setCookie({
    key: 'CloudFront-Key-Pair-Id',
    value: data.keyPairId,
    expireDate,
  });

  // Add these Parameters to Media URLs
  setCookie({
    key: 'app:media:params',
    value: queryString.stringify(cloudfrontCookies),
    expireDate,
  });
}

/**
 * Check the user session and fetch new tokens if needed
 */
export async function checkSession() {
  const isValid = isValidSession();

  if (isValid) {
    // session is valid and cookies/tokens do not need to be updated
    return;
  }

  const refreshToken = getCookie('app:user:refreshToken');

  if (!refreshToken) {
    // cannot refresh a session without a refresh token
    console.warn('checkSession(): Cannot refresh without a refresh token');
    logout();
    return;
  }

  const authData = {
    authType: 'refreshToken',
    authParams: {
      refreshToken,
    },
  };

  try {
    const response = await authenticate(authData);
    const success = get(response, '[0].type') === 'AUTHENTICATION.AUTHENTICATED';

    if (success) {
      const {
        accessToken,
        idToken,
      } = get(response, '[0].data', {});

      saveTokens({
        idToken,
        accessToken,
      });

      // refresh CloudFront cookies
      await refreshHostedContent({
        idToken,
      });

      console.warn('checkSession(): Tokens Refreshed', response);
    } else {
      console.error('checkSession(): Auth failed', response);
      logout();
    }
  } catch (err) {
    console.error('checkSession(): Auth failed, redirecting to login page', err);
    logout();
  }
}

/**
 * Get the current user session
 */
export async function getSession() {
  await checkSession();

  const idToken = getCookie('app:user:idToken');
  const accessToken = getCookie('app:user:accessToken');
  const refreshToken = getCookie('app:user:refreshToken');

  if (!idToken && !accessToken && !refreshToken) {
    console.warn('There is no active user session');
    return {};
  }

  return {
    idToken,
    accessToken,
    refreshToken,
  };
}
