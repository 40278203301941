import {
  get,
  set,
  map,
  orderBy,
  toInteger,
} from 'lodash';
import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/music`;

export default {
  list: async (data, options) => {
    const tempo = get(data, 'filters.tempo');

    if (tempo) {
      // tempo must be a number
      set(data, 'filters.tempo', toInteger(tempo));
    }

    const response = await Http.post(`${baseUrl}/search`, data, options);

    return options?.headers?.Accept === 'text/csv'
      ? response
      : {
        ...response,
        data: response.data.map(item => {
          return {
            ...item,
            contentUrl: Http.cloudfront(item.contentUrl),
          };
        }),
      };
  },
  listUploaded: async (data, options) => {
    const tempo = get(data, 'filters.tempo');

    if (tempo) {
      // tempo must be a number
      set(data, 'filters.tempo', toInteger(tempo));
    }

    const response = await Http.post(`${baseUrl}/uploaded/search`, data, options);

    return {
      ...response,
      data: response.map(item => {
        return {
          ...item,
          contentUrl: Http.cloudfront(item.contentUrl),
        };
      }),
    };
  },
  getById: id => Http.get(`${baseUrl}/${id}`),
  getProList: async () => {
    const response = await Http.get(`${baseUrl}/pros`);

    const proDefinitions = map(response.proDefinitions, (item, name) => {
      return {
        name,
        ...item,
      };
    });

    return orderBy(proDefinitions, 'sequence');
  },
  getPlaylists: (data) => Http.get(`${baseUrl}/${data._id}/playlists`),
  create: data => Http.post(baseUrl, data),
  update: (data) => Http.put(`${baseUrl}/${data._id}`, data),
  updateBulk: (data) => Http.put(baseUrl, data),
  reviewPublish: data => Http.put(`${baseUrl}/uploaded`, data),
  block: (data) => Http.put(`${baseUrl}/${data._id}/block`, data),
  replaceArtwork: (id, image, onProgress) => Http.postFileUpload(
    `${baseUrl}/${id}/artwork`, image, 'artwork', onProgress,
  ),
  replaceMusic: (id, media) => Http.postFile(`${baseUrl}/${id}/replace`, { media }),
  uploadMusic: (file, onProgress) => Http.postFileUpload(`${baseUrl}`, file, 'media', onProgress),
  deactivateBulk: (music) => Http.delete(baseUrl, {
    music,
  }),
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
};
