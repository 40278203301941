import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
  find,
  clone,
  sortBy,
} from 'lodash';
import {
  API,
  EventModel,
  EventActions,
  LocationHelper,
  TimeUtil,
  Field2 as Field,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  Drawer,
  VibeTooltip,
  VibeButton,
  VibeModal,
  VibeIcon,
  viClose,
  viTrash,
  color,
} from 'vibeguide';
import ScheduleHeader from '../../../Locations/IndividualLocation/Schedule/ScheduleHeader';
import ScheduleData from '../../../Locations/IndividualLocation/Schedule/ScheduleData';
import DisplayOptions from '../../../Locations/IndividualLocation/Schedule/Controls/DisplayOptions';
import './EventSchedule.scss';

function EventSchedule({
  className,
  event: propEvent,
  user,
  currentLocation,
  onUpdate,
  onClose,
}) {
  const [event, setEvent] = useState(propEvent);
  const [activeDisplay, setActiveDisplay] = useState('24h');
  const [confirmClear, setConfirmClear] = useState(false);

  const onUpdateEvent = (eventData) => {
    setEvent(EventActions.updateEvent({
      ...event,
      ...eventData,
    }));
    onUpdate({
      ...eventData,
    });
  };

  /**
   * When an item is dropped onto the editor
   */
  const onDropItemOnEditor = (e) => {
    const {
      type,
      itemStart,
      itemStop,
      item,
    } = e.detail;

    switch (type) {
      case 'Playlist': {
        const newPlaylists = event.addPlaylist({
          playlist: item,
          startTime: itemStart,
          stopTime: itemStop,
        });

        onUpdateEvent({
          playlists: newPlaylists,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Music',
            items: sortBy([
              ...newPlaylists,
              ...event.mixes,
            ], 'startTime'),
          },
        }));
        break;
      }

      case 'Mix': {
        const newStations = event.addStation({
          station: item,
          startTime: itemStart,
          stopTime: itemStop,
        });

        onUpdateEvent({
          mixes: newStations,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Music',
            items: sortBy([
              ...event.playlists,
              ...newStations,
            ], 'startTime'),
          },
        }));
        break;
      }

      case 'Message Block': {
        const newMessageBlocks = event.addMessageBlock({
          messageBlock: item,
          startTime: itemStart,
          stopTime: itemStop,
        });

        onUpdateEvent({
          messageBlocks: newMessageBlocks,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Message Blocks',
            items: newMessageBlocks,
          },
        }));
        break;
      }

      case 'Message': {
        const companyId = get(currentLocation, 'companyId', null);
        const locationId = get(currentLocation, '_id', null);
        // only check the interrupts at a location if we are on a specific location
        const skipLocationCheck = !companyId || !locationId;

        const compareResult = LocationHelper.compare(event.locations, item.locations);
        // Determine if the interrupt has access to all the locations for this object
        const accessAllLocations = compareResult.parent.missing.length <= 0;
        // Determine if the interrupt has access to this location
        const accessCurrentLocation = accessAllLocations
          || find(item.locations, { companyId, locationId }) !== undefined
          || find(item.locations, { companyId, locationId: '*' }) !== undefined;

        item.accessAllLocations = accessAllLocations;
        item.accessCurrentLocation = skipLocationCheck || accessCurrentLocation;

        const newInterrupts = event.addInterrupt({
          interrupt: item,
          startTime: itemStart,
        });

        onUpdateEvent({
          interruptions: newInterrupts,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Interrupts',
            items: newInterrupts,
          },
        }));
        break;
      }

      default:
        break;
    }
  };

  /**
   * When an item is dropped onto the editor
   */
  const onUpdateScheduleItems = (e) => {
    const {
      items,
    } = e.detail;

    items.forEach((item) => {
      if (item.playlist) {
        const newPlaylists = event.updatePlaylist(item);

        onUpdateEvent({
          playlists: newPlaylists,
        });
      } else if (item.mix) {
        const newStations = event.updateStation(item);

        onUpdateEvent({
          mixes: newStations,
        });
      } else if (item.messageBlock) {
        const newMessageBlocks = event.updateMessageBlock(item);

        onUpdateEvent({
          messageBlocks: newMessageBlocks,
        });
      } else if (item.message) {
        const newInterrupts = event.updateInterrupt(item);

        onUpdateEvent({
          interruptions: newInterrupts,
        });
      }
    });
  };

  /**
   * Change an items start/stop time
   */
  const onChangeItemDuration = (e) => {
    const {
      type,
      startTime,
      stopTime,
      _uniqueId,
    } = e.detail;

    const data = {};

    if (startTime) {
      data.startTime = startTime;
    }

    if (stopTime) {
      data.stopTime = stopTime;
    }

    switch (type) {
      case 'Playlist': {
        const newPlaylists = event.updatePlaylist({
          _uniqueId,
          ...data,
        });

        onUpdateEvent({
          playlists: newPlaylists,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Music',
            items: sortBy([
              ...newPlaylists,
              ...event.mixes,
            ], 'startTime'),
          },
        }));
        break;
      }

      case 'Mix': {
        const newStations = event.updateStation({
          _uniqueId,
          ...data,
        });

        onUpdateEvent({
          mixes: newStations,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Music',
            items: sortBy([
              ...event.playlists,
              ...newStations,
            ], 'startTime'),
          },
        }));
        break;
      }

      case 'Message Block': {
        const newMessageBlocks = event.updateMessageBlock({
          _uniqueId,
          ...data,
        });

        onUpdateEvent({
          messageBlocks: newMessageBlocks,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Message Blocks',
            items: newMessageBlocks,
          },
        }));
        break;
      }

      case 'Message': {
        const newInterrupts = event.updateInterrupt({
          _uniqueId,
          ...data,
        });

        onUpdateEvent({
          interruptions: newInterrupts,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Interrupts',
            items: newInterrupts,
          },
        }));
        break;
      }

      default:
        break;
    }
  };

  /**
   * Remove an item from the event
   */
  const onRemoveItem = (e) => {
    const {
      type,
      _uniqueId,
      removeOption,
    } = e.detail;

    switch (type) {
      case 'Playlist': {
        const newPlaylists = event.removePlaylist(_uniqueId);

        onUpdateEvent({
          playlists: newPlaylists,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Music',
            items: sortBy([
              ...newPlaylists,
              ...event.mixes,
            ], 'startTime'),
          },
        }));
        break;
      }

      case 'Mix': {
        const newStations = event.removeStation(_uniqueId);

        onUpdateEvent({
          mixes: newStations,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Music',
            items: sortBy([
              ...event.playlists,
              ...newStations,
            ], 'startTime'),
          },
        }));
        break;
      }

      case 'Message Block': {
        const newMessageBlocks = event.removeMessageBlock(_uniqueId);

        onUpdateEvent({
          messageBlocks: newMessageBlocks,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Message Blocks',
            items: newMessageBlocks,
          },
        }));
        break;
      }

      case 'Message': {
        const newInterrupts = event.removeInterrupt(_uniqueId, removeOption);

        onUpdateEvent({
          interruptions: newInterrupts,
        });

        setTimeout(() => {
          document.dispatchEvent(new CustomEvent('onChangeItems', {
            detail: {
              type: 'Interrupts',
              items: newInterrupts,
            },
          }));
        }, 0);
        break;
      }

      default:
        break;
    }
  };

  /**
   * Repeat an interrupt
   */
  const onRepeatInterrupt = (e) => {
    const {
      _uniqueId,
      rangeStart,
      rangeStop,
      interval,
      unit,
    } = e.detail;

    // find the interrupt to copy
    const interrupt = clone(find(event.interruptions, { _uniqueId }));
    delete interrupt._uniqueId;

    if (!interrupt) {
      console.error('Interrupt not found to copy with _uniqueId', _uniqueId);
      return;
    }

    const eventStartMinutes = TimeUtil.getMinutes(event.startTime);
    const eventStopMinutes = TimeUtil.getMinutes(event.stopTime);
    const rangeStartMinutes = TimeUtil.getMinutes(rangeStart);
    const rangeStopMinutes = TimeUtil.getMinutes(rangeStop);
    // Get the repeat innterval in minutes
    const repeatIntervalMinutes = unit === 'minutes'
      ? interval
      : (interval * 60);

    for (let i = rangeStartMinutes; i <= rangeStopMinutes; i += repeatIntervalMinutes) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      const startTime = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
      // get the minutes for this iteration
      const instanceMinutes = TimeUtil.getMinutes(startTime);

      if (instanceMinutes < eventStartMinutes) {
        // cannot repeat before an event starts
        // eslint-disable-next-line no-continue
        continue;
      }

      if (instanceMinutes >= eventStopMinutes) {
        // cannot repeat after an event ends
        // eslint-disable-next-line no-continue
        continue;
      }

      const item = find(event.interruptions, { startTime });

      if (!item) {
        // interrupt does not exist at this time, add it
        const newInterrupts = event.addInterrupt({
          interrupt: interrupt.message,
          startTime,
        });

        onUpdateEvent({
          interruptions: newInterrupts,
        });

        document.dispatchEvent(new CustomEvent('onChangeItems', {
          detail: {
            type: 'Interrupts',
            items: newInterrupts,
          },
        }));
      }
    }
  };

  useEffect(() => {
    document.addEventListener('onDropItemOnEditor', onDropItemOnEditor);
    document.addEventListener('onUpdateScheduleItems', onUpdateScheduleItems);
    document.addEventListener('onChangeItemDuration', onChangeItemDuration);
    document.addEventListener('onRemoveItem', onRemoveItem);
    document.addEventListener('onRepeatInterrupt', onRepeatInterrupt);

    return () => {
      document.removeEventListener('onDropItemOnEditor', onDropItemOnEditor);
      document.removeEventListener('onUpdateScheduleItems', onUpdateScheduleItems);
      document.removeEventListener('onChangeItemDuration', onChangeItemDuration);
      document.removeEventListener('onRemoveItem', onRemoveItem);
      document.removeEventListener('onRepeatInterrupt', onRepeatInterrupt);
    };
  }, [event]);

  const onChangeDisplay = (display) => {
    setActiveDisplay(display);
  };

  const onChangeStartTime = (data) => {
    const spltTime = data.time24.split(':');
    const hour = spltTime[0];
    const minute = spltTime[1];

    const newStartDate = new moment(event.startDate)
      .hour(hour)
      .minute(minute)
      .second(0);

    onUpdateEvent({
      startDate: newStartDate.toDate(),
      startTime: data.time24,
      updateRule: true,
    });
  };

  const onChangeStopTime = (data) => {
    onUpdateEvent({
      stopTime: data.time24,
    });
  };

  const onClickClearEvent = () => {
    setConfirmClear(true);
  };

  const onConfirmClearEvent = () => {
    setConfirmClear(false);
    onUpdateEvent({
      playlists: [],
      mixes: [],
      messageBlocks: [],
      interruptions: [],
    });

    document.dispatchEvent(new Event('onClearScheduleItems'));
  };

  const onCancelClearEvent = () => {
    setConfirmClear(false);
  };

  const canModifyEvent = ((event._id && event._id !== 'new-event' && user.can('event.modify'))
    || ((!event._id || event._id === 'new-event') && user.can('event.create')));

  const drawerItems = [];
  // Group the location spec
  const group = LocationHelper.group(event.locations);

  if (canModifyEvent) {
    if (user.can('playlist.view')) {
      drawerItems.push({
        label: 'Playlists',
        fetch: API.Playlist.list,
        filters: {
          locations: {
            matchType: 'contains',
            locations: group,
          },
        },
      });
    }

    if (user.can('mix.view')) {
      drawerItems.push({
        label: 'Stations',
        fetch: API.Mix.list,
        filters: {
          locations: {
            matchType: 'contains',
            locations: group,
          },
        },
      });
    }

    if (user.can('messagelist.view')) {
      drawerItems.push({
        label: 'Message Blocks',
        fetch: API.MessageBlock.list,
        filters: {
          locations: {
            matchType: 'contains',
            locations: group,
          },
        },
      });
    }

    if (user.can('message.view')) {
      drawerItems.push({
        label: 'Interrupts',
        fetch: API.Message.list,
        filters: {
          locations: {
            matchType: 'intersect',
            locations: group,
          },
        },
      });
    }

    if (user.can('advertisement.schedule') && user.can('message.view')) {
      drawerItems.push({
        label: 'Ads',
        fetch: API.Message.list,
        filters: {
          messageType: 'ad',
          locations: {
            matchType: 'intersect',
            locations: group,
          },
        },
      });
    }
  }

  return (
    <SidePanelContainer className={classNames('EventSchedule', className)}>
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="details-header">
          <VibeTooltip title={event.title}>
            <div className="title">
              {event.title}
            </div>
          </VibeTooltip>

          <div className="event-toolbar">
            <div className="toolbar-item">
              <Field
                type="time"
                value={event.startTime}
                disabled={!canModifyEvent}
                onChange={onChangeStartTime}
              />
            </div>

            <div className="toolbar-item item-small">
              to
            </div>

            <div className="toolbar-item item-small">
              <Field
                type="time"
                value={event.stopTime}
                disabled={!canModifyEvent}
                onChange={onChangeStopTime}
              />
            </div>

            <div className="toolbar-item item-display">
              <DisplayOptions
                className="display-options"
                activeDisplay={activeDisplay}
                onChangeDisplay={onChangeDisplay}
              />

              {canModifyEvent && (
                <div className="toolbar-item">
                  <VibeButton
                    text="Clear Event"
                    color="primary"
                    icon={viTrash}
                    onClick={onClickClearEvent}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </SidePanelHeader>

      <SidePanelContent className="event-sidepanel-content">
        <ScheduleHeader
          type="event"
          activeEvent={event}
          readOnly={!canModifyEvent}
          onUpdateEvent={onUpdateEvent}
        />

        <ScheduleData
          type="event"
          style={{
            height:
              activeDisplay === '24h'
                ? 'calc(100% - 65px)'
                : 'auto',
          }}
          activeDisplay={activeDisplay}
          startOfWeek={moment().startOf('week')}
          currTime={moment()}
          playlists={event.playlists}
          mixes={event.mixes}
          messageBlocks={event.messageBlocks}
          interrupts={event.interruptions}
          activeEvent={event}
          readOnly={!canModifyEvent}
          // onUpdateEvent={onUpdateEvent}
        />
      </SidePanelContent>

      {canModifyEvent ? (
        <SidePanelFooter className="event-sidepanel-footer">
          <Drawer
            items={drawerItems}
          />
        </SidePanelFooter>
      ) : null}

      <VibeModal
        show={confirmClear}
        type="confirm"
        confirmProps={{
          text: 'Clear Event',
        }}
        cancelProps={{
          text: 'Cancel',
        }}
        title={`Clear ${event.title}`}
        text="Are you sure you want to remove all items in this event? This action cannot be undone."
        onConfirm={onConfirmClearEvent}
        onClose={onCancelClearEvent}
      />
    </SidePanelContainer>
  );
}

EventSchedule.propTypes = {
  className: PropTypes.string,
  event: PropTypes.instanceOf(EventModel).isRequired,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
};

EventSchedule.defaultProps = {
  className: '',
  onUpdate: () => {},
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default connect(mapStateToProps)(EventSchedule);
