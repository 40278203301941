import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/mixes`;

export default {
  list: async (data, options) => {
    const response = await Http.post(`${baseUrl}/search`, data, options);

    return {
      ...response,
      data: response.data.map(item => {
        return {
          ...item,
          imageUrl: Http.cloudfront(item.imageUrl),
        };
      }),
    };
  },
  getById: async (id) => {
    const response = await Http.get(`${baseUrl}/${id}`);

    return {
      ...response,
      imageUrl: Http.cloudfront(response.imageUrl),
    };
  },
  getMusic: (id, options) => Http.get(`${baseUrl}/${id}/music`, options),
  getExpandedLocations: async (id, options) => {
    const response = await Http.get(`${baseUrl}/${id}/locations/expanded`, options) ?? [];

    return response.map(item => {
      const locations = item.locations ?? [];

      return {
        ...item,
        imageUrl: Http.cloudfront(item.imageUrl),
        locations: locations.map(location => {
          return {
            ...location,
            imageUrl: Http.cloudfront(location.imageUrl),
          };
        }),
      };
    });
  },
  create: data => Http.post(baseUrl, data),
  modify: (id, data) => Http.put(`${baseUrl}/${id}`, data),
  uploadImage: (id, file, onProgress) => Http.postFileUpload(
    `${baseUrl}/${id}/image`, file, 'image', onProgress,
  ),
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (mixes) => Http.delete(baseUrl, {
    mixes,
  }),
};
