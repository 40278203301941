import Http from '../Http';

const baseUrl = `${process.env.VAPI_BASE_URL}/locationzones`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  getById: (id, options) => Http.get(`${baseUrl}/${id}`, options),
  update: (data) => Http.put(`${baseUrl}/${data._id}`, data),
  create: (data) => Http.post(`${baseUrl}`, data),
  reactivate: (data, options) => Http.post(`${baseUrl}/${data._id}`, data, options),
  deactivate: (id) => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (locationZones) => Http.delete(baseUrl, {
    locationZones,
  }),
};
