import Http from '../Http';

const baseUrl = `${process.env.VAPI_BASE_URL}/targetgroups`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  get: (id, options) => Http.get(`${baseUrl}/${id}`, options),
  getExpandedLocations: async (data, options) => {
    const response = await Http.get(`${baseUrl}/${data._id}/locations/expanded`, options) ?? [];

    return response.map(item => {
      const locations = item.locations ?? [];

      return {
        ...item,
        imageUrl: Http.cloudfront(item.imageUrl),
        locations: locations.map(location => {
          return {
            ...location,
            imageUrl: Http.cloudfront(location.imageUrl),
          };
        }),
      };
    });
  },
  create: (data, options) => Http.post(baseUrl, data, options),
  update: (data, options) => Http.put(`${baseUrl}/${data._id}`, data, options),
  history: (data, options) => Http.post(`${baseUrl}/${data._id}/history`, data, options),
  reactivate: (data, options) => Http.post(`${baseUrl}/${data._id}`, data, options),
  deactivate: (id) => Http.delete(`${baseUrl}/${id}`),
};
