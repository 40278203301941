import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  API,
  GlobalActions,
  NavigationHelper,
  TableSupportDashboard,
  withRouter,
  VibeButton,
} from 'vibeguide';
import SupportSidebar from './SupportSidebar/SupportSidebar';
import LocationSidebar from '../Locations/Sidebar/LocationSidebar';
import DashboardHeader from './DashboardHeader';
import './DashboardSupport.scss';

function DashboardSupport({
  location,
  history,
  user,
  setPanel,
}) {
  const { type } = useParams();
  const prevSearchRef = useRef(location.search);

  const qs = NavigationHelper.getParams() || {};

  const suppressSupportDashboardLookup = !qs.force
  && (user.locationsAllowed.length > 50
  || user.locationsAllowed.some(({ companyId, locationId }) => companyId === '*' || locationId === '*'));

  const [requireFilter, setRequireFilter] = useState(suppressSupportDashboardLookup);

  let sidebarTimeout = null;

  /**
   * Ensure a filter is provided if required
   */
  const onFetchComplete = ({
    requireFilter = false,
  }) => {
    setRequireFilter(requireFilter);
  };

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      locationId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
    document.dispatchEvent(new Event('onUpdateTableSupportDashboard'));
  };

  const onExport = (data) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `support-locations-${timestamp}.csv`);
  };

  const sidebar = (type, locationId = null) => {
    const canManageSettings = user.can('alert.manage_settings');

    setPanel({
      show: true,
      backdrop: false,
      width: type === 'flags'
        // flag sidebar
        ? 400
        // quick view location sidebar
        : 600,
      children: type === 'flags' ? (
        <SupportSidebar
          locationId={locationId}
          type={type}
          canManageSettings={canManageSettings}
          onClose={onCloseSidebar}
        />
      ) : (
        <LocationSidebar
          locationId={locationId}
          isNew={type === 'new'}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const isNew = qs.type === 'new';
    const isView = qs.locationId;

    if (isNew || isView) {
      sidebarTimeout = setTimeout(() => {
        sidebar(qs.type, qs.locationId);
      }, 1500);
    }

    return () => {
      clearTimeout(sidebarTimeout);
    };
  }, []);

  /**
   * Fetch locations when the force flag is changed
   */
  useEffect(() => {
    const suppressLocationLookup = !qs.force
      && (user.locationsAllowed.length > 50
        || user.locationsAllowed.some(({ companyId, locationId }) => companyId === '*' || locationId === '*'));

    if (requireFilter !== suppressLocationLookup) {
      setRequireFilter(suppressLocationLookup);
      document.dispatchEvent(new Event('onSaveLocation'));
    }
  }, [qs.force]);

  useEffect(() => {
    const {
      search,
    } = location;

    if (search !== prevSearchRef) {
      const qs = NavigationHelper.getParams();

      if (qs.locationId || qs.type === 'new') {
        sidebar(qs.type, qs.locationId, type);
      }
    }
  }, [location.search]);

  return (
    <div className="DashboardSupport">
      <DashboardHeader
        className="support-header"
        title="Support Dashboard"
        showNewLocationButton
      />

      <TableSupportDashboard
        columns={[
          'Location',
          'Company',
          'Device Type',
          'Provisioning',
          'Device Online',
          'Location Online',
          'Flags',
          'Last Check In',
          'Serial Number',
          'Ad Network',
          'Ad Programs',
          'Audio',
          'Prov.',
          'Mast. Vol',
          'Msg. Vol',
          'Mus. Vol',
          'Battery',
          'Charge',
          'Avail Memory',
          'Player ID',
          'IMEI',
          'Cell',
          'Version',
          'Model',
          'Phone Number',
          'IP Address',
          'MAC Address',
          'Wifi SSID',
          'Network Type',
          'Connection Type',
          'Address 1',
          'Address 2',
          'Country',
          'City',
          'State/Province',
          'Postal Code',
          'Latitude',
          'Longitude',
          'Last Start Time',
          'Last Connected',
          'Last Disconnected',
          'Tags',
          '...',
        ]}
        fetch={API.Location.Telemetry.getCurrentList}
        fetchProps={{
          // only fetch when a filter is supplied
          requireFilter,
        }}
        defaultSortBy={{
          label: 'Last Check In',
          attr: 'lastCheckInTime',
          direction: 'desc',
        }}
        paginator
        paginatorProps={{
          label: 'Locations',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Locations',
          tooltip: true,
        }}
        csv={user.sysAdmin}
        csvProps={{
          onExport,
        }}
        rowLink="/location/{_id}/telemetry"
        emptyProps={{
          title: !requireFilter
            ? 'No Results'
            : 'A Search Query is Required',
          description: !requireFilter
            ? 'Nothing was found, try changing your filters'
            : 'You must include at least one filter to view results',
          buttonHtml: !requireFilter
            ? null
            : (
              <VibeButton
                text="View All Locations"
                color="secondary"
                link={NavigationHelper.updateParams({
                  force: true,
                })}
              />
            ),
        }}
        menuItems={[
          {
            name: 'Quick View',
            link: {
              locationId: '_id',
            },
            userCan: 'location.view',
          },
        ]}
        onFetchComplete={onFetchComplete}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardSupport));
