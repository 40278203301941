// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserDetails .row,
.SystemUserDetails .row,
.PartnerUserDetails .row {
  margin-bottom: 8px;
}
.UserDetails .row.extra-space,
.SystemUserDetails .row.extra-space,
.PartnerUserDetails .row.extra-space {
  margin-top: 8px;
}
.UserDetails .details-header,
.SystemUserDetails .details-header,
.PartnerUserDetails .details-header {
  display: flex;
  align-items: center;
}
.UserDetails .details-header .user-image,
.SystemUserDetails .details-header .user-image,
.PartnerUserDetails .details-header .user-image {
  margin-right: 16px;
}
.UserDetails .details-header .user-image .upload-image,
.SystemUserDetails .details-header .user-image .upload-image,
.PartnerUserDetails .details-header .user-image .upload-image {
  width: 40px;
  height: 40px;
}
.UserDetails .details-title,
.SystemUserDetails .details-title,
.PartnerUserDetails .details-title {
  font-size: 14px;
  font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Users/Sidebar/UserDetails.scss"],"names":[],"mappings":"AAIE;;;EACE,kBAAA;AADJ;AAGI;;;EACE,eAAA;AACN;AAGE;;;EACE,aAAA;EACA,mBAAA;AACJ;AACI;;;EACE,kBAAA;AAGN;AADM;;;EACE,WAAA;EACA,YAAA;AAKR;AAAE;;;EACE,eAAA;EACA,gBAAA;AAIJ","sourcesContent":[".UserDetails,\n.SystemUserDetails,\n.PartnerUserDetails {\n\n  .row {\n    margin-bottom: 8px;\n\n    &.extra-space {\n      margin-top: 8px;\n    }\n  }\n\n  .details-header {\n    display: flex;\n    align-items: center;\n\n    .user-image {\n      margin-right: 16px;\n\n      .upload-image {\n        width: 40px;\n        height: 40px;\n      }\n    }\n  }\n\n  .details-title {\n    font-size: 14px;\n    font-weight: 900;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
