import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  isEmpty,
} from 'lodash';
import {
  API,
  NavigationHelper,
  ToastActions,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  VibeModal,
  VibeTooltip,
  VibeButton,
  VibeIcon,
  viClose,
  viCopy,
  viArchive,
  viUnarchive,
  withRouter,
  color,
} from 'vibeguide';
import Information from './Information';
import Address from './Address';
import Settings from './Settings';
import DisplayInformation from './DisplayInformation';
import './LocationDetails.scss';

class LocationDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.imageFile = null;

    this.state = {
      confirmArchive: false,
    };
  }

  /**
   * When the archive button is clicked
   */
  onClickArchive = () => {
    this.setState({
      confirmArchive: true,
    });
  };

  /**
   * When the unarchive button is clicked
   */
  onClickUnarchive = async () => {
    const {
      locationData: {
        _id,
      },
      onClose,
    } = this.props;

    await API.Location.reactivate({
      _id,
    });
    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveLocation'));
  };

  /**
   * When the archive modal is closed
   */
  onCloseArchive = () => {
    this.setState({
      confirmArchive: false,
    });
  };

  /**
   * When the archive modal is confirmed
   */
  onConfirmArchive = async () => {
    const {
      locationData: {
        _id,
      },
      onClose,
    } = this.props;

    await API.Location.deactivate(_id);

    this.onCloseArchive();
    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveLocation'));
  };

  onUploadImage = (file) => {
    this.imageFile = file;
  };

  /**
   * When duplicating, reset the image
   */
  onClickDuplicate = () => {
    const {
      onUpdate,
    } = this.props;

    this.imageFile = null;

    onUpdate({
      imageUrl: null,
    });
  };

  /**
   * Save the Location
   */
  onSave = async () => {
    const {
      locationData: {
        _id,
        name,
        companyId,
        category,
        tags = [],
        address1,
        address2,
        country,
        city,
        state,
        postalCode,
        geoCoordinatesLocked,
        lat,
        long,
        thirdPartyData,
        bannerName,
        contentRating,
        adProgramEnabled,
        adNetworkEnabled,
        salesforceId,
        testLocation,
        mediaFormat,
        contentDeliverySystem,
        contentNetworkOwner,
        adNetworkOwner,
        adDeliverySystem,
        screenSize,
        orientation,
        resolution,
      },
      history,
      isNew,
      queueToast,
    } = this.props;

    document.dispatchEvent(new Event('onSaveLocationStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    // Get tags to add/remove
    const modifyTags = tags.filter(tag => tag.status === 'add' || tag.status === 'remove');
    const locationId = _id;

    const visualData = mediaFormat === 'visual'
      ? {
        screenSize,
        orientation,
        resolution: resolution.custom ? {
          custom: resolution.custom,
          size: resolution.size,
        } : {
          custom: resolution.custom,
          size: resolution.size,
          name: resolution.value,
          _id: resolution._id,
        },
      } : {};

    console.log('onSave:visualData: ', visualData);

    const data = {
      name,
      companyId,
      category,
      tags: modifyTags.map((tag) => {
        return {
          _id: tag._id,
          action: tag.status,
        };
      }),
      address1,
      address2,
      country,
      city,
      state,
      postalCode,
      geoCoordinatesLocked,
      lat,
      long,
      thirdPartyData,
      bannerName,
      contentRating,
      adProgramEnabled,
      adNetworkEnabled,
      salesforceId,
      testLocation,
      mediaFormat,
      contentDeliverySystem,
      contentNetworkOwner,
      adDeliverySystem: adNetworkEnabled ? adDeliverySystem : '',
      adNetworkOwner: adNetworkEnabled ? adNetworkOwner : '',
      ...visualData,
    };

    let modify = false;

    if (locationId && !isNew) {
      // Editing or saving an item
      data._id = locationId;
      modify = true;
    }

    try {
      const response = modify
        ? await API.Location.update(data)
        : await API.Location.create(data);
      const newLocationId = get(response, '[0].documentId', null);

      if (this.imageFile) {
        // Upload image
        const uploadImage = await API.Location.uploadImage(newLocationId, this.imageFile);
        const uploadImageId = get(uploadImage, '[0].documentId', null);

        if (uploadImageId) {
          // Image was successful
          queueToast({
            type: 'success',
            title: 'Saved!',
            allowClose: true,
          });
        } else {
          queueToast({
            type: 'error',
            title: 'Error Saving Location',
            timeout: 10,
            allowClose: true,
          });
        }
      } else {
        // No image to upload
        queueToast({
          type: 'success',
          title: 'Saved!',
          allowClose: true,
        });
      }

      if (isNew) {
        // Redirect user to the edit page for the new item
        history(NavigationHelper.updateParams({
          locationId: newLocationId,
          type: null,
        }));
      }

      // tell listening components the object was saved
      document.dispatchEvent(new Event('onSaveLocation'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveLocationError'));
    }
  };

  render() {
    const {
      user,
      locationData,
      isNew,
      onUpdate,
      onClose,
    } = this.props;

    const {
      confirmArchive,
    } = this.state;

    const disableInput = (locationData._id && !user.can('location.modify'))
      || (!locationData._id && !user.can('location.create'));

    const disableBannerField = !locationData.companyId;

    const hasVisualIssue = locationData.mediaFormat === 'visual' ? (!locationData.screenSize
      || !locationData.orientation || !locationData.resolution)
      || (isEmpty(locationData.resolution) || !locationData.resolution.selected) : false;

    const disableSave = disableInput
      || !locationData.name
      || !locationData.companyId
      || !locationData.address1
      || !locationData.country
      || !locationData.city
      || !locationData.state
      || !locationData.postalCode
      || (locationData.mediaFormat !== 'visual' && !locationData.contentRating)
      || !locationData.bannerName
      || !locationData.mediaFormat
      || !locationData.category
      || hasVisualIssue
      || (locationData.adNetworkEnabled && !locationData.adDeliverySystem)
      || (locationData.adNetworkEnabled && !locationData.adNetworkOwner)
      || !locationData.contentDeliverySystem
      || !locationData.contentNetworkOwner
      || (locationData.adProgramEnabled && !locationData.adNetworkEnabled);

    const showArchive = user.can('location.delete');
    const isArchived = !locationData.active;

    return (
      <SidePanelContainer className="LocationDetails">
        <SidePanelHeader
          icons={(
            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onClose}
            />
          )}
        >
          {locationData._id ? (
            <div className="title">
              <VibeTooltip title={locationData.name}>
                <span>
                  {locationData.name}
                </span>
              </VibeTooltip>
            </div>
          ) : (
            <div className="title">
              New Location
            </div>
          )}
        </SidePanelHeader>

        <SidePanelContent>
          <Information
            name={locationData.name}
            companyId={locationData.companyId}
            companyName={locationData.companyName}
            bannerName={locationData.bannerName}
            category={locationData.category}
            salesforceId={locationData.salesforceId}
            testLocation={locationData.testLocation}
            image={locationData.imageUrl}
            tags={locationData.tags}
            disableInput={disableInput}
            isNew={isNew}
            onUpdate={onUpdate}
            onUploadImage={this.onUploadImage}
          />

          <Address
            address1={locationData.address1}
            address2={locationData.address2}
            country={locationData.country}
            city={locationData.city}
            locationState={locationData.state}
            postalCode={locationData.postalCode}
            geoCoordinatesLocked={locationData.geoCoordinatesLocked}
            lat={locationData.lat}
            long={locationData.long}
            disableInput={disableInput}
            isNew={isNew}
            onUpdate={onUpdate}
          />

          {locationData.mediaFormat === 'visual' && (
            <DisplayInformation
              screenSize={locationData.screenSize}
              orientation={locationData.orientation}
              resolution={locationData.resolution}
              canEdit={user.can('location.modify')}
              disableInput={disableInput}
              isNew={isNew}
              onUpdate={onUpdate}
            />
          )}

          {user.sysAdmin ? (
            <Settings
              contentRating={locationData.contentRating}
              companyId={locationData.companyId}
              mediaFormat={locationData.mediaFormat}
              canEdit={user.can('location.modify')}
              contentNetworkOwner={locationData.contentNetworkOwner}
              contentDeliverySystem={locationData.contentDeliverySystem}
              adNetworkOwner={locationData.adNetworkOwner}
              adDeliverySystem={locationData.adDeliverySystem}
              adProgramEnabled={locationData.adProgramEnabled}
              adNetworkEnabled={locationData.adNetworkEnabled}
              companyAdProgramEnabled={locationData.companyAdProgramEnabled}
              companyAdNetworkEnabled={locationData.companyAdNetworkEnabled}
              disableInput={disableInput}
              disableBannerField={disableBannerField}
              isNew={isNew}
              onUpdate={onUpdate}
            />
          ) : null}

        </SidePanelContent>

        <SidePanelFooter>
          <VibeButton
            text="Save Changes"
            color="secondary"
            loadingEvent="onSaveLocation"
            disabled={disableSave}
            onClick={this.onSave}
          />

          {!isNew && (
            <>
              <VibeIcon
                icon={viCopy}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.primary,
                }}
                color={color.primary}
                size={20}
                tooltip="Duplicate"
                link={NavigationHelper.updateParams({
                  type: 'new',
                })}
                onClick={this.onClickDuplicate}
              />

              {showArchive && !isArchived && (
                <VibeIcon
                  icon={viArchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.error,
                  }}
                  tooltip="Archive"
                  color={color.error}
                  size={20}
                  onClick={this.onClickArchive}
                />
              )}

              {showArchive && isArchived && (
                <VibeIcon
                  icon={viUnarchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.success,
                  }}
                  tooltip="Unarchive"
                  color={color.success}
                  size={20}
                  onClick={this.onClickUnarchive}
                />
              )}
            </>
          )}
        </SidePanelFooter>

        <VibeModal
          show={confirmArchive}
          type="confirm"
          title="Archive Location"
          text={`Are you sure you want to archive ${locationData.name}?`}
          confirmProps={{
            text: 'Archive',
            color: 'error',
          }}
          cancelProps={{
            text: 'Cancel',
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCloseArchive}
        />
      </SidePanelContainer>
    );
  }
}

LocationDetails.propTypes = {
  isNew: PropTypes.bool,
  locationData: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

LocationDetails.defaultProps = {
  isNew: false,
  locationData: {},
  onClose: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationDetails));
