import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import './Avatar.scss';

function Avatar({
  className,
  style,
  imageUrl,
  imageSize,
  imageDefault,
  iconDefault,
  text,
  displayText,
  tooltipProps: {
    placement,
    enterDelay,
  },
}) {
  return (
    <VibeTooltip
      title={text}
      placement={placement}
      enterDelay={enterDelay}
    >
      <div
        className={classNames('Avatar', className)}
        style={style}
      >
        <div
          className={classNames('avatar-container', { 'has-text': displayText })}
          style={{
            width: imageSize,
            height: imageSize,
            minWidth: imageSize,
            minHeight: imageSize,
          }}
        >
          {(imageUrl || imageDefault) ? (
            <img
              className="avatar"
              src={imageUrl || imageDefault}
              alt={text}
              style={{
                width: imageSize,
                minHeight: imageSize,
              }}
            />
          ) : iconDefault}
        </div>

        {displayText ? (
          <div className="text">
            {text}
          </div>
        ) : null}
      </div>
    </VibeTooltip>
  );
}

Avatar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  imageUrl: PropTypes.string,
  imageSize: PropTypes.number,
  imageDefault: PropTypes.string,
  iconDefault: PropTypes.node,
  text: PropTypes.string,
  /** Display text next to the image */
  displayText: PropTypes.bool,
  /** Tooltip props */
  tooltipProps: PropTypes.shape({
    placement: PropTypes.string,
    enterDelay: PropTypes.number,
  }),
};

Avatar.defaultProps = {
  className: '',
  style: {},
  imageUrl: '',
  imageSize: 24,
  imageDefault: null,
  iconDefault: null,
  text: '',
  displayText: false,
  tooltipProps: {
    placement: 'right',
  },
};

export default Avatar;
