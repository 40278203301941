import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  sortBy,
  clone,
  uniqBy,
} from 'lodash';
import {
  Field2 as Field,
  DaySelector,
  VibeButton,
} from 'vibeguide';
import DayPart from './DayPart';

const hourOptions = [];
for (let i = 0; i <= 24; i++) {
  const hour = i < 10 ? `0${i}:00` : `${i}:00`;
  hourOptions.push({
    label: hour,
    value: hour,
  });
}

function SegmentDateTime({
  advertiserId,
  segmentLocations,
  startDate,
  endDate,
  daysOfWeek,
  dayParts,
  disableMedia,
  canPartMultiple,
  onUpdate,
}) {
  const [mediaDateErrors, setMediaDateErrors] = useState(
    dayParts.map(() => {
      return {
        startTime: null,
        endTime: null,
        dateRanges: [{
          index: 0,
          error: false,
        }],
      };
    }),
  );

  const onChangeStartDate = (startDate) => {
    onUpdate({
      startDate,
    });
  };

  const onChangeEndDate = (endDate) => {
    onUpdate({
      endDate,
    });
  };

  const onChangeDays = (days) => {
    onUpdate({
      daysOfWeek: days,
    });
  };

  const checkAllContinuousCreativeDates = () => {
    const tempErrorDayParts = [];
    dayParts.forEach((daypart) => {
      const tempErrorDayPart = {
        startTime: daypart.startTime,
        endTime: daypart.endTime,
        dateRanges: [],
      };
      daypart.dateRanges.forEach((dateRange, index) => {
        if (index > 0) {
          const firstEndDate = moment(daypart.dateRanges[index - 1].endDate);
          const startdate = moment(daypart.dateRanges[index].startDate);
          const tempErrors = mediaDateErrors.filter((err) => err.index !== index);
          const tempError = { index, error: startdate.isBefore(firstEndDate) };
          tempErrors.push(tempError);
          tempErrorDayPart.dateRanges.push(tempError);
        }
      });
      tempErrorDayParts.push(tempErrorDayPart);
    });
    setMediaDateErrors(tempErrorDayParts);
  };

  useEffect(() => {
    const mediaDateErrs = [];
    dayParts.forEach((daypart) => {
      const dayPartErr = {
        startTime: daypart.startTime,
        endTime: daypart.endTime,
        dateRanges: [],
      };
      daypart.dateRanges.forEach((dateRange, i) => {
        dayPartErr.dateRanges.push({
          index: i,
          error: false,
        });
      });
      mediaDateErrs.push(dayPartErr);
    });
    checkAllContinuousCreativeDates();
  }, [dayParts]);

  useEffect(() => {
    const mediaDateErrs = [];
    dayParts.forEach((daypart) => {
      const dayPartErr = {
        startTime: daypart.startTime,
        endTime: daypart.endTime,
        dateRanges: [],
      };
      daypart.dateRanges.forEach((dateRange, i) => {
        dayPartErr.dateRanges.push({
          index: i,
          error: false,
        });
      });
      mediaDateErrs.push(dayPartErr);
    });
    setMediaDateErrors(mediaDateErrs);
  }, []);

  const updateDayParts = (updatedPart, index) => {
    const updatedDayParts = [
      ...dayParts.slice(0, index),
      ...dayParts.slice(index + 1),
      updatedPart,
    ];

    onUpdate({
      dayParts: sortBy(updatedDayParts, 'startTime'),
    });
  };

  const onDayPartCreativeChange = (data, index) => {
    const updatedDateRange = {
      ...dayParts[index].dateRanges[data.dateRangeIndex],
      creatives: data.creatives,
    };

    const newDateRanges = dayParts[index].dateRanges;
    newDateRanges[data.dateRangeIndex] = updatedDateRange;

    const updatedPart = {
      ...dayParts[index],
    };

    updateDayParts(updatedPart, index);
  };

  const onDayPartSelectCreativeMedia = (data, index) => {
    const combinedCreatives = dayParts[index].dateRanges[data.dateRangeIndex].creatives ? uniqBy([
      ...data.creatives,
      ...dayParts[index].dateRanges[data.dateRangeIndex].creatives,
    ], 'creativeId') : uniqBy([
      ...data.creatives,
    ], 'creativeId');

    const updatedDateRange = {
      ...dayParts[index].dateRanges[data.dateRangeIndex],
      creatives: combinedCreatives,
    };

    const newDateRanges = dayParts[index].dateRanges;
    newDateRanges[data.dateRangeIndex] = updatedDateRange;

    const updatedPart = {
      ...dayParts[index],
    };

    updateDayParts(updatedPart, index);
  };

  const onDateRangeStartDateChange = (data, index) => {
    const updatedDateRange = {
      ...dayParts[index].dateRanges[data.dateRangeIndex],
      startDate: data.startDate,
    };

    const newDateRanges = clone(dayParts[index].dateRanges);
    newDateRanges[data.dateRangeIndex] = updatedDateRange;

    const updatedPart = {
      ...dayParts[index],
      dateRanges: newDateRanges,
    };

    updateDayParts(updatedPart, index);
  };

  const onDateRangeEndDateChange = (data, index) => {
    const updatedDateRange = {
      ...dayParts[index].dateRanges[data.dateRangeIndex],
      endDate: data.endDate,
    };

    const newDateRanges = clone(dayParts[index].dateRanges);
    newDateRanges[data.dateRangeIndex] = updatedDateRange;

    const updatedPart = {
      ...dayParts[index],
      dateRanges: newDateRanges,
    };

    updateDayParts(updatedPart, index);
  };

  const onDayPartTimeChange = (e, index) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const updatedPart = {
      ...dayParts[index],
      [name]: value,
    };

    updateDayParts(updatedPart, index);
    e.target.blur();
  };

  const onDeleteDayPart = (dayPartIndex) => {
    const updatedDayParts = dayParts.filter((part, index) => index !== dayPartIndex);
    onUpdate({
      dayParts: sortBy(updatedDayParts, 'startTime'),
    });
  };

  const onAddDayPart = () => {
    setMediaDateErrors(
      [
        ...mediaDateErrors,
        {
          startTime: null,
          endTime: null,
          dateRanges: [{
            index: 0,
            error: false,
          }],
        },
      ],
    );
    onUpdate({
      dayParts: [
        ...dayParts,
        {
          startTime: null,
          endTime: null,
          dateRanges: [{
            startDate: null,
            endDate: null,
            creatives: [],
          }],
        },
      ],
    });
  };

  return (
    <div className="SegmentDateTime">
      <Grid
        spacing={2}
        container
      >
        <Grid
          xs={6}
          item
        >
          <Field
            type="datepicker"
            label="Start Date"
            placeholder="YYYY-MM-DD"
            value={startDate}
            dateProps={{
              format: 'YYYY-MM-DD',
            }}
            tabIndex={36}
            onChange={onChangeStartDate}
            required
          />
        </Grid>
        <Grid
          xs={6}
          item
        >
          <Field
            type="datepicker"
            label="End Date"
            placeholder="YYYY-MM-DD"
            value={endDate}
            dateProps={{
              format: 'YYYY-MM-DD',
            }}
            tabIndex={37}
            onChange={onChangeEndDate}
            required
          />
        </Grid>
        <Grid
          xs={12}
          item
        >
          <Field
            type="custom"
            label="Days"
            tabIndex={38}
            required
          >
            <DaySelector
              style={{
                width: 250,
              }}
              dayFormat="dd"
              selectedDays={daysOfWeek}
              toggleAll
              onChange={onChangeDays}
            />
          </Field>
        </Grid>
        {(dayParts.length > 0) && (
          <Grid
            className="day-parts"
            xs={12}
            item
          >
            {dayParts.map((part, index) => (
              <DayPart
                key={index}
                canAddMedia={canPartMultiple}
                advertiserId={advertiserId}
                segmentLocations={segmentLocations}
                partIndex={index}
                part={part}
                startDate={startDate}
                endDate={endDate}
                totalParts={dayParts.length}
                hourOptions={hourOptions}
                disableMedia={disableMedia}
                onTimeChange={(e) => onDayPartTimeChange(e, index)}
                onCreativeChange={(data) => onDayPartCreativeChange(data, index)}
                onSelectCreativeMedia={(data) => onDayPartSelectCreativeMedia(data, index)}
                updateDayParts={updateDayParts}
                onDateRangeStartDateChange={(data) => onDateRangeStartDateChange(data, index)}
                onDateRangeEndDateChange={(data) => onDateRangeEndDateChange(data, index)}
                mediaDateErrors={mediaDateErrors[index].dateRanges}
                onDelete={onDeleteDayPart}
              />
            ))}
          </Grid>
        )}
        {canPartMultiple && (
          <Grid
            xs={12}
            item
          >
            <VibeButton
              text="Add Day Part"
              color="secondary"
              onClick={onAddDayPart}
              disabled={dayParts.some(part => !part.startTime || !part.endTime)}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

SegmentDateTime.propTypes = {
  advertiserId: PropTypes.string,
  segmentLocations: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  daysOfWeek: PropTypes.arrayOf(PropTypes.string),
  dayParts: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    dateRanges: PropTypes.arrayOf(PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      creatives: PropTypes.arrayOf(PropTypes.shape({
        creativeId: PropTypes.string,
        weight: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      })),
    })),
  })),
  canPartMultiple: PropTypes.bool,
  disableMedia: PropTypes.bool,
  onUpdate: PropTypes.func,
};

SegmentDateTime.defaultProps = {
  advertiserId: '',
  segmentLocations: {},
  startDate: '',
  endDate: '',
  daysOfWeek: [],
  dayParts: [],
  canPartMultiple: false,
  disableMedia: false,
  onUpdate: () => {},
};

export default SegmentDateTime;
