import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import moment from 'moment';
import {
  color,
  Field2 as Field,
  VibeIcon,
  viTrash,
} from 'vibeguide';
import {
  isEmpty,
} from 'lodash';
import './CreativeDateTime.scss';

const hourOptions = [];
for (let i = 0; i <= 24; i++) {
  const hour = i < 10 ? `0${i}:00` : `${i}:00`;
  hourOptions.push({
    label: hour,
    value: hour,
  });
}

function CreativeDateTime({
  startDate,
  endDate,
  segmentStartDate,
  // segmentEndDate,
  dateRangeIndex,
  onStartDateUpdate,
  onEndDateUpdate,
  mediaDateErrors,
  clickDeleteCreativeDates,
}) {
  const [errorState, setErrorState] = useState(false);

  const checkForMediaDateError = () => {
    if (mediaDateErrors.filter(err => err.index === dateRangeIndex)[0]) {
      return mediaDateErrors.filter(err => err.index === dateRangeIndex)[0].error;
    }
    return false;
  };

  const selectStartDateUpdate = (creativeStartDate) => {
    onStartDateUpdate({
      startDate: creativeStartDate,
      dateRangeIndex,
    });
  };

  const selectEndDateUpdate = (creativeEndDate) => {
    onEndDateUpdate({
      endDate: creativeEndDate,
      dateRangeIndex,
    });
  };

  useEffect(() => {
    if (!isEmpty(mediaDateErrors)) {
      setErrorState(checkForMediaDateError());
    }
  }, [mediaDateErrors]);

  return (
    <div className={classNames('CreativeDateTime', { 'error-state': errorState })}>
      {dateRangeIndex > 0 && (
        <div className="creative-dates-delete">
          <VibeIcon
            icon={viTrash}
            color={color.primary}
            hoverColor={color.primary60}
            size={16}
            tooltip="Delete Creative Dates"
            onClick={() => clickDeleteCreativeDates(dateRangeIndex)}
          />
        </div>
      )}
      <Grid
        spacing={2}
        container
      >
        <Grid
          xs={6}
          item
        >
          <Field
            type="datepicker"
            label="Start Date"
            placeholder="YYYY-MM-DD"
            value={startDate}
            dateProps={{
              format: 'YYYY-MM-DD',
              // minDate: moment(segmentStartDate).toDate(),
              // maxDate: moment(segmentEndDate).toDate(),
            }}
            tabIndex={36}
            onChange={selectStartDateUpdate}
            disabled={isEmpty(segmentStartDate)}
            required
          />
        </Grid>
        <Grid
          xs={6}
          item
        >
          <Field
            type="datepicker"
            label="End Date"
            placeholder="YYYY-MM-DD"
            value={endDate}
            dateProps={{
              format: 'YYYY-MM-DD',
              // minDate: moment(segmentStartDate).toDate(),
              // maxDate: moment(segmentEndDate).toDate(),
            }}
            tabIndex={37}
            onChange={selectEndDateUpdate}
            // disabled={isEmpty(startDate)}
            required
          />
        </Grid>
        { errorState && (
          <Grid
            xs={12}
            item
          >
            <div className="dates-error-message">Dates cannot overlap and must be continuous</div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

CreativeDateTime.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  segmentStartDate: PropTypes.string,
  // segmentEndDate: PropTypes.string,
  dateRangeIndex: PropTypes.number,
  onStartDateUpdate: PropTypes.func,
  onEndDateUpdate: PropTypes.func,
  mediaDateErrors: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    error: PropTypes.bool,
  })),
};

CreativeDateTime.defaultProps = {
  startDate: '',
  endDate: '',
  segmentStartDate: '',
  // segmentEndDate: '',
  dateRangeIndex: 0,
  onStartDateUpdate: () => {},
  onEndDateUpdate: () => {},
  mediaDateErrors: [],
};

export default CreativeDateTime;
