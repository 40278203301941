import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from 'react-router-dom';
import {
  find,
  without,
} from 'lodash';
import {
  StorageUtil,
  VibeButton,
  color,
} from 'vibeguide';
import {
  FormHelperText,
} from '@mui/material';
import {
  AccountCircle,
  Lock,
} from '@mui/icons-material';
import './LoginForm.scss';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    const {
      username,
      password,
    } = props;

    const quickLogins = StorageUtil.getLocal('login:quickLogins', []);

    this.state = {
      username,
      password,
      quickLogins,
      addUsername: '',
      addPassword: '',
      showAddQuickLogin: quickLogins.length <= 0,
      isAccountFocus: false,
      isPasswordFocus: false,
      isCapsLockOn: false,
    };
  }

  onClickQuickLogin = (e) => {
    const {
      target: {
        dataset: {
          username,
          password,
        },
      },
    } = e;

    const {
      onUsernameChange,
      onPasswordChange,
      checkCredentials,
    } = this.props;

    onUsernameChange({
      target: {
        value: username,
      },
    });

    onPasswordChange({
      target: {
        value: password,
      },
    });

    this.setState({
      username,
      password,
    }, () => {
      checkCredentials(e);
    });
  };

  onUsernameChange = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const {
      onUsernameChange,
    } = this.props;

    this.setState({
      username: value,
    });

    onUsernameChange(e);
  };

  onPasswordChange = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const {
      onPasswordChange,
    } = this.props;

    this.setState({
      password: value,
    });

    onPasswordChange(e);
  };

  onFocusAccount = () => {
    this.setState({
      isAccountFocus: true,
    });
  };

  onBlurAccount = () => {
    this.setState({
      isAccountFocus: false,
    });
  };

  onFocusPassword = () => {
    this.setState({
      isPasswordFocus: true,
    });
  };

  onBlurPassword = () => {
    this.setState({
      isPasswordFocus: false,
    });
  };

  onKeyDown = (e) => {
    const {
      checkCredentials,
    } = this.props;

    const {
      isCapsLockOn,
    } = this.state;

    const capsLockOn = e.getModifierState('CapsLock');

    if (!isCapsLockOn && capsLockOn) {
      // Caps lock is on
      this.setState({
        isCapsLockOn: true,
      });
    } else if (isCapsLockOn && !capsLockOn) {
      // Caps lock is off
      this.setState({
        isCapsLockOn: false,
      });
    }

    if (e.key === 'Enter') {
      checkCredentials(e);
    }
  };

  onChangeAddUsername = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      addUsername: value,
    });
  };

  onChangeAddPassword = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      addPassword: value,
    });
  };

  onKeyDownAddQuickLogin = (e) => {
    if (e.key === 'Enter') {
      this.onAddQuickLogin();
    }
  };

  onAddQuickLogin = () => {
    const {
      quickLogins,
      addUsername,
      addPassword,
    } = this.state;

    if (!addUsername || !addPassword) {
      console.warn('Username and Password required to add');
      return;
    }

    // Quick login object
    const quickLogin = {
      username: addUsername,
      password: addPassword,
    };

    // Add quick login
    const newQuickLogins = [...quickLogins, quickLogin];

    StorageUtil.setLocal('login:quickLogins', newQuickLogins);

    this.setState({
      quickLogins: newQuickLogins,
      showAddQuickLogin: false,
      addUsername: '',
      addPassword: '',
    });
  };

  onRemoveQuickLogin = (e) => {
    const {
      target: {
        dataset: {
          username,
          password,
        },
      },
    } = e;

    const {
      quickLogins,
    } = this.state;

    // Find quick login to remove
    const quickLogin = find(quickLogins, { username, password });

    if (quickLogin) {
      // Remove quick login
      const newQuickLogins = without(quickLogins, quickLogin);

      StorageUtil.setLocal('login:quickLogins', newQuickLogins);

      this.setState({
        quickLogins: newQuickLogins,
      });
    }
  };

  toggleShowAddQuickLogin = () => {
    this.setState((state) => {
      return {
        showAddQuickLogin: !state.showAddQuickLogin,
      };
    });
  };

  render() {
    const {
      checkCredentials,
      loginError,
      loginErrorMessage,
      onForgotPassword,
      cookiesAccepted,
    } = this.props;

    const {
      username,
      password,
      isAccountFocus,
      isPasswordFocus,
      isCapsLockOn,
      addUsername,
      addPassword,
      quickLogins,
      showAddQuickLogin,
    } = this.state;

    const local = process.env.APP_ENV === 'local';
    const development = process.env.APP_ENV === 'development';

    return (
      <div
        className="LoginForm"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
        }}
      >
        <div
          className="form-title"
          style={{
            fontSize: 16,
            textAlign: 'center',
          }}
        >
          Your retail platform to standardize and unify digital in-store.
        </div>

        <form className="login-container">
          <div className={`input-container ${isAccountFocus ? 'active' : ''}`}>
            <input
              className="login-input"
              type="email"
              placeholder="Username"
              value={username}
              // defaultValue={username}
              onChange={this.onUsernameChange}
              onKeyDown={this.onKeyDown}
              onFocus={this.onFocusAccount}
              onBlur={this.onBlurAccount}
              disabled={!cookiesAccepted}
              autoFocus
            />

            <AccountCircle className="login-icon account-icon" />

            {isCapsLockOn && (
              <FormHelperText className="login-capslock">
                CAPSLOCK is on
              </FormHelperText>
            )}
          </div>

          <div
            className={`input-container ${isPasswordFocus ? 'active' : ''}`}
          >
            <input
              className="login-input"
              type="password"
              placeholder="********"
              // defaultValue={password}
              value={password}
              onChange={this.onPasswordChange}
              onKeyDown={this.onKeyDown}
              onFocus={this.onFocusPassword}
              onBlur={this.onBlurPassword}
              disabled={!cookiesAccepted}
            />

            <Lock className="login-icon lock-icon" />

            {loginError && (
              <FormHelperText className="login-error">
                {loginErrorMessage}
              </FormHelperText>
            )}
          </div>

          {cookiesAccepted && (
            <Link
              className="forgot"
              to="/forgot"
              onClick={onForgotPassword}
            >
              Forgot password
            </Link>
          )}

          {cookiesAccepted && (
            <VibeButton
              style={{
                width: '100%',
                height: 48,
                fontSize: 16,
              }}
              text="Sign in"
              color="primary"
              disabled={password.length < 8 || !username}
              onClick={checkCredentials}
            />
          )}
        </form>

        {local || development ? (
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              width: 400,
              minHeight: 35,
              padding: 15,
              background: color.manatee,
              color: '#FFFFFF',
            }}
          >
            <div
              style={{
                fontSize: 16,
                borderBottom: '1px solid #CCC',
                paddingBottom: 5,
                marginBottom: 15,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  Quick Login (Development Only)
                </div>

                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'right',
                    fontSize: 12,
                  }}
                >
                  <span
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={this.toggleShowAddQuickLogin}
                  >
                    Add
                  </span>
                </div>
              </div>
            </div>

            <div>
              {showAddQuickLogin || quickLogins.length <= 0 ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '10px 0',
                    fontSize: 14,
                  }}
                >
                  <div>
                    <input
                      type="text"
                      placeholder="Username"
                      value={addUsername}
                      onChange={this.onChangeAddUsername}
                      onKeyDown={this.onKeyDownAddQuickLogin}
                      style={{
                        display: 'block',
                        padding: '5px 3px',
                      }}
                      autoFocus
                    />
                  </div>

                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Password"
                      value={addPassword}
                      onChange={this.onChangeAddPassword}
                      onKeyDown={this.onKeyDownAddQuickLogin}
                      style={{
                        display: 'block',
                        padding: '5px 3px',
                      }}
                    />
                  </div>

                  <div
                    style={{
                      marginLeft: 10,
                      cursor: 'pointer',
                      height: 28,
                      border: '1px solid #CCC',
                      padding: '0 5px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                    onClick={this.onAddQuickLogin}
                  >
                    Add
                  </div>
                </div>
              ) : null}

              {quickLogins.map((login, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '10px 0',
                      fontSize: 14,
                      userSelect: 'none',
                    }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      data-username={login.username}
                      data-password={login.password}
                      onClick={this.onClickQuickLogin}
                    >
                      {login.username}
                    </div>

                    <div
                      style={{
                        flexGrow: 1,
                        textAlign: 'right',
                        fontSize: 12,
                        color: color.lightGray,
                      }}
                    >
                      <span
                        style={{
                          cursor: 'pointer',
                        }}
                        data-username={login.username}
                        data-password={login.password}
                        onClick={this.onRemoveQuickLogin}
                      >
                        del
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

LoginForm.propTypes = {
  /**
   * Function that stores the username in the login container state when changed
   */
  onUsernameChange: PropTypes.func.isRequired,
  /**
   * Function that stores the password in the login cotnainer when changed
   */
  onPasswordChange: PropTypes.func.isRequired,
  /**
   * Function that stores the new password when forgotten in the login container
   */
  onForgotPassword: PropTypes.func.isRequired,
  /**
   * Function that checks the users credentials that were entered when they click to login
   */
  checkCredentials: PropTypes.func.isRequired,
  /**
   * String representation of the users username/email
   */
  username: PropTypes.string.isRequired,
  /**
   * String representation of the users password
   */
  password: PropTypes.string.isRequired,
  /**
   * Boolean value determining if there is an error with the login
   */
  loginError: PropTypes.bool,
  /**
   * String representation of the error message shown for the login
   */
  loginErrorMessage: PropTypes.string,
};

LoginForm.defaultProps = {
  loginError: false,
  loginErrorMessage: '',
};

export default LoginForm;
