import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/locationControlFiles`;

export default {
  list: async (data, options) => {
    const response = await Http.post(`${baseUrl}/search`, data, options);

    return {
      ...response,
      data: response.data.map(item => {
        return {
          ...item,
          dataUrl: Http.cloudfront(item.dataUrl),
        };
      }),
    };
  },
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: (id) => Http.delete(`${baseUrl}/${id}`),
};
