import forEach from 'lodash/forEach';
import Http from '../Http';
import {
  addVersion,
} from '../HelperAPI';

const baseUrl = `${process.env.VAPI_BASE_URL}/baselines`;

export default {
  list: (data, options) => Http.post(`${baseUrl}/search`, data, options),
  getById: async (id, options = {}) => {
    options = addVersion('5.6.0', options);
    const response = await Http.get(`${baseUrl}/${id}`, options);

    forEach(response.refs.interrupts, (item) => {
      item.url = Http.cloudfront(item.url);
    });

    forEach(response.refs.messageBlocks, (item) => {
      item.messages = item.messages.map((message) => {
        return {
          ...message,
          url: Http.cloudfront(message.url),
        };
      });
    });

    forEach(response.refs.mixes, (item) => {
      item.imageUrl = Http.cloudfront(item.imageUrl);
    });

    forEach(response.refs.playlists, (item) => {
      item.imageUrl = Http.cloudfront(item.imageUrl);
    });

    return response;
  },
  create: data => Http.post(baseUrl, data),
  modify: data => Http.put(`${baseUrl}/${data._id}`, data),
  getLocations: (id, data, options) => Http.post(`${baseUrl}/${id}/locations`, data, options),
  getExpandedLocations: async (id, options) => {
    const response = await Http.get(`${baseUrl}/${id}/locations/expanded`, options) ?? [];

    return response.map(item => {
      const locations = item.locations ?? [];

      return {
        ...item,
        imageUrl: Http.cloudfront(item.imageUrl),
        locations: locations.map(location => {
          return {
            ...location,
            imageUrl: Http.cloudfront(location.imageUrl),
          };
        }),
      };
    });
  },
  reactivate: (data, options) => {
    options = addVersion('5.36.0', options);
    return Http.post(`${baseUrl}/${data._id}`, data, options);
  },
  deactivate: id => Http.delete(`${baseUrl}/${id}`),
  deactivateBulk: (baselines) => Http.delete(baseUrl, {
    baselines,
  }),
};
