import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  get,
} from 'lodash';
import {
  API,
  VibeButton,
  withRouter,
} from 'vibeguide';
import './ImportHeader.scss';

class ImportHeader extends PureComponent {
  /**
   * Import Locations
   */
  import = async () => {
    const {
      importJobId,
      history,
    } = this.props;

    const response = await API.Location.Import.run({
      _id: importJobId,
    });

    const success = get(response, '[0].type', null) === 'LOCATIONIMPORT.STARTED';

    if (success) {
      history('/locations');
    }
  };

  render() {
    const {
      status,
      rowCount,
    } = this.props;

    return (
      <div className="ImportHeader">
        <div className="header-info">
          <div className="title">
            Import
          </div>

          <div className="description">
            Upload a spreadsheet of the locations you would like to create.
            This process also creates service accounts, with the option to create a client admin per location.
          </div>
        </div>

        <div className="header-toolbar">
          {status === 'validated' ? (
            <VibeButton
              style={{
                width: '100%',
              }}
              text={`Create ${rowCount} ${rowCount === 1 ? 'Location' : 'Locations'}`}
              color="primary"
              onClick={this.import}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    importJobId: state.import.locations._id,
    status: state.import.locations.data.status,
    rowCount: state.import.locations.data.rowCount,
  };
}

export default withRouter(connect(mapStateToProps)(ImportHeader));
