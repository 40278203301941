import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  GlobalActions,
  PartnerSelection,
  VibeButton,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';

function PartnerAccess({
  allowChanges,
  partners: propPartners,
  setPanel,
  onUpdate,
}) {
  const [partners, setPartners] = useState(propPartners);

  /**
   * When the partners are changed
   */
  const onChange = (partners) => {
    setPartners(partners);

    onUpdate({
      partnersAssigned: partners,
    });
  };

  /**
   * Close the third panel on the sidebar
   */
  const onCloseAvailable = () => {
    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  const onClickAssign = () => {
    setPanel({
      thirdPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <PartnerSelection
            selected={partners}
            customToolbar={(
              <VibeIcon
                icon={viClose}
                color={color.white}
                hoverColor={color.white}
                size={24}
                onClick={onCloseAvailable}
              />
            )}
            assigning
            allowChanges
            onChange={onChange}
          />
        ),
      },
    });
  };

  const onClose = () => {
    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  const missingData = partners.filter(partner => !partner.sensitiveData).length > 0;

  return (
    <PartnerSelection
      selected={partners}
      customToolbar={(
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          {allowChanges && (
            <VibeButton
              text="Assign Partners"
              color="primary"
              onClick={onClickAssign}
            />
          )}

          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            disabled={missingData}
            tooltip={missingData
              ? 'Missing required partner data'
              : null}
            size={24}
            onClick={onClose}
          />
        </div>
      )}
      allowChanges={allowChanges}
      onChange={onChange}
    />
  );
}

PartnerAccess.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.object),
  /** Allow changes to the selection */
  allowChanges: PropTypes.bool,
  onUpdate: PropTypes.func,
};

PartnerAccess.defaultProps = {
  partners: [],
  allowChanges: false,
  onUpdate: () => {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(PartnerAccess);
