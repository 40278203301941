import { each, isUndefined } from 'lodash';

class FlightSummary {
  constructor(data = {}) {
    this._id = data._id || '';
    this.name = data.name || '';
    this.flightStage = data.flightStage || '';
    this.budget = data.budget || 0;
    this.currencyCode = data.currencyCode || '';
    this.frequency = data.frequency || 0;
    this.isScenario = data.isScenario || false;
    this.locationSummary = data.locationSummary || {
      banners: 0,
      companies: 0,
      locations: 0,
    };
    this.mediaFormatSummary = data.mediaFormatSummary || {};
    this.segments = data.segments || [];
    this.startDate = data.startDate || null;
    this.endDate = data.endDate || null;
    this.targetImpressions = data.targetImpressions || 0;
    this.totalAddedValueImpressions = data.totalAddedValueImpressions || 0;
    this.totalHours = data.totalHours || 0;
    this.totalImpressions = data.totalImpressions || 0;
    this.totalSpots = data.totalSpots || 0;
    this.warnings = data.warnings || [];
    this.weightedCpm = data.weightedCpm || null;
    this.createdDate = data.createdDate || null;

    each(data, (val, key) => {
      if (isUndefined(this[key])) {
        // Add the property to the model
        this[key] = val;

        console.warn(`${key} is not added to Flight Summary Model`);
      }
    });
  }
}

export default FlightSummary;
