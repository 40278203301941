class Location {
  constructor(data = {}, company = {}) {
    this._id = data._id || data.locationId;
    this.active = data.active || true;
    this.name = data.name || data.locationName;
    this.companyId = data.companyId || company._id;
    this.companyName = data.companyName || company.name;
    this.dmaCode = data.dmaCode || '';
    this.dmaName = data.dmaName || '';
    this.imageUrl = data.imageUrl || null;
    this.address = data.address || {
      line1: data.address1 || '',
      line2: data.address2 || '',
      city: data.city || '',
      country: data.country || '',
      state: data.state || '',
      postalCode: data.postalCode || '',
    };
    this.mediaFormat = data.mediaFormat || '';
    this.orientation = data.orientation || '';
    this.screenSize = data.screenSize || '';
    this.resolution = data.resolution || '';
    this.playerOnline = data.playerOnline || false;
    this.adNetworkEnabled = data.adNetworkEnabled || false;
    this.adProgramEnabled = data.adProgramEnabled || false;
    this.tags = data.tags || [];

    // Set address items
    this.address1 = data.address1 || this.address.line1;
    this.address2 = data.address2 || this.address.line2;
    this.city = data.city || this.address.city;
    this.country = data.country || this.address.country;
    this.state = data.state || this.address.state;
    this.postalCode = data.postalCode || this.address.postalCode;
  }
}

export default Location;
