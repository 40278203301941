import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  API,
  TableSongs,
  TablePlaylists,
  VibeButton,
} from 'vibeguide';
import './PlaylistSelector.scss';

class PlaylistSelector extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedPlaylists: [],
    };
  }

  onAddToPlaylists = () => {
    const {
      songs,
      onAddToPlaylists,
    } = this.props;

    const {
      selectedPlaylists: playlists,
    } = this.state;

    onAddToPlaylists({
      songs,
      playlists,
    });
  };

  onChangeSelected = (selectedRows) => {
    this.setState({
      selectedPlaylists: selectedRows,
    });
  };

  render() {
    const {
      songs,
    } = this.props;

    const {
      selectedPlaylists,
    } = this.state;

    return (
      <div className="PlaylistSelector">
        <div className="song-playlist-songs">
          <TableSongs
            columns={[
              'Song Title',
              'Artist',
              'Album',
              'Genre',
              'ISRC',
              'ISWC',
              'Record Label',
              'Source',
              'Release Year',
              'Duration',
              'Tempo',
              'Ranking',
              'Rating',
              'Reason',
              'Playlists',
              'Modified',
              'Tags',
              '...',
            ]}
            collection={songs}
            defaultSortBy={{
              label: 'Song Title',
              attr: 'name',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Songs',
              urlPaging: false,
              urlFilters: false,
            }}
            toolbarProps={{
              customButtons: [
                (
                  <VibeButton
                    key="confirm-playlists"
                    text="Confirm Playlists"
                    color="primary"
                    disabled={selectedPlaylists.length <= 0}
                    onClick={this.onAddToPlaylists}
                  />
                ),
              ],
            }}
            menuItems={[
              { name: 'Archive', userCan: 'music.delete' },
            ]}
          />
        </div>

        <div className="song-playlist-content">
          <TablePlaylists
            columns={[
              '.',
              'Name',
              'Description',
              'Company',
              'Songs',
              'Duration',
              'Avg. Song',
              'Genre',
              'Tags',
              '...',
            ]}
            fetch={API.Playlist.list}
            defaultSortBy={{
              label: 'Name',
              attr: 'name',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Playlists',
              urlPaging: false,
              urlFilters: false,
            }}
            toggle
            toggleProps={{
              label: 'Show Archived Playlists',
              tooltip: true,
            }}
            bulk
            menuItems={[
              { name: 'Archive', userCan: 'playlist.delete' },
            ]}
            onChangeSelected={this.onChangeSelected}
          />
        </div>
      </div>
    );
  }
}

PlaylistSelector.propTypes = {
  songs: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    album: PropTypes.string,
    artist: PropTypes.string,
    artworkUrl: PropTypes.string,
    blocked: PropTypes.number,
    contentUrl: PropTypes.string,
    durationSeconds: PropTypes.number,
    genre: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    playlists: PropTypes.number,
    releaseYear: PropTypes.string,
  })).isRequired,
  onAddToPlaylists: PropTypes.func,
};

PlaylistSelector.defaultProps = {
  onAddToPlaylists: () => {},
};

export default PlaylistSelector;
