import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Field2 as Field,
} from 'vibeguide';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viAdd from '../../../icons/viAdd';
import viTrash from '../../../icons/viTrash';
import color from '../../../sass/color.scss';
import './Tracking.scss';

function Tracking({
  className,
  style,
  onUpdate,
  trackingUrls,
}) {
  const disableInput = false;

  const deleteTrackingURL = (index, name) => {
    if (trackingUrls.length <= 1) {
      console.warn('At least one string must be in the trackingUrls array');

      onUpdate({
        [name]: [''],
      });
      return;
    }

    onUpdate({
      [name]: trackingUrls.filter((trackingURL, i) => i !== parseInt(index, 10)),
    });
  };

  const onChangeField = (e) => {
    const {
      target: {
        name,
        value,
        dataset: {
          id,
        },
      },
    } = e;

    const updatedTrackingUrls = trackingUrls.map((trackingURL, i) => {
      if (i === parseInt(id, 10)) {
        return value;
      }
      return trackingURL;
    });

    onUpdate({
      [name]: updatedTrackingUrls,
    });
  };

  const addChild = (name) => {
    onUpdate({
      [name]: [...trackingUrls, ''],
    });
  };

  return (
    <div
      className={classNames('Tracking', className)}
      style={style}
    >
      <div className="tracking-container">
        {trackingUrls.map((trackingURL, index) => {
          return (
            <div className="tracking-row" key={index}>
              <Field
                type="text"
                label="URL"
                name="trackingUrls"
                placeholder="Tracking URL"
                value={trackingURL}
                tabIndex={1 + index}
                disabled={disableInput}
                onChange={onChangeField}
                dataId={index.toString()}
              />
              <VibeIcon
                className="tracking-row-trash"
                icon={viTrash}
                color={color.primary}
                hoverColor={color.obsidian}
                size={20}
                onClick={() => deleteTrackingURL(index, 'trackingUrls')}
              />
            </div>
          );
        },
        )}
      </div>
      <div
        className="add-child"
        onClick={() => addChild('trackingUrls')}
      >
        <VibeIcon
          className="add-child-icon"
          icon={viAdd}
          color={color.primary}
          size={16}
        />
        <div className="text">
          Add Third Party Tracking URL
        </div>
      </div>
    </div>
  );
}

Tracking.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onUpdate: PropTypes.func,
  trackingUrls: PropTypes.arrayOf(PropTypes.string),
};

Tracking.defaultProps = {
  className: '',
  style: {},
  onUpdate: () => {},
  trackingUrls: [],
};

export default Tracking;
