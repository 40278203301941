import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  CurrencyActions,
  Field2 as Field,
} from 'vibeguide';
import Grid from '@mui/material/Grid';

function CurrencySettings({
  className,
  style,
  currencyCode,
  disableInput,
  onUpdate,
  currencies,
  getCurrencies,
}) {
  const onChangeCurrency = (e) => {
    const {
      name,
      value,
    } = e.target;

    onUpdate({
      [name]: value,
    });
  };

  useEffect(() => {
    // fetch supported currencies
    getCurrencies();
  }, []);

  return (
    <Grid
      className={classNames('CurrencySettings', 'row', className)}
      style={style}
      spacing={2}
      container
    >
      <Grid xs={6} item>
        <Field
          type="select"
          label="Currency Default"
          name="currencyCode"
          value={currencyCode}
          options={[
            {
              label: '',
              value: '',
              placeholder: 'Choose a Currency...',
            },
            ...currencies.map(currency => {
              return {
                label: currency.label,
                value: currency.key,
              };
            }),
          ]}
          disabled={disableInput}
          onChange={onChangeCurrency}
          required
        />
      </Grid>
    </Grid>
  );
}

CurrencySettings.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  currencyCode: PropTypes.string,
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

CurrencySettings.defaultProps = {
  className: '',
  style: {},
  currencyCode: '',
  disableInput: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    currencies: state.currency.list,
  };
}

const mapDispatchToProps = {
  getCurrencies: CurrencyActions.getCurrencies,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySettings);
