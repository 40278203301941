import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  Link,
} from 'react-router-dom';
import {
  API,
  StorageUtil,
  LoginActions,
  GlobalActions,
  Authentication,
  VibeIcon,
  viIsmLogo,
  color,
  withRouter,
} from 'vibeguide';
import {
  Divider,
  Menu,
  MenuItem,
} from '@mui/material';
import UserSidebar from '../../Users/Sidebar/UserSidebar';
import UserCan from '../../Shared/UserCan';
import Notifications from '../Notifications';
import AppVersions from './AppVersions';
import defaultUserImage from '../../../assets/users.png';
import './Navigation.scss';

class Navigation extends PureComponent {
  constructor(props) {
    super(props);

    this.menuRef = React.createRef();

    // Current value for the users default page for locations
    const defaultPageLocations = this.getLocationDefaultPage();

    this.state = {
      profileDropdownShown: false,
      apiVersion: null,
      defaultPageLocations,
    };
  }

  componentDidUpdate() {
    const {
      location: {
        pathname,
      },
    } = this.props;

    if (pathname.indexOf('/locations') >= 0
      || pathname.indexOf('/dashboard') >= 0
    ) {
      const defaultPage = this.getLocationDefaultPage();
      let defaultPageLocations = null;

      // Update default page
      if (pathname.indexOf('/locations') >= 0 && defaultPage !== 'locations') {
        defaultPageLocations = 'locations';
      } else if (pathname.indexOf('/dashboard/support') >= 0 && defaultPage !== 'dashboard/support') {
        defaultPageLocations = 'dashboard/support';
      } else if (pathname.indexOf('/dashboard/alerts') >= 0 && defaultPage !== 'dashboard/alerts') {
        defaultPageLocations = 'dashboard/alerts';
      }

      if (defaultPageLocations !== null) {
        // Update default page
        StorageUtil.setLocal('locations:defaultPage', defaultPageLocations);

        this.setState({
          defaultPageLocations,
        });
      }
    }
  }

  handleProfileClose = () => {
    this.setState({
      profileDropdownShown: false,
    });
  };

  onClickUserAvatar = () => {
    const {
      user,
    } = this.props;

    this.setState({
      profileDropdownShown: true,
    });

    // get the API version if the user is an admin
    if (user.sysAdmin) {
      API.getVersion()
        .then((apiVersion) => {
          this.setState({
            apiVersion,
          });
        })
        .catch((err) => {
          console.error('Failed to fetch the API version', err);

          this.setState({
            apiVersion: 'Unknown',
          });
        });
    }
  };

  /**
   * Handle location default page changes
   */
  getLocationDefaultPage = () => {
    const {
      user,
    } = this.props;

    // Current value for the users default page for locations
    return user.sysAdmin
      ? StorageUtil.getLocal('locations:defaultPage', 'locations')
      : 'locations';
  };

  selectUserSettings = (settings) => {
    const {
      user,
      setPanel,
    } = this.props;

    switch (settings) {
      case 'Account Settings':
        setPanel({
          show: true,
          backdrop: true,
          width: 600,
          children: (
            <UserSidebar
              userId={user._id}
              accountType={user.accountType}
            />
          ),
        });

        this.setState({
          profileDropdownShown: false,
        });
        break;
      case 'Terms of Service':
      case 'Privacy Policy':
      default:
        this.setState({ profileDropdownShown: false });
        break;
    }
  };

  signUserOut = () => {
    const {
      history,
      doAuthenticateUser,
      doResetPasswordSuccess,
    } = this.props;

    this.setState({
      profileDropdownShown: false,
    });

    Authentication.logout();

    // User has logged out
    // Settings to save from localStorage
    const showAdLayer = StorageUtil.getLocal('locations:showAdLayer');
    const locationsScheduleDisplay = StorageUtil.getLocal('locations:scheduleDisplay');
    const baselineScheduleDisplay = StorageUtil.getLocal('baselines:scheduleDisplay');
    const quickLogins = StorageUtil.getLocal('login:quickLogins');

    // If cookies have been accepted, save and write to localStorage after clearing all
    const cookiesAccepted = StorageUtil.getLocal('cookiesAccepted', false);
    const cookiesAcceptedDate = StorageUtil.getLocal('cookiesAcceptedDate', false);

    // Clear localStorage
    StorageUtil.clearLocal();

    // Write cookie acceptance to localStorage
    if (cookiesAccepted) {
      StorageUtil.setLocal('cookiesAccepted', true);
      StorageUtil.setLocal('cookiesAcceptedDate', cookiesAcceptedDate);
    }

    if (showAdLayer === true || showAdLayer === false) {
      StorageUtil.setLocal('locations:showAdLayer', showAdLayer);
    }

    if (locationsScheduleDisplay) {
      StorageUtil.setLocal('locations:scheduleDisplay', locationsScheduleDisplay);
    }

    if (baselineScheduleDisplay) {
      StorageUtil.setLocal('baselines:scheduleDisplay', baselineScheduleDisplay);
    }

    if (quickLogins) {
      StorageUtil.setLocal('login:quickLogins', quickLogins);
    }

    doAuthenticateUser(false);
    doResetPasswordSuccess(false);

    history('/');
  };

  render() {
    const {
      location: {
        pathname = '',
      },
      user,
    } = this.props;

    const {
      profileDropdownShown,
      apiVersion,
      defaultPageLocations,
    } = this.state;

    const {
      menuRef: {
        current: menuRef,
      },
    } = this;

    const menuItems = [
      { name: 'Account Settings' },
      { name: 'divider' },
      { name: 'Privacy Policy', link: '/privacy-policy' },
      { name: 'Terms of Service', link: '/terms-of-service' },
      { name: 'divider' },
    ];

    const subPage = pathname.split('/')[1];

    return (
      <nav className="Navigation">
        <div className="nav-root nav-root-left">
          {!user.voiceTalent ? (
            <ul className="left-navigation-container">
              <li className="left-nav-item logo">
                <Link
                  className="no-style"
                  to="/"
                >
                  <VibeIcon
                    icon={viIsmLogo}
                    color={color.white}
                    size={48}
                  />
                </Link>
              </li>

              <UserCan can={['location.view']}>
                <li className="left-nav-item">
                  <Link
                    className={classNames({
                      active: subPage === 'locations'
                        || subPage === 'location'
                        || subPage === 'dashboard'
                        || subPage === 'alerts'
                        || subPage === 'integration',
                    })}
                    to={`/${defaultPageLocations}`}
                  >
                    Locations
                  </Link>
                </li>
              </UserCan>

              <UserCan anyOf={['baseline.view', 'event.view']}>
                <li className="left-nav-item">
                  <Link
                    className={classNames({ active: subPage === 'programs' })}
                    to="/programs"
                  >
                    Programs
                  </Link>
                </li>
              </UserCan>

              <UserCan anyOf={['message.view', 'advertisement.view', 'messagelist.view', 'voice.talent']}>
                <li className="left-nav-item">
                  <Link
                    className={classNames({ active: subPage === 'messages' })}
                    to="/messages"
                  >
                    Messages
                  </Link>
                </li>
              </UserCan>

              <UserCan anyOf={['music.view', 'playlist.view', 'mix.view']}>
                <li className="left-nav-item">
                  <Link
                    className={classNames({ active: subPage === 'music' })}
                    to="/music"
                  >
                    Music
                  </Link>
                </li>
              </UserCan>

              <UserCan
                anyOf={[
                  'company.view',
                  'company_category.view',
                  'company_banner.view',
                ]}
              >
                <li className="left-nav-item">
                  <Link
                    className={classNames({ active: subPage === 'companies' })}
                    to="/companies"
                  >
                    Companies
                  </Link>
                </li>
              </UserCan>

              <UserCan
                anyOf={[
                  'flight.view',
                  'advertiser.view',
                  'targeting_tag.view',
                  'device_attribute.view',
                  'screen_resolution.view',
                  'flight_delivery_system.view',
                ]}
              >
                <li className="left-nav-item">
                  <Link
                    className={classNames({ active: subPage === 'ad-ops' })}
                    to="/ad-ops"
                  >
                    Ad Ops
                  </Link>
                </li>
              </UserCan>

              <UserCan
                anyOf={[
                  'partner.view',
                ]}
              >
                <li className="left-nav-item">
                  <Link
                    className={classNames({ active: subPage === 'partners' })}
                    to="/partners"
                  >
                    Partners
                  </Link>
                </li>
              </UserCan>

              {/**
               * Restrict Reporting View to Partner Users
               */}
              {user.accountType === 'partner' && (
                <UserCan
                  anyOf={[
                    'partner.view_report',
                  ]}
                >
                  <li className="left-nav-item">
                    <Link
                      className={classNames({ active: subPage === 'reporting' })}
                      to="/reporting"
                    >
                      Reporting
                    </Link>
                  </li>
                </UserCan>
              )}

              <UserCan
                anyOf={[
                  'user.view',
                  'integration_user.view',
                  'partner_user.view',
                  'permissionset.view',
                ]}
              >
                <li className="left-nav-item">
                  <Link
                    className={classNames({ active: subPage === 'users' })}
                    to="/users"
                  >
                    Global Users
                  </Link>
                </li>
              </UserCan>
            </ul>
          ) : (
            // Only voice talent users see this navigation
            <ul className="left-navigation-container">
              <li className="left-nav-item logo">
                <Link
                  className="no-style"
                  to="/"
                >
                  <VibeIcon
                    icon={viIsmLogo}
                    color={color.white}
                    size={48}
                  />
                </Link>
              </li>
            </ul>
          )}
        </div>

        <div className="nav-root nav-root-right">
          <div className="right-navigation-container">
            <ul className="right-navigation-item-container">
              <li className="right-nav-item">
                <Notifications />
              </li>
              <li className="right-nav-item user-image dropdown-nav-item">
                <div
                  ref={this.menuRef}
                >
                  <div
                    className="profile-name-container"
                    onClick={this.onClickUserAvatar}
                    aria-owns={profileDropdownShown ? 'menu-list-grow' : null}
                    aria-haspopup="true"
                  >
                    {user.imageUrl ? (
                      <img src={user.imageUrl} className="user-icon" alt="profile" />
                    ) : (
                      <img src={defaultUserImage} className="user-icon" alt="profile" />
                    )}
                  </div>
                </div>

                <Menu
                  className="NavigationMenu"
                  classes={{
                    paper: 'NavigationMenuPaper',
                  }}
                  anchorEl={menuRef}
                  open={profileDropdownShown}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  autoFocus={false}
                  onClose={this.handleProfileClose}
                >
                  {menuItems.map((item, index) => {
                    if (item.name === 'divider') {
                      return (
                        <Divider key={index} />
                      );
                    }

                    const menuElem = item.link ? (
                      <Link to={item.link}>
                        {item.name}
                      </Link>
                    ) : item.name;

                    return (
                      <MenuItem
                        key={item.name}
                        disabled={item.disabled}
                        onClick={() => this.selectUserSettings(item.name)}
                      >
                        {menuElem}
                      </MenuItem>
                    );
                  })}

                  <MenuItem
                    onClick={this.signUserOut}
                  >
                    Log Out
                  </MenuItem>

                  {user.sysAdmin && (
                    <AppVersions
                      webVersion={process.env.VERSION}
                      apiVersion={apiVersion}
                    />
                  )}
                </Menu>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  doAuthenticateUser: LoginActions.doAuthenticateUser,
  doResetPasswordSuccess: LoginActions.doResetPasswordSuccess,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
