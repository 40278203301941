import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  getLocationsData,
} from '../../helpers/ExpandedLocations';
import {
  events as searchByEvents,
} from '../../helpers/SearchBy';
import {
  events as sortByEvents,
} from '../../helpers/SortBy';
import API from '../../api';
import PageLayout from '../PageLayout/PageLayout';
import Paginator from '../Paginator/Paginator';
import Cards from '../Cards/Cards';
import EventCard from '../EventCard/EventCard';
import TableBaselines from '../VibeTable/Tables/TableBaselines';
import TableMessages from '../VibeTable/Tables/TableMessages';
import TableMessageBlocks from '../VibeTable/Tables/TableMessageBlocks';
import TablePlaylists from '../VibeTable/Tables/TablePlaylists';
import TableStations from '../VibeTable/Tables/TableStations';
import TableUsers from '../VibeTable/Tables/TableUsers';
import VibeModal from '../VibeModal/VibeModal';
import './AssignObject.scss';

class AssignObject extends PureComponent {
  constructor(props) {
    super(props);

    this._scrollRef = null;

    this.state = {
      assignObject: {},
      // collection of filtered items from the assign by object
      collection: [],
      // collection type to not render data of one type in another container
      collectionType: '',
      totalItems: 0,
      showAssignObjectLocationsConfirm: false,
    };
  }

  /**
   * When an object is selected to copy the locations
   */
  onAssignObjectLocations = (data) => {
    const {
      type,
    } = this.props;

    // combine users first/last name (name is used for every other object type)
    if (type === 'user') {
      data.name = `${data.fname} ${data.lname}`;
    }

    this.setState({
      assignObject: data,
      showAssignObjectLocationsConfirm: true,
    });
  };

  /**
   * When a row is clicked in the table when assigning locations by object
   */
  onClickRow = (data) => {
    this.onAssignObjectLocations(data);
  };

  /**
   * When the user confirms copying the objects locations
   */
  onConfirmAssignObjectLocations = async () => {
    const {
      type,
      onAssignObjectLocations,
    } = this.props;

    const {
      assignObject: {
        _id,
        locations,
      },
    } = this.state;

    let expanded;

    switch (type) {
      case 'baseline': {
        expanded = await API.Baseline.getExpandedLocations(_id);
        break;
      }

      case 'message': {
        expanded = await API.Message.getExpandedLocations(_id);
        break;
      }

      case 'event': {
        expanded = await API.Event.getExpandedLocations(_id);
        break;
      }

      case 'message block': {
        expanded = await API.MessageBlock.getExpandedLocations(_id);
        break;
      }

      case 'playlist': {
        expanded = await API.Playlist.getExpandedLocations(_id);
        break;
      }

      case 'station': {
        expanded = await API.Mix.getExpandedLocations(_id);
        break;
      }

      case 'user': {
        expanded = await API.User.getExpandedLocations(_id);
        break;
      }

      // unknown object
      default:
        break;
    }

    const locationsData = getLocationsData(expanded);

    // only get the item if it's a message and no locations were found
    const item = type === 'message' && locations.length <= 0
      // get message locations (not included in the list messages endpoint)
      ? await API.Message.getById(_id)
      // use locations found in the object from the list endpoint
      : {};

    const useLocations = item.locations || locations;

    // Send the expanded locations back to the component being altered
    onAssignObjectLocations({
      locations: useLocations,
      locationsData,
    });

    this.setState({
      assignObject: {},
      showAssignObjectLocationsConfirm: false,
    });
  };

  /**
   * When the user closes the copy locations confirmation modal
   */
  onCloseAssignObjectLocations = () => {
    this.setState({
      assignObject: {},
      showAssignObjectLocationsConfirm: false,
    });
  };

  onFilterCards = (data) => {
    const {
      type,
    } = this.props;

    const {
      collection,
      totalItems,
    } = data;

    this.setState({
      collection,
      collectionType: type,
      totalItems,
    });
  };

  /**
   * Render the selection column with the data of the selected object assignment
   */
  renderObjectAssignment = () => {
    const {
      companyId,
      type,
    } = this.props;

    const {
      collection,
      collectionType,
      totalItems,
    } = this.state;

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    // Only search for objects that match the company of the main object being altered
    const filterSearch = companyId === adminCompanyId
      // main object is assigned to the admin company
      ? {}
      // restrict object search to the company of the main object
      : { companyId };

    switch (type) {
      case 'baseline':
        return (
          <div className="assign-object-container">
            <TableBaselines
              columns={[
                'Name',
                'Company',
                'Description',
                'Locations',
                'Modified',
                'Tags',
                '...',
              ]}
              fetch={API.Baseline.list}
              filters={filterSearch}
              defaultSortBy={{
                label: 'Name',
                attr: 'name',
                direction: 'asc',
              }}
              paginator
              paginatorProps={{
                label: 'Baselines',
                urlPaging: false,
                urlFilters: false,
              }}
              toggle
              toggleProps={{
                label: 'Show Archived Baselines',
                tooltip: true,
              }}
              bulk
              bulkProps={{
                edit: false,
                add: false,
                block: false,
                remove: false,
                archive: true,
              }}
              menuItems={[
                { name: 'Archive', userCan: 'baseline.delete' },
              ]}
              onClickRow={this.onClickRow}
            />
          </div>
        );

      case 'message':
        return (
          <div className="assign-object-container">
            <TableMessages
              columns={[
                'Message Name',
                'Company',
                'Last Updated',
                'Requester',
                'Start Date',
                'End Date',
                'Duration',
                'Script',
                'Assigned Voice Talent',
                'Tags',
                '...',
              ]}
              fetch={API.Message.list}
              filters={filterSearch}
              defaultSortBy={{
                label: 'Last Updated',
                attr: 'modifiedDate',
                direction: 'desc',
              }}
              paginator
              paginatorProps={{
                label: 'Messages',
                urlPaging: false,
                urlFilters: false,
              }}
              toggle
              toggleProps={{
                label: 'Show Archived Messages',
                tooltip: true,
              }}
              bulk
              bulkProps={{
                edit: true,
                add: false,
                block: false,
                remove: false,
                archive: true,
              }}
              menuItems={(row) => {
                const isAd = row.messageType === 'ad'
                  || row.messageType === 'ext-ad';

                return [{
                  name: 'Archive',
                  userCan: isAd
                    ? 'advertisement.delete'
                    : 'message.delete',
                }];
              }}
              onClickRow={this.onClickRow}
            />
          </div>
        );

      case 'event':
        return (
          <div className="assign-object-container">
            <PageLayout
              sortOptions={sortByEvents}
              searchOptions={searchByEvents}
              scrollRef={this._scrollRef}
              toggleLabel="Show Archived Events"
              filter={API.Event.list}
              filterSearch={filterSearch}
              onFilter={this.onFilterCards}
              disableView
              showToggle
            />

            <div className="object-content-root">
              <PerfectScrollbar
                containerRef={(ref) => { this._scrollRef = ref; }}
              >
                <div className="object-content">
                  <Paginator
                    className="object-paginator"
                    totalLoaded={collection.length}
                    totalItems={totalItems}
                    label="Events"
                  />

                  <Cards>
                    {collectionType === type && collection.map((event) => (
                      <EventCard
                        key={event._id}
                        event={event}
                        allowClick
                        onClick={this.onAssignObjectLocations}
                      />
                    ))}
                  </Cards>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        );

      case 'message block':
        return (
          <div className="assign-object-container">
            <TableMessageBlocks
              columns={[
                'Name',
                'Company',
                'Integration',
                'Frequency',
                'Consecutive',
                'Start Type',
                'Sequence',
                'Locations',
                'Tags',
                '...',
              ]}
              fetch={API.MessageBlock.list}
              filters={filterSearch}
              defaultSortBy={{
                label: 'Name',
                attr: 'name',
                direction: 'asc',
              }}
              paginator
              paginatorProps={{
                label: 'Message Blocks',
                urlPaging: false,
                urlFilters: false,
              }}
              toggle
              toggleProps={{
                label: 'Show Archived Message Blocks',
                tooltip: true,
              }}
              bulk
              bulkProps={{
                edit: false,
                add: false,
                block: false,
                remove: false,
                archive: true,
              }}
              cardProps={{
                requireFilter: false,
                filters: filterSearch,
              }}
              menuItems={[
                { name: 'Archive', userCan: 'messagelist.delete' },
              ]}
              onClickRow={this.onClickRow}
            />
          </div>
        );

      case 'playlist':
        return (
          <div className="assign-object-container">
            <TablePlaylists
              columns={[
                'Name',
                'Description',
                'Company',
                'Songs',
                'Duration',
                'Avg. Song',
                'Genre',
                'Tags',
                '...',
              ]}
              fetch={API.Playlist.list}
              filters={filterSearch}
              defaultSortBy={{
                label: 'Name',
                attr: 'name',
                direction: 'asc',
              }}
              paginator
              paginatorProps={{
                label: 'Playlists',
                urlPaging: false,
                urlFilters: false,
              }}
              toggle
              toggleProps={{
                label: 'Show Archived Playlists',
                tooltip: true,
              }}
              bulk
              bulkProps={{
                edit: false,
                add: false,
                block: false,
                remove: false,
                archive: true,
              }}
              menuItems={[
                { name: 'Archive', userCan: 'playlist.delete' },
              ]}
              onClickRow={this.onClickRow}
            />
          </div>
        );

      case 'station':
        return (
          <div className="assign-object-container">
            <TableStations
              columns={[
                'Name',
                'Description',
                'Company',
                'Duration',
                'Playlists',
                'Modified',
                'Tags',
                '...',
              ]}
              fetch={API.Mix.list}
              filters={filterSearch}
              defaultSortBy={{
                label: 'Name',
                attr: 'name',
                direction: 'asc',
              }}
              paginator
              paginatorProps={{
                label: 'Stations',
                urlPaging: false,
                urlFilters: false,
              }}
              toggle
              toggleProps={{
                label: 'Show Archived Stations',
                tooltip: true,
              }}
              bulk
              bulkProps={{
                edit: false,
                add: false,
                block: false,
                remove: false,
                archive: true,
              }}
              menuItems={[
                { name: 'Archive', userCan: 'mix.delete' },
              ]}
              onClickRow={this.onClickRow}
            />
          </div>
        );

      case 'user':
        return (
          <div className="assign-object-container">
            <TableUsers
              columns={[
                '.',
                'First Name',
                'Last Name',
                'Title',
                'Email',
                'Phone #',
                'Company',
                'Tags',
                '...',
              ]}
              fetch={API.User.list}
              filters={filterSearch}
              defaultSortBy={{
                label: 'First Name',
                attr: 'fname',
                direction: 'asc',
              }}
              paginator
              paginatorProps={{
                label: 'Users',
                urlPaging: false,
                urlFilters: false,
              }}
              toggle
              toggleProps={{
                label: 'Show Archived Users',
                tooltip: true,
              }}
              bulk
              bulkProps={{
                edit: false,
                add: false,
                block: false,
                remove: false,
                archive: true,
              }}
              menuItems={[
                { name: 'Archive', userCan: 'user.delete' },
              ]}
              onClickRow={this.onClickRow}
            />
          </div>
        );

      // Unknown object to assign
      default:
        return (
          <div className="assign-object-container unknown">
            Unknown Object ({type})
          </div>
        );
    }
  };

  render() {
    const {
      className,
      type,
    } = this.props;

    const {
      assignObject,
      showAssignObjectLocationsConfirm,
    } = this.state;

    return (
      <div className={classNames('AssignObject', className)}>
        {this.renderObjectAssignment()}

        <VibeModal
          show={showAssignObjectLocationsConfirm}
          type="confirm"
          confirmProps={{
            text: 'Yes, Assign Locations',
            color: 'success',
          }}
          cancelProps={{
            text: 'No Thanks',
          }}
          title={`Assign all locations from ${assignObject.name}?`}
          text={(
            <div>
              This will add all locations already assigned to this {type}.
              <br />
              This cannot be undone without manually removing the locations.
            </div>
          )}
          onConfirm={this.onConfirmAssignObjectLocations}
          onClose={this.onCloseAssignObjectLocations}
        />
      </div>
    );
  }
}

AssignObject.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  onAssignObjectLocations: PropTypes.func,
};

AssignObject.defaultProps = {
  className: '',
  onAssignObjectLocations: () => {},
};

export default AssignObject;
