import React from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  Field2 as Field,
} from 'vibeguide';
import { Grid } from '@mui/material';
// import './ProfileDisplayInformation.scss';

function ProfileDisplayInformation({
  className,
  screenSize,
  orientation,
  resolution,
  canEdit,
  isAdmin,
  onUpdate,
}) {
  const canAdminEdit = isAdmin && canEdit;

  /**
   * When a field is changed
   */
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * Update Location when dropdown changes (either via typing or selecting an item)
   */
  const onDropdownChange = (e) => {
    const {
      name,
      value,
      data,
      id,
      selected,
    } = e;

    const dataSize = data !== undefined ? data.size : undefined;

    let returnData = {};

    if (data && data.custom) {
      returnData = {
        value: `Custom (${resolution.size[0]} x ${resolution.size[1]})`,
        size: resolution.size,
        custom: true,
        selected,
      };
    } else {
      // this runs when value in search box changes, so preserve custom item size data here
      const saveCustomSizeData = resolution.custom && dataSize === undefined ? {
        id,
        value,
        size: resolution.size,
        custom: true,
        selected,
      } : {
        ...data,
        selected,
      };
      // if incoming data size is empty and a current custom item doesn't exist, set size [0,0].
      returnData = dataSize === undefined && !resolution.custom ? {
        id,
        value,
        size: [0, 0],
        custom: false,
        selected,
      } : saveCustomSizeData;
    }

    onUpdate({
      [name]: returnData,
    });
  };

  /**
   * Prevent default select event
   */
  const clickDisplayInput = (e) => {
    e.stopPropagation();
  };

  /**
   * Update custom resolution item width
   */
  const onChangeDisplayWidth = (e) => {
    const {
      target: {
        value = '',
      },
    } = e;

    onUpdate({
      resolution: {
        value: `Custom (${value} x ${resolution.size[1] || 0})`,
        size: [value, resolution.size[1] || 0],
        custom: true,
      },
    });
  };

  /**
   * Update custom resolution item height
   */
  const onChangeDisplayHeight = (e) => {
    const {
      target: {
        value = '',
      },
    } = e;

    onUpdate({
      resolution: {
        value: `Custom (${resolution.size[0] || 0} x ${value})`,
        size: [resolution.size[0] || 0, value],
        custom: true,
      },
    });
  };

  /**
   * Check if blur should be blocked when interacting with custom item fields
   */
  const checkBlockBlur = (e) => {
    let isDisplayInput = false;
    if (e.relatedTarget !== null) {
      isDisplayInput = e.relatedTarget.classList.contains('display-input-width')
        || e.relatedTarget.classList.contains('display-input-height');
    }

    if (isDisplayInput) {
      return true;
    }
    return false;
  };

  return (
    <div className={classNames('ProfileDisplayInformation', className)}>
      <Grid spacing={2} container>
        <Grid xs={12} lg={6} item>
          <Field
            type="text"
            label="Screen Size"
            name="screenSize"
            placeholder="Screen Size"
            innerLabelProps={{
              text: 'inches',
            }}
            value={screenSize}
            onChange={onChange}
            disabled={!canAdminEdit}
            required
          />
        </Grid>

        <Grid xs={12} lg={6} item>
          <Field
            type="select"
            label="Screen Orientation"
            name="orientation"
            value={orientation}
            options={[
              {
                label: '',
                value: '',
              },
              {
                label: 'Landscape',
                value: 'landscape',
              },
              {
                label: 'Portrait',
                value: 'portrait',
              },
            ]}
            tabIndex={12}
            disabled={!canAdminEdit}
            required
            onChange={onChange}
          />
        </Grid>

        <Grid xs={12} lg={12} item>
          <Field
            type="dropdown"
            label="Resolution"
            name="resolution"
            value={resolution.value}
            tabIndex={10}
            dropdownProps={{
              type: 'screen resolution',
              attr: 'value',
              fetch: API.ScreenResolution.list,
              canCreateCustomItem: true,
              blockBlur: checkBlockBlur,
              customHTML: (
                <div className="custom-content">
                  <div className="text">
                    Custom
                  </div>
                  <div className="display-custom-inputs">
                    <div className="display-custom-width">
                      <input
                        type="text"
                        value={resolution.size ? resolution.size[0] : 0}
                        className="display-input-width"
                        onClick={clickDisplayInput}
                        onChange={onChangeDisplayWidth}
                      />
                      <span className="display-input-placeholder">W</span>
                    </div>
                    <div className="display-custom-height">
                      <input
                        type="text"
                        value={resolution.size ? resolution.size[1] : 0}
                        className="display-input-height"
                        onClick={clickDisplayInput}
                        onChange={onChangeDisplayHeight}
                      />
                      <span className="display-input-placeholder">H</span>
                    </div>
                  </div>
                </div>
              ),
              customItem: resolution.custom ? {
                active: true,
                name: 'Custom',
                size: resolution.size,
                length: 2,
                value: resolution.value,
                custom: true,
              } : {
                active: true,
                name: 'Custom',
                size: [0, 0],
                length: 2,
                value: 'Custom (0 x 0)',
                custom: true,
              },
            }}
            disabled={!canAdminEdit}
            required
            onChange={onDropdownChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}

ProfileDisplayInformation.propTypes = {
  screenSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  orientation: PropTypes.string,
  resolution: PropTypes.object,
  onUpdate: PropTypes.func,
};

ProfileDisplayInformation.defaultProps = {
  screenSize: 0,
  orientation: '',
  resolution: {},
  onUpdate: () => {},
};

export default ProfileDisplayInformation;
